import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { FilterRow, TableContainer } from "../../Utils/FilterRow";
import { ProgressMovingBar } from "../../Utils/ProgressMovingBar";
import { formatNumber, capitalize } from "../../Utils";
import Pagination from "../../Utils/Pagination";
import Loader from "../../Loader";
import { config } from "../../../config/config";
import ContextWrapper, { Context } from "../../Utils/ContextWrapper";
import CopyTool from "../../Utils/CopyTool";
import { BsBoxArrowLeft, BsBoxArrowInRight } from "react-icons/bs";
import ExternalLinkBtn from "../../Utils/ExternalLinkBtn";
import DetailsModal from "./Modals/DetailsModal";


// Specific to this page
const QUERY_INDEX = "HistoryTransfer";
const URL = `${config.apiurl}/transfer/list`;
// const { export_data } = useExportData();
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;
  io?: string;
  account?: string;
  type?: string;
  status?: string;
  limit: number;
  house: boolean;
  sortOrder: string;
};
const initialFetch = {
  sortOrder: "desc",
  type: "deposit,withdraw",
  limit: 10,
  pageNum: 1,
  house: false,
};

// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const getTransferStatus = (status : String, financeStatus: string,  data: any)=> {
    switch (true) {
      case status === "Rejected" || financeStatus === "Rejected":
        return {
          status_clr: "clr-deny",
          status_text: "Rejected",
        };
      case status === "Approved":
        return {
          status_clr: "clr-approve",
          status_text: "Approved",
        };
      case status === 'Pending' && financeStatus === 'Approved':
        return {
          status_clr: "clr-pending",
          status_text: "Pending",
        };
      case financeStatus === 'Pending':
        return {
          status_clr: "clr-pending",
          status_text: "Pending",
        };
      default:
        return {
          status_clr: "clr-grey",
          status_text: "Other",
        };
    }
  }
  useEffect(() => {
    if (listData?.data) {
      setCurrentPage(listData?.data);
      setInitialized(true);
    }
  }, [listData?.data]);

  return (
    <>
      {/* {JSON.stringify(listData)} */}
      <div className="">
        <div>
          <div>
            <div>
              <div className="">
                <div className="border_box" style={{}}>
                  <FilterRow
                    export_data={export_data}
                    searchParams={fetchParams}
                    startDate={{
                      value: fetchParams.startDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          startDate: val,
                        });
                      },
                    }}
                    endDate={{
                      value: fetchParams.endDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          endDate: val,
                        });
                      },
                    }}
                    limit={{
                      value: fetchParams.limit,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          limit: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    asset={{
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          asset: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    clearFilter={{
                      set: (val: any) => {
                      setFetchParams(
                          initialFetch
                      )}
                  }}
                    // transaction={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       asset: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // exportCsv={{}}
                    // exportPdf={{}}
                    // source={{
                    //   set: (val: any) => {
                    //     setFetchBalanceMetalParams({
                    //       ...fetchBalanceMetalParams,
                    //       source: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // location={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       country: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                  ></FilterRow>

                  <div className="table-responsive">
                    <Loader visible={listIsLoading}></Loader>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="main-table transfer_info_container"
                    >
                      {/* <colgroup>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col style={{ width: "10px" }}></col>
                        <col></col>
                        <col></col>
                      </colgroup> */}
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Asset</th>
                          <th>Amount</th>
                          <th>Destination</th>
                          <th>TXID</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!initialized && listIsLoading ? (
                          <tr>
                            <td colSpan={8}>
                              {/* <ProgressMovingBar></ProgressMovingBar> */}
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPage?.length === 0 && (
                              <tr>
                                <td className="clr-pending" colSpan={9}>
                                  No rows found. Try clearing your filters.
                                </td>
                              </tr>
                            )}
                            {currentPage?.map((data: any, index: any) => {
                              const {
                                timestamp,
                                receiveAmount,
                                type,
                                name,
                                status,
                                financeStatus,
                                fromAddress,
                                toAddress,
                                asset,
                                txId,
                              } = data;
                              let address = fromAddress || toAddress;
                              let icon:any;
                              if (type === "deposit") {
                                icon = <BsBoxArrowInRight className="clr-grey"/>;
                              } else {
                                icon = <BsBoxArrowLeft className="clr-grey"/>;
                              }
                              let status_info = getTransferStatus(status, financeStatus, data);
                              let status_clr = status_info?.status_clr;
                              let status_text = status_info?.status_text;
                              return (
                                <tr key={index} className="transfer_row">
                                  <td className="transfer_row_item">
                                    <span className="transfer_time">
                                      {moment(timestamp).format(
                                        "YYYY/MM/DD hh:mm:ss")}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_currency">
                                      {asset}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_amount">
                                      {formatNumber(receiveAmount)}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    {address && <CopyTool text={address} digit={6}/>}
                                  </td>
                                  <td className="transfer_row_item">
                                    {txId && (
                                      <div className="tool_chain">
                                        <CopyTool text={txId} digit={6}/>
                                        <ExternalLinkBtn text={txId} currency={asset}/>
                                      </div>
                                      )}
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_side row-ele-center">
                                      {icon}&nbsp;&nbsp;
                                      {capitalize(type)}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className={`transfer_status ${status_clr}`}>
                                      {status_text}
                                    </span> 
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className={`transfer_status ${status_clr}`}>
                                      <DetailsModal data={data}/>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="custom_table">
                    <TableContainer isLoading={initialized && listIsLoading}>
                      <table className="table"></table>

                      {count !== 0 && (
                        <div className={"col-md-11"}>
                          <Pagination
                            onChange={(page: any) => {
                              setPageNum(page);
                            }}
                            count={fetchParams.limit}
                            totalCount={totalCount}
                          ></Pagination>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
