import React, { useContext } from "react";
import { AdminContext } from "./AdminContextWrapper";
import AdminSetting from "./AdminSetting";


const Index = () => {
  return (
    <>
      <div
        className="tab-pane"
        id="admin-setting"
        role="tabpanel"
        aria-labelledby="admin-setting-tab"
      >
        <AdminSetting/>
      </div>
    </>
  );
};
export default Index;
