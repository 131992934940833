import React from "react";
import ManageBankAccount from "./ManageBankAccount/index";

const NavTabs = () => {
  return (
    <>
      <div
        className="tab-pane"
        id="profile-bank-account"
        role="tabpanel"
        aria-labelledby="profile-bank-account-tab"
      >
        <ManageBankAccount />
      </div>
    </>
  );
};
export default NavTabs;