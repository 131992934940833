/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import awsconfig from '../../config/aws-exports';
import Logo from '../../images/otc-logo.svg';
import UserIcon from '../../images/user-icon.svg';
import { useAuth } from '../../auth-context';
import awsexports from '../../../src/config/aws-exports';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const Header = () => {
  const { userInfo, isAuthenticated }: any = useAuth();
  const [isloggedinNav, setIsLoggedinNav] = useState(false);

  let location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    window.onscroll = function () {
      myFunction();
    };

    var header: any = document.getElementById('fixed_header');
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset - 200 > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    }
  }, []);

  useEffect(() => {
    const clientID = awsexports.aws_user_pools_web_client_id;
    const cognigto = 'CognitoIdentityServiceProvider';
    const target = 'LastAuthUser';
    const getLastAuthUser = localStorage.getItem(
      `${cognigto}.${clientID}.${target}`
    );
    if (getLastAuthUser) {
      setIsLoggedinNav(true);
    } else {
      setIsLoggedinNav(false);
    }
  }, [isAuthenticated, userInfo]);

  return (
    <>
      <header className="header inner-header" id="fixed_header">
        <div className="container_header">
          <div className="row">
            <nav className="navbar navbar-expand-lg">
              <Link className="navbar-brand" to="/">
                <img src={Logo} alt="" />
              </Link>

              {isloggedinNav && (
                <>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon">
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            pathname === '/market' ? 'active' : ' '
                          }`}
                          to="/market"
                        >
                          Market
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            pathname === '/balance' ? 'active' : ' '
                          }`}
                          to="/balance"
                        >
                          Wallet
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            pathname === '/history' ? 'active' : ' '
                          }`}
                          to="/history"
                        >
                          History
                        </Link>
                      </li>
                      {userInfo?.roles?.includes('Finance') && (
                        <li id="finance" className="nav-item">
                          <Link
                            className={`nav-link ${
                              pathname === '/finance' ? 'active' : ' '
                            }`}
                            to="/finance"
                          >
                            Finance
                          </Link>
                        </li>
                      )}
                      {userInfo?.roles?.includes('Administrator') && (
                        <li id="admin" className="nav-item">
                          <Link
                            className={`nav-link ${
                              pathname === '/admin' ? 'active' : ' '
                            }`}
                            to="/admin"
                          >
                            Admin
                          </Link>
                        </li>
                      )}
                      {/* <!-- mobile view nav--> */}
                      <li className="nav-item mobile-nav-item">
                        <Link className="nav-link" to="/profile">
                          My Profile
                        </Link>
                      </li>
                      <li className="nav-item mobile-nav-item">
                        <AmplifySignOut />
                        {/* <a className="nav-link" href="index.html">
                        Logout
                      </a> */}
                      </li>
                      {/* <!-- mobile view nav--> */}
                    </ul>
                  </div>
                </>
              )}

              {isloggedinNav && (
                <div className="profile-dropdown dropdown">
                  <a
                    className=""
                    id="profile_dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={UserIcon}
                      alt=""
                      className="header_profile_image"
                    />
                    {userInfo?.name}
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="profile_dropdown"
                  >
                    <li className="drodown-item">
                      <Link to={'/profile'}>
                        <i className="fa fa-user" aria-hidden="true"></i>My
                        Profile
                      </Link>
                    </li>
                    <li>
                      <AmplifySignOut />
                      {/* <a
                        onClick={(event) => {
                          event.preventDefault();
                          authContext.signOut();
                        }}
                        href="index.html"
                      >
                        Logout
                      </a> */}
                    </li>
                  </ul>
                </div>
              )}

              {!isloggedinNav && (
                <ul className="navbar-nav ml-md-auto">
                  <li className="nav-item">
                    <Link
                      to="/login"
                      onClick={() => {}}
                      className="nav-link register-btn"
                      href="#"
                    >
                      Connect
                    </Link>
                  </li>
                </ul>
              )}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
