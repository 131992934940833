import moment from "moment";
import React, { useContext } from "react";
import { CSVLink } from "react-csv";
import { AdminContext } from "./AdminContextWrapper";
import DatePicker from "react-datepicker";
import Loader from "../Loader";
import AdminTransfer from "./AdminTransfer";

const Index = () => {
  const { Transferlist } = useContext(AdminContext);
  var TransferTemp1: any = [];
  if (Transferlist) {
    TransferTemp1 = Object.keys(Transferlist).map(function (key: any) {
      return <tr key={key}></tr>;
    }, []);
  }
  return (
    <>
      <div
        className="tab-pane"
        id="admin-transfer"
        role="tabpanel"
        aria-labelledby="admin-transfer-tab"
      >
        <AdminTransfer></AdminTransfer>
      </div>
    </>
  );
};
export default Index;

const ActionButtons = () => {
  const { csvData } = useContext(AdminContext);
  return (
    <>
      <div className="filter-btnsarea">
        {csvData && (
          <a href="#" className="main-small-btn">
            <CSVLink
              data={csvData}
              className="grad_btn"
              style={{ marginRight: "10px", color: "#fff" }}
            >
              Export
            </CSVLink>
          </a>
        )}

        <a
          data-toggle="modal"
          data-target="#admin_transfer_add"
          href="#"
          className="main-small-btn"
        >
          Add
        </a>
      </div>
    </>
  );
};

const ToDatePicker = () => {
  const { enddate_trans1, handleChanges1, startdate_trans1 } =
    useContext(AdminContext);
  return (
    <>
      <div style={{ width: "167px" }} className="select-outer input-outer">
        <DatePicker
          placeholder="From"
          className="form-control datepicker from-date"
          // selected={startDate}
          dataFormat="mm/dd/yyyy"
          // onChange={(date: any) => setStartDate(date)}

          selected={enddate_trans1}
          onChange={handleChanges1}
          value={enddate_trans1}
          minDate={startdate_trans1}
          relativeSize={true}
          autoComplete="off"
          onKeyDown={(e: { preventDefault: () => any }) => e.preventDefault()}
          showYearDropdown
          placeholderText="To"
          dateFormat="yyyy-MM-dd"
        />
        <i className="fa fa-calendar" aria-hidden="true"></i>
      </div>
    </>
  );
};

const FromDatePicker = () => {
  const { handleChanges, startdate_trans1 } = useContext(AdminContext);
  return (
    <>
      <div style={{ width: "167px" }} className="select-outer input-outer">
        <DatePicker
          selected={startdate_trans1}
          onChange={handleChanges}
          value={startdate_trans1}
          placeholder="From"
          className="form-control datepicker from-date"
          // selected={startDate}
          dataFormat="mm/dd/yyyy"
          // onChange={(date: any) => setStartDate(date)}

          maxDate={new Date()}
          relativeSize={true}
          autoComplete="off"
          onKeyDown={(e: { preventDefault: () => any }) => e.preventDefault()}
          showYearDropdown
          placeholderText="From"
          dateFormat="yyyy-MM-dd"
        />
        <i className="fa fa-calendar" aria-hidden="true"></i>
      </div>
    </>
  );
};

const SelectLimitDropdown = () => {
  const { handleChangelimit } = useContext(AdminContext);
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => handleChangelimit(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option value="20">Select Limit</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
          <option value="3000">3000</option>
        </select>
      </div>
    </>
  );
};

const SideDropdown = () => {
  const { handleChangeside } = useContext(AdminContext);
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => handleChangeside(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option value="deposit,withdraw">Select Side</option>
          <option value="deposit">Deposit</option>
          <option value="withdraw">Withdraw</option>
        </select>
      </div>
    </>
  );
};

const Action = ({ keyA: key }: { keyA: string }) => {
  return <></>;
};
