import React from "react";
import useBalance from "./useBalance";
type Props = {};
export const BalanceContext = React.createContext<any>({});

const BalanceContextWrapper: React.FC<Props> = ({ children }) => {
  const {
    accountInfo,
    deposit_req,
    depo_paramErr,
    depo_param,
    allowNumbersOnly,
    userInfo,
    bankdata,
    deposit_req1,
    addressOnly,
    deposite_address,
    AssetName,
    withdraw_request,
    bankdetails,
    Err_type,
    Err_amt,
    handleChangeWithdraw_amt,
    availableamt,
    openModaldepoc,
    closeModaldepoc,
    CurrencyBal,
    openModalwith,
    closeModalwith,
    csvData,
    CurrencyType,
  } = useBalance();
  return (
      <BalanceContext.Provider
        value={{
          CurrencyType,
          accountInfo,
          deposit_req,
          depo_paramErr,
          depo_param,
          allowNumbersOnly,
          userInfo,
          bankdata,
          deposit_req1,
          addressOnly,
          deposite_address,
          AssetName,
          withdraw_request,
          bankdetails,
          Err_type,
          Err_amt,
          handleChangeWithdraw_amt,
          availableamt,
          openModaldepoc,
          closeModaldepoc,
          CurrencyBal,
          openModalwith,
          closeModalwith,
          csvData,
        }}
      >
        {children}
      </BalanceContext.Provider>
  );
};
export default BalanceContextWrapper;
