import React, { useEffect, useState } from 'react';
import { MdContentCopy, MdDone } from 'react-icons/md';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function CopyTool({ text, digit }: any) {
  const [copyText, setCopyText] = useState('');
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    setCopyText(text);
  }, [text]);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <>
      <div className="tool-container">
        <OverlayTrigger
          key={`txid-${text}`}
          placement="right"
          overlay={<Tooltip id={`txid-${text}`}>{text}</Tooltip>}
        >
          <span id={text} className="copyId">
            {digit ? `${copyText.slice(0, digit)}...` : copyText}
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          key={`txid-btn-${text}`}
          placement="right"
          overlay={
            <Tooltip id={`exterLink-${text}`}>
              {copied ? 'Copied' : 'Click to copy'}
            </Tooltip>
          }
        >
          <CopyToClipboard text={copyText} onCopy={() => setCopied(true)}>
            <button className="tool-btn copyBtn" id="copy">
              {copied ? (
                <MdDone className="toolIcon" />
              ) : (
                <MdContentCopy className="toolIcon" />
              )}
            </button>
          </CopyToClipboard>
        </OverlayTrigger>
      </div>
    </>
  );
}
