import moment from "moment";
import React, { useContext } from "react";
import { CSVLink } from "react-csv";
import { AdminContext } from "./AdminContextWrapper";
import DatePicker from "react-datepicker";
import Loader from "../Loader";
import AdminTrade from "./AdminTrade";

const Index = () => {
  const {
    csvData,
    TradeList,
    handleChangesideTrade,
    handleChangelimitTrade,
    handleChangeProd1,
    ProdOptions,
    handleChangeAccount,
    getTradeList,
    handleChangesTrade,
    handleChangesTrade1,
    enddate1,
    startdate1,
  } = useContext(AdminContext);
  var TradeTemp: any = [];
  if (TradeList) {
    TradeTemp = Object.keys(TradeList).map(function (key: any) {
      return <tr></tr>;
    }, []);
  }

  return (
    <>
      <div
        className="tab-pane"
        id="admin-trade"
        role="tabpanel"
        aria-labelledby="admin-trade-tab"
      >
        <AdminTrade></AdminTrade>
      </div>
    </>
  );
};
export default Index;

const ToDatePicker = () => {
  const { startdate1, enddate1, handleChangesTrade1 } =
    useContext(AdminContext);
  return (
    <>
      <div style={{ width: "167px" }} className="select-outer input-outer">
        <DatePicker
          placeholder="From"
          className="form-control datepicker from-date"
          // selected={startDate}
          dataFormat="mm/dd/yyyy"
          // onChange={(date: any) => setStartDate(date)}
          relativeSize={true}
          showYearDropdown
          placeholderText="To"
          dateFormat="yyyy-MM-dd"
          selected={enddate1}
          onChange={handleChangesTrade1}
          value={enddate1}
          minDate={startdate1}
          autoComplete="off"
          onKeyDown={(e: { preventDefault: () => any }) => e.preventDefault()}
        />
        <i className="fa fa-calendar" aria-hidden="true"></i>
      </div>
    </>
  );
};

const FromDatePicker = () => {
  const { startdate1, handleChangesTrade } = useContext(AdminContext);
  return (
    <>
      <div style={{ width: "167px" }} className="select-outer input-outer">
        <DatePicker
          placeholder="From"
          className="form-control datepicker from-date"
          // selected={startDate}
          dataFormat="mm/dd/yyyy"
          // onChange={(date: any) => setStartDate(date)}
          relativeSize={true}
          showYearDropdown
          dateFormat="yyyy-MM-dd"
          minDate={startdate1}
          autoComplete="off"
          onKeyDown={(e: { preventDefault: () => any }) => e.preventDefault()}
          selected={startdate1}
          onChange={handleChangesTrade}
          value={startdate1}
          maxDate={new Date()}
          placeholderText="From"
        />
        <i className="fa fa-calendar" aria-hidden="true"></i>
      </div>
    </>
  );
};

const SelectLimitDropdown = () => {
  const { handleChangelimitTrade } = useContext(AdminContext);
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => handleChangelimitTrade(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option value="20">Select Limit</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
          <option value="3000">3000</option>
        </select>
      </div>
    </>
  );
};

const SideDropdown = () => {
  const { enddate1, handleChangesTrade1, handleChangesideTrade, startdate1 } =
    useContext(AdminContext);
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => handleChangesideTrade(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option>Select Side</option>
          <option>Buy</option>
          <option>Sell</option>
        </select>
      </div>
    </>
  );
};

const Action = ({ keyA: key }: { keyA: string }) => {
  const { Transferlist, transfer_approve, openModalSucc, openModalReject } =
    useContext(AdminContext);
  return (
    <>
      {Transferlist[key].status === "Pending" ? (
        <>
          <a
            onClick={() => openModalReject(Transferlist[key].id)}
            className="border-btn border-black-btn"
          >
            Deny
          </a>
          <a
            onClick={() => transfer_approve(Transferlist[key].id)}
            className="border-btn"
          >
            Approve
          </a>
        </>
      ) : (
        <>
          {Transferlist[key].status === "Approved" ? (
            <></>
          ) : (
            <>
              {Transferlist[key].rejectReason ? (
                <a
                  onClick={() =>
                    openModalSucc(Transferlist[key].rejectReason, "Reason")
                  }
                  className="border-btn"
                >
                  Reason
                </a>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
