import { useContext, useEffect, useState } from 'react';
import {  Button, Table, Modal } from 'react-bootstrap';
import { config } from '../../../../config/config';
import { formatNumber } from '../../../Utils';
import ContextWrapper, { Context } from '../../../Utils/ContextWrapper';
import Loader from '../../../Loader';

// Specific to this page
const QUERY_INDEX = 'BalanceUser';
// End of specific to this page

function SellOrderModal({ data }: { data: any }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fetchParams, setFetchParams] = useState<any>({});
  const URL = `${config.apiurl}/userInfo/balance?target=${data.username}`;

  return (
    <>
      <Button
        className="main-small-btn mt-0"
        onClick={handleShow}
      >
        Balance
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Balance
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
          <ContextWrapper
            fetchParams={fetchParams}
            setFetchParams={setFetchParams}
            query_index={`${QUERY_INDEX}-${data.username}`}
            url={URL}
          >
            <Table_></Table_>
          </ContextWrapper>
          <Table></Table>
          <p>{data.rejectReason}</p>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              className={'main-color-btn full-btn'}
              onClick={() => {
                handleClose();
              }}
            >
              Okay
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SellOrderModal;

const Table_ = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (listData) {
      setCurrentPage(listData);
      setInitialized(true);
    }
  }, [listData]);

  return (
    <>
      <div className=''>
        <div>
          <div>
            <div>
              {}
              <div className=''>
                <>
                  {listIsLoading ? (
                    <>
                      <Loader visible={listIsLoading}></Loader>
                    </>
                  ) : (
                    <>
                      {initialized && currentPage?.length === 0 && (
                        <p>No balance found</p>
                      )}
                      <Table striped bordered>
                        <thead>
                          <tr>
                            <th>Currency</th>
                            <th>Available</th>
                            <th>Lock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPage?.map((data: any, index: any) => {
                            return (
                                <tr key={index}>
                                  <td>
                                    {data?.asset?.name}
                                    {/* <pre>
                                      {JSON.stringify(data, undefined, 2)}
                                    </pre> */}
                                  </td>
                                  <td>{formatNumber(data.free)}</td>
                                  <td>{formatNumber(data.locked)}</td>
                                </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
