import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext<{
  errorObj: any;
  setErrorObj: any;
  clearErrorObj: any;
  successObj: any;
  setSuccessObj: any;
  clearSuccessObj: any;
  toastObj: any;
  setToastObj: any;
  clearToastObj: any;
}>({
  errorObj: null,
  successObj: null,
  toastObj: null,
  setErrorObj: () => {},
  clearErrorObj: () => {},
  setSuccessObj: () => {},
  clearSuccessObj: () => {},
  setToastObj: () => {},
  clearToastObj: () => {},
});

const AuthContextProvider = (props: any) => {
  const [successObj, setSuccessObj] = useState<any>(null);
  const [errorObj, setErrorObj] = useState<any>(null);
  const [toastObj, setToastObj] = useState<any>(null);

  return (
    <AuthContext.Provider
      value={{
        errorObj,
        setErrorObj: (obj: any) => {
          setErrorObj(obj);
        },
        clearErrorObj: () => {
          setErrorObj(null);
        },
        successObj,
        setSuccessObj: (obj: any) => {
          setSuccessObj(obj);
        },
        clearSuccessObj: () => {
          setSuccessObj(null);
        },
        toastObj,
        setToastObj:(obj: any)=>{
          setToastObj(obj);
        },
        clearToastObj: () => {
          setSuccessObj(null);
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
