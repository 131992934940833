import { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../../config/config';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Context } from '../../../Utils/ContextWrapper';
import { tokenService } from './../../../../_services/tokens.services';
import { useAuth } from "./../../../../auth-context";

function SellOrderModal({ data }: { data: any }) {
  const { userRefetch } = useAuth();
  const { configheadersTemplate }= tokenService;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [roles, setRoles] = useState<any>({});
  const { setToastObj } = useContext(AuthContext);

  useEffect(() => {
    if (data?.roles) {
      const roles = data.roles.reduce((accumulator: any, role: any) => {
        accumulator[role] = true;

        return accumulator;
      }, {});
      setRoles(roles);
    }
  }, [data]);

  const mutation: any = useMutation(
    () => {
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/userInfo/updateRole`;
      var formData = new FormData();
      formData.append('username', data.username);
      Object.keys(roles).forEach((role) => {
        if (roles[role]) {
          formData.append('role', role);
        }
      });
      
      return axios.put(url, formData, configheaders);
    },
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries(['AdminUser', fetchParams]);
        await userRefetch()
        setToastObj({
          message: 'You have successfully updated roles',
          type: 'success'
        });
        handleClose();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || 'Update roles request failed',
          type: 'error'
        });
        handleClose();
      },
    }
  );
  const fetchRoles = async () => {
    let configheaders = configheadersTemplate();
    let url = `${config.apiurl}/userInfo/listAllRole`;
    const { data } = await axios.get(url, configheaders);
    return data;
  };
  const {
    isLoading: rolesIsLoading,
    error: roleError,
    data: roleData,
  } = useQuery('GetRoles', () => {
    return fetchRoles();
  });
  return (
    <>
      <Button
        className="main-small-btn mt-0"
        onClick={handleShow}
      >
        Edit
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Select roles
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
          {rolesIsLoading ? (
            <>Loading</>
          ) : (
            <>
              {roleData
              .filter((role: string) => role !== 'Buyer' && role !== 'Seller' && role !== 'Custodian' && role !== 'Principal')
              .map((role: string, index: any) => {

                return (
                    <Form.Group controlId={role} key={index}>
                      <Form.Check
                        onClick={(e:any) => {
                          setRoles({ ...roles, [role]: !roles[role] });
                        }}
                        type='checkbox'
                        checked={roles[role]}
                       label={role}
                      />
                    </Form.Group>
                );
              })}
              {/* <pre>{JSON.stringify(roleData, undefined, 2)}</pre> */}
              {/* <pre>{JSON.stringify(data.roles, undefined, 2)}</pre> */}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              className={'main-color-btn full-btn'}
              onClick={() => {
                mutation.mutate();
              }}
            >
              {mutation.isLoading ? <>Submitting order...</> : <> Submit</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SellOrderModal;
