import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Modal,
} from 'react-bootstrap';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorMessage } from '../../Utils';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';
import { config } from '../../../config/config';


function TwoFactorAuthModal({
  initialShow = false,
  callback = () => {},
  onClose = () => {},
  onSuccess = () => {},
}: {
  callback: () => void;
  onSuccess: () => void;
  initialShow: boolean;
  onClose: any;
}) {
  const [failAuth, setFailAuth] = useState(false);
  const [show, setShow] = useState(initialShow);
  
  const { userInfo } = useAuth();
  const handleClose = () => {
    onClose();
    // setShow(fase);
  };
  //   const handleShow = () => setShow(true);
  const [code, setCode] = useState<any>();
  // const [inventoryId, setInventoryId] = useState(initialInventoryId);
  const [error, setError] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  let schema = yup.object().shape({
    code: yup.string().required().length(6),
  });

  const mutation: any = useMutation(
    () => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/2fa/verify`;
      let formData = new FormData();
      formData.append('code', code);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setCode(null);
        if (data?.data?.result) {
          // success
          setFailAuth(false);
          onSuccess();
        } else {
          // failed
          setFailAuth(true);
        }
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage ||`Error`,
          type: 'error'
      });
        // handleClose();
      },
    }
  );

  if (initialShow && !userInfo?.enable2FA) {
    onSuccess();
    return <></>;
  }
  return (
    <>
      <Modal
        size={'lg'}
        centered
        show={initialShow}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Two-factor authentication
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {failAuth && (
            <>
              <Alert variant={'warning'}>
                <span>The code you provided is not valid</span>
              </Alert>
            </>
          )}
          <h3 style={{ marginBottom: 15 }}>Input 6 digit number</h3>
          <br />
          <form>
            <div className='row'>
              <aside className='col-lg-3 paddingtop20'>
                <FormLabel>Code:</FormLabel>
              </aside>
              <aside className='col-lg-9'>
                <FormControl
                  value={code}
                  onChange={(event: any) => {
                    setCode(event.currentTarget.value);
                  }}
                  type='number'
                  placeholder='Enter number'
                />
                <ErrorMessage path={'amount'} error={error}></ErrorMessage>
              </aside>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <div>
              {mutation.isError ? (
                <div>An error occurred: {mutation.error.message}</div>
              ) : null}
            </div>

            <Button
              className={'main-color-btn full-btn'}
              onClick={() => {
                schema
                  .validate({ code })
                  .then(function (valid: any) {
                    if (valid) {
                      setError(null);
                      mutation.mutate({
                        code,
                      });
                    } else {
                      alert('fail');
                    }
                    // valid; // => true
                  })
                  .catch(function (err: any) {
                    setError(err);
                    // err.name; // => 'ValidationError'
                    // err.errors; // => ['Deve ser maior que 18']
                  });
              }}
            >
              {mutation.isLoading ? <>Submitting...</> : <> Submit</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TwoFactorAuthModal;
