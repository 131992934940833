import { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../../config/config';
import { capitalize } from '../../../Utils';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Context } from '../../../Utils/ContextWrapper';
import { tokenService } from './../../../../_services/tokens.services';
import { FaBan } from 'react-icons/fa';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function DisableModal({ data }: { data: any }) {
    const { enable, username, name } = data;
    const { configheadersTemplate }= tokenService;
    const { fetchParams } = useContext(Context);
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { setToastObj } = useContext(AuthContext);
    const [status, setStatus] = useState(enable);
    const action = status === true ? 'disable' : 'enable';
    const mutation: any = useMutation(
      () => {
        let configheaders = configheadersTemplate();
        let url = `${config.apiurl}/userInfo/${action}`;
        var formData = new FormData();
        formData.append('username', username);
        return axios.put(url, formData, configheaders);
      },
      {
        onSuccess: (data, variables, context) => {
            setToastObj({
                message: `You have successfully ${action}d the user`,
                type: 'success'
              });
            queryClient.invalidateQueries(['AdminDisable', fetchParams]);
            handleClose();
            setStatus(!status)
        },
        onError: (error: any, variables, context) => {
            const errorMessage = error?.response?.data?.message;
            setToastObj({
                message:  errorMessage || `${capitalize(action)} request failed`,
                type: 'error'
              });
            handleClose();
        },
        }
    );
    return (
        <>
            <OverlayTrigger
                key={`disable-${username}`}
                placement='bottom'
                overlay={
                    <Tooltip id={`disable-${username}-tootip`}>
                        {action} this user
                    </Tooltip>
                }
            >
                <button
                    onClick={handleShow}
                    className={`btn-icon ${status && 'clr-grey'}`}>
                        <FaBan/>
                </button>
            </OverlayTrigger>
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <h5 className='modal-title'>Are you sure you want to {action} this user?</h5>
                </Modal.Header>
                <Modal.Body className='modal-title'>
                    <div className='transfer_detail_row'>
                        <div className='transfer_detail_title'>
                            <span>
                                <strong>
                                    User Name
                                </strong>
                            </span>
                        </div>
                        <div className='transfer_detail_content'>
                            <span>
                                {name}
                            </span>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className='modal-title'>
                    <Button
                        className={'btn-full-dark full-btn'}
                        onClick={() => {
                            mutation.mutate();
                        }}
                    >
                      {mutation.isLoading ? <>Submitting...</> : <>Yes</>}
                    </Button>
                    <Button
                      className={'btn-dark-border full-btn'}
                      onClick={handleClose}
                    >
                        No
                    </Button>
                  </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default DisableModal;
