import React, { useEffect, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

import styles from './FrankieOne.module.scss';
import FrankieSmartUI from '../FrankieSmartUi/FrankieSmartUi';
import { tokenService } from '../../../_services/tokens.services';
import { config } from '../../../config/config';
import Spinner from '../Spinner/Spinner';

const window_: any = window;

interface props {
  putUserInfoWithEntityId: (entityId: string) => void;
  kycBtnText: string;
  userId: string;
}

const FrankieOne: React.FC<props> = ({
  putUserInfoWithEntityId = () => {},
  kycBtnText = '',
  userId = '',
}) => {
  const [entityId, setEntityId] = useState('');
  const [entityType, setEntityType] = useState('');
  const [isKycLoading, setIsKycLoading] = useState(false);
  const [isKybLoading, setIsKybLoading] = useState(false);
  const [isFrankieSmartUILoaded, setIsFrankieSmartUILoaded] = useState(false);

  const FRANKIE_URL = process.env.REACT_APP_FRANKIE_URL;

  const FRANKIE_CONFIG = {
    frankieBackendUrl: FRANKIE_URL,
    documentTypes: [
      {
        type: 'PASSPORT',
        acceptedCountries: 'ALL',
      },
    ],
    requestAddress: true,
    acceptedCountries: 'ALL',
    progressBar: true,
    ageRange: [18, 125],
    idScanVerification: true,
    checkProfile: 'auto',
    useLiveness: true,
    maxAttemptCount: 5,
    welcomeScreen: {
      htmlContent: `
          <div class="wrapper">
            <h1>Before you start...</h1>
            <p class='bold'>We need to collect some personal information to verify your identity before we can open your account.</p>
            <span>It’s important you enter your details as accurately as possible. This will save time and get you into your new account right away!</span>
            <b>You’ll need:</b>
            <ol>
              <li>We need 5 mins of your time to complete this application</li>
              <li>You must be over 18 years of age</li>
              <li>A valid passport</li>
            </ol>
          </div>
          <style>
            .wrapper {
              padding: 16px; 
            }
            span {
              display: block;
            }
            p {
              font-size: 16px;
              font-weight: bold;
            }
          </style>
        `,
      ctaLabel: 'Identify me!',
    },
    // successScreen: {
    //   ctaLabel: 'Create Account :)',
    //   ctaUrl:
    //     'https://my-organisation.com/create-account?applicant=99-custom-applicant-id&secret-token=hash_to_validate_99-custom-applicant-id',
    // },
    // failureScreen: {
    //   ctaUrl:
    //     'https://my-organisation.com/contact-us?applicant=99-custom-applicant-id$secret-token=hash_to_validate_99-custom-applicant-id',
    //   ctaText: 'Contact Us',
    // },
    googleAPIKey: false,
    organisationName: 'Inbase Partners Taiwan Limited',
    phrases: {
      document_select: {
        title: 'Verify your identity',
        subtitle:
          'We’ll need you to use your device to scan your PASSPORT and record a photo of yourself.',
        hint_message: 'Click on the following to proceed.',
        footer_text: 'Your Passport must be valid and not expired',
      },
    },
    consentText:
      "I agree with the terms described in the Consent section of the Company's webpage",
  };

  const FRANKIE_HEADERS = {
    authorization: `machine ${process.env.REACT_APP_FRANKIE_CREDENTIALS}`,
  };

  const VerifiedButtons = [
    {
      type: 'Individual',
      text: kycBtnText,
      isLoading: isKycLoading,
    },
    // {
    //   type: 'Organization',
    //   text: 'Complete the Verification',
    //   isLoading: isKybLoading,
    // },
  ];

  const getEntityIdClicked = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const btnEntityType: string | any = e?.currentTarget?.dataset?.entity;

    setEntityType(btnEntityType);

    switch (btnEntityType) {
      case 'Individual':
        setIsKycLoading(true);
        break;
      case 'Organization':
        setIsKybLoading(true);
        break;
    }

    setIsFrankieSmartUILoaded(true);
  };

  const closeIframeHelper = () => {
    let ref: any;
    ref = setTimeout(async () => {
      const iframe = document.getElementsByTagName('iframe');
      !!iframe && Array.from(iframe).map(i => (i.style.zIndex = '-1'));
      console.log('close iframe');
      clearTimeout(ref);
    }, 1000);
  };

  const postFrankieEntityId = async (id: string, type: string) => {
    console.log('before', id, type);
    if (!!id && !!type) {
      console.log('after', id, type);
      const { configheadersTemplate } = tokenService;
      const configheaders = configheadersTemplate();

      await axios.post(
        `${config.apiurl}/userInfo/frankie/entity/${type}/${id}`,
        {},
        configheaders
      );
    }
  };

  useEffect(() => {
    if (!!entityId && !!entityType) postFrankieEntityId(entityId, entityType);
  }, [entityId, entityType]);

  useEffect(() => {
    window_.addEventListener('INIT', (e: CustomEvent) => {
      console.log('INIT');
      closeIframeHelper();
    });

    window_.addEventListener('FF_CHECK_RESULT', (e: CustomEvent) => {
      if (!!e.detail?.applicant?.entityId) {
        console.log('FF_CHECK_RESULT');
        setEntityId(e.detail.applicant.entityId);
      }
      closeIframeHelper();
    }); // Includes details used to decide what view comes next. More info after this table.

    window_.addEventListener('DESTROY', (e: CustomEvent) => {
      if (!!e.detail?.applicant?.entityId) {
        console.log('DESTROY');
        setEntityId(e.detail.applicant.entityId);
      }
      closeIframeHelper();
    });

    window_.addEventListener(
      'FF_EXTERNAL_IDV_CHECK_COMPLETED',
      (e: CustomEvent) => {
        if (!!e.detail?.applicant?.entityId) {
          console.log('FF_EXTERNAL_IDV_CHECK_COMPLETED');
          setEntityId(e.detail.applicant.entityId);
        }
        closeIframeHelper();
      } // Emitted after idScanVerification process is completed
    );

    return () => {
      setEntityId('');
      setEntityType('');
      setIsKycLoading(false);
      setIsKybLoading(false);
      setIsFrankieSmartUILoaded(false);
    };
  }, []);

  useEffect(() => {
    const initialiseWidget = async () => {
      const res = await fetch(`${FRANKIE_URL}/auth/v2/machine-session`, {
        headers: FRANKIE_HEADERS,
        method: 'POST',
        mode: 'cors',
      });

      const ffToken = res.headers.get('token');

      return window_.frankieFinancial.initialiseOnboardingWidget({
        applicantReference: userId,
        config: FRANKIE_CONFIG,
        ffToken,
        width: '100%',
        height: `${window_.innerHeight / 1.25}px`,
        // entityId,
      });
    };

    if (window_.frankieFinancial && isFrankieSmartUILoaded) {
      initialiseWidget().then(res => {
        setIsKycLoading(false);
        setIsKybLoading(false);
      });
    }
  }, [isFrankieSmartUILoaded]);

  return (
    <>
      {VerifiedButtons.map(({ type, text, isLoading }) => {
        return (
          <button
            type="button"
            key={type}
            data-entity={type}
            onClick={getEntityIdClicked}
            className={cn('main-color-btn btn indented', styles.Btn)}
            disabled={isLoading}
          >
            {text}
            {isLoading && <Spinner />}
          </button>
        );
      })}
      <FrankieSmartUI
        isFrankieSmartUILoaded={isFrankieSmartUILoaded}
        isFrankieSmartUILoading={isKycLoading || isKybLoading}
        onClose={async () => {
          !!entityId && (await putUserInfoWithEntityId(entityId));
          !entityId && setEntityId('');
          setIsFrankieSmartUILoaded(false);
        }}
      />
    </>
  );
};

export default FrankieOne;
