import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MarketContext } from "./MarketContextWrapper";

const FailModal = () => {
  const { warningModal, setWarningModal, errorMessage } = useContext(
    MarketContext
  );
  const [show, setShow] = useState(warningModal);
  useEffect(() => {
    setShow(warningModal);
  }, [warningModal]);

  const handleClose = () => setWarningModal(false);
  const handleShow = () => setWarningModal(true);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Body>
          <div style={{ margin: 20, textAlign: "center" }}>
            <img
              src={require("../../images/warning.png").default}
              style={{ height: "80px", marginBottom: "9px" }}
              alt="green"
            />
            {
              errorMessage ? (
                <h3>{errorMessage}</h3>
              ):(
                <>
                  <h3>Error: Trading Failed</h3>
                  <span><address><a href="mailto:support@catchotc.com">Contact Admin</a></address></span>
                </>
              )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default FailModal;
