import React, { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from '../../auth-context';

import { AuthContext } from '../../contexts/AuthContext';
import { userService } from '../../_services';

function useProfile(initialValue = false) {
  const { userInfo, userRefetch }: any = useAuth();
  const [password, setpassword] = useState() as any;
  const [passwordErr, setpasswordErr] = useState('') as any;
  const [Cpassword, setCpassword] = useState() as any;
  const [CpasswordErr, setCpasswordErr] = useState('') as any;
  const [address, setAddress] = useState('') as any;
  const [phone, setPhone] = useState('') as any;
  const [country, setCountry] = useState('') as any;
  const [ProductList, setProductList] = useState() as any;
  const [modalIsOpendeposite1, setIsOpendeposite1] = useState(false);
  const [depo_result, setDepoResult] = useState([]) as any;
  const [bankCurr, setbankCurr] = useState('') as any;
  const [bankAccountName, SetbankAccountName] = useState('');
  const [bankAsset, setBankAsset] = useState() as any;
  const [cryptoAsset, setCryptoAsset] = useState() as any;

  const queryClient = useQueryClient();
  const [myparams_bank, SetParamsBank] = useState({
    asset: '',
    description: '',
    name: '',
    bankName: '',
    bankBranchName: '',
    bankAddress: '',
    bankSwiftCode: '',
    bankAccountName: '',
    bankAccountNo: '',
    intermediaryBankName: '',
    intermediaryBankSwiftCode: '',
    intermediaryBankAddress: '',
  }) as any;
  const [myparams_crypto, SetParamsCrypto] = useState({
    asset: '',
    address: '',
    name: '',
  }) as any;

  // const [ProfileUpdate,setIsOpenProfile = useState(false) as any
  const customStyles = {
    content: {
      left: '50%',
      border: 'none',
    },
  };
  const bankdetail = (id: any) => {
    userService
      .get_bank(id)
      .then(function (response: any) {
        setbankCurr(response.data.currency.name);
        setDepoResult(response.data);
        openModaldepo1();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChange_bankname = (name: any) => {
    // console.log(myparams_bank)
    SetbankAccountName(name);
    myparams_bank.bankAccountName = name;
  };
  const saveBankAsset = (asset: any) => {
    setBankAsset(asset);
  };
  function openModaldepo1() {
    // alert(id+"===="+name)

    setIsOpendeposite1(true);
  }
  function closeModaldepo1() {
    setIsOpendeposite1(false);
  }
  useEffect(() => {
    if (userInfo) {
      SetbankAccountName(userInfo.name);
      if (userInfo.profile) {
        SetParamsBank.name(userInfo.profile.name);

        SetParamsBank.bankAccountName(userInfo.profile.name);
        SetParamsCrypto.name(userInfo.profile.name);
        setAddress(userInfo.profile.address);
        setPhone(userInfo.profile.phone);
        setCountry(userInfo.profile.country);
      }
      userService
        .get_asset()
        .then(function (response: any) {
          setProductList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [userInfo]);
  const add_bank = () => {
    if (bankAsset) {
      myparams_bank.asset = bankAsset;
    }
    userService
      .addBank(myparams_bank)
      .then(function (response: any) {
        queryClient.invalidateQueries('UserInfo');
        userRefetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveCryptoAsset = (asset: any) => {
    setCryptoAsset(asset);
  };
  const addcrypto = () => {
    if (cryptoAsset) {
      myparams_crypto.asset = cryptoAsset;
    }
    userService
      .addcrypto(myparams_crypto)
      .then(function (response: any) {
        queryClient.invalidateQueries('UserInfo');
        userRefetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const delete_bank = (id: any) => {
    userService
      .delete_bank(id)
      .then(function (response: any) {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const delete_crypto = (id: any) => {
    userService
      .delete_crypto(id)
      .then(function (response: any) {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const chanePassword = () => {
    if (password) {
      var reg =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
      var validpassword = reg.test(password);
      if (!validpassword) {
        setpasswordErr(
          'Password must be eight characters long and contain at least one alphabet, one number and one special character.'
        );
      } else {
        setpasswordErr('');
      }
      if (validpassword) {
        if (Cpassword) {
          if (password === Cpassword && validpassword) {
            userService
              .changepass(password, userInfo.username)
              .then(function (response: any) {
                // setMessage("Your password updated successfully!");
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            setCpasswordErr('Confirm Password and password do not match');
          }
        } else {
          setCpasswordErr("Confirm Password can't be empty");
        }
      }
    } else {
      setpasswordErr("Password can't be empty");
    }
  };
  const updateprofile = () => {
    userService
      .updateProfile(address, phone, country)
      .then(function (response: any) {
        // setMessage("Your Profile updated successfully!");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  var roleTemp: any = [];
  if (userInfo?.roles) {
    roleTemp = Object.keys(userInfo.roles).map(function (key2: any) {
      return (
        <li key={key2}>
          <span>{userInfo.roles[key2]}</span>
        </li>
      );
    }, []);
  }
  let ProdOption1: any = [];
  let ProdOption: any = [];
  let i = 0;
  if (ProductList) {
    // eslint-disable-next-line
    ProdOption = Object.keys(ProductList).map(function (key: any) {
      if (ProductList[key].currencyType === 'Fiat') {
        if (i === 0) {
          // alert(ProductList[key].id)
          myparams_bank.asset = ProductList[key].id;
        }
        i++;
        return (
          <option value={ProductList[key].id} key={key}>
            {ProductList[key].name}
          </option>
        );
      }
    }, []);

    let j = 0;
    // eslint-disable-next-line
    ProdOption1 = Object.keys(ProductList).map(function (key: any) {
      if (ProductList[key].currencyType === 'Crypto') {
        if (j === 0) {
          myparams_crypto.asset = ProductList[key].id;
        }
        j++;
        return (
          <option value={ProductList[key].id} key={key}>
            {ProductList[key].name}
          </option>
        );
      }
    }, []);
  }
  var bankTemp: any = [];
  if (userInfo?.bankList) {
    bankTemp = Object.keys(userInfo.bankList).map(function (key2: any) {
      // console.log(userInfo.bankList[key2].currency.name)
      return (
        <tr key={key2}>
          <td>{userInfo.bankList[key2].currency.name}</td>
          <td>{userInfo.bankList[key2].description}</td>
          <td>{userInfo.bankList[key2].bankName}</td>
          <td>{userInfo.bankList[key2].bankAccountNo}</td>

          {userInfo.bankList[key2].verified ? <td>Yes</td> : <td>No</td>}
          <td
            className="detail"
            onClick={() => bankdetail(userInfo.bankList[key2].id)}
          >
            Detail
          </td>
          <td>
            <button
              type="submit"
              className="btn grad_btn grad_rev_btn"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this bank account?'
                  )
                ) {
                  delete_bank(userInfo.bankList[key2].id);
                }
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    }, []);
  }
  var addTemp: any = [];
  if (userInfo?.addressList) {
    addTemp = Object.keys(userInfo.addressList).map(function (key2: any) {
      //  console.log(userInfo.addressList[key2].asset.name)
      return (
        <tr key={key2}>
          <td>{userInfo.addressList[key2].asset.name}</td>
          <td>{userInfo.addressList[key2].name}</td>
          <td>{userInfo.addressList[key2].address}</td>
          <td>
            <button
              type="submit"
              className="btn grad_btn grad_rev_btn"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to delete this address?'
                  )
                ) {
                  delete_crypto(userInfo.addressList[key2].id);
                }
              }}
            >
              Delete
            </button>
          </td>
        </tr>
      );
    }, []);
  }

  return {
    saveBankAsset,
    saveCryptoAsset,
    bankAccountName,
    handleChange_bankname,
    myparams_bank,
    SetParamsBank,
    userInfo,
    myparams_crypto,
    SetParamsCrypto,
    add_bank,
    address,
    phone,
    country,
    ProductList,
    addcrypto,
    password,
    setpassword,
    passwordErr,
    setCpassword,
    CpasswordErr,
    chanePassword,
    delete_bank,
    delete_crypto,
  };
}
export default useProfile;
