import UserInfoContainer from './Table';

const Index = () => {
  return (
    <div
      className="tab-pane"
      id="finance-user"
      role="tabpanel"
      aria-labelledby="finance-user-tab"
    >
      <UserInfoContainer />
    </div>
  );
};
export default Index;
