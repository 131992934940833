import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FilterRow, TableContainer } from '../../Utils/FilterRow';
import { formatNumber, capitalize } from '../../Utils';
import Pagination from '../../Utils/Pagination';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import ContextWrapper, { Context } from '../../Utils/ContextWrapper';
import DenyModal from './Modals/DenyModal';
import ApproveModal from './Modals/ApproveModal';
import ReasonModal from './Modals/ReasonModal';
import { BsBoxArrowLeft, BsBoxArrowInRight } from 'react-icons/bs';
import CopyTool from '../../Utils/CopyTool';
import ExternalLinkBtn from '../../Utils/ExternalLinkBtn';
import DetailsModal from './Modals/DetailsModal';
import { GrDocumentText } from 'react-icons/gr';
import Decimal from 'decimal.js-light';

// Specific to this page
const QUERY_INDEX = 'AdminTransfer';
const URL = `${config.apiurl}/transfer/list?house=true`;

// const { export_data } = useExportData();
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;
  io?: string;
  account?: string;
  type?: string;
  status?: string;
  limit: number;
  house?: boolean;
  sortOrder: string;
  assetType: string;
  side: string;
}
const initialFetch = {
  pageNum: 1,
  sortOrder: 'desc',
  type: 'deposit,withdraw',
  assetType: 'currency,metal',
  side: '',
  limit: 20,
};

// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const getTransferStatus = (
    status: String,
    financeStatus: string,
    data: any
  ) => {
    switch (true) {
      case status === 'Rejected':
      case financeStatus === 'Rejected':
        return {
          status_clr: 'clr-deny',
          status_text: 'Rejected',
          status_action: <ReasonModal data={data} />,
        };
      case status === 'Approved':
        return {
          status_clr: 'clr-approve',
          status_text: 'Approved',
          status_action: <DetailsModal data={data} />,
        };
      case status === 'Pending' && financeStatus === 'Approved':
        return {
          status_clr: 'clr-pending',
          status_text: 'Pending',
          status_action: (
            <>
              <ApproveModal data={data} />
              <DenyModal data={data} />
            </>
          ),
        };
      case financeStatus === 'Pending':
        return {
          status_clr: 'clr-pending',
          status_text: 'Pending',
          status_action: (
            <div className="pending_box">
              <GrDocumentText /> &nbsp;
              <span>Finance has not yet approved this transfer.</span>
            </div>
          ),
        };
      default:
        return {
          status_clr: 'clr-grey',
          status_text: 'Other',
          status_action: <></>,
        };
    }
  };

  useEffect(() => {
    if (listData?.data) {
      setCurrentPage(listData?.data);
      setInitialized(true);
    }
  }, [listData?.data]);

  return (
    <>
      {/* {JSON.stringify(listData)} */}
      <div className="">
        <div>
          <div>
            <div>
              <div className="">
                <div className="border_box">
                  <FilterRow
                    export_data={export_data}
                    searchParams={fetchParams}
                    startDate={{
                      value: fetchParams.startDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          startDate: val,
                        });
                      },
                    }}
                    endDate={{
                      value: fetchParams.endDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          endDate: val,
                        });
                      },
                    }}
                    limit={{
                      value: fetchParams.limit,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          limit: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    clearFilter={{
                      set: (val: any) => {
                        setFetchParams(initialFetch);
                      },
                    }}
                    // source={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       asset: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // source={{
                    //   set: (val: any) => {
                    //     setFetchBalanceMetalParams({
                    //       ...fetchBalanceMetalParams,
                    //       source: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // location={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       country: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                  ></FilterRow>

                  <div className="table-responsive">
                    <Loader visible={listIsLoading}></Loader>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="main-table transfer_info_container"
                    >
                      <thead>
                        <tr>
                          <th className="user_item_row">Date</th>
                          <th className="user_item_row">Side</th>
                          <th className="user_item_row">Account</th>
                          <th className="user_item_row">Currency</th>
                          <th className="user_item_row">Amount</th>
                          <th className="user_item_row">Transaction Fee</th>
                          <th className="user_item_row">Wire Amount</th>
                          <th className="user_item_row">Destination</th>
                          <th className="user_item_row">TXID</th>
                          <th className="user_item_row">Status</th>
                          <th className="user_item_row">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!initialized && listIsLoading ? (
                          <tr>
                            <td colSpan={8}>
                              {/* <ProgressMovingBar></ProgressMovingBar> */}
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPage?.length === 0 && (
                              <tr>
                                <td className="clr-pending" colSpan={9}>
                                  No rows found. Try clearing your filters.
                                </td>
                              </tr>
                            )}
                            {currentPage?.map((data: any, index: any) => {
                              const {
                                amount,
                                asset,
                                financeStatus,
                                name,
                                status,
                                type,
                                timestamp,
                                txId,
                                toAddress,
                                fee,
                              } = data;

                              let icon: any;
                              if (type === 'deposit') {
                                icon = (
                                  <BsBoxArrowInRight className="clr-grey" />
                                );
                              } else {
                                icon = <BsBoxArrowLeft className="clr-grey" />;
                              }
                              let status_info = getTransferStatus(
                                status,
                                financeStatus,
                                data
                              );
                              let status_clr = status_info?.status_clr;
                              let status_text = status_info?.status_text;
                              let status_action = status_info?.status_action;
                              var t = new Date(timestamp);
                              const myMoment = moment(t).format(
                                'YYYY-MM-DD hh:mm:ss'
                              );
                              let processedAmount, processedFee, wireAmount;
                              try {
                                processedAmount = new Decimal(amount);
                                processedFee = fee ? new Decimal(fee) : 0;
                                wireAmount =
                                  processedAmount.minus(processedFee);
                              } catch (error) {
                                processedAmount = '';
                                processedFee = '';
                                wireAmount = '';
                                console.log(error);
                              }

                              return (
                                <tr key={index} className="transfer_row">
                                  <td className="transfer_row_item">
                                    <span className="transfer_time">
                                      {myMoment}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_side row-ele-center">
                                      {icon}&nbsp;&nbsp;
                                      {capitalize(type)}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_name">
                                      {name}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_currency">
                                      {asset}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_amount">
                                      {processedAmount
                                        ? formatNumber(processedAmount)
                                        : 'Not stated'}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_network_fee">
                                      {processedFee
                                        ? formatNumber(processedFee)
                                        : 'Not stated'}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_wire_amount">
                                      {wireAmount
                                        ? formatNumber(wireAmount)
                                        : 'Not stated'}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <span className="transfer_destination">
                                      {toAddress && (
                                        <CopyTool text={toAddress} digit={6} />
                                      )}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    {txId && (
                                      <div className="tool_chain">
                                        <CopyTool text={txId} digit={6} />
                                        <ExternalLinkBtn
                                          text={txId}
                                          currency={asset}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td className="transfer_row_item">
                                    <span
                                      className={`transfer_status ${status_clr}`}
                                    >
                                      {status_text}
                                    </span>
                                  </td>
                                  <td className="transfer_row_item">
                                    <div className="user_action_btns">
                                      {status_action}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className=" custom_table">
                    <TableContainer isLoading={initialized && listIsLoading}>
                      <table className="table"></table>
                      {count !== 0 && (
                        <div className={'col-md-11'}>
                          <Pagination
                            onChange={(page: any) => {
                              setPageNum(page);
                            }}
                            count={fetchParams.limit}
                            totalCount={totalCount}
                          ></Pagination>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
