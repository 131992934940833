import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenconfirm,
    closeModalconfirm,
    disable_img,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
    admin_transfer,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        isOpen={modalIsOpenconfirm}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={modalIsOpenconfirm}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div className="form_box outer_form">
              <div className="form_box inner_form modal_parent">
                <form className="custom_form text-center">
                  <h3>Are you sure, you want to submit?</h3>
                  <div>
                    <div className="pop_btn_padding"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            className="btn grad_btn"
            onClick={admin_transfer}
          >
            Yes
          </Button>
          <Button
            type="button"
            className="btn grad_btn grad_btn2"
            onClick={closeModalconfirm}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
