import React, { useContext } from "react";
import { ProfileContext } from "./ProfileContextWrapper";

const Confirm = ({ currentSelectedCrypto }: any) => {
  const { addcrypto, delete_crypto } = useContext(ProfileContext);
  return (
    <>
      {/* <td><button type="submit" className="btn grad_btn grad_rev_btn" onClick={() => { if (window.confirm('Are you sure you want to delete this bank account?')) { delete_bank(userInfo.bankList[key2].id) }; }}>Delete</button></td> */}
      {/* <td><button type="submit" className="btn grad_btn grad_rev_btn" onClick={() => { if (window.confirm('Are you sure you want to delete this address?')) { delete_crypto(userInfo.addressList[key2].id) }; }} >Delete</button></td> */}
      <div
        className="modal fade"
        id="confirm-delete-crypto-popup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close close-popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close.svg" alt="" className="" />
              </button>
              <form className="withdrawal-formarea main-form">
                <div className="text-center margintop40">
                  <p>Are you sure you want to delete this address?</p>
                  <a
                    data-dismiss="modal"
                    style={{ cursor: 'pointer', marginRight: 10 }}
                    onClick={()=>{}}
                    className=" main-color-bigbtn"
                  >
                    Cancel
                  </a>
                  <a
                    onClick={() => {
                      delete_crypto(currentSelectedCrypto.id);
                    }}
                    className="main-color-btn main-color-bigbtn"
                  >
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirm;
