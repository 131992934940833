import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenReject,
    closeModalReject,
    setRejectMsg,
    transfer_reject,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        size={"lg"}
        isOpen={modalIsOpenReject}
        onHide={closeModalReject}
      >
        <Modal.Header>
          <button type="button" className="close" onClick={closeModalReject}>
            &times;
          </button>
          <h4 className="modal-title">Deny Request</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form_box modal_parent">
              <form className="login_form tranfer_pop_pd">
                <div className="form-group">
                  <textarea
                    className="form-control textarea"
                    onChange={(e: any) => setRejectMsg(e.target.value)}
                  ></textarea>
                </div>
              </form>
              <div className="confirm_btn">
                <button className="yellow_btn" onClick={transfer_reject}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
