import Table from './Table';

const Index = () => (
  <div
    className="tab-pane active"
    id="finance-transfer"
    role="tabpanel"
    aria-labelledby="finance-transfer-tab"
  >
    <Table />
  </div>
);
export default Index;
