import React, { useContext } from "react";
import { HistoryContext } from "./HistoryContextWrapper";
import HistoryTrade from "./HistoryTrade";

const NavTabs = () => {
  return (
    <>
      <div
        className="tab-pane"
        id="history-trade"
        role="tabpanel"
        aria-labelledby="history-trade-tab"
      >
        <HistoryTrade></HistoryTrade>
      </div>
    </>
  );
};
export default NavTabs;
