import React, { useContext } from "react";
import { AdminContext } from "./AdminContextWrapper";
import AdminIncome from "./AdminIncome";


const Index = () => {
  return (
    <>
      <div
        className="tab-pane"
        id="admin-income"
        role="tabpanel"
        aria-labelledby="admin-income-tab"
      >
        <AdminIncome/>
      </div>
    </>
  );
};
export default Index;
