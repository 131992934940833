import moment from "moment";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { AdminContext } from "../Admin/AdminContextWrapper";
import useHistory from "../History/useHistory";
import useMarket from "../Market/useMarket";

export const FilterRow = ({
  startDate,
  endDate,
  timespan,
  side,
  limit,
  searchList,
  searchParams,
  export_data,
  grade,
  location,
  source,
  insert,
  exportCsv,
  exportPdf,
  market,
  base,
  asset,
  transaction,
  accountType,
  verificationStatus,
  sortBy,
  sortOrder,
  clearFilter,
}: FilterRowInterface) => {
  const { activityAssets, AssetList } = useHistory();
  const [_startDate, setStartDate] = useState<any>(startDate?.value);
  const [_endDate, setEndDate] = useState<any>(endDate?.value);
  const [_timespan, setTimespan] = useState<any>(timespan?.value);
  const [_limit, setLimit] = useState<any>(limit?.value);
  const [_sortBy, setSortBy] = useState<any>(sortBy?.value);
  const [_sortOrder, setSortOrder] = useState<any>(sortOrder?.value);
  const [_accountType, setAccountType] = useState<any>(accountType?.value);
  const [_verificationStatus, setVerificationStatus] = useState<any>(verificationStatus?.value);
  const [_market, setMarket] = useState<any>(market?.value);
  const [_base, setBase] = useState<any>(base?.value);
  const [_side, setSide] = useState<any>(side?.value);
  const { productList, productRelationshipMap } = useMarket(false);

  function handleClear(){
    setStartDate("");
    setTimespan("");
    setEndDate("");
    setLimit("");
    setSortBy("");
    setSortOrder("");
    setAccountType("");
    setVerificationStatus("");
    clearFilter.set("")
  }
  return (
    <>
      {/* <pre>{JSON.stringify(activityAssets, undefined, 2)}</pre>
      <pre>{JSON.stringify(AssetList, undefined, 2)}</pre> */}
      <div className="row table_filter_row" style={{ marginBottom: 20 }}>
        <div className="col-md-8">
          <div
            className="select_date"
            style={{ display: "inline-block", marginBottom: 10 }}
          >
            {startDate && (
              <>
                <div
                  className="select-outer input-outer"
                >
                  <DatePicker
                    showYearDropdown
                    autoComplete="off"
                    placeholderText="Start Date"
                    dateFormat="yyyy-MM-dd"
                    className="form-control datepicker from-date"
                    relativeSize={true}
                    value={_startDate}
                    selected={_startDate}
                    maxDate={new Date()}
                    onChange={(date: any) => {  
                      if(date){
                        setStartDate(date);
                        const myMoment = moment(date).format("yyyy-MM-DD");
                        startDate.set(myMoment);
                      }else{
                        setStartDate("");
                        startDate.set("");
                      }
                    }}
                    onKeyDown={(e: { preventDefault: () => any }) =>
                      e.preventDefault()
                    }
                  />
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </>
            )}
            {endDate && (
              <>
                <div
                  className="select-outer input-outer"
                >
                  <DatePicker
                    showYearDropdown
                    autoComplete="off"
                    placeholderText="End Date"
                    dateFormat="yyyy-MM-dd"
                    className="form-control datepicker from-date"
                    relativeSize={true}
                    value={_endDate}
                    selected={_endDate}
                    minDate={_startDate}
                    onChange={(date: any) => {
                      if(date){
                        setEndDate(date);
                        const myMoment = moment(date).format("yyyy-MM-DD");
                        endDate.set(myMoment);
                      }else{
                        setEndDate("");
                        endDate.set("");
                      }
                    }}
                    onKeyDown={(e: { preventDefault: () => any }) =>
                      e.preventDefault()
                    }
                  />
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </div>
              </>
            )}
          </div>
          {timespan && (
            <>
              <div className="select-outer">
                <select
                  value={_timespan}
                  onChange={(e: any) => {
                    if(e.target.value){
                      let days = e.target.value;
                    }else{
                    }
                    timespan.set(e.target.value)
                    setTimespan(e.target.value)
                  }}
                  className="custom-select filter_timespan"
                >
                  <option value="">Select Limit</option>
                  <option value="30">30</option>
                  <option value="60">60</option>
                  <option value="90">90</option>
                </select>
              </div>
            </>
          )}
          {limit && (
            <>
              <div className="select-outer">
                <select
                  value={_limit}
                  onChange={(e: any) => {
                    limit.set(e.target.value)
                    setLimit(e.target.value)
                  }}
                  className="custom-select filter_limit"
                >
                  <option value="20">Select Limit</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </>
          )}
          {asset && (
            <div className="select-outer">
              <select
                onChange={(e: any) => asset.set(e.target.value)}
                className="custom-select filter_asset"
              >
                <option value="">Select Asset</option>
                {AssetList?.map((data: any, index: any) => {
                  return (
                    <option value={data.id} key={index}>
                      {data.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {source && (
            <SourceDropdown
              onChange={(value: any) => {
                source.set(value);
              }}
            ></SourceDropdown>
          )}
          {transaction && (
            <TransactionTypeDropdown
              onChange={(value: any) => {
                transaction.set(value);
              }}
            ></TransactionTypeDropdown>
          )}
          {side && (
            <div className="select-outer">
              <select
                value={_side}
                onChange={(e: any) => {
                  side.set(e.target.value)
                  setSide(e.target.value)
                }}
                className="custom-select filter_side"
              >
                <option value="">Select Side</option>
                <option value="BUY">Buy</option>
                <option value="SELL">Sell</option>
              </select>
            </div>
          )}
          {base && (
            <div className="select-outer">
              <select
                value={_base}
                onChange={(e: any) => {
                  base.set(e.target.value)
                  setBase(e.target.value)
                }}
                className="custom-select filter_base"
              >
                <option value="">Select Asset</option>
                <option value="BTC">BTC</option>
                <option value="USDT">USDT</option>
              </select>
            </div>
          )}
          {market && (
            <MarketDropdown
              value={_market}
              onChange={(value: any) => {
                market.set(value);
                setMarket(value)
              }}
            ></MarketDropdown>
          )}
          {insert && (
            <div className="select-outer">
              <input
                type="text"
                placeholder="Insert Account"
                value=""
                // onChange={(e: any) => handleChangeAccount(e.target.value)}
                className="form-control filter_insert"
              />
            </div>
          )}
          {sortBy && (
            <div className="select-outer">
              <select
                value={_sortBy}
                onChange={(e: any) => {
                  sortBy.set(e.target.value)
                  setSortBy(e.target.value)
                }}
                className="custom-select filter_by"
              >
                <option value="">Sort By</option>
                <option value="account">Account</option>
                <option value="email">Email</option>
                <option value="date">Date</option>
              </select>
            </div>
          )}
          {sortOrder && (
            <div className="select-outer">
              <select
                value={_sortOrder}
                onChange={(e: any) => {
                  sortOrder.set(e.target.value)
                  setSortOrder(e.target.value)
                }}
                className="custom-select filter_order"
              >
                <option value="">Order In</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          )}
          {accountType && (
            <div className="select-outer">
              <select
                value={_accountType}
                onChange={(e: any) => {
                  accountType.set(e.target.value)
                  setAccountType(e.target.value)
                }}
                className="custom-select filter_account"
              >
                <option value="">Select Account Type</option>
                <option value="Organization">Organization</option>
                <option value="Individual">Individual</option>
              </select>
            </div>
          )}
          {verificationStatus && (
            <div className="select-outer">
              <select
                value={_verificationStatus}
                onChange={(e: any) => {
                  verificationStatus.set(e.target.value)
                  setVerificationStatus(e.target.value)
                }}
                className="custom-select filter_verification"
              >
                <option value="">Select Verification Status</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
                <option value="Processing">Processing</option>
                <option value="Require Document">Require Document</option>
                <option value="Need Confirm">Need Confirm</option>
              </select>
            </div>
          )}
          {clearFilter && (
            <div className="selector-outer">
              <button 
                className="btn-full-dark"
                onClick={()=> handleClear()}>
                  Clear
              </button>
            </div>
          )}
        </div>
        <div
          style={{ overflow: "hidden" }}
          className="col-md-4 text-right right_btn"
        >
          <div>
            <div className="filter-btnsarea">
              {/* <CSVLink
              ref={csvRef}
              data={csvData}
              className="yellow_btn"
              id="exp_data"
              style={{ marginRight: "10px", display: "none" }}
              onClick={() => console.log("clicked")}
            >
              Export
            </CSVLink> */}
              {exportCsv && (
                <a
                  href="#"
                  // onClick={export_datadepo}
                  className="main-small-btn main-small-black-btn"
                >
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>Export
                  CSV
                </a>
              )}
              {exportPdf && (
                <a
                  //   onClick={export_pdf}
                  href="#"
                  className="main-small-btn"
                >
                  <i className="fa fa-file-pdf-o" aria-hidden="true"></i>Export
                  PDF
                </a>
              )}
            </div>
          </div>
          <ActionButtons></ActionButtons>
          {searchList && searchList?.length !== 0 ? (
            <>
              <button
                className="yellow_btn"
                style={{ marginTop: 0, height: 42 }}
                onClick={() => export_data(searchParams)}
              >
                Export
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

const ActionButtons = () => {
  //   const { csvData, getTradeList } = useContext(AdminContext);
  return (
    <>
      <div className="filter-btnsarea">
        {/* <a onClick={getTradeList} href="#" className="main-small-btn">
          Search
        </a> */}
        {/* {csvData && (
          <a href="#" className="main-small-btn">
            <CSVLink
              data={csvData}
              className="yellow_btn"
              id="exp_data"
              style={{ marginRight: "10px", display: "none" }}
            ></CSVLink>
            Export
          </a>
        )} */}
      </div>
    </>
  );
};
// const GradeDropdown = ({ onChange }: any) => {
//   const { productListIsLoading, productListError, productListData } =
//     useContext(GlobalContext);
//   return (
//     <div
//       style={{
//         display: "inline-block",
//         minWidth: 210,
//         padding: 0,
//         verticalAlign: "top",
//         marginRight: 10,
//       }}
//       className="select_box"
//     >
//       <div className="styled-select">
//         <select onChange={(e: any) => onChange(e.target.value)}>
//           <option value="">Select grade</option>
//           {productListData?.data.map((asset: any, index: number) => {
//             return (
//               <option value={asset.baseAssetId}>{asset.baseAssetName}</option>
//             );
//           })}
//         </select>
//         <span className="fa fa-sort-desc"></span>
//       </div>
//     </div>
//   );
// };
// const LocationDropdown = ({ onChange }: any) => {
//   const { supplierListIsLoading, supplierListError, supplierListData } =
//     useContext(GlobalContext);
//   return (
//     <div
//       style={{
//         display: "inline-block",
//         minWidth: 210,
//         padding: 0,
//         verticalAlign: "top",
//         marginRight: 10,
//       }}
//       className="select_box"
//     >
//       <div className="styled-select">
//         <select onChange={(e: any) => onChange(e.target.value)}>
//           <option value="">Select location</option>
//           {supplierListData?.data.map((location: string, index: number) => {
//             return <option value={location}>{location}</option>;
//           })}
//         </select>
//         <span className="fa fa-sort-desc"></span>
//       </div>
//     </div>
//   );
// };

const MarketDropdown = ({ onChange }: any) => {
  const { ProdOptions } = useContext(AdminContext);
  return (
    <div className="select-outer">
      <select
        onChange={(e: any) => onChange(e.target.value)}
        className="custom-select"
        style={{ width: "167px" }}
      >
        <option value="">Select Market</option>

        {ProdOptions}
      </select>
    </div>
  );
};

const SourceDropdown = ({ onChange }: any) => {
  return (
    <div className="select-outer">
      <select
        className="custom-select"
        style={{ width: "167px" }}
        onChange={(e: any) => onChange(e.target.value)}
      >
        <option value="">Select source</option>
        <option value="Bought">Bought</option>
        <option value="Deposited">Deposited</option>
        <option value="Withdraw">Withdraw</option>
      </select>
    </div>
  );
};

interface FilterRowInterface {
  clearFilter?: any;
  searchParams?: any;
  searchList?: any;
  export_data?: any;
  insert?: any;
  exportCsv?: any;
  exportPdf?: any;
  market?: any;
  base?: any;
  asset?: any;
  transaction?: any;
  timespan?: {
    value: number;
    set: any;
  };
  verificationStatus?: {
    value: any;
    set: any;
  };
  sortBy?: {
    value: any;
    set: any;
  };
  sortOrder?: {
    value: any;
    set: any;
  };
  accountType?: {
    value: any;
    set: any;
  };
  startDate?: {
    value: any;
    set: any;
  };
  endDate?: {
    value: any;
    set: any;
  };
  side?: {
    value?: any;
    set: any;
  };
  limit?: {
    value: any;
    set: any;
  };
  grade?: {
    set: any;
  };
  location?: {
    set: any;
  };
  source?: {
    set: any;
  };
}
export const TableContainer: React.FC<any> = ({ children, isLoading }) => {
  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        {/* <img
          style={{
            display: isLoading ? "block" : "none",
            opacity: isLoading ? 1 : 0,
            width: 75,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            transition: "all 10s",
          }}
          src={require("../../images/loader.gif").default}
          alt="loader"
        /> */}
        {children}
      </div>
    </>
  );
};

const SelectLimitDropdown = ({ onChange }: any) => {
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => onChange(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option value="20">Select Limit</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
          <option value="3000">3000</option>
        </select>
      </div>
    </>
  );
};

const TransactionTypeDropdown = ({ onChange }: any) => {
  return (
    <>
      <div className="select-outer">
        <select
          onChange={(e: any) => onChange(e.target.value)}
          className="custom-select"
          style={{ width: "167px" }}
        >
          <option value="deposit,withdraw">Select transaction</option>
          <option value="deposit">Deposit</option>
          <option value="withdraw">Withdraw</option>
        </select>
      </div>
    </>
  );
};