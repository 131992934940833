import React, { useContext } from "react";
import { AdminContext } from "./AdminContextWrapper";
import {
  NavLink,
  useRouteMatch,
  useLocation
} from "react-router-dom";

const Index = () => {
  const { getusers, getBalance, getTransferList, getTradeList } = useContext(
    AdminContext
  );
  let { url } = useRouteMatch();
  const { pathname } = useLocation();
  // useEffect(() => {
  //   let settle = localStorage.getItem("settle")
  //   if(settle && settle=="yes"){
  //     let  link:any = document.getElementById('admin-trade-tab');
  //     link.click();
  //   }
  // }, []);
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="admin-user-tab"
            isActive={() => ['/admin'].includes(pathname)}
            to={`${url}`}
          >
            User
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="admin-transfer-tab"
            isActive={() => ['/admin/transfer'].includes(pathname)}
            to={`${url}/transfer`}
          >
            Transfer
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="admin-trade-tab"
            isActive={() => ['/admin/trade'].includes(pathname)}
            to={`${url}/trade`}
          >
            Trade
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="admin-setting-tab"
            isActive={() => ['/admin/setting'].includes(pathname)}
            to={`${url}/setting`}
          >
            Setting
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="admin-income-tab"
            isActive={() => ['/admin/income'].includes(pathname)}
            to={`${url}/income`}
          >
            Income
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <a
            onClick={getBalance}
            className="nav-link"
            id="admin-balance-tab"
            data-toggle="tab"
            href="#admin-balance"
            role="tab"
            aria-controls="admin-balance"
            aria-selected="false"
          >
            Balance
          </a>
        </li> */}
      </ul>
    </>
  );
};
export default Index;
