import React from "react";
import {
  NavLink,
  useRouteMatch,
  useLocation
} from "react-router-dom";

const NavTabs = () => {
  let { url } = useRouteMatch();
  const { pathname } = useLocation();

    return (
    <>
      <ul className="nav nav-tabs" >
        <li className="nav-item">
          <NavLink 
            className="nav-link"
            isActive={() => ['/profile'].includes(pathname)}
            to={`${url}`}>Account Info   
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink 
            className="nav-link"
            isActive={() => ['/profile/manage_bank_account'].includes(pathname)}
            to={`${url}/manage_bank_account`}>Manage Bank Account</NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            isActive={() => ['/profile/manage_crypto_address'].includes(pathname)}
            to={`${url}/manage_crypto_address`}>Manage Crypto Address
          </NavLink>
        </li>
      </ul>
    </>
  );
};
export default NavTabs;
