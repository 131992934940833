import React, { useContext } from "react";
import Loader from "../Loader";
import { MarketContext } from "./MarketContextWrapper";
import RecentTrades from "./RecentTrades";
import TradingBox from "./TradingBox";
import { Quote } from "./useMarket";

export function formatDate(date: any) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return (
    date.getMonth() +
    1 +
    "/" +
    date.getDate() +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
}
const ExpireTrade = () => {
  const { expireQuate, ExpTemp, TradeTemp, TradeQuate, cancelQuate } =
  useContext(MarketContext);
  return (
    <>
      <>
        <section className="expire-trade-area">
          <div className="container">
            <div className="row">
              <aside className="col-lg-6">
                <div>
                  <h3>Expired Quotes</h3>
                  <Loader visible={!expireQuate}></Loader>
                  {expireQuate?.length !== 0 ? (
                    <>
                      {expireQuate?.map((quate: Quote, index: any) => (
                        <TradingBox isExpired={true} quate={quate} key={index} />
                      ))}
                    </>
                  ) : (
                    <>No expired quotes found</>
                  )}
                </div>
                <div>
                  <h3>Cancelled Quotes</h3>
                    <Loader visible={!cancelQuate}></Loader>
                    {cancelQuate.cancelled ? (
                      <>
                        <TradingBox isExpired={true} quate={cancelQuate} key={cancelQuate.orderId} />
                      </>
                    ) : (
                      <>No cancelled quotes found</>
                    )}
                </div>
              </aside>
              <aside className="col-lg-6">
                <h3>Recent Trades</h3>
                <RecentTrades />
              </aside>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
export default ExpireTrade;
