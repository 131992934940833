import jsPDF from "jspdf";
import { useContext, useEffect, useRef, useState } from "react";
import { QueryClient, useQueryClient } from "react-query";
import { useAuth } from "../../auth-context";
import { AuthContext } from "../../contexts/AuthContext";
import { userService } from "../../_services";

export interface BalanceAccount {
  Coin: string;
  Total: Float32Array;
  Available: Float32Array;
  Locked: Float32Array;
}

function useBalance(initialValue = false): {
  get_deposite_address: any;
  accountInfo: BalanceAccount[];
  deposit_req: any;
  depo_paramErr: any;
  depo_param: any;
  allowNumbersOnly: any;
  userInfo: any;
  bankdata: any;
  deposit_req1: any;
  addressOnly: any;
  deposite_address: any;
  AssetName: any;
  withdraw_request: any;
  bankdetails: any;
  Err_type: any;
  Err_amt: any;
  handleChangeWithdraw_amt: any;
  availableamt: any;
  openModaldepoc: any;
  closeModaldepoc: any;
  CurrencyBal: any;
  openModalwith: any;
  closeModalwith: any;
  csvData: any;
  balance_export_pdf: any;
  openVerifyAlertModal: any;
  setOpenVerifyAlertModal: any;
  CurrencyType: any;
} {
  const queryClient = useQueryClient();
  const csvRef = useRef() as any;
  const [csvData, setcsvData] = useState([]) as any;
  const { userInfo } = useAuth();
  const [CurrencyBal, setCurrencyBal] = useState<any>();
  // const [ProductList,setProductList] = useState() as any;
  // const [copy, setCopy] = useState(false);
  const [availableamt, Setavailableamt] = useState(0) as any;
  const [Err_amt, SetErr_amt] = useState("") as any;
  const [Err_type, SetErr_type] = useState("") as any;
  //  const [side,SetSide] = useState("") as any;
  // const [side1,SetSide1] = useState("") as any;
  // const [modalIsOpenSucc,setIsOpenSucc] =useState(false);
  const [modalIsOpenwithdraw, setIsOpenwithdraw] = useState(false);
  // const [modalIsOpenwithdraw1,setIsOpenwithdraw1] =useState(false);
  // const [modalIsOpendeposite1,setIsOpendeposite1] =useState(false);
  // const [depo_result,setDepoResult] = useState([]) as any;
  // const [depo_address,SetDepoaddress] = useState("") as any;
  const [bankdetails, setbankdetail] = useState() as any;
  const [bankdata, setbankdata] = useState() as any;
  const [assetType, SetassetType] = useState("All") as any;
  // const [reason_msg,setReason] =useState("") as any;
  // const [titlehead,setHead] =useState("Reason") as any;
  const [deposite_address, setdeposite_address] = useState("") as any;
  const [AssetName, setAssetName] = useState() as any;
  const [CurrencyType, setCurrencyType] = useState() as any;
  const [modalIsOpendeposite, setIsOpendeposite] = useState(false);
  const [modalIsOpendepositec, setIsOpendepositec] = useState(false);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [openVerifyAlertModal, setOpenVerifyAlertModal] = useState(false);

  function allowNumbersOnly(e: any) {
    var code = e.which ? e.which : e.keyCode;
    if (code > 31 && (code < 48 || code > 57)) {
      e.preventDefault();
    }
  }
  function addressOnly(e: any) {
    depo_param.cryptoFromAddress = e;
    // SetDepoaddress(e)
  }

  const balance_export_pdf = () => {
    // initialize jsPDF
    const doc: any = new jsPDF();

    // define the columns we want and their titles
    const tableColumn = ["Coin", "Total", "Available", "Locked"];
    // define an empty array of rows
    const tableRows: any = [];

    // for each ticket pass all its data into an array
    csvData.forEach((ticket: any) => {
      const ticketData = [
        ticket.Coin,
        ticket.Total,
        ticket.Available,
        ticket.Locked,
      ];
      // push each tickcet's info into a row
      tableRows.push(ticketData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text("CatchOTC Balance", 14, 15);
    doc.save(`catchOTC_balance.pdf`);
  };
  const withdraw_request = () => {
    userService
      .withdrawRequest(with_param)
      .then(function (response: any) {
        closeModalwith();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function bankdetail(asset: any) {
    userService
      .get_bankdetail(asset)
      .then(function (response: any) {
        setbankdata(response.data);
        setIsOpendeposite(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function get_deposite_address(asset: any) {
    userService
      .get_deposite_address(asset)
      .then(function (response: any) {
        console.log(response.data)
        setdeposite_address(response.data.address);
      })
      .catch((error) => {
        // settradetyle({display:"none"})
        console.log(error);
      });
  }

  function openModaldepo(asset: any, assetname: any, curType: any, data: any) {
    // alert(id+"===="+name)
    // /console.log(data)
    if (userInfo.completeKYC) {
      depo_param.asset = asset;
      bankdetail(asset);
      setAssetName(assetname);
      setCurrencyType(curType);
    } else {
      setOpenVerifyAlertModal(true);
    }
  }

  function closeModaldepo() {
    setIsOpendeposite(false);
  }
  function closeModaldepoc() {
    setdeposite_address("No address found");
    setIsOpendepositec(false);
  }
  function openModaldepoc(
    asset: any,
    assetname: any,
    curType: any,
    Currrency: any
  ) {
    // alert(asset)
    // console.log(Currrency)
    if (userInfo.completeKYC) {
      get_deposite_address(asset);
      setAssetName(assetname);
      depo_param.asset = asset;
      setCurrencyType(curType);
      setIsOpendepositec(true);
    } else {
      setOpenVerifyAlertModal(true);
    }
  }
  // function openModalwith1(){
  //   if(with_param.amount && (with_param.address || with_param.userBankId)){
  //     SetErr_amt("");
  //     SetErr_type("");
  //     setbankdetail()
  //     closeModalwith()
  //     setIsOpenwithdraw1(true);
  //   }else{
  //     if(!with_param.amount){
  //       SetErr_amt("Enter amount");
  //     }else{
  //       SetErr_amt("");
  //     }
  //     if(!with_param.address && !with_param.userBankId){
  //       SetErr_type("Please select option");
  //     }
  //   }

  //  }
  //  function closeModalwith1(){

  //    setIsOpenwithdraw1(false);
  //  }

  function openModalwith(
    asset: any,
    assetname: any,
    curType: any,
    available: any
  ) {
    // alert(curType)
    SetErr_amt("");
    SetErr_type("");
    Setavailableamt(available);
    setCurrencyType(curType);
    setAssetName(assetname);
    with_param.asset = asset;
    setIsOpenwithdraw(true);
  }
  function closeModalwith() {
    setbankdetail();
    setIsOpenwithdraw(false);
  }
  const customStyles = {
    content: {
      left: "50%",
      border: "none",
      //  background                 : '#fff',
    },
    //  overlay: {zIndex: 99999}
  };
  // eslint-disable-next-line
  const [depo_param, SetParamsDepo] = useState({
    asset: "currency",
    amount: 0,
    wet: 0,
    div: 0,
    dry: 0,
    pct: 0,
    fine: 0,
  }) as any;

  const [depo_paramErr, SetParamsDepoErr] = useState({
    asset: "currency",
    amount: "",
    wet: "",
    div: "",
    dry: "",
    pct: "",
    fine: "",
  }) as any;
  // eslint-disable-next-line
  const [with_param, SetParamsWith] = useState({
    asset: "USD",
    address: "",
    bankAccountName: "",
    bankAccountNo: "",
    bankAddress: "",
    bankName: "",
    bankSwiftCode: "",
  }) as any;

  // function closeModalSucc(){

  // setIsOpenSucc(false);
  // }
  const handleChangeWithdraw_amt = (amount: any) => {
    if (amount > availableamt) {
      SetErr_amt("Invalid amount");
    } else {
      SetErr_amt("");
      with_param.amount = amount;
    }
  };
  const handleChangeWithdraw = (bankdetail: any) => {
    with_param.bankAccountName = bankdetail.bankAccountName;
    with_param.bankAccountNo = bankdetail.bankAccountNo;
    with_param.bankAddress = bankdetail.bankAddress;
    with_param.bankName = bankdetail.bankName;
    with_param.bankSwiftCode = bankdetail.bankSwiftCode;
    with_param.asset = bankdetail.currency.name;
    with_param.userBankId = bankdetail.id;
    with_param.type = "Bank";

    setbankdetail(bankdetail);
    // console.log(bankdetail,"======>")
  };
  const handleChangeWithdraw1 = (bankdetail: any) => {
    //  console.log(bankdetail,"======>")
    with_param.address = bankdetail.address;
    with_param.userAddressId = bankdetail.id;

    with_param.asset = bankdetail.asset.id;

    with_param.type = "Crypto";
    // console.log(bankdetail,"======>")
  };

  const deposit_req = () => {
    // openModaldepo1()
    //alert(depo_param.amount)
    if (depo_param.amount > 0 && depo_param.amount !== "") {
      SetParamsDepoErr({ amount: "" });
      userService
        .deposit_request(depo_param)
        .then(function (response: any) {
          //  console.log(response.data,"=====trade")
          closeModaldepo();
          //  setDepoResult(response.data)
          //  openModaldepo1()
        })
        .catch((error) => {
          // settradetyle({display:"none"})

          console.log(error);
        });
    } else {
      SetParamsDepoErr({ amount: "Amount can not be Empty" });
    }
  };
  const deposit_req1 = () => {
    if (depo_param.amount > 0 && depo_param.amount !== "") {
      SetParamsDepoErr({ amount: "" });
      userService
        .deposit_request(depo_param)
        .then(function (response: any) {
          //  console.log(response.data,"=====trade")

          queryClient.invalidateQueries(["CurrencyBalance"]);
          closeModaldepoc();
        })
        .catch((error) => {
          // settradetyle({display:"none"})

          console.log(error);
        });
    } else {
      SetParamsDepoErr({ amount: "Amount can not be Empty" });
    }
  };
  // function openModaldepo1(){
  //   // alert(id+"===="+name)
  //   closeModaldepo()
  //   setIsOpendeposite1(true);
  // }
  // function closeModaldepo1(){

  //   setIsOpendeposite1(false);
  // }

  useEffect(() => {
    // userService.get_product().then(function (response:any) {

    //     setProductList(response.data)

    //      }).catch(error => {
    //         console.log(error)
    //     });
    userService
      .get_balance()
      .then(function (response: any) {
        var arr: any = [];
        response.data.forEach(function (value: any, k: any) {
          arr.push({
            Coin: value.asset.name,
            Total: value.total.toFixed(8),
            Available: value.free.toFixed(8),
            Locked: value.locked.toFixed(8),
          });
        });
        setcsvData(arr);
        setCurrencyBal(response.data);
      })
      .catch((error) => {
       if(error?.response?.status === 401 ){
           window.location.href = "/login";
       }
        console.log(error);
      });

    // eslint-disable-next-line
  }, []);

  const handleChangeasset = (asset: any) => {
    SetassetType(asset);
  };

  return {
    get_deposite_address,
    accountInfo: csvData,
    deposit_req,
    depo_paramErr,
    depo_param,
    allowNumbersOnly,
    userInfo,
    bankdata,
    deposit_req1,
    addressOnly,
    deposite_address,
    AssetName,
    withdraw_request,
    bankdetails,
    Err_type,
    Err_amt,
    handleChangeWithdraw_amt,
    availableamt,
    openModaldepoc,
    closeModaldepoc,
    CurrencyBal,
    openModalwith,
    closeModalwith,
    csvData,
    balance_export_pdf,
    openVerifyAlertModal,
    setOpenVerifyAlertModal,
    CurrencyType,
  };
}
export default useBalance;
