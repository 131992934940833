import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './userDetails.style.css';
import CopyTool from '../../../../Utils/CopyTool';
import Loader from '../../../../Loader';
import {
  AddressListDataType,
  AddressListType,
  BankListDataType,
  BankListType,
  UserDetailDataInterface,
} from '../../../financeDataType';

function UserDetailsModal({ data }: { data: UserDetailDataInterface }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { name, email, addressList, bankList, username, accountType } = data;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data.name) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <>
      <button onClick={handleShow} className="detail_btn">
        <OverlayTrigger
          key={`user-details-${username}`}
          placement="bottom"
          overlay={
            <Tooltip id={`user-details-${username}`}>User Details</Tooltip>
          }
        >
          <span className="clr-dark">
            <BsThreeDotsVertical />
          </span>
        </OverlayTrigger>
      </button>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-full-size"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>User Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loader visible={isLoading} />
          ) : (
            <>
              <div className="user_detail_info">
                <div className="user_detail_info_row">
                  <div className="user_detail_info_row_title ">
                    <span>
                      <strong>Name</strong>
                    </span>
                  </div>
                  <div>
                    <span>{name}</span>
                  </div>
                </div>
                <div className="user_detail_info_row">
                  <div className="user_detail_info_row_title ">
                    <span>
                      <strong>Email</strong>
                    </span>
                  </div>
                  <div>
                    <span>{email}</span>
                  </div>
                </div>
                <div className="user_detail_info_row">
                  <div className="user_detail_info_row_title">
                    <span>
                      <strong>Account Type</strong>
                    </span>
                  </div>
                  <div>
                    <span>{accountType}</span>
                  </div>
                </div>
              </div>
              <br />
              <div className="tab-content white-shadowbox">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="admin-banklist-tab"
                      data-toggle="tab"
                      href="#admin-banklist"
                      role="tab"
                      aria-controls="admin-banklist"
                      aria-selected="true"
                    >
                      Bank Info
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="admin-addresslist-tab"
                      data-toggle="tab"
                      href="#admin-addresslist"
                      role="tab"
                      aria-controls="admin-addresslist"
                      aria-selected="false"
                    >
                      Crypto Withdrawal Address
                    </a>
                  </li>
                </ul>
              </div>
              <div className="table-responsive tab-content">
                <BankList_table data={bankList} />
                <CryptoAddressList_table data={addressList} />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default UserDetailsModal;

const BankList_table = ({ data }: BankListType) => {
  return (
    <div
      className="tab-pane active"
      id="admin-banklist"
      role="tabpanel"
      aria-labelledby="admin-banklist-tab"
    >
      <table className="main-table user_detail_container">
        <thead>
          <tr>
            <th>Currency</th>
            <th>Description</th>
            <th>Bank Account Name</th>
            <th>Bank Name</th>
            <th>Bank Branch Name</th>
            <th>Bank Swift Code</th>
            <th>Bank Account Number</th>
            <th>Bank Address</th>
            <th>Intermediary Bank Address</th>
            <th>Intermediary Bank Name</th>
            <th>Intermediary Bank Swift Code</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr className="user_detail_row">No Data Available</tr>
          )}
          {data.length > 0 &&
            data.map((item: BankListDataType, index: number) => {
              const {
                currency,
                description,
                bankAccountName,
                bankName,
                bankBranchName,
                bankSwiftCode,
                bankAccountNo,
                bankAddress,
                intermediaryBankAddress,
                intermediaryBankName,
                intermediaryBankSwiftCode,
              } = item;
              return (
                <tr className="user_detail_row" key={index}>
                  <td>{currency.name}</td>
                  <td>{description}</td>
                  <td>{bankAccountName}</td>
                  <td>{bankName}</td>
                  <td>{bankBranchName}</td>
                  <td>
                    <CopyTool text={bankSwiftCode} />
                  </td>
                  <td>
                    <CopyTool text={bankAccountNo} />
                  </td>
                  <td>{bankAddress}</td>
                  <td>{intermediaryBankAddress}</td>
                  <td>{intermediaryBankName}</td>
                  <td>{intermediaryBankSwiftCode}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const CryptoAddressList_table = ({ data }: AddressListType) => {
  return (
    <div
      className="tab-pane"
      id="admin-addresslist"
      role="tabpanel"
      aria-labelledby="admin-addresslist-tab"
    >
      <table className="main-table user_detail_container">
        <thead>
          <tr>
            <th>Currency</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 && (
            <tr className="user_detail_row">No Data Available</tr>
          )}
          {data.length > 0 &&
            data.map((item: AddressListDataType, index: number) => {
              const { asset, address } = item;
              let imageSrc = '';
              try {
                imageSrc = require('../../../../../images/' +
                  asset.name +
                  '.png').default;
              } catch (e) {
                imageSrc = require('../../../../../images/fevicon.png').default;
              }
              return (
                <tr className="user_detail_row" key={index}>
                  <td>
                    <img
                      src={imageSrc}
                      alt={asset.name}
                      height={25}
                      width={25}
                    />
                    {asset.name}
                  </td>
                  <td>
                    <CopyTool text={address} digit={6} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
