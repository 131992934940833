import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MarketContext } from "./MarketContextWrapper";

const SuccessModal = () => {
  const { modalIsOpencong, setIsOpencong } = useContext(MarketContext);
  const [show, setShow] = useState(modalIsOpencong);
  useEffect(() => {
    setShow(modalIsOpencong);
  }, [modalIsOpencong]);

  function openModalcong() {
    setIsOpencong(true);
  }
  function closeModalcong() {
    setIsOpencong(false);
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        onHide={closeModalcong}
      >
        <Modal.Body>
          <div style={{ margin: 20, textAlign: "center" }}>
            <img
              src={require("../../images/green_check.png").default}
              alt="green"
            />
            <h3>Congratulations</h3>
            <p>You have got new trade.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalcong}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SuccessModal;
