import React from "react";
import ManageBankAccount from "./ManageBankAccount";
import ManageCryptoAddress from "./ManageCryptoAddress";
import ProfileInfo from "./ProfileInfo";
import ProfileContextWrapper from "./ProfileContextWrapper";
import NavTabs from "./NavTabs";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

const Index = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <ProfileContextWrapper>
        <section className="inner-main-section my-profilearea">
          <div className="container">
            <h3 className="main-heading-title">Information</h3>
            <div className="white-shadowbox">
              <NavTabs/>
              <div className="padding20">
                <Switch>
                  <Route exact path={path}>
                    <ProfileInfo/>
                  </Route>
                  <Route exact path={`${path}/manage_bank_account`}>
                    <ManageBankAccount/>
                  </Route>
                  <Route exact path={`${path}/manage_crypto_address`}>
                    <ManageCryptoAddress/>
                  </Route>
                  <Redirect from="*" to="/profile" />
                </Switch>
              </div>
            </div>
          </div>
        </section>
      </ProfileContextWrapper>
    </>
  );
};
export default Index;
