import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { Context } from '../../../Utils/ContextWrapper';
import { AuthContext } from '../../../../contexts/AuthContext';
import { tokenService } from '../../../../_services/tokens.services';
import { config } from '../../../../config/config';
import { TransferDetail } from '../../../Utils/TransferDetail';
import { TransferModalInterface } from '../../financeDataType';

const DenyModal = ({ data }: TransferModalInterface) => {
  const { id, type } = data;
  const [rejectReason, setRejectReason] = useState('');
  const [show, setShow] = useState(false);
  const [deny, setDeny] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { configheadersTemplate } = tokenService;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);
  const rejectMutation: any = useMutation(
    ({ rejectReason }: any) => {
      let url = `${config.apiurl}/transfer/finance/deny/${id}`;
      let configheaders = configheadersTemplate();
      let formData = new FormData();
      formData.append('rejectReason', rejectReason);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setRejectReason('');
        queryClient.invalidateQueries(['FinanceTransfer', fetchParams]);
        setToastObj({
          message: 'You have successfully denied the request.',
          type: 'success',
        });
      },
      onError: (data, variables, context) => {
        setRejectReason('');
        queryClient.invalidateQueries(['FinanceTransfer', fetchParams]);
        setToastObj({
          message: 'Denied Request Failed',
          type: 'error',
        });
      },
    }
  );
  function handleDenyBtn() {
    handleShow();
    setDeny(false);
    setRejectReason('');
  }
  function handleSaveChange() {
    setDeny(true);
  }
  function handleSubmitChange() {
    rejectMutation.mutate({ rejectReason });
  }
  return (
    <>
      <Button
        className="btn-alert-border row-ele-center"
        onClick={handleDenyBtn}
      >
        <strong>Deny</strong>
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3 className="modal-title" id="exampleModalLabel">
              Deny Transfer
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!deny && <TransferDetail data={data} role="finance" />}
          <Form>
            {deny ? (
              <div className="confirm_info">
                <span>Are you sure you want to reject this tranfer?</span>
                {rejectReason && <h5>Reject Reason : {rejectReason}</h5>}
              </div>
            ) : (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                  <strong>Reject Reason</strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter if any"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setRejectReason(event.currentTarget.value);
                  }}
                />
                <Form.Text className="text-muted">*Optional</Form.Text>
              </Form.Group>
            )}
            <hr />
            <div className="btn-container">
              <Button
                type="button"
                className="btn-full-dark"
                onClick={handleClose}
              >
                Cancel
              </Button>
              {!deny && (
                <Button
                  type="button"
                  className="btn-full-light"
                  onClick={handleSaveChange}
                >
                  Deny
                </Button>
              )}
              {deny && (
                <Button
                  type="button"
                  className="btn-full-light"
                  onClick={handleSubmitChange}
                >
                  {rejectMutation.isLoading ? <>Submitting...</> : <>Submit</>}
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DenyModal;
