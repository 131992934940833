import React, { useContext, useState } from "react";
import { useAuth } from "../../../auth-context";
import { AuthContext } from "../../../contexts/AuthContext";
import ConfirmDeleteBank from "../ConfirmDeleteBank";
import NewBankModal from "../NewBankModal";
import { ProfileContext } from "./../ProfileContextWrapper";

const Index = () => {
  const { userIsLoading, userInfo } = useAuth();
  const [currentSelectedBank, setCurrentSelectedBank] = useState(null);

  return (
    <>
      <div className="white-shadowbox">
        <div className="padding20">
          <h3 className="section-subheading section-subheading2">
            Manage Bank Account
          </h3>
          <p>
            We <b>DO NOT</b> send to or receive funds from 3rd parties, please
            ensure the bank account details provided are correct and are in the
            exact name of the account you contracted with CatchOTC.
          </p>
          {/* <pre>{JSON.stringify(userInfo, undefined, 2)}</pre> */}
          <div className="table-responsive">
            <table cellPadding="0" cellSpacing="0" className="main-table">
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Description</th>
                  <th>Bank Name</th>
                  <th>Bank Account Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userIsLoading && <>Loading</>}
                {!userIsLoading &&
                  userInfo?.bankList &&
                  Object.keys(userInfo?.bankList).map((key) => {
                    return (
                        <tr key={key}>
                          <td>{userInfo.bankList[key].currency.name}</td>
                          <td>{userInfo.bankList[key].description}</td>
                          <td>{userInfo.bankList[key].bankName}</td>
                          <td>{userInfo.bankList[key].bankAccountNo}</td>
                          <td>
                            <a
                              onClick={() => {
                                setCurrentSelectedBank(userInfo.bankList[key]);
                              }}
                              className="border-btn border-black-btn"
                              data-toggle="modal"
                              data-target="#confirm-delete-bank-popup"
                            >
                              Delete
                            </a>
                          </td>
                        </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="text-center margintop40">
            <a
              role="button"
              className="main-color-btn main-color-bigbtn"
              data-toggle="modal"
              data-target="#add-new-bank-popup"
            >
              Add New Bank Account
            </a>
          </div>
        </div>
      </div>
      <NewBankModal></NewBankModal>
      <ConfirmDeleteBank
        currentSelectedBank={currentSelectedBank}
      ></ConfirmDeleteBank>
    </>
  );
};
export default Index;
