import React, { useContext } from "react";
import { MarketContext } from "./MarketContextWrapper";
import { Quote } from "./useMarket";
import Arrow from "../../images/long-arrow.png";
import { formatNumber } from "../Utils/index";

const Confirm = () => {
  const { QuateData, take_quate, cancel_quate }: { QuateData: Quote[]; take_quate: any; cancel_quate: any } =
    useContext(MarketContext);
  const index = 0;
  const quate = QuateData[index];

  return (
    <>
      {quate && (
        <div
          className="confirm-trading"
          style={{ display: QuateData ? "block" : "none" }}
        >
          <div className="trading-box">
            <div className="trading-topbox">
              <img src="images/dollar-icon.png" alt="" />
              <p>
                {`${quate.side} ${formatNumber(quate.baseCurrencySize)} ${quate.baseCurrency} with ${formatNumber(quate.quoteCurrencySize)} ${quate.quoteCurrency}`}
              </p>
            </div>
            <div className="trading-bottombox">
              <div className="box-3">
                <h3>{quate.quoteCurrency}</h3>
                <p>{formatNumber(quate.quoteCurrencySize)}</p>
              </div>
              <div className="box-3 trading-price">
                <img
                  style={{
                    transform:
                      quate.side === "BUY" ? "rotate(0deg)" : "rotate(180deg)",
                  }}
                  src={Arrow}
                  alt=""
                />
                <p>Price {formatNumber(quate.price)}</p>
              </div>
              <div className="box-3">
                <h3>{quate.baseCurrency}</h3>
                <p>{formatNumber(quate.baseCurrencySize)}</p>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <div className="trading-expire-in">
            <p>
              Expires in:{" "}
              <span className="expire-time">
                <i className="fa fa-clock-o" aria-hidden="true"></i>
                {quate.mysecond} Seconds!
              </span>
            </p>
            <div className="progress-bar">
              <div
                className="progress-bar-inner"
                style={{ width: `${quate.percentage}%` }}
              ></div>
            </div>
          </div>
          <button
            className="sub-color-btn sub-color-bigbtn"
            type="button"
            data-toggle="modal"
            data-target="#failed-popup"
            onClick={() => {
              cancel_quate(quate.quoteId, index);
            }}
          >
            Cancel
          </button>
          <button
            className="main-color-btn main-color-bigbtn"
            type="button"
            data-toggle="modal"
            data-target="#failed-popup"
            onClick={() => {
              take_quate(quate.quoteId, index);
            }}
          >
            Confirm
          </button>
        </div>
      )}
    </>
  );
};
export default Confirm;
