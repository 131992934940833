import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

const PaginationComponent = ({
  activePage = 1,
  count,
  totalCount,
  onChange = (page: any) => {},
}: {
  onChange?: any;
  activePage?: any;
  count: any;
  totalCount: any;
}) => {
  const [_active, setActive] = useState(activePage);
  const [limit, setCount] = useState(count);
  const [_totalCount, setTotalCount] = useState(totalCount);
  let items = [];

  useEffect(() => {
    count && setCount(count);
    totalCount && setTotalCount(totalCount);
  }, [count, totalCount]);

  const numOfPages = Math.ceil(_totalCount / limit);

  for (let number = 1; number <= numOfPages; ++number) {
    items.push(
      <Pagination.Item
        key={number}
        onClick={() => {
          setActive(number);
          onChange(number);
        }}
        active={number === _active}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      {items.length > 1 && (
        <div style={{ marginTop: 10 }}>
          <Pagination>{items}</Pagination>
        </div>
      )}
    </>
  );
};

export default PaginationComponent;
