const tradeMsg: any = {
    errorMsg: {
        USD: "The maximum size of USD per trade is 3 million.",
        USDT: "The maximum size of USDT per trade is 3 million.",
        USDC: "The maximum size of USDC per trade is 3 million.",
        BTC: "The maximum size of BTC per trade is 50 coins.",
        ETH: "The maximum size of ETH per trade is 500 coins.",
    }
}
const createTradeFailedMsg = (tradeInfo: any)=> {
    if(tradeInfo.baseCurrencySize !== 0 && tradeInfo.quoteCurrencySize === 0){
        const targetCurrency = tradeInfo.baseCurrency.toUpperCase();
        switch (targetCurrency) {
            case "USDT":
                return tradeMsg.errorMsg.USDT;
            case "USDC":
                return tradeMsg.errorMsg.USDC;
            case "ETH":
                return tradeMsg.errorMsg.ETH;
            case "BTC":
                return tradeMsg.errorMsg.BTC;
            }    
    }else{
        return tradeMsg.errorMsg.USD;
    }
}

export { createTradeFailedMsg }
