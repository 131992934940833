import { useContext, useState, useEffect } from 'react';
import { ProfileContext } from './ProfileContextWrapper';
import { ErrorMessage } from '../Utils';
import { AuthContext } from '../../contexts/AuthContext';
import * as yup from 'yup';

const CryptoAddressModal = () => {
  const [error, setError] = useState<any>(null);
  const { setToastObj } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const {
    myparams_bank,
    myparams_crypto,
    addcrypto,
    ProductList,
    saveCryptoAsset,
    SetParamsCrypto,
  } = useContext(ProfileContext);
  let schema = yup.object().shape({
    address: yup.string().required(),
    addressSuffix: yup.string(),
  });
  function handleSaveChange(event: any) {
    if (event.type === 'change') {
      const { name, value } = event.target;
      SetParamsCrypto({
        ...myparams_crypto,
        [name]: value,
      });
    }
  }
  useEffect(() => {
    if (add) {
      setError(null);
      setAdd(false);
    }
  }, [add]);
  return (
    <>
      <div
        className="modal fade"
        id="add-new-crypto-popup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Crypto Address
              </h5>
              <button
                type="button"
                className="close close-popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close.svg" alt="" className="" />
              </button>
            </div>
            <div className="modal-body">
              <form className="withdrawal-formarea main-form">
                <div className="form-group">
                  <label>Currency:</label>
                  <select
                    className="form-control custom-select"
                    style={{ width: '100%' }}
                    name="asset"
                    onChange={(e: any) => saveCryptoAsset(e.target.value)}
                  >
                    {ProductList &&
                      Object.keys(ProductList).map(function (key: any) {
                        if (ProductList[key].currencyType === 'Crypto') {
                          let i = 0;
                          if (i === 0) {
                            myparams_bank.asset = ProductList[key].id;
                          }
                          i++;
                          return (
                            <option value={ProductList[key].id} key={key}>
                              {ProductList[key].name}
                            </option>
                          );
                        }
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Address:</label>
                  <input
                    className="form-control"
                    placeholder="Enter Address (Required)"
                    type="text"
                    name="address"
                    onChange={handleSaveChange}
                    value={myparams_crypto.address}
                  />
                  <ErrorMessage path={'address'} error={error}></ErrorMessage>
                </div>
                <div className="form-group">
                  <label>Address Suffix:</label>
                  <input
                    className="form-control"
                    placeholder="Enter Address Suffix:Tag,Memo..."
                    type="text"
                    name="name"
                    onChange={handleSaveChange}
                    value={myparams_crypto.name}
                  />
                  <ErrorMessage
                    path={'addressSuffix'}
                    error={error}
                  ></ErrorMessage>
                </div>
                <div className="text-center margintop40">
                  <a
                    data-dismiss="modal"
                    className="main-color-btn main-color-bigbtn"
                    type="submit"
                    onClick={() => {
                      const { name, address } = myparams_crypto;
                      const validationForm = {
                        address: address,
                        addressSuffix: name,
                      };
                      schema
                        .validate(validationForm)
                        .then((valid: any) => {
                          if (valid) {
                            addcrypto();
                            setAdd(true);
                            setToastObj({
                              message: 'You have added the new crypto address.',
                              type: 'success',
                            });
                            SetParamsCrypto({
                              asset: '',
                              address: '',
                              name: '',
                            });
                          }
                        })
                        .catch((err: any) => {
                          setError(err);
                          setToastObj({
                            message: 'Failed to add new crypto address',
                            type: 'error',
                          });
                          SetParamsCrypto({
                            asset: '',
                            address: '',
                            name: '',
                          });
                        });
                    }}
                  >
                    Submit
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CryptoAddressModal;
