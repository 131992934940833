import { useContext, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { AuthContext } from './../../contexts/AuthContext';
import './utils_style.css';

const ToastNotification = () => {
  const { toastObj } = useContext(AuthContext);
  let toastMessage = toastObj?.message;
  let toastType = toastObj?.type;

  useEffect(() => {
    if (toastObj) {
      if (toastType === 'error') {
        toast.error(toastMessage || 'Error');
      } else if (toastType === 'success') {
        toast.success(toastMessage || 'Success');
      }
    }
  }, [toastObj]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        limit={3}
        className="toast_container"
      />
    </div>
  );
};
export default ToastNotification;
