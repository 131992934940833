import userEvent from "@testing-library/user-event";
import React from "react";
import AddFeeModal from "./AddFeeModal";
import AddRoleModal from "./AddRoleModal";
import AdminContextWrapper from "./AdminContextWrapper";
import AdminTransferAddModal from "./AdminTransferAddModal";
import AssignCompanyModal from "./AssignCompanyModal";
import Balance from "./Balance";
import Setting from "./Setting";
import Income from "./Income";
import BalancePopModal from "./BalancePopModal";
import ConfirmModal from "./ConfirmModal";
import NavTabs from "./NavTabs";
import RefineModal from "./RefineModal";
import Trade from "./Trade";
import Transfer from "./Transfer";
import TransferPop2Modal from "./TransferPop2Modal";
import TransferPopModal from "./TransferPopModal";
import User from "./User";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

const Index = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <AdminContextWrapper>
        <section className="inner-main-section">
          <div className="container">
            <h3 className="main-heading-title">Admin</h3>
            <div className="white-shadowbox">
              <NavTabs></NavTabs>
              <div className="padding20">
                {/* <div className="tab-content"> */}
                <Switch>
                  <Route exact path={`${path}`}>
                    <User/>
                  </Route>
                  {/* <Route exact path={`${path}/balance`}>
                    <Balance/>
                  </Route> */}
                  <Route exact path={`${path}/transfer`}>
                    <Transfer/>
                  </Route>
                  <Route exact path={`${path}/trade`}>
                    <Trade/>
                  </Route>
                  <Route exact path={`${path}/setting`}>
                    <Setting/>
                  </Route>
                  <Route exact path={`${path}/income`}>
                    <Income/>
                  </Route>
                  <Redirect from="*" to="/admin"/>
                  <AddFeeModal></AddFeeModal>
                  <AddRoleModal></AddRoleModal>
                  <AdminTransferAddModal></AdminTransferAddModal>
                  <AssignCompanyModal></AssignCompanyModal>
                  <BalancePopModal></BalancePopModal>
                  <ConfirmModal></ConfirmModal>
                  <RefineModal></RefineModal>
                  <TransferPop2Modal></TransferPop2Modal>
                  <TransferPopModal></TransferPopModal>
                </Switch>
                </div>
              </div>
            {/* </div> */}
          </div>
        </section>
      </AdminContextWrapper>
    </>
  );
};
export default Index;
