import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Kyc.module.scss';

const window_: any = window;

const Kyc = () => {
  const onClose = () => {};

  const [isKycLoading, setIsKycLoading] = useState(true);

  const FRANKIE_URL = process.env.REACT_APP_FRANKIE_URL;

  const FRANKIE_CONFIG = {
    frankieBackendUrl: FRANKIE_URL,
    documentTypes: [
      {
        type: 'PASSPORT',
        acceptedCountries: 'ALL',
      },
    ],
    requestAddress: true,
    acceptedCountries: 'ALL',
    progressBar: true,
    ageRange: [18, 125],
    idScanVerification: true,
    checkProfile: 'auto',
    useLiveness: true,
    maxAttemptCount: 5,
    welcomeScreen: {
      htmlContent: `
          <div class="wrapper">
            <h1>Before you start...</h1>
            <p class='bold'>We need to collect some personal information to verify your identity before we can open your account.</p>
            <span>It’s important you enter your details as accurately as possible. This will save time and get you into your new account right away!</span>
            <b>You’ll need:</b>
            <ol>
              <li>We need 5 mins of your time to complete this application</li>
              <li>You must be over 18 years of age</li>
              <li>A valid passport</li>
            </ol>
          </div>
          <style>
            .wrapper {
              padding: 16px; 
            }
            span {
              display: block;
            }
            p {
              font-size: 16px;
              font-weight: bold;
            }
          </style>
        `,
      ctaLabel: 'Identify me!',
    },
    googleAPIKey: false,
    organisationName: 'Inbase Partners Taiwan Limited',
    phrases: {
      document_select: {
        title: 'Verify your identity',
        subtitle:
          'We’ll need you to use your device to scan your PASSPORT and record a photo of yourself.',
        hint_message: 'Click on the following to proceed.',
        footer_text: 'Your Passport must be valid and not expired',
      },
    },
    consentText:
      "I agree with the terms described in the Consent section of the Company's webpage",
  };

  const FRANKIE_HEADERS = {
    authorization: `machine ${process.env.REACT_APP_FRANKIE_CREDENTIALS}`,
  };

  useEffect(() => {
    const initialiseWidget = async () => {
      const res = await fetch(`${FRANKIE_URL}/auth/v2/machine-session`, {
        headers: FRANKIE_HEADERS,
        method: 'POST',
        mode: 'cors',
      });

      const ffToken = res.headers.get('token');

      return window_.frankieFinancial.initialiseOnboardingWidget({
        // applicantReference: userId,
        config: FRANKIE_CONFIG,
        ffToken,
        width: '100%',
        height: `${window.innerHeight / 1.25}px`,
        // entityId,
      });
    };

    if (window_.frankieFinancial) {
      initialiseWidget().then(res => {
        setIsKycLoading(false);
        // setIsKybLoading(false);
      });
    }
  }, []);

  return (
    <div
      className={cn(
        styles.Modal
        // (isFrankieSmartUILoading || isFrankieSmartUILoaded) && styles.active
      )}
    >
      <div className={styles.Backdrop} onClick={onClose}>
        <div className={styles.load}>
          <p>Loading</p>
          <div className={styles.loader} />
        </div>
      </div>
      <div
        className={cn(
          styles.Main,
          !isKycLoading && styles.active
          // !isFrankieSmartUILoading && isFrankieSmartUILoaded && styles.active
        )}
      >
        <section>
          {/* <button type="button" onClick={onClose}>
            Close
          </button> */}
          <ff-onboarding-widget />
        </section>
      </div>
    </div>
  );
};

export default Kyc;
