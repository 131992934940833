import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpentrans,
    closeModaltrans,
    handleChangeProd,
    ProdOption,
    handleChangetype,
    amount,
    openModalconfirm,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
    SetAmount,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        isOpen={modalIsOpentrans}
        onHide={() => {
          setIsOpentrans(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={modalIsOpentrans}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div className="form_box modal_parent">
              <form className="custom_form text-center">
                <div className="row">
                  <div className="col-md-6">
                    <div className="select_box">
                      <div className="styled-select">
                        <select
                          onChange={(e: any) =>
                            handleChangeProd(e.target.value)
                          }
                        >
                          {ProdOption}
                        </select>
                        <span className="fa fa-sort-desc"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="select_box">
                      <div className="styled-select">
                        <select
                          onChange={(e: any) =>
                            handleChangetype(e.target.value)
                          }
                        >
                          <option value="deposit">Deposit</option>
                          <option value="withdraw">Withdraw</option>
                        </select>
                        <span className="fa fa-sort-desc"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e: any) => SetAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(event) => {
              openModalconfirm(event);
              setIsOpentrans(false);
              onClose?.();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
