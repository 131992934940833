import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { capitalize } from '../../../Utils';
import { BsInfoCircle } from 'react-icons/bs';
import { TransferDetail } from '../../../Utils/TransferDetail';
import { TransferDataInterface } from '../../financeDataType';

function DetailsModal({ data }: { data: TransferDataInterface }) {
  const { type } = data;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        className="btn-dark-border row-ele-center  btn-large"
        onClick={handleShow}
      >
        <BsInfoCircle />
        &nbsp; &nbsp;
        <strong>Details</strong>
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title">{capitalize(type)} Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransferDetail data={data} role="detail" />
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button className={'main-color-btn full-btn'} onClick={handleClose}>
              Okay
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DetailsModal;
