import { useContext, useEffect, useState } from 'react';
import QRCode from 'qrcode.react';
import { Button, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import {  useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../config/config';
import { AuthContext } from '../../../contexts/AuthContext';
import useBalance from '../useBalance';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';

const Chains = [
  { name: 'ERC20', id: 'ERC20' },
  { name: 'OMNI', id: 'OMNI' },
];

function SellOrderModal({
  data, status
}: {
  data: { asset: any; total: any; free: any; locked: any };
  status: boolean;
}) {
  const { get_deposite_address } = useBalance();
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { userInfo } = useAuth();
  const [amount, setAmount] = useState<any>();
  const [from, setFrom] = useState<any>();
  const [taxId, setTaxId] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  const [ selectedChain, setSelectedChain ] = useState<any>(Chains[0].id);

  useEffect(() => {
    if (show) {
      get_deposite_address(data.asset.id);
    }
  }, [show]);
  let schema = yup.object().shape({
    amount: yup.number().required().positive(),
    from: yup.string().required(),
    taxId: yup.string().required(),
  });

  const mutation: any = useMutation(
    ({ amount, asset, from, taxId }: any) => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/transfer/deposit`;
      let formData = new FormData();
      formData.append('amount', amount);
      formData.append('asset', asset);
      formData.append('cryptoFromAddress', from);
      formData.append('cryptoTXId', taxId);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setAmount(null);
        setFrom(null);
        setTaxId(null);
        setToastObj({
          message: 'Crypto deposit requested',
          type: 'success'
        });
        queryClient.invalidateQueries(['CurrencyBalance', {}]);
        handleClose();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Failed to deposit`,
          type: 'error'
      });
        handleClose();
      },
    }
  );
  const [address, setAddress] = useState<any>();

  useEffect(() => {
    const list: any[] = userInfo?.addressList;
    const address_ = list?.find((address) => {
      return address.asset.id === data.asset.id && address.type === 'deposit';
    });

    setAddress(address_);
  }, [userInfo.addressList]);

  return (
    <>
      {/* <pre>

    {JSON.stringify(address.address, undefined, 2)}
    </pre> */}
      <Button
        className={`btn-dark-border ${status || 'disabled-status'}`}
        onClick={handleShow}
      >
        <strong>
          Deposit
        </strong>
      </Button>

      <Modal size={'lg'} centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Deposit {/* */}
              {data.asset.name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
          {/* <Alert variant={'warning'}>
            {`Only ${assetName} should be sent to this address! Sending any other coins may result in the loss of your deposit`}
          </Alert> */}
          {/* <ButtonGroup className='mb-2'>
            {Chains.map(chain=>{return <>
              <Button onClick={()=>{
                setSelectedChain(chain.id)
              }} variant={selectedChain === chain.id ? 'primary': 'secondary'}>{chain.name}</Button>
            </>})}
          </ButtonGroup> */}
          <div className='withdrawal-formarea'>
            <div className='row'>
              <aside className='col-lg-3'>Deposit Address:</aside>
              <aside className='col-lg-9'>
                <p className='deposit-address'>
                  {address?.address
                    ? address?.address
                    : `No ${data.asset.id} deposit address found`}{' '}
                  {/* <span className='main-small-btn main-small-black-btn'>
                    <i className='fa fa-files-o' aria-hidden='true'></i>Copy
                  </span> */}
                  {/* <CopyToClipboard text={deposite_address}>
                      <button className='grad_btn btn copybtn'>Copy</button>
                    </CopyToClipboard> */}
                </p>
              </aside>
            </div>
            {address?.address && (
              <>
                <div className='row'>
                  <aside className='col-lg-3'>QR Code:</aside>
                  <aside className='col-lg-9'>
                    <QRCode value={address?.address} />
                  </aside>
                </div>
              </>
            )}
            {/* <form>
              <div className='row'>
                <aside className='col-lg-3 paddingtop20'>
                  <FormLabel>Amount:</FormLabel>
                </aside>
                <aside className='col-lg-9'>
                  <FormControl
                    value={amount}
                    onChange={(event: any) => {
                      setAmount(event.currentTarget.value);
                    }}
                    type='number'
                    placeholder='Enter amount'
                  />
                  <ErrorMessage path={'amount'} error={error}></ErrorMessage>
                </aside>
              </div>
              <div className='row'>
                <aside className='col-lg-3 paddingtop20'>
                  <FormLabel>From:</FormLabel>
                </aside>
                <aside className='col-lg-9'>
                  <FormControl
                    value={from}
                    onChange={(event: any) => {
                      setFrom(event.currentTarget.value);
                    }}
                    type='text'
                    placeholder='Enter address'
                  />
                  <ErrorMessage path={'from'} error={error}></ErrorMessage>
                </aside>
              </div>

              <div className='row'>
                <aside className='col-lg-3 paddingtop20'>
                  <FormLabel>Tax ID:</FormLabel>
                </aside>
                <aside className='col-lg-9'>
                  <FormControl
                    value={taxId}
                    onChange={(event: any) => {
                      setTaxId(event.currentTarget.value);
                    }}
                    type='text'
                    placeholder='Enter tax ID'
                  />
                  <ErrorMessage path={'taxId'} error={error}></ErrorMessage>
                </aside>
              </div>
            </form> */}

            <h6 style={{ marginTop: 20 }}>Tips</h6>
            <ul style={{ listStyleType: 'box', marginTop: 15, marginLeft: 30 }}>
              <li>
                Please don’t deposit any other digital assets except{' '}
                {data?.asset?.name}({data?.asset?.name === 'BTC' ? 'OMNI' : 'ERC20'}) to the above address.

                Otherwise you may lose your assets permanently.
              </li>
              <li>
                Minimum deposit amount 0.01 USDT. Any deposits less than the
                minimum will not be credited or refunded.
              </li>
              <li>
                Please make sure that your computer and browser are secure and
                your information is protected from being tampered or leaked.
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            <div>
              {mutation.isError ? (
                <div>An error occurred: {mutation.error.message}</div>
              ) : null}
            </div>

            <Button
              className={'main-color-btn full-btn'}
              onClick={() => {
                handleClose();
                // schema
                //   .validate({ amount, from, taxId })
                //   .then(function (valid: any) {
                //     if (valid) {
                //       setError(null);
                //       mutation.mutate({
                //         amount,
                //         from,
                //         taxId,
                //         asset: data.asset.id,
                //       });
                //     } else {
                //       alert('fail');
                //     }
                //     // valid; // => true
                //   })
                //   .catch(function (err: any) {
                //     setError(err);
                //     // err.name; // => 'ValidationError'
                //     // err.errors; // => ['Deve ser maior que 18']
                //   });
              }}
            >
              {mutation.isLoading ? <>Submitting order...</> : <> Close</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SellOrderModal;
