import { useState } from 'react';
import cn from 'classnames';

import styles from './KybPopUp.module.scss';

interface props {
  isKybActive: boolean;
  onClose: () => void;
}

const _window: any = window;

const KybPopUp: React.FC<props> = ({
  isKybActive = false,
  onClose = () => {},
}) => {
  const [isHintActive, setHintActive] = useState(false);

  function copy() {
    try {
      const copyArea: any = document.getElementById('mailAddress');

      var range = document.createRange();
      range.selectNode(copyArea);
      _window.getSelection().addRange(range);
      const status = document.execCommand('copy');
      !!status && displayHint();
      _window.getSelection().removeAllRanges();
    } catch (e) {
      console.log(e);
    }
  }

  function displayHint() {
    !isHintActive && setHintActive(true);
  }

  function onClosePopup() {
    setHintActive(false);
    onClose();
  }

  return (
    <div className={cn(styles.Modal, isKybActive && styles.active)}>
      <div className={styles.Backdrop} onClick={onClosePopup} />
      <div className={cn(styles.Main, isKybActive && styles.active)}>
        <section>
          <header>
            <div>KYB verification</div>
            <button type="button" onClick={onClosePopup} />
          </header>
          <div className={styles.Container}>
            <div className={styles.Copy}>
              <p>For KYB verification, please send the following</p>
              <p>
                documents to
                <a href="mailto:legal@catchotc.com" id="mailAddress">
                  legal@catchotc.com
                </a>
                <img
                  src={require('../../../images/link.svg').default}
                  alt="copy link"
                  onClick={copy}
                />
              </p>
              <div
                className={cn(styles.Hint, isHintActive && styles.active)}
                onClick={() => setHintActive(false)}
              >
                Address copied to clipboard
              </div>
            </div>
            <div className={styles.Description}>
              <div>
                <img
                  src={require('../../../images/mail.svg').default}
                  alt="mail"
                  width="24"
                  height="24"
                />
                <div>
                  <div className={styles.SubTitle}>Mail title format</div>
                  <div className={styles.Title}>
                    <b>[KYB]“Your company name”,</b> for example: [KYB] Coinful
                    Capital
                  </div>
                </div>
              </div>
              <div>
                <img
                  src={require('../../../images/file.svg').default}
                  alt="file"
                  width="24"
                  height="24"
                />
                <div>
                  <div className={styles.SubTitle}>
                    Document format and size
                  </div>
                  <div className={styles.Title}>
                    <b>.jpeg, .png or .pdf</b> files only and keep the max file
                    size below <b>20MB</b>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.Prepare}>
              <h2>Documents you need to prepare:</h2>
              <div className={styles.Content}>
                <p>1. Certificate of Incorporation (or similar document)</p>
                <p>2. Memorandum and Articles of Association</p>
                <p>3. Register of Directors</p>
                <p>4. Register of Members</p>
                <p>
                  5. Entity Proof of Address
                  <span>
                    • Utility bill, tax bill, bank statement (dated within the
                    last 3 months)
                  </span>
                </p>
                <p>
                  6. Proof of Identity of the directors and shareholders
                  <span>
                    • Valid Passport and/or national identification document
                    showing the photo, name, id number, and signature
                  </span>
                </p>
                <p>
                  7. Proof of Address of the directors and shareholders
                  <span>
                    • Utility bill, tax bill, bank statement (dated within the
                    last 3 months)
                  </span>
                </p>
              </div>
              <div className={styles.Declare}>
                The data you provide will not be used in a manner inconsistent
                with the KYB verification purposes.
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default KybPopUp;
