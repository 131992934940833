import { useContext, useState, useEffect } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useMutation, useQuery, useQueryClient} from "react-query";
import axios from "axios";
import { Context } from "../../../Utils/ContextWrapper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tokenService } from "../../../../_services/tokens.services";
import { config } from "../../../../config/config";
import Loader from "../../../Loader";
import { capitalize } from "../../../Utils";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";
import { BsFillInfoCircleFill } from "react-icons/bs";
const { configheadersTemplate } = tokenService;
let configheaders = configheadersTemplate();

const AddUserFee = (data : any) => {
    const { username, name } = data.data;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Button
                className="main-small-btn mt-0"
                onClick={()=>handleShow()}
            >
              Fee 
            </Button>
            <Modal
                centered
                show={show} 
                onHide={handleClose}
            >
            <Modal.Header>
                <Modal.Title>

                    <h3 className="modal-title">
                        User - {name} 
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CurrencyTable type="network" username={username}/>
                <CurrencyTable type="withdraw" username={username}/>
                <div className="btn-container">
                    <Button  
                        type="button"
                        className="btn-full-dark"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    </>
  );
};

const CurrencyTable = ({type, username} : any) =>{
    const [feeDataSystem, setFeeDataSystem] = useState({});
    const [feeDataUser, setFeeDataUser] = useState({});
    const [feeDataMerged, setFeeDataMerged]  = useState({});
    let url = `${config.apiurl}/transfer/fee/${type}/default`;
    const { isLoading, data } = useQuery(type, ()=>{
        return axios.get(url, configheaders);
    });
    function fetchTransactionFeeByUser (type: string, username: string) {
        axios.get(`${config.apiurl}/transfer/fee/${type}/user/${username}`, configheaders)
            .then((res)=>{
                let data = res.data;
                setFeeDataUser(data);
            })
            .catch((error)=> {
                console.log(error);
            }
        )
    };
    function mergeData (){
        const feeDataMerged = Object.assign({}, feeDataSystem);
        Object.entries(feeDataMerged).forEach(([systemKey, systemValue]: any) => {
            Object.entries(feeDataUser).forEach(([userKey, userValue]: any)=>{
            if(systemKey === userKey){
                systemValue.forEach((systemElement: any) => {
                    userValue.forEach((userElement: any)=>{
                        if(systemElement.lower === userElement.lower){
                            systemElement.value = userElement.value;
                        }
                    })
                })}
            })
        });
        setFeeDataMerged(feeDataMerged);
    };
    useEffect(() => {
        setFeeDataSystem(data?.data);
        fetchTransactionFeeByUser(type, username);
    }, [data]);
    useEffect(() => {
        if(feeDataUser){
            mergeData();
        }else{
            setFeeDataMerged(feeDataSystem);
        }
    }, [feeDataUser])
    useEffect(() => {
        setFeeDataMerged({})
    }, [username])
    if(isLoading){
        <Loader visible={isLoading}/>
    };
    return(
        <div className="table-responsive">
            <Table borderless bordered={ false }>
                <thead>
                    <tr className="table-row">
                        <th>Currency</th>
                        <th className=""> 
                            <OverlayTrigger
                                key={`edit-info-range`}
                                placement="right"
                                overlay={
                                    <Tooltip id={`edit-info-range`} className="tooltip">
                                            Range should be set by admin on a universal form
                                    </Tooltip>
                                }
                            >
                                <BsFillInfoCircleFill/>
                            </OverlayTrigger>&nbsp;
                            Range
                        </th>
                        <th>{capitalize(type)} Fee</th>
                    </tr>
                </thead>
                {
                    feeDataMerged && Object.entries(feeDataMerged).map((currencyFee: any) => {
                        return(
                            <CurrencyItem type={type} username={username} calcFee={currencyFee} />
                        )
                    })
                }
            </Table>
        </div>
    )
};
const CurrencyItem = ({type, username, calcFee} : any) =>{
    const { fetchParams } = useContext(Context);
    const queryClient = useQueryClient();
    const { setToastObj } = useContext(AuthContext);
    const [changed, setChanged] = useState(false);
    const [editing, setEditing] = useState(false);
    const [editingCurrency, setEditingCurrency] = useState("");
    const [currencyFee, setCurrecnyFee] = useState<any>([]);

    function handleEditFeeBtn(){
        setEditing(!editing);
    };
    function handleSaveChange (event: any){
        if(event.type === "change"){
            setChanged(true)
            const {name, value} = event.target;
            currencyFee[name].value = value
        };
    };
    function handleSubmitChange (){
        editUserFeeMutation.mutate(currencyFee);
        setEditing(false);
        setChanged(false);
    };
    const editUserFeeMutation: any = useMutation(
        (oid: string) => {
            let url = `${config.apiurl}/transfer/fee/${type}/user/${username}/asset/${editingCurrency}`;
            let formData = new FormData();
            currencyFee?.forEach((element: any)=>{
                Object.entries(element)
                    .forEach(([key, value] : any) =>{
                        if(key !== "asset"){
                            let stringizeVal = value.toString()
                            formData.append(key, stringizeVal)
                        }
                    })
        })
        return axios.post(
                url,
                formData,
                configheaders
            );
        },
        {
            onSuccess: (data, variables, context) => {
                queryClient.invalidateQueries(["userFeeSetting", fetchParams]);
                setToastObj({
                    message: `User Fee Successfully Updated`,
                    type: 'success'
                  });
            },
            onError: (data, variables, context) => {
                queryClient.invalidateQueries(["userFeeSetting", fetchParams]);
                setToastObj({
                    message: `Failed to update user Fee`,
                    type: 'error'
                  });
            },
        }
    );
    useEffect(() => {
        if(calcFee){
            setCurrecnyFee(calcFee[1])
            setEditingCurrency(calcFee[0])
        }
    }, [calcFee]);
    return(
        <>
            <tbody>
                {
                    currencyFee.map((item: any, index: number) =>{
                        let {asset, lower, upper, value} = item;
                        return(
                            <tr> 
                                <td>
                                    {
                                        index === 0 && (
                                            <>
                                                {editingCurrency}
                                                <br/><br/>
                                                <OverlayTrigger
                                                    key={`edit-${asset}-fee`}
                                                    placement="right"
                                                    overlay={
                                                        <Tooltip id={`edit-${asset}-fee`} className="tooltip">
                                                            Edit {asset}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <button 
                                                        className="row-ele-center btn-icon"
                                                        onClick={handleEditFeeBtn}
                                                    >
                                                        {
                                                            editing ?  <AiFillEdit/> :  <AiOutlineEdit/>    
                                                        }
                                                    </button>
                                                </OverlayTrigger>         
                                            </>
                                        )
                                    }
                                </td>
                                <td>
                                    {lower} - {upper}
                                </td>
                                <td>
                                    <Form.Group className="mb-3" >
                                        <Form.Control
                                            required
                                            type="number" 
                                            name={index.toString()}
                                            placeholder={value.toString()} 
                                            defaultValue={value}
                                            onChange={handleSaveChange}
                                            disabled={!editing}
                                        />
                                    </Form.Group>
                                </td>
                            </tr>
                        )
                    })
                }
                {
                    changed && (
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <div>
                                    <Button 
                                        type="button" 
                                        className="btn-dark-border"
                                        onClick={handleSubmitChange}
                                    >
                                        {editUserFeeMutation.isLoading ? <>Submitting...</> : <> Submit</>}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    )
                }
            </tbody>
        </>
    )
};

export default AddUserFee;

