import React from "react";
import { useLocation } from "react-router-dom";

const Footer = () => {
  let location = useLocation();
  return (
    <>
      {location.pathname !== "/" && (
        <footer className="footer text-center">
          <div className="container">
            Copyright &copy; 2021, All Rights Reserved.
          </div>
        </footer>
      )}
    </>
  );
};
export default Footer;
