import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Context } from '../../../Utils/ContextWrapper';
import { userService } from '../../../../_services';
import { tokenService } from './../../../../_services/tokens.services';
import { config } from './../../../../config/config';

function SpreadOrderModal({ data }: { data: any }) {
  const { configheadersTemplate } = tokenService;
  const { fetchParams } = useContext(Context);
  const { setToastObj } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const [ show, setShow ] = useState(false);
  const [ amount, setAmount ] = useState<any>();
  const [ error, setError ] = useState<any>();
  const [ currencypair, setCurrencypair ] = useState() as any;
  const [ currencypairval, setCurrencypairval ] = useState({}) as any;
  const [ updateCurrencypairval, setUpdateCurrencypairval ] = useState([]) as any;

  const handleShow = ( spread:any ) => {
    showDisplay(spread);
    setShow(true);
  };
  const handleClose = () =>  setShow(false);

  const mutation: any = useMutation(
    ({ reason }: any) => {
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/userInfo/`+data.username+`/spread`;
      let dataContent = new FormData();
      Object.keys(currencypairval).map( (key: any)=> {
        let nn:any = Number(currencypairval[key]/10000).toFixed(4);
        dataContent.append(key,nn);
      })
      return axios.put(url, dataContent, configheaders);
    },
    {
      onSuccess: () => {
        setAmount(null);
        setToastObj({
          message:  'You have successfully updated spreads',
          type: 'success'
        });
        queryClient.invalidateQueries(['AdminUser', fetchParams ]);
        handleClose();
      },
      onError: (error: any) => {
        setAmount(null);
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message:  errorMessage || 'Failed to update spreads',
          type: 'error'
        });
        handleClose();
      },
    }
  );
  const handlechange = (e:any)=>{
    setCurrencypairval({ ...currencypairval, [e.target.name]: e.target.value });
    if (!updateCurrencypairval.includes(e.target.name)){
      setUpdateCurrencypairval([...updateCurrencypairval, e.target.name])
    }
  };
  const handleConfirm = () => {
    if(currencypairval !== 0){
      for ( let val of Object.values(currencypairval) ){
        let inputvVal = Number(val)
        if(!(Number.isInteger(inputvVal) && inputvVal >= 10 && inputvVal < 1000 )){
          alert('Value invalid! Please input an integer between 10 and 999 !');
          return 
        }
      };
      setError(null);
      mutation.mutate({ amount });
    } else {
      setError('Please insert at least one value.');
    }
  };
  const showDisplay = (data : any)=>{
    let displaySpread : { [k: string]:  any } = {};
    for (const [key, value] of Object.entries(data)) {
      let spreadName : string = key;
      let val: number = Number(value);
      let tenThousand : number = 10000;
      let res : number = 0;
      res = val * tenThousand;
      displaySpread[spreadName] = Math.floor(Number(res)); 
    }
    setCurrencypairval(displaySpread)
  };
  useEffect(() => {
    userService.get_currency_pair()
      .then((response: any) => {setCurrencypair(response.data)})
      .catch((error) => console.log(error));
    }, []);
  return (
    <>
      <Button
        className="main-small-btn mt-0"
        onClick={()=>handleShow(data.spreads)}
      >
        Spread 
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Spread Setting
            <span style={{float:'right'}}>
            </span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='withdrawal-formarea'>
            <form>
            <div className='row'>
                <aside className='col-12'>
                  <form>
                      <div className='row'>
                        <div className='col-sm-6'>
                          <h4>Currency Pair</h4>
                        </div>
                        <div className='col-sm-6'>
                          <h4>Spread Basis Points</h4>
                        </div>
                        <span className='error' >{error}</span>
                      </div>
                      { currencypair  && (
                        Object.keys(currencypair).map((key: any)=> {
                          return (
                            <div className='form-group row' key={key}>
                              <label className='col-sm-6 col-form-label'>{currencypair[key]}</label>
                              <div className='col-sm-6 currency-pair'>
                                <input 
                                  key={key}
                                  type='number' className='form-control' 
                                  value={currencypairval[currencypair[key]]} 
                                  name={currencypair[key]} 
                                  max='999' min='10'
                                  onChange={(e:any)=>handlechange(e)} id={key} 
                                  defaultValue='10'
                                  placeholder='10'                     
                                />
                              </div>
                            </div>
                            )
                          })
                        )}
                      <div className='form-group btn-container'>
                        <button type='button' className='btn-full-dark' onClick={handleClose}>Cancel</button>
                        <button type='button' className='btn-full-light' onClick={handleConfirm}>
                          {mutation.isLoading ? <>loading...</> : <> Confirm</>}
                        </button> 
                      </div>
                  </form>
                </aside>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer >
            <p className='clr-deny'><strong>Info : </strong>Please input an integer between 10 and 999, otherwise the value would be invalid.</p>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SpreadOrderModal;
