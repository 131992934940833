import { useState, useEffect } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { config } from '../../../config/config';
import Loader from '../../Loader';
import { useQuery } from 'react-query';
import axios from 'axios';
import { tokenService } from './../../../_services/tokens.services';
import { formatNumber } from '../../Utils';

type TransactionFeeAppendixProps = {
  amount: number;
  type: string;
  asset: string;
  getReceived: Function;
};

function TransactionFeeAppendix({
  amount,
  type,
  asset,
  getReceived,
}: TransactionFeeAppendixProps) {
  const { configheadersTemplate } = tokenService;
  let configheaders = configheadersTemplate();
  const fetchTransactionFee = () => {
    return axios.get(
      `${config.apiurl}/transfer/fee/${type}/asset/${asset}`,
      configheaders
    );
  };
  const { isLoading, data } = useQuery('transactionFee', fetchTransactionFee);
  const [transactionFee, setTransactionFee] = useState(0);
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [transactionFeeAll, setTransactionFeeAll] = useState([]);
  useEffect(() => {
    if (data?.data) {
      setTransactionFeeAll(data?.data);
    }
  }, [data]);
  useEffect(() => {
    setReceiveAmount(0);
    let inputAmount = Number(amount);
    let arrayLength = transactionFeeAll.length;
    if (inputAmount && arrayLength) {
      for (let i = 0; i < arrayLength; i++) {
        let { value, lower, upper } = transactionFeeAll[i];
        if (inputAmount >= lower && inputAmount <= upper) {
          let calcResult = inputAmount - value;
          setReceiveAmount(calcResult);
          setTransactionFee(value);
          return;
        } else {
          setReceiveAmount(inputAmount);
          setTransactionFee(0);
        }
      }
    } else {
      setTransactionFee(0);
      setReceiveAmount(inputAmount);
    }
  }, [amount]);
  useEffect(() => {
    getReceived(receiveAmount);
  }, [receiveAmount]);

  if (isLoading) {
    return <Loader visible={isLoading}></Loader>;
  }
  return (
    <div className="row">
      <aside className="col-lg-3">Receive amount:</aside>
      <aside className="col-lg-9 flex-column">
        <>
          <span>
            {formatNumber(receiveAmount > 0 ? receiveAmount : 0)} {asset}
          </span>
          <div>
            <span>
              {formatNumber(transactionFee)} {asset} {type} fee included
            </span>
            &nbsp;&nbsp;
            {type === 'network' && (
              <OverlayTrigger
                key={`balance-${amount}`}
                placement="right"
                overlay={
                  <Tooltip id={`transactionFee-${amount}`} className="tooltip">
                    Your assets will be sent to the destination address via the
                    selected blockchain and you will pay the transfer fees
                    associated with that blockchain for the transfer.
                  </Tooltip>
                }
              >
                <BsFillInfoCircleFill />
              </OverlayTrigger>
            )}
          </div>
        </>
      </aside>
    </div>
  );
}
export default TransactionFeeAppendix;
