import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../auth-context';
import { items, currency, features } from './data';

import styles from './Home.module.scss';

const Home = (props: any) => {
  const { isAuthenticated }: any = useAuth();
  const history = useHistory();

  const isDarkMode: boolean =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  function helpFn(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    if (!isAuthenticated) history.push('/login');
    return history.push('/market');
  }

  return (
    <>
      <div className={styles.Container}>
        <article className={styles.Page1}>
          <div className={styles.Content}>
            <div className={styles.Left}>
              <h1>
                Providing Private and Personalized Services for Large{' '}
                <span>Block Trades</span>
              </h1>
              <p>
                Providing ultra-competitive pricing, execution, and settlement
                services that are discreet, secure, and compliant.
              </p>
              <button onClick={helpFn} type="submit">
                {!isAuthenticated ? 'Get Started' : 'Fund Account'}
              </button>
            </div>
            <div className={styles.Right}>
              <img
                src={
                  require('../../images/landing-page/trade-input.svg').default
                }
                alt="trading input"
              />
            </div>
          </div>
        </article>
        <article className={styles.Page2}>
          <div className={styles.Content}>
            <h1>Your fast and reliable platform for large OTC trades</h1>
            <p>
              Execute fiat to crypto, crypto to crypto, and crypto to fiat
              trades through our fully compliant platform.
            </p>
            <ul>
              {items.map(({ src, title, description }) => (
                <li key={title} className={styles.Item}>
                  <div className={styles.Img}>
                    <img src={src(isDarkMode)} alt={title} />
                  </div>
                  <b>{title}</b>
                  <div>{description}</div>
                </li>
              ))}
            </ul>
          </div>
        </article>
        <article className={styles.Page3}>
          <div className={styles.Content}>
            <h1>Get a quote in your ideal currency</h1>
            <p>
              Supporting the top cryptos and stablecoins with US dollar fiat
              pairs.
            </p>
            <div className={styles.Wrapper}>
              <ul>
                {currency.map(({ src, title }) => (
                  <li key={title}>
                    <img src={src} alt={title} width="60" height="60" />
                    <div>{title}</div>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.Btn}>
              <button onClick={helpFn} type="submit">
                {!isAuthenticated ? 'Start Now' : 'Trade Now'}
              </button>
            </div>
          </div>
        </article>
        <article className={styles.Page4}>
          <div className={styles.Content}>
            <h1>
              CatchOTC is dedicated to serving private and institutional clients
              with the professional services and cutting-edge technology
            </h1>
            <p>3 Steps to Start Exploring Catch OTC.</p>
            <ul>
              {features.map(({ src, title, description }) => {
                return (
                  <li key={title}>
                    <div className={styles.Img}>
                      <img src={src} alt={title} />
                    </div>
                    <div className={styles.Wrapper}>
                      <b>{title}</b>
                      <div>{description}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </article>
        <footer>Copyright ©2023 Catch OTC. All right reserved.</footer>
      </div>
    </>
  );
};
export default Home;
