import React, { useState } from "react";

const Loader = ({ visible }: any) => {
  //   const [visible, setVisible] = useState(false);
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
      }}
    >
      <img
        className={`loader ${visible ? "fadeIn" : "fadeOut"}`}
        src={require("../images/loader.gif").default}
        alt="loader"
      />
    </div>
  );
};
export default Loader;

export const FullWidthLoader = ({ visible }: any) => {
  //   const [visible, setVisible] = useState(false);
  return (
    <div>
      <Loader visible={visible}></Loader>
    </div>
  );
};
export const TableLoader = ({ visible, colSpan }: any) => {
  //   const [visible, setVisible] = useState(false);
  return (
    <tr className={`loader ${visible ? "fadeIn" : "fadeOut"}`}>
      <td colSpan={colSpan}>
        <div style={{ textAlign: "center", width: "100%" }}>
          <img src={require("../images/loader.gif").default} alt="loader" />
        </div>
      </td>
    </tr>
  );
};
