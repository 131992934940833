import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    roles_html,
    modalIsOpenrole,
    closeModalrole,
    add_role,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div className="form_box modal_parent">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  <img src="images/disable.png" alt="disable" />
                </button>
              </div>
              <form className="custom_form text-center">
                <div className="row">
                  <div className="col-md-12">
                    <div className="select_box">
                      <div className="styled-select">
                        <select>
                          <option>George</option>
                          <option>Mark</option>
                          <option>George</option>
                        </select>
                        <span className="fa fa-sort-desc"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="select_box">
                      <div className="styled-select">
                        <select>
                          <option>BTC</option>
                          <option>ETH</option>
                          <option>BTC</option>
                        </select>
                        <span className="fa fa-sort-desc"></span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="select_box">
                      <div className="styled-select">
                        <select>
                          <option>Deposit</option>
                          <option>Deposit</option>
                          <option>Deposit</option>
                        </select>
                        <span className="fa fa-sort-desc"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn grad_btn"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#admin_transfer_add_confirm"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
