import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { formatNumber } from '../../../Utils';
import { Context } from '../../../Utils/ContextWrapper';
import { AuthContext } from '../../../../contexts/AuthContext';
import { tokenService } from '../../../../_services/tokens.services';
import { config } from '../../../../config/config';
import { TransferDetail } from '../../../Utils/TransferDetail';

const ApproveModal = ({ data }: any) => {
  const {
    amount, asset, cryptoAsset
  } = data;
  const [txId, setTxId] = useState(data?.txid);
  const [show, setShow] = useState(false);
  const [approve, setApprove] = useState(false);
  const [adjusted, setAdjusted] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { configheadersTemplate } = tokenService;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);

  const approveMutation: any = useMutation(
    (oid: string) => {
      let configheaders = configheadersTemplate();
      let formData = new FormData();
      formData.append('txId', txId);
      return axios.put(
        `${config.apiurl}/transfer/approve/${data.id}`,
        formData,
        configheaders
      );
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['AdminTransfer', fetchParams]);
        setToastObj({
          message: 'You have successfully approved the transfer request.',
          type: 'success'
        });
      },
      onError: (data, variables, context) => {
        queryClient.invalidateQueries(['AdminTransfer', fetchParams]);
        setToastObj({
          message: 'Approve request failed',
          type: 'error'
        });
      },
    }
  );
  function handleAproveBtn (){
    handleShow();
    setApprove(false);
    setAdjusted(false);
  };
  function handleSaveChange (){
    setApprove(true);
  };
  function handleSubmitChange (){
    approveMutation.mutate({txId})
  };
  return (
    <>
      <Button 
        className='btn-light-border row-ele-center'
        onClick={handleAproveBtn}
      >
        <strong>Approve</strong>
      </Button>
      <Modal
        centered
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <h3 className='modal-title'>
              Approve Transfer
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !approve && (
              <TransferDetail data={data} role='admin'/>)
          }
          {
            !approve && cryptoAsset && (
              <Form>
                <Form.Group className='mb-3' controlId='forTxId'>
                  <Form.Label>
                    <strong>Transaction Hash #</strong>
                  </Form.Label>
                  <Form.Control 
                    type='text'
                    placeholder='Please enter if any'
                    onChange={(event: any) => {    
                      setTxId(event.currentTarget.value);
                    }}
                  />
                  <Form.Text className='text-muted'>
                    *Optional
                  </Form.Text>
                </Form.Group>
              </Form>
            )
          }
          {
            approve && (
              <div className='confirm_info'>
                <span>Are you sure you want to approve this transfer?</span>
                {txId && (
                  <span>
                    Transaction Hash&nbsp;
                    <strong>#{txId}</strong>
                  </span>
                )}
                <span>
                  {adjusted ? 'Adjusted' : 'Approved'} Amount : &nbsp;
                  <strong>{formatNumber(amount)} {asset}</strong>
                </span>
              </div>
            )
          }
          <br/>
          <div className='btn-container'>
            <Button  
              type='button'
              className='btn-full-dark'
              onClick={handleClose}
            >
              Cancel
            </Button>
            {!approve && 
              (<Button 
                type='button'
                className='btn-full-light'
                onClick={handleSaveChange}
                >
                  Approve
              </Button>)
            }
            {approve && 
              (<Button 
                type='button'
                className='btn-full-light'
                onClick={handleSubmitChange}
                >
                  {approveMutation.isLoading ? <>Submitting...</> : <> Submit</>}
              </Button>)
            }
          </div>
        </Modal.Body>
      </Modal>
  </>
  );
};
export default ApproveModal;
