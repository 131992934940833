import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import {
  AmplifyAuthenticator,
  AmplifyForgotPassword,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import aws_exports from './config/aws-exports';
import { AuthProvider, useAuth } from './auth-context';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import App from './App';
import Header from './components/common/Header';
import Home from './components/Home/Home';
import MarketContextWrapper from './components/Market/MarketContextWrapper';
import NewOrder from './components/Market/market';
import Profile from './components/Profile/index';
import History from './components/History/index';
import Finance from './components/Finance/index';
import Admin from './components/Admin/index';
import Balance from './components/Balance/index';
import Kyc from './components/Kyc/Kyc';
import { QueryClient, QueryClientProvider } from 'react-query';
import TwoFactorAuthModal from './components/common/modals/TwoFactorAuthModal';
import PulseLoader from 'react-spinners/PulseLoader';
import GREEN_CHECK_IMAGE from './images/green_check.png';

export const Routes = ({ childProps }: any) => (
  <Switch>
    <Route exact path="/" render={() => <Home />} />
    <Route exact path="/login" render={() => <Login />} />
    <Route exact path="/kyc" render={() => <Kyc />} />
    <PrivateRoute exact path="/market">
      <MarketContextWrapper>
        <NewOrder />
      </MarketContextWrapper>
    </PrivateRoute>
    <PrivateRoute path="/profile" component={Profile} />
    <PrivateRoute path="/history" component={History} />
    <PrivateRoute exact path="/balance" component={Balance} />
    <PrivateRoute path="/finance" component={Finance} />
    <PrivateRoute path="/admin" component={Admin} />
    <Redirect from="*" to="/market" />
  </Switch>
);

const App_ = () => {
  return (
    <div className="App" style={{ paddingTop: '50px' }}>
      <Header />
      <App />
    </div>
  );
};
Amplify.configure(aws_exports);

export const AuthStateApp = () => {
  const queryClient = new QueryClient({});
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <App_ />
        </AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

function PrivateRoute({ children, ...rest }: any) {
  const { authState, user }: any = useAuth();
  const isAuthenticated = authState === AuthState.SignedIn && user;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function Login() {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [showToFactor, setShowToFactor] = useState(false);
  const [show, setShow] = useState(false);
  const [showSentCode, setShowSentCode] = useState(false);

  Hub.listen('auth', data => {
    if (data.payload.event == 'forgotPasswordSubmit') {
      setShow(true);
    } else if (data.payload.event == 'forgotPassword') {
      setShowSentCode(true);
    }
  });
  const handleClose = () => setShow(false);
  const handleCloseSent = () => setShowSentCode(false);
  const history = useHistory();
  const { userInfo, userIsLoading, userIsFetching, userError } = useAuth();
  const { state }: any = useLocation();
  const submit = (state: string) => {
    return onAuthUIStateChange((nextAuthState, authData: any) => {
      if (nextAuthState === 'signedin') {
        setShowToFactor(true);
        setRedirectToReferrer(true);
      }
    });
  };

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData: any) => {
      if (nextAuthState === 'signedin') {
        setShowToFactor(true);
        setRedirectToReferrer(true);
      }
    });
  }, []);

  return (
    <>
      {Object.keys(userInfo).length > 0 && (
        <TwoFactorAuthModal
          onSuccess={() => {
            setShowToFactor(false);
            history.push(state?.from || '/');
          }}
          onClose={() => {
            Auth.signOut();
            setShowToFactor(false);
          }}
          initialShow={showToFactor}
          callback={() => {}}
        />
      )}
      <AmplifyAuthenticator
        usernameAlias="email"
        slot="forgot-password"
        handleAuthStateChange={submit}
      >
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              type: 'email',
              label: 'Email Address',
              placeholder: 'Enter email address',
              required: true,
            },
            {
              type: 'password',
              label: 'Password',
              placeholder: 'Enter password',
              required: true,
            },
          ]}
        />
        <AmplifyForgotPassword
          headerText="Reset Your Password"
          slot="forgot-password"
        />
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
      </AmplifyAuthenticator>
      {(userError || userIsLoading || userIsFetching) && (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {userError ? (
            <>
              Loading... If this doesn't login, try logging in again. &nbsp;
              <a
                href="#"
                onClick={() => {
                  Auth.signOut();
                }}
              >
                Retry
              </a>
            </>
          ) : (
            <>
              <PulseLoader loading={true} size={3} />
              Loading
            </>
          )}
        </div>
      )}
      <Modal
        centered
        style={{
          transform: 'translate(0, 50%) !important',
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <img src={GREEN_CHECK_IMAGE} alt="green" />
          <p style={{ color: 'black' }}>Your password has been reset</p>
          <p>Please login to your account</p>
        </Modal.Body>
        <Modal.Footer>
          <Button block className={'yellow_btn'} onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        style={{
          transform: 'translate(0, 50%) !important',
        }}
        show={showSentCode}
        onHide={handleCloseSent}
      >
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <img src={GREEN_CHECK_IMAGE} alt="green" />
          <p style={{ color: 'black' }}>OTP has been sent to your E-mail</p>
          <p>Please check your inbox</p>
        </Modal.Body>
        <Modal.Footer>
          <Button block className={'yellow_btn'} onClick={handleCloseSent}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
