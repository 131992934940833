import { useContext, useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../../config/config';
import { AuthContext } from '../../../../contexts/AuthContext';
import { tokenService } from '../../../../_services/tokens.services';
import { Context } from '../../../Utils/ContextWrapper';
import { formatNumber } from '../../../Utils';
import { TransferDetail } from '../../../Utils/TransferDetail';
import { useDropzone } from 'react-dropzone';
import { FaRegFileAlt } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { TransferModalInterface } from '../../financeDataType';

function ApproveModal({ data }: TransferModalInterface) {
  const { amount, id, type, asset, cryptoAsset } = data;
  const [adjustedAmount, setAdjustedAmount] = useState(amount);
  const [proofFile, setProofFile] = useState([] as any);
  const [getFile, setGetFile] = useState(false);
  const [show, setShow] = useState(false);
  const [approve, setApprove] = useState(false);
  const [adjusted, setAdjusted] = useState(false);
  const { configheadersTemplate } = tokenService;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);
  const isWithdrawal: boolean = type === 'withdraw';

  const approveMutation: any = useMutation(
    (oid: string) => {
      let configheaders = configheadersTemplate();
      let formData = new FormData();
      configheaders.headers['Content-Type'] = 'multipart/form-data';
      adjustedAmount && formData.append('amount', adjustedAmount);
      proofFile && formData.append('file', proofFile[0]);
      return axios.post(
        `${config.apiurl}/transfer/finance/approve/${id}`,
        formData,
        configheaders
      );
    },
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(['FinanceTransfer', fetchParams]);
        setToastObj({
          message: `You have successfully ${
            adjusted ? 'adjusted' : 'approved'
          } the transfer request.`,
          type: 'success',
        });
      },
      onError: (data, variables, context) => {
        queryClient.invalidateQueries(['FinanceTransfer', fetchParams]);
        setToastObj({
          message: `${adjusted ? 'Adjust' : 'Approve'} request failed.`,
          type: 'error',
        });
      },
    }
  );
  function handleShow() {
    setAdjustedAmount(amount);
    setProofFile([]);
    setShow(true);
    setGetFile(false);
  }
  function handleClose() {
    setAdjustedAmount('');
    setProofFile([]);
    setShow(false);
    setGetFile(false);
  }
  function handleAproveBtn() {
    handleShow();
    setApprove(false);
    setAdjusted(false);
  }
  function handleSaveChange() {
    setApprove(true);
  }
  function handleSubmitChange() {
    approveMutation.mutate({ adjustedAmount });
  }
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: 1200000,
    accept: 'image/jpeg, image/png, application/pdf',
    onDrop: (acceptedFiles) => {
      setProofFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setGetFile(true);
    },
  });
  const proofInfo = acceptedFiles.map((file) => (
    <span>
      <FaRegFileAlt />
      &nbsp;{file.name}
      {file.type}
    </span>
  ));

  const thumbs = proofFile.map((file: any) => (
    <div key={file.name}>
      <img
        src={file.preview}
        className="img-size"
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />
    </div>
  ));

  useEffect(() => {
    return () =>
      proofFile.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);
  return (
    <>
      <Button
        className="btn-light-border row-ele-center"
        onClick={handleAproveBtn}
      >
        <strong>Approve</strong>
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3 className="modal-title" id="exampleModalLabel">
              Approve Transfer
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!approve && <TransferDetail data={data} role="finance" />}
          {!approve && !cryptoAsset && !isWithdrawal && (
            <>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <strong>Adjusted Amount</strong>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Please enter if any"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAdjustedAmount(event.currentTarget.value);
                      if (event.currentTarget.value) {
                        setAdjusted(true);
                      }
                    }}
                  />
                  <Form.Text className="text-muted">*Optional</Form.Text>
                </Form.Group>
              </Form>
              <section className="upload_sec">
                <div {...getRootProps()}>
                  <label>
                    <strong>Proof of Deposit</strong>&nbsp;
                    <span>
                      (Only one *.jpeg, *.png, *.pdf file will be accepted)
                    </span>
                  </label>
                  <input {...getInputProps()} />
                  <div>
                    {getFile ? (
                      proofInfo
                    ) : (
                      <p>
                        <MdAdd />
                        &nbsp;Drag or drop file here, or click to select file
                      </p>
                    )}
                  </div>
                </div>
                <aside>{thumbs}</aside>
              </section>
              <br />
            </>
          )}
          {approve && (
            <div className="confirm_info">
              <span>Are you sure you want to approve this amount?</span>
              <span>
                {adjusted ? 'Adjusted' : 'Approved'} Amount:&nbsp;
                <strong>
                  {formatNumber(adjustedAmount)} {asset}
                </strong>
              </span>
              {getFile && (
                <span>
                  Upload Proof File:&nbsp;
                  <strong>{proofInfo}</strong>
                </span>
              )}
            </div>
          )}
          <div className="btn-container">
            <Button
              type="button"
              className="btn-full-dark"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {!approve && (
              <Button
                type="button"
                className="btn-full-light"
                onClick={handleSaveChange}
              >
                Approve
              </Button>
            )}
            {approve && (
              <Button
                type="button"
                className="btn-full-light"
                onClick={handleSubmitChange}
              >
                {approveMutation.isLoading ? <>Submitting...</> : <> Submit</>}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ApproveModal;
