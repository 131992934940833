// import { authHeader } from '../_helpers';
import { config } from "../config/config";
import { tokenService } from "./tokens.services";
import axios from "axios";
export const adminService = {
  admin_transfer,
  update_role,
  update_group,
  get_users,
  get_balance,
  get_tradeList,
  get_transfer,
  get_roles,
  get_asset,
  user_disable,
  user_enable,
  add_fees,
  transfer_reject,
  transfer_accept,
  // get_product,
  // get_open_orderList,
  // get_tradeList_balance,
  // get_transfer_balance,
  get_refine_list,
  // get_candle,
  get_transfer_download,
  admin_balance,
  //  get_orderList
};

const { configheadersTemplate } = tokenService;
const baseURL = config.apiurl;

function admin_transfer(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append("amount", params.amount);
  formData.append("asset", params.asset);
  formData.append("type", params.type);
  formData.append("username", params.username);
  let url = `${baseURL}/transfer`;
  return axios.post(url, formData, configheaders)
}

function update_role(params: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/updateRole`;
  let formData = new FormData();
  formData.append("username", params.username);
  params.role.forEach(function (item: any) {
    formData.append("role", item);
  });
  return axios.put(url, formData, configheaders)
}

function user_disable(params: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/disable`;
  let formData = new FormData();
  formData.append("username", params.username);
  return axios.put(url, formData, configheaders)
}

function user_enable(params: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/enable`;
  let formData = new FormData();
  formData.append("username", params.username);
  return axios.put(url, formData, configheaders)
}

function admin_balance(id: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance?target=` + id;
  return axios.get(url, configheaders)
}

function update_group(params: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/updateGroup`;
  return axios.put(url, params, configheaders)
}

function get_users() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/list`;
  return axios.get(url, configheaders)
}

function get_roles() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/listAllRole`;
  return axios.get(url, configheaders)
}

function get_balance() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance?house=true`;
  return axios.get(url, configheaders)
}

function get_tradeList(myparams: any) {
  let configheaders = configheadersTemplate();  
  for (var propName in myparams) {
    if (myparams[propName] === null || myparams[propName] === "") {
      delete myparams[propName];
    }
  }
  let url = `${baseURL}/otc/trade/list?house=true`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
    Cookie: "developkey=value",
  };
  return axios.get(url, customizedRequest)
}

function get_transfer(myparams: any) {
  let configheaders = configheadersTemplate();
  for (var propName in myparams) {
    if (myparams[propName] === null || myparams[propName] === "") {
      delete myparams[propName];
    }
  }
  let url = `${baseURL}/transfer/list?house=true`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest)
}

function get_transfer_download(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/list/download?house=true`;
  return axios.get(url, configheaders)
}

function get_asset() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/asset/list`;
  return axios.get(url, configheaders)
}

function get_refine_list(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/trade/refine/list?house=true`;
  return axios.get(url, configheaders)
}

function add_fees(fee: any, usename: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/` + usename + `/fee`;
  let formData = new FormData();
  fee.forEach(function (item: any) {
    formData.append(item.key, item.value);
  });
  return axios.put(url, formData, configheaders)
}

function transfer_reject(id: any, reason: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/deny/` + id;
  let formData = new FormData();
  formData.append("reason", reason);
  return axios.put(url, formData, configheaders)
}

function transfer_accept(id: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/approve/` + id;
  return axios.put(url, null, configheaders)
}

// function get_currency_pairVal(data: any, currencypairval: any){
//   let configheaders = configheadersTemplate();
//   let url = `${config.apiurl}/userInfo/`+data.username+`/spread`;
//   let formData = new FormData();
//   Object.keys(currencypairval).map( (key: any)=> {
//     console.log(currencypairval[key]/10000)
//     let nn:any = currencypairval[key]/10000;
//     formData.append(key,nn);
//   });
//   return axios.put(url, formData, configheaders);

// }







//  function get_product() {
//   let url =  `${config.apiurl}/product/list`;
//   const headers ={ headers: {'Content-Type' : 'application/json'},

//   withCredentials: true,crossDomain: true,
//   'Cookie': 'developkey=value',
//  }
//  return axios.get(url,headers)
//  }

//  function get_open_orderList(myparams:any) {
//   let url =  
//   `${config.apiurl}/order/open`;
//   const headers ={ headers: {'Content-Type' : 'application/json'},
//   params:myparams,
//   withCredentials: true,crossDomain: true,
//   'Cookie': 'developkey=value',
//  }
//  return axios.get(url,headers)
//  }
//  function get_orderList(myparams:any) {
//   let url =  
//   `${config.apiurl}/order/list`;
//   const headers ={ headers: {'Content-Type' : 'application/json'},
//   params:myparams,
//   withCredentials: true,crossDomain: true,
//   'Cookie': 'developkey=value',
//  }
//  return axios.get(url,headers)
//  }
//  function get_tradeList_balance(myparams:any) {
//   let url =  `${config.apiurl}/userInfo/balance/history`;
//   const headers ={ headers: {'Content-Type' : 'application/json'},
//   params:myparams,
//   withCredentials: true,crossDomain: true,
//   'Cookie': 'developkey=value',
//  }
//  return axios.get(url,headers)
//  }

//  function get_transfer_balance(myparams:any) {
//   let url =  `${config.apiurl}/userInfo/balance/history`;
//   const headers ={ headers: {'Content-Type' : 'application/json'},
//   params:myparams,
//   withCredentials: true,crossDomain: true,
//   'Cookie': 'developkey=value',
//  }
//  return axios.get(url,headers)
//  }
