import { useContext, useState } from 'react';
import {
  Button,
  FormControl,
  Modal,
  Table,
  FormLabel,
  Alert,
} from 'react-bootstrap';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../config/config';
import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorMessage, formatNumber } from '../../Utils';
import TwoFactorAuthModal from '../../common/modals/TwoFactorAuthModal';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';
import { Link } from 'react-router-dom';
import { splitCamelCase } from '../../Utils/index';
import TransactionFeeAppendix from './TransactionFeeAppendix';

function SellOrderModal({
  data,
  available,
}: {
  data: { asset: any; total: any; free: any; locked: any };
  available: number;
}) {
  const { userInfo } = useAuth();
  const [bankAddress, setBankAddress] = useState(
    userInfo?.bankList?.[0]?.id || null
  );
  const [showToFactor, setShowToFactor] = useState(false);
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    mutation.reset();
    setAmount(0);
    setError('');
  };
  const [price, setPrice] = useState(0);
  const [amount, setAmount] = useState<any>();
  const [receivedAmount, setReceivedAmount] = useState<any>();
  const [error, setError] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  const [allowPartial, setAllowPartial] = useState(false);
  let schema = yup.object().shape({
    amount: yup
      .number()
      .max(available)
      .min(1)
      .required('Please inaput a valid value'),
    receivedAmount: yup
      .number()
      .positive('Insufficient balance available for withdrawal'),
  });
  const mutation: any = useMutation(
    ({ amount }: any) => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/transfer/withdraw`;
      let formData = new FormData();
      formData.append('amount', amount);
      formData.append('asset', data.asset.id);
      formData.append('userBankId', bankAddress);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setAmount(0);
        queryClient.invalidateQueries(['CurrencyBalance', {}]);
        setToastObj({
          message: 'Bank withdrawal requested',
          type: 'success',
        });
        handleClose();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Failed to withdraw`,
          type: 'error',
        });
        handleClose();
      },
    }
  );
  function getReceived(received: number) {
    setReceivedAmount(received);
  }
  return (
    <>
      <Button className="btn-light-border" onClick={handleShow}>
        <strong>Withdraw</strong>
      </Button>
      <TwoFactorAuthModal
        onSuccess={() => {
          setShowToFactor(false);
          // mutation.mutate({
          //   amount,
          //   price,
          //   allowPartial,
          //   quoteAsset: 'USD',
          //   side: 'sell',
          // });
        }}
        onClose={() => {
          setShowToFactor(false);
        }}
        initialShow={showToFactor}
        callback={() => {}}
      ></TwoFactorAuthModal>
      <Modal size={'lg'} centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="modal-title" id="exampleModalLabel">
              Withdrawal fiat request
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={'warning'}>
            <span>
              Please note that fiat withdrawals below $10,000 in value will have
              a $75 fee. This is a fee our bank charges us
            </span>
          </Alert>
          <div className="withdrawal-formarea">
            <div className="row">
              <aside className="col-lg-3">Currency:</aside>
              <aside className="col-lg-9">
                {data.asset.name} {data.asset.name === 'USDT' ? '' : ''}
              </aside>
            </div>
            <div className="row">
              <aside className="col-lg-3">Available:</aside>
              <aside className="col-lg-9">{formatNumber(available)}</aside>
            </div>
            <form>
              <div className="row">
                <aside className="col-lg-3 paddingtop20">
                  <FormLabel>Amount:</FormLabel>
                </aside>
                <aside className="col-lg-9">
                  <FormControl
                    value={amount}
                    onChange={(event: any) => {
                      setAmount(event.currentTarget.value);
                    }}
                    type="number"
                    placeholder="Enter amount"
                    required
                  />
                  <ErrorMessage path={'amount'} error={error}></ErrorMessage>
                </aside>
              </div>
              <TransactionFeeAppendix
                amount={amount}
                type="withdraw"
                asset={data.asset.name}
                getReceived={getReceived}
              />
              <ErrorMessage
                path={'receivedAmount'}
                error={error}
              ></ErrorMessage>
            </form>
            <h4 className="table-title">Choose bank address</h4>
            <Table hover className="withdraw-table-list">
              <tbody>
                {userInfo?.bankList.length === 0 && (
                  <Alert variant={'Danger'}>
                    <span>
                      No bank account provided. Please add one on your{' '}
                      <Link to="/profile/manage_bank_account">
                        Profile page
                      </Link>
                      .
                    </span>
                  </Alert>
                )}
                {userInfo?.bankList?.length > 0 &&
                  Object.keys(userInfo.bankList).map((key, index) => {
                    const {
                      asset,
                      bankName,
                      currency: { name },
                      address,
                      id,
                    } = userInfo.bankList[key];
                    return (
                      <tr key={index}>
                        {/* <pre>
                              {JSON.stringify(userInfo.bankList[key], undefined, 2)}
                            </pre> */}
                        <td>
                          <div className="withdraw-table-content">
                            <input
                              onChange={(event) => {
                                setBankAddress(event.currentTarget.value);
                              }}
                              value={id}
                              checked={
                                bankAddress ? bankAddress === id : index === 0
                              }
                              name={'group1'}
                              type={'radio'}
                              required
                            />
                            <label>{bankName}</label>
                            <div>{name}</div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {userInfo?.bankList.length > 0 &&
              userInfo?.bankList?.map((bank: any, index: any) => {
                if (bank.id === bankAddress) {
                  return (
                    <>
                      <h4 className="table-title">Bank details</h4>
                      <Table
                        className="withdraw-table-list"
                        striped
                        bordered
                        hover
                        key={index}
                        size="sm"
                      >
                        <tbody>
                          {Object.keys(bank)
                            .filter(
                              (key) =>
                                key !== 'id' &&
                                key !== 'version' &&
                                key !== 'name' &&
                                key !== 'verified'
                            )
                            .map((key: any) => {
                              return (
                                <>
                                  {typeof bank[key] !== 'object' && (
                                    <>
                                      <tr key={key}>
                                        <td className="bank-details-title">
                                          {splitCamelCase(key)}
                                        </td>
                                        <td>
                                          {JSON.stringify(bank[key]).replaceAll(
                                            '"',
                                            ''
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </>
                              );
                            })}
                        </tbody>
                      </Table>
                    </>
                  );
                }
              })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            {mutation.isError ? (
              <div onClick={() => mutation.reset()}>
                An error occurred: {mutation.error.message}
              </div>
            ) : null}
            <Button
              className={'main-color-btn full-width-btn'}
              onClick={() => {
                // closeModalwith();
                // withdraw_request();
                schema
                  .validate({ amount, price, receivedAmount })
                  .then(function (valid: any) {
                    if (valid) {
                      // this is where we have the two factor authentication
                      setError(null);
                      setShowToFactor(true);
                      mutation.mutate({
                        amount,
                        price,
                        allowPartial,
                        quoteAsset: 'USD',
                        side: 'sell',
                      });
                      handleClose();
                    } else {
                      alert('fail');
                    }
                    // valid; // => true
                  })
                  .catch(function (err: any) {
                    setError(err);
                  });
              }}
            >
              {mutation.isLoading ? <>Submitting order...</> : <> Submit</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SellOrderModal;
