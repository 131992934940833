import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenaddfee,
    closeModaladdfee,
    Setfeecurrency,
    setFeeamount,
    addfees,
    ProdOptionFee,
    Setfeecurrency1,
    ProdOption1Fee,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        isOpen={modalIsOpenaddfee}
        onHide={() => {
          setIsOpenaddfee(false);
        }}
        id="warning_modal"
        show={modalIsOpenaddfee}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <form className="custom_form text-center">
              <div className="row">
                <div className="col-md-6">
                  <div className="select_box">
                    <div className="styled-select">
                      <select
                        onChange={(e: any) => Setfeecurrency(e.target.value)}
                      >
                        {ProdOptionFee}
                      </select>
                      <span className="fa fa-sort-desc"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="select_box">
                    <div className="styled-select">
                      <select
                        onChange={(e: any) => Setfeecurrency1(e.target.value)}
                      >
                        {ProdOption1Fee}
                      </select>
                      <span className="fa fa-sort-desc"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Fee"
                      onChange={(e: any) => setFeeamount(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <button type="submit" className="btn grad_btn" onClick={addfees}>
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenaddfee(false);
              onClose?.();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
