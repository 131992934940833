import moment from "moment";
import React, { useEffect, useState } from "react";
import { adminService, userService } from "../../_services";
import disable_img from "../../images/disable.png";
import localize from "../../localize/alerts";
import ReactHtmlParser from "html-react-parser";
export const AdminContext = React.createContext<any>({});

type Props = {};
const AdminContextWrapper: React.FC<Props> = ({ children }) => {
  const [Userlist, setUserList] = useState() as any;
  const [startdate_trans1, SetStartdates1] = useState("") as any;
  const [enddate_trans1, SetEnddates1] = useState("") as any;
  const [modalIsOpengrp, setIsOpengrp] = useState(false);
  const [modalIsOpenCompany, setIsOpenCompany] = useState(false);
  const [modalIsOpenconfirm, setIsOpenconfirm] = useState(false);
  const [modalIsOpenaddfee, setIsOpenaddfee] = useState(false);
  const [modalIsOpenrole, setIsOpenrole] = useState(false);
  const [modalIsOpentrans, setIsOpentrans] = useState(false);
  const [user_name, setUsername] = useState("") as any;
  const [user_nameT, setUsernameT] = useState("") as any;
  // eslint-disable-next-line
  const [update_roles, setUpdateroles] = useState([]) as any;
  const [Transferlist, setTransferlist] = useState() as any;
  const [BalanceList, setBalanceList] = useState() as any;
  const [TradeList, setTradeList] = useState() as any;
  const [totaltransfer, Settotaltransfer] = useState() as any;
  const [startdate, SetStartdate] = useState("") as any;
  const [startdate1, SetStartdate1] = useState("") as any;
  const [enddate, SetEnddate] = useState("") as any;
  const [enddate1, SetEnddate1] = useState("") as any;
  // const [update_roles1,setUpdateroles1] =useState("") as any;
  // const [loadStyle, setloadStyle] = useState({display: 'table'}) as any;
  const [allroles, setAllroles] = useState("") as any;
  const [roles_html, setRolesHtmls] = useState("<></>") as any;
  const [ProductList, setProductList] = useState() as any;
  const [ProductList1, setProductList1] = useState() as any;
  const [product, SetProduct] = useState("") as any;
  const [type, SetType] = useState("deposit") as any;
  const [amount, SetAmount] = useState("") as any;
  const [csvData, SetcsvData] = useState([]) as any;
  const [side, SetSide] = useState("") as any;
  const [userFees, SetUserFees] = useState("") as any;
  const [userFeeName, SetuserFeeName] = useState("") as any;
  const [feecurrency, Setfeecurrency] = useState() as any;
  const [feecurrency1, Setfeecurrency1] = useState() as any;
  const [feeAmount, setFeeamount] = useState() as any;
  const [feeArray, setfeeArray] = useState([]) as any;
  const [modalIsOpenSucc, setIsOpenSucc] = useState(false);
  const [modalIsOpenReject, setIsOpenReject] = useState(false);
  const [reject_msg, setRejectMsg] = useState("") as any;
  const [reject_id, setRejectId] = useState("") as any;
  const [reason_msg, setReason] = useState("") as any;
  const [titlehead, setHead] = useState("Reason") as any;
  const [userBalnce, SetUserBalance] = useState("") as any;
  const [modalIsOpenBal, setIsOpenBal] = useState(false) as any;
  const [companyName, setCompanyName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [myparams, SetParams] = useState({
    pageNum: 1,
    sortBy: "timestamp",
    sortOrder: "desc",
    limit: 20,

    side: side,
  }) as any;
  // eslint-disable-next-line
  const [myparams_transfer, SetParams1] = useState({
    pageNum: 1,
    sortOrder: "desc",
    type: "deposit,withdraw",
    assetType: "currency,metal",
    side: type,
    limit: 20,
  }) as any;
  const customStyles = {
    content: {
      left: "50%",
      border: "none",
    },
  };
  function openModalReject(id: any) {
    setRejectId(id);
    setIsOpenReject(true);
  }
  function closeModalReject() {
    setIsOpenReject(false);
  }
  const transfer_approve = (id: any) => {
    adminService
      .transfer_accept(id)
      .then(function (response: any) {
        getTransferList();
        openModalSucc("Successfully approved request", "Success!");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const transfer_reject = () => {
    adminService
      .transfer_reject(reject_id, reject_msg)
      .then(function (response: any) {
        closeModalReject();
        getTransferList();
        openModalSucc("Successfully denyed request", "Success!");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function openModalSucc(mess: any, head: any) {
    setHead(head);
    setReason(mess);
    setIsOpenSucc(true);
  }
  function closeModalSucc() {
    setIsOpenSucc(false);
  }

  const handleChangeProd = (p: any) => {
    SetProduct(p);
  };
  const handleChangetype = (p: any) => {
    SetType(p);
  };
  const handleChangelimit = (limit: any) => {
    myparams_transfer.limit = limit;
    getTransferList();
  };

  const handleChangeside = (side: any) => {
    myparams_transfer.type = side;
    // SetSide1(side)
    getTransferList();
  };

  const handleChanges = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    // let dates = new Date();
    // const myMoments = moment(dates).format("yyyy-MM-DD");
    SetEnddates1(new Date());
    // SetEnddates(myMoments)
    // SetStartdates(myMoment)
    SetStartdates1(date);
    myparams_transfer.startDate = myMoment;
    getTransferList();
  };
  const handleChanges1 = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    // let dates = new Date();

    SetEnddates1(date);
    myparams_transfer.endDate = myMoment;
    getTransferList();
  };
  const UserDis = (user_nameD: any) => {
    let param = {
      username: user_nameD,
    };
    adminService
      .user_disable(param)
      .then(function (response: any) {
        getusers();
        alert("success");
      })
      .catch((error) => {
        // alert("ttttyyyy")
        console.log(error);
      });
  };
  function openModalBal() {
    setIsOpenBal(true);
  }
  function closeModalBal() {
    setIsOpenBal(false);
  }
  function admin_balance(id: any) {
    openModalBal();
    adminService
      .admin_balance(id)
      .then(function (response: any) {
        SetUserBalance(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const getTransferList = () => {
    // setloadStyle({display: 'table'});
    get_transfer_download();
    // settransestyle({display:"table"})
    //  console.log(myparams_transfer)
    adminService
      .get_transfer(myparams_transfer)
      .then(function (response: any) {
        //   settransestyle({display:"none"})
        setTransferlist(response.data.data);
        // setloadStyle({display: 'none'});
        Settotaltransfer(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBalance = () => {
    adminService
      .get_balance()
      .then(function (response: any) {
        setBalanceList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const handlePageChange = (pageNumber: any) => {
  //   myparams.pageNum = pageNumber;
  //   // Setpage(pageNumber)
  //   //getTradeList()
  // };
  const handleChangesideTrade = (side: any) => {
    myparams.side = side;
    SetSide(side);
    // getTradeList();
  };
  const handleChangelimitTrade = (limit: any) => {
    myparams.limit = limit;
    //getTradeList();
  };
  const handleChangesTrade = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    let dates = new Date();
    const myMoments = moment(dates).format("yyyy-MM-DD");
    SetEnddate1(new Date());
    SetEnddate(myMoments);
    SetStartdate(myMoment);
    SetStartdate1(date);
    myparams.startDate = myMoment;
    myparams.endDate = enddate;
    //getTradeList();
  };
  const handleChangesTrade1 = (date: any) => {
    const myMoment1 = moment(date).format("yyyy-MM-DD");
    SetEnddate1(date);
    SetEnddate(myMoment1);
    myparams.startDate = startdate;
    myparams.endDate = myMoment1;
    // getTradeList();
  };
  const handleChangeProd1 = (p: any) => {
    // console.log(p);
    var res = p.split("-");
    myparams.BaseCurrency = res[0];
    myparams.QuoteCurrency = res[1];
    // console.log(res);
  };
  const handleChangeAccount = (p: any) => {
    myparams.account = p;
  };
  const getTradeList = () => {
    adminService
      .get_tradeList(myparams)
      .then(function (response: any) {
        setTradeList(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get_transfer_download = () => {
    adminService
      .get_transfer_download(myparams_transfer)
      .then(function (response: any) {
        SetcsvData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userEnable = (user_nameD: any) => {
    let param = {
      username: user_nameD,
    };
    adminService
      .user_enable(param)
      .then(function (response: any) {
        getusers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function selectOnlyOne() {
    $('input[name="user_roles[]"]:checked').each(function () {
      // alert($(this).attr("id"))
      update_roles.push($(this).val());
    });
  }
  const add_role = () => {
    selectOnlyOne();

    //console.log(update_roles.toString())
    let param: any = {
      username: user_name,
      role: update_roles,
    };

    adminService
      .update_role(param)
      .then(function (response: any) {
        closeModalrole();
        getusers();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const admin_transfer = () => {
    // alert(user_nameT)
    let param: any = {
      username: user_nameT,
      amount: amount,
      asset: product,
      type: type,
    };
    adminService
      .admin_transfer(param)
      .then(function (response: any) {
        closeModalconfirm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    userService
      .get_product()
      .then(function (response: any) {
        setProductList1(response.data);
        Setfeecurrency1(response.data[0].quoteAssetId);
        Setfeecurrency(response.data[0].baseAssetId);
      })
      .catch((error) => {
        console.log(error);
      });
    adminService
      .get_asset()
      .then(function (response: any) {
        setProductList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    adminService
      .get_roles()
      .then(function (response: any) {
        setAllroles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    adminService
      .get_users()
      .then(function (response: any) {
        setUserList(response.data);
        setTimeout(() => {
          //setloadStyle({display: 'none'});
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  $(document).on("click", ".remove_btn", function (e: any) {
    let val: any = $(this).attr("data-id");
    let key1: any = $(this).attr("data-name");
    $(this).closest("li").remove();

    if (feeArray.length > 0) {
      let param = {
        key: key1,
        value: val,
      };
      feeArray.pop(param);

      console.log(feeArray);
      save_fee();
      e.stopImmediatePropagation();
    }
  });
  // const removeFee = (amount:any)=>{

  // }
  const addfees = () => {
    let key1: any = feecurrency + "/" + feecurrency1;

    let datali: any =
      '<li> <span class="pull-left">' +
      key1 +
      '</span><span class="pull-right">' +
      feeAmount +
      '</span><div class="clearfix"></div><button ><img src="' +
      disable_img +
      '" data-id="' +
      feeAmount +
      '" data-name="' +
      key1 +
      '" alt="disable" class="remove_btn"/> </button></li>';
    // SetUserFees(userFees+'<li> <span class="pull-left">'+key1+'</span><span class="pull-right">'+feeAmount+'</span><div class="clearfix"></div><button ><img src="'+disable_img+'" data-id="'+feeAmount+'" data-name="'+key1+'" alt="disable" class="remove_btn"/> </button></li>')
    SetUserFees(userFees + datali);
    let param = {
      key: key1,
      value: feeAmount,
    };
    feeArray.push(param);
    closeModaladdfee();
  };
  const save_fee = () => {
    adminService
      .add_fees(feeArray, userFeeName)
      .then(function (response: any) {
        closeModaladdfee();
        closeModalgroup();
        getusers();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getusers = () => {
    adminService
      .get_users()
      .then(function (response: any) {
        setUserList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function openModalgroup(fee: any, name: any) {
    let data: any = "";
    let dataArray: any = [];
    //
    SetuserFeeName(name);
    // eslint-disable-next-line
    Object.keys(fee).map(function (key2: any) {
      let param = {
        key: key2,
        value: fee[key2],
      };
      dataArray.push(param);
      data +=
        '<li> <span class="pull-left">' +
        key2 +
        '</span><span class="pull-right">' +
        fee[key2] +
        '</span><div class="clearfix"></div><button ><img src=' +
        disable_img +
        ' alt="disable" data-id="' +
        fee[key2] +
        '" data-name="' +
        key2 +
        '" class="remove_btn"/> </button></li>';
    }, []);
    // if(data!==""){
    setfeeArray(dataArray);
    SetUserFees(data);
    // console.log(data,"======feeTemp")
    setIsOpengrp(true);
    // }
  }
  function openModalCompany(name: any, company: any) {
    setErrorMessage("");
    setUsername(name);
    setCompanyName(company);
    setIsOpenCompany(true);
  }
  function save_company() {
    setErrorMessage("");
    userService
      .accountType(user_name, "Organization", companyName)
      .then(function (response: any) {
        setIsOpenCompany(false);
        getusers();
      })
      .catch((error) => {
        console.log(error.response.data);
        if (
          error.response.data.code &&
          localize["en"].errors.admin[`${error.response.data.code}`]
        ) {
          setErrorMessage(
            localize["en"].errors.admin[`${error.response.data.code}`]
          );
        } else {
          setErrorMessage(localize["en"].errors.admin.other);
        }
        // setIsOpenCompany(false);
      });
  }

  function closeModalgroup() {
    setIsOpengrp(false);
  }
  function openModaltrans(id: any) {
    setUsernameT(id);
    setIsOpentrans(true);
  }
  function closeModaltrans() {
    setIsOpentrans(false);
  }
  function openModalconfirm() {
    closeModaltrans();
    setIsOpenconfirm(true);
  }
  function closeModalconfirm() {
    setIsOpenconfirm(false);
  }
  function openModaladdfee() {
    //setIsOpengrp(false)
    setIsOpenaddfee(true);
  }
  function closeModaladdfee() {
    setIsOpenaddfee(false);
  }
  var userTemp: any = [];
  if (Userlist) {
    userTemp = Object.keys(Userlist).map(function (key: any) {
      let fee: any = Userlist[key].fee;
      let roles: any = "";
      let enable: any = Userlist[key]["enable"];
      let name: any = Userlist[key]["username"];
      let comapny: any = Userlist[key]["orgName"];
      if (Userlist[key]["roles"].length === 0) {
        roles = '<div class="type"><p></p></div>';
      } else {
        roles = "";
      }

      $(document).on("click", ".my_roles", function (e: any) {
        // alert($(this).is(":checked"))
        // eslint-disable-next-line
        if ($(this).is(":checked") == true) {
          $(this).prop("checked", true);
          // $(this).removeAttr("checked")
        } else {
          $(this).prop("checked", false);
        }
        // e.stopImmediatePropagation();
        //  e.preventDefault();
      });

      let myroles: any = "";

      if (Userlist[key]["roles"]) {
        myroles = Userlist[key]["roles"];
      }
      // eslint-disable-next-line
      Object.keys(Userlist[key]["roles"]).map(function (key2: any) {
        roles +=
          ' <div class="type"><p>' +
          Userlist[key]["roles"][key2] +
          "</p></div>";
      }, []);

      return (
        <li key={key}>
          {comapny && <p>Company: {comapny}</p>}
          <div className="user_image">
            {/* <img   src={require("../../images/user_image.png")} alt="exchange" /> */}
            <p>{Userlist[key]["name"]}</p>
          </div>
          <div className="disable_right_btn">
            {enable === true ? (
              <button className="disable_btn" onClick={() => UserDis(name)}>
                <img
                  src={require("../../images/disable.png").default}
                  alt="disable"
                />
                <p>Disable{enable}</p>
              </button>
            ) : (
              <button className="enable_btn" onClick={() => userEnable(name)}>
                <i className="fa fa-check" aria-hidden="true"></i>

                {/* <img  src={require("../../images/disable.png")}/> */}
                <p>Enable{enable}</p>
              </button>
            )}
          </div>

          <div className="user_details">
            <div className="user_type">{ReactHtmlParser(roles)}</div>

            <div className="custom_drop_btn">
              <div className="dropdown">
                <button
                  className="btn"
                  type="button"
                  data-toggle="modal"
                  data-target="#add_user_type"
                  onClick={() => openModalrole(name, myroles)}
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div className="button_box">
            <button className="grad_btn" onClick={() => admin_balance(name)}>
              Balance
            </button>
            <button
              className="grad_btn"
              data-toggle="modal"
              data-target="#transfer_popup"
              onClick={() => openModaltrans(name)}
            >
              Transfer
            </button>
            <button
              className="grad_btn"
              onClick={() => openModalgroup(fee, name)}
            >
              Fee model
            </button>
            <button
              className="grad_btn"
              onClick={() => openModalCompany(name, comapny)}
            >
              Assign Company
            </button>
          </div>
        </li>
      );
    }, []);
  }
  //  let roleTemp: any = [];
  let roless: any = "<></>";
  function openModalrole(name: any, myroles: any) {
    roless = "";
    setUsername(name);
    if (allroles) {
      // alert("teee")
      let mycheck: any = "";
      // eslint-disable-next-line
      Object.keys(allroles).map(function (key: any) {
        // eslint-disable-next-line
        mycheck = "";
        // eslint-disable-next-line
        Object.keys(myroles).map(function (key1: any) {
          if (myroles[key1] && myroles[key1] === allroles[key]) {
            // alert(myroles[key1]+"==="+allroles[key])
            mycheck = "checked";
          }
        }, []);

        // <div class="form-group custom_check_box"> <input type="checkbox" class="my_roles" '+mycheck+' value='+allroles[key]+'  name="user_roles[]" id='+allroles[key]+'> <label for='+allroles[key]+'>'+allroles[key]+'</label></div>'
        roless +=
          '<li><label class="cutom_check">' +
          allroles[key] +
          '<input type="checkbox" class="my_roles" ' +
          mycheck +
          " value=" +
          allroles[key] +
          ' name="user_roles[]"/>  </label>  </li>';
      }, []);
      //  setIsOpenrole(true);
    }
    if (roless !== "") {
      //   alert(name)
      setRolesHtmls(roless);
      setIsOpenrole(true);
    }
  }
  function closeModalrole() {
    setIsOpenrole(false);
  }
  $(document).on("click", ".transfer_admin", function () {
    let value1 = $(this).attr("data-name");
    // alert(value1)
    setUsernameT(value1);
  });

  let ProdOption: any = [];
  if (ProductList) {
    ProdOption = Object.keys(ProductList).map(function (key: any) {
      return (
        <option value={ProductList[key].name} key={key}>
          {ProductList[key].name}
        </option>
      );
    }, []);
  }
  let ProdOptionFee: any = [];
  if (ProductList1) {
    ProdOptionFee = Object.keys(ProductList1).map(function (key: any) {
      // if(key==0){
      //   Setfeecurrency(ProductList1[key].baseAssetId)
      // }
      return (
        <option value={ProductList1[key].baseAssetId} key={key}>
          {ProductList1[key].baseAssetName}
        </option>
      );
    }, []);
  }
  let ProdOption1Fee: any = [];
  if (ProductList1) {
    // console.log(ProductList)
    // console.log(feeProduct)

    ProdOption1Fee = Object.keys(ProductList1).map(function (key: any) {
      // let addd: any = false;
      //  if(key==0){
      //   Setfeecurrency1(ProductList1[key].quoteAssetId)
      // }
      return (
        <option value={ProductList1[key].quoteAssetId} key={key}>
          {ProductList1[key].quoteAssetName}
        </option>
      );
    }, []);
  }
  let ProdOptions: any = [];
  if (ProductList1) {
    ProdOptions = Object.keys(ProductList1).map(function (key: any) {
      return (
        <option value={ProductList1[key].id} key={key}>
          {ProductList1[key].name}
        </option>
      );
    }, []);
  }

  var TransferTemp1: any = [];
  if (Transferlist) {
    // console.log(Transferlist,"====")
    TransferTemp1 = Object.keys(Transferlist).map(function (key: any) {
      let classtrade: any;
      if (Transferlist[key].type === "deposit") {
        classtrade = "green";
      } else {
        classtrade = "red";
      }
      let classtrade1: any;
      if (Transferlist[key].status === "Pending") {
        classtrade1 = "yellow_text";
      } else if (Transferlist[key].status === "Approved") {
        classtrade1 = "green";
      } else {
        classtrade1 = "red";
      }
      var t = new Date(Transferlist[key].timestamp);
      const myMoment = moment(t).format("YYYY-MM-DD hh:mm:ss.SSS");
      return (
        <tr key={key}>
          <td>{myMoment}</td>
          <td> {Transferlist[key].name}</td>
          <td className={classtrade} style={{ textTransform: "capitalize" }}>
            {Transferlist[key].type.toLowerCase()}
          </td>
          <td> {Transferlist[key].asset}</td>
          <td> {parseFloat(Transferlist[key].amount)}</td>
          <td>coming soom</td>
          <td className={classtrade1}>{Transferlist[key].status}</td>

          {Transferlist[key].status === "Pending" ? (
            <td>
              {" "}
              <div className="row">
                <div className="col-sm-6 text-right">
                  <button
                    className="grad_btn"
                    onClick={() => transfer_approve(Transferlist[key].id)}
                  >
                    Approve
                  </button>
                </div>
                <div className="col-sm-6 text-left">
                  <button
                    className="grad_btn"
                    onClick={() => openModalReject(Transferlist[key].id)}
                  >
                    Denay
                  </button>
                </div>
              </div>
            </td>
          ) : (
            <>
              {Transferlist[key].status === "Approved" ? (
                <td></td>
              ) : (
                <>
                  {Transferlist[key].rejectReason ? (
                    <td className="error">
                      <div
                        className="col-sm-6 text-right"
                        onClick={() =>
                          openModalSucc(
                            Transferlist[key].rejectReason,
                            "Reason"
                          )
                        }
                      >
                        reason
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </>
              )}
            </>
          )}
        </tr>
      );
    }, []);
  }

  let BalanceCurr: any = [];
  if (userBalnce) {
    // eslint-disable-next-line
    BalanceCurr = Object.keys(userBalnce).map(function (key: any) {
      if (userBalnce[key]["asset"].assetType === "Currency") {
        // let Totalavil = userBalnce[key].free+userBalnce[key].locked
        return (
          <tr key={key}>
            <td>{userBalnce[key]["asset"].name}</td>
            <td>{userBalnce[key].free}</td>
            <td>{userBalnce[key].locked}</td>
          </tr>
        );
      }
    }, []);
  }
  return (
      <AdminContext.Provider
        value={{
          add_role,
          roles_html,
          save_fee,
          userFees,
          SetAmount,
          admin_transfer,
          closeModalconfirm,
          handleChangeProd,
          ProdOption,
          ProductList,
          handleChangetype,
          openModalconfirm,
          closeModalBal,
          BalanceCurr,
          csvData,
          TradeList,
          handleChangesideTrade,
          handleChangelimitTrade,
          handleChangeProd1,
          ProdOptions,
          handleChangeAccount,
          getTradeList,
          handleChangesTrade,
          handleChangesTrade1,
          enddate1,
          startdate1,
          Transferlist,
          transfer_approve,
          openModalSucc,
          openModalReject,
          handleChangeside,
          handleChangelimit,
          enddate_trans1,
          handleChanges1,
          startdate_trans1,
          handleChanges,
          getusers,
          getBalance,
          getTransferList,
          BalanceList,
          Userlist,
          openModalrole,
          admin_balance,
          openModaltrans,
          openModalCompany,
          openModalgroup,
          UserDis,
          userEnable,
          modalIsOpenBal,
          modalIsOpenCompany,
          modalIsOpenReject,
          modalIsOpenSucc,
          modalIsOpenaddfee,
          modalIsOpenconfirm,
          modalIsOpengrp,
          modalIsOpenrole,
          modalIsOpentrans,
          setIsOpengrp,
          setIsOpenCompany,
          setIsOpenconfirm,
          setIsOpenaddfee,
          setIsOpenrole,
          setIsOpentrans,
          setIsOpenBal,
        }}
      >
        {children}
      </AdminContext.Provider>
  );
};
export default AdminContextWrapper;
