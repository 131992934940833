import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { userService } from './_services/user.services';
import { tokenService } from './_services/tokens.services';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useQuery, useQueryClient } from 'react-query';
import { config } from './config/config';

const AuthContext = createContext<any>({});
const { configheadersTemplate } = tokenService;
function AuthProvider({ children }: any) {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const queryClient = useQueryClient();
  useEffect(() => {
    userRefetch();
  }, [isAuthenticated]);

  const putUserInfoWithEntityId = async (entityId: string) => {
    const configheaders = configheadersTemplate();

    const url: string = `${config.apiurl}/userInfo/frankie/entity/${entityId}`;
    const formData = new FormData();
    const { data } = await axios.put(url, formData, configheaders);

    setUserInfo({
      ...userInfo,
      ...data,
    });
  };

  const fetchUserInfo = async () => {
    let configheaders = configheadersTemplate();
    let url: any = `${config.apiurl}/userInfo`;
    const { data } = await axios.get(url, configheaders);
    return data;
  };
  const {
    isFetching: userIsFetching,
    isLoading: userIsLoading,
    error: userError,
    data: userData,
    refetch: userRefetch,
  } = useQuery(
    'UserInfo',
    () => {
      return fetchUserInfo();
    },
    {
      onSuccess: data_ => {
        setUserInfo(data_);
      },
      onError: error => {
        setUserInfo({});
      },
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const isAuthenticated_ = authState === AuthState.SignedIn && user;
    setIsAuthenticated(isAuthenticated_);
  }, [authState, user]);

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData: any) => {
      setAuthState(nextAuthState);
      setUser(authData);
      if (nextAuthState === 'signedin') {
        var access_token = authData.signInUserSession.accessToken.jwtToken;
        var exp;
        if (
          authData.signInUserSession.accessToken.payload.auth_time &&
          authData.signInUserSession.accessToken.payload.exp
        ) {
          exp =
            authData.signInUserSession.accessToken.payload.exp -
            authData.signInUserSession.accessToken.payload.auth_time;
        }
        userService
          .jwtVerify(access_token, exp)
          .then(response => {
            setTimeout(() => {
              userRefetch();
            }, 0);
          })
          .catch(error => {
            console.log(error);
          });
      }
      if (nextAuthState == 'signedout') {
        queryClient.removeQueries();
        setUserInfo({});

        userService
          .jwtLogout()
          .then(response => {
            console.info('response: ', response);
            window.location.href = '/login';
          })
          .catch(error => {
            console.info('ERROR: ', error);
          });
      }
    });
  }, []);

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    res => {
      return res;
    },
    err => {
      if (err?.response?.status === 408) {
        queryClient.removeQueries();
        Auth.signOut();
      }
      return Promise.reject(err);
    }
  );
  const value = {
    authState,
    user,
    isAuthenticated,
    userInfo,
    userIsLoading,
    userIsFetching,
    userError,
    userRefetch,
    putUserInfoWithEntityId,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
