import React, { useContext, useEffect, useState } from 'react';
import Confirm from './Confirm';
import BtcIcon from '../../images/btc-icon.png';
import UsdtIcon from '../../images/usdt-icon.png';
import UsdcIcon from '../../images/usdc-icon.png';
import UsdIcon from '../../images/usd-icon.png';
import EurIcon from '../../images/eur-icon.png';
import ETHIcon from '../../images/ETH_Icon.png';

import MarketContextWrapper, { MarketContext } from './MarketContextWrapper';
import BuySellBox from './BuySellBox';
import PulseLoader from 'react-spinners/PulseLoader';
import { capitalize } from '../Utils';
import { OverlayTrigger, Popover } from 'react-bootstrap';
// @ts-ignore: Unreachable code error
const $ = window.$;

const currencyIconMap: any = {
  btc: BtcIcon,
  usd: UsdIcon,
  usdt: UsdtIcon,
  usdc: UsdcIcon,
  eur: EurIcon,
  eth: ETHIcon,
};
const Sell = () => {
  const {
    loader,
    getQuate,
    baseCurrencySize,
    quoteCurrencySize,
    setquoteCurrencyFn,
    setquoteCurrencySizeFn,
    setbaseCurrencyFn,
    setbaseCurrencySizeFn,
    side,
    productRelationshipMap: productRelationshipMap_,
    currencyBalance,
  } = useContext(MarketContext);

  const [currentBaseCurrency, setCurrentBaseCurrency] = useState('btc');
  const [currentQuoteCurrency, setCurrentQuoteCurrency] = useState('usd');
  const [allCurrencyBalance, setAllCurrencyBalance] = useState({
    usd: 0,
    usdt: 0,
    usdc: 0,
    btc: 0,
    eth: 0,
  });
  const [productRelationshipMap, setProductRelationshipMap] = useState({
    baseRelationshipMap: {
      btc: {},
      usdt: {},
      usdc: {},
      eth: {},
    },
    quoteRelationshipMap: {
      usd: {},
    },
  });

  const setNumberDisplayFn = (val: string) => {
    const formatVal = val.replaceAll(',', '');
    let formatNumber;
    if (!!Number(formatVal)) {
      if (Number(formatVal) < 1) {
        formatNumber = formatVal;
      } else if (formatVal.includes('.')) {
        // decimal without commas
        formatNumber = formatVal;
      } else {
        formatNumber = formatVal
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    } else {
      // decimal number start with period i.e `0.`
      if (formatVal[1] === '.') {
        formatNumber = formatVal;
      } else {
        // remove not digit/char
        formatNumber = formatVal.toString().replace(/[^0-9]/g, '');
      }
    }

    return formatNumber;
  };

  useEffect(() => {
    // @ts-ignore: Unreachable code error
    $('select.selectpicker').selectpicker({
      caretIcon: 'glyphicon glyphicon-menu-down',
    });
  }, []);

  useEffect(() => {
    setquoteCurrencyFn(currentQuoteCurrency);
    setbaseCurrencyFn(currentBaseCurrency);
  }, [currentBaseCurrency, currentQuoteCurrency]);

  useEffect(() => {
    if (currencyBalance.BTC) {
      let { USD, USDT, USDC, ETH, BTC } = currencyBalance;
      setAllCurrencyBalance({
        usd: USD.Available,
        usdt: USDT.Available,
        usdc: USDC.Available,
        btc: BTC.Available,
        eth: ETH.Available,
      });
    }
  }, [currencyBalance]);
  return (
    <>
      {/* this is the sell section*/}
      <section className="buy-sell-box">
        <div className="container">
          <div className="buy-sell-white-box">
            <BuySellBox></BuySellBox>
            <div className="buy-sell-inputareas text-center">
              <div
                className="buy-sell-input-outer"
                style={{ paddingRight: quoteCurrencySize ? 500 : 200 }}
              >
                <label id="label">{capitalize(side.toLowerCase())}</label>
                {quoteCurrencySize ? (
                  <>
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      overlay={basePopover}
                    >
                      <div style={{ position: 'relative' }}>
                        <input
                          disabled={quoteCurrencySize}
                          value={baseCurrencySize}
                          autoComplete="off"
                          className={'form-control-number-format'}
                          onChange={(event: any) => {
                            const value = event.currentTarget.value;
                            setbaseCurrencySizeFn(setNumberDisplayFn(value));
                          }}
                          placeholder="0"
                        />
                      </div>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <input
                      disabled={quoteCurrencySize}
                      value={baseCurrencySize}
                      autoComplete="off"
                      className={'form-control-number-format'}
                      onChange={(event: any) => {
                        const value = event.currentTarget.value;
                        setbaseCurrencySizeFn(setNumberDisplayFn(value));
                      }}
                      placeholder="0"
                    />
                  </>
                )}

                <div className="input-outer select-imagesbox">
                  <select
                    onChange={event => {
                      setCurrentBaseCurrency(
                        event.currentTarget.value.toLowerCase()
                      );
                    }}
                    title="Select"
                    className="selectpicker"
                  >
                    {Object.keys(
                      productRelationshipMap.baseRelationshipMap
                    ).map(_product => {
                      const product = _product.toLowerCase();
                      return (
                        <option
                          key={product}
                          value={product}
                          defaultChecked={'btc' === product}
                          data-thumbnail={currencyIconMap[product]}
                        >
                          {product.toUpperCase()}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="available_balance" style={{ color: '#70708a' }}>
                  <span>Available Balance: </span>
                  <strong>
                    {currentBaseCurrency.toUpperCase()}&nbsp;
                    {Object.entries(allCurrencyBalance)
                      .filter(([key, value]) => key === currentBaseCurrency)
                      .map(filtered => filtered[1])}
                  </strong>
                </div>
              </div>
              <div
                className="buy-sell-input-outer"
                style={{ paddingRight: baseCurrencySize ? 500 : 200 }}
              >
                <label>{side === 'BUY' ? 'Spend' : 'Receive'} </label>
                {baseCurrencySize ? (
                  <>
                    <OverlayTrigger
                      trigger="hover"
                      placement="right"
                      overlay={quotePopover}
                    >
                      <div style={{ position: 'relative' }}>
                        <input
                          disabled={baseCurrencySize}
                          value={quoteCurrencySize}
                          autoComplete="off"
                          className={'form-control-number-format'}
                          onChange={(event: any) => {
                            const value = event.currentTarget.value;
                            setquoteCurrencySizeFn(setNumberDisplayFn(value));
                          }}
                          placeholder="0"
                        />
                      </div>
                    </OverlayTrigger>
                  </>
                ) : (
                  <>
                    <input
                      disabled={baseCurrencySize}
                      value={quoteCurrencySize}
                      autoComplete="off"
                      className={'form-control-number-format'}
                      onChange={(event: any) => {
                        const value = event.currentTarget.value;
                        setquoteCurrencySizeFn(setNumberDisplayFn(value));
                      }}
                      placeholder="0"
                    />
                  </>
                )}
                <div className="input-outer select-imagesbox">
                  <select
                    onChange={event => {
                      setCurrentQuoteCurrency(
                        event.currentTarget.value.toLowerCase()
                      );
                    }}
                    title="Select"
                    className="selectpicker"
                  >
                    {Object.keys(
                      productRelationshipMap.quoteRelationshipMap
                    ).map(_product => {
                      const product = _product.toLowerCase();
                      return (
                        <option
                          key={product}
                          defaultChecked={currentQuoteCurrency === product}
                          data-thumbnail={currencyIconMap[product]}
                        >
                          {product.toUpperCase()}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="available_balance" style={{ color: '#70708a' }}>
                  <span>Available Balance: </span>
                  <strong>
                    {currentQuoteCurrency.toUpperCase()}&nbsp;
                    {Object.entries(allCurrencyBalance)
                      .filter(([key, value]) => key === currentQuoteCurrency)
                      .map(filtered => filtered[1])}
                  </strong>
                </div>
              </div>
              <a
                onClick={() => {
                  getQuate();
                }}
                className="main-color-btn main-color-bigbtn"
                id="get-quote"
              >
                {loader ? (
                  <>
                    Loading
                    <PulseLoader color="white" loading={true} size={3} />
                  </>
                ) : (
                  'Get Quote'
                )}
              </a>
              <Confirm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Sell;
const basePopover = (
  <Popover id="popover-basic">
    {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
    <Popover.Content>
      This input box is disabled. Remove the contents of the input box below to
      re-enable this popover.
    </Popover.Content>
  </Popover>
);
const quotePopover = (
  <Popover id="popover-basic">
    {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
    <Popover.Content>
      This input box is disabled. Remove the contents of the input box above to
      re-enable this popover.
    </Popover.Content>
  </Popover>
);
