import React from "react";

const Crypto = () => {
  return (
    <>
      {/* <section className="need-help">
            <div className="container">
                <h3>Want to buy or sell crypto currencies <br /><a href="#">Need help?</a></h3>
            </div>
        </section> */}
    </>
  );
};
export default Crypto;
