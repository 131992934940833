import React,{ useContext, useEffect, useState }  from "react";
import { formatNumber, capitalize } from ".";
import CopyTool from "./CopyTool";
import ContextWrapper, { Context } from "./ContextWrapper";
import { config } from "../../config/config";
import moment from "moment";
import Loader from "../Loader";
import { tokenService } from "./../../_services/tokens.services";
import { useQuery } from "react-query";
import axios from "axios";


export const TransferDetail = ({data, role} : InfoInterface ) => {
    const {
        id, amount, name, type, asset, toAddress, fromAddress, bankAccountName, bankAccountNo, bankName, fee, username, cryptoAsset, addressSuffix, timestamp, status, financeStatus, rejectReason, rejectTime, financeRejectReason, financeRejectTime
    } = data;
    const isWithdrawal: boolean = type === "withdraw";
    const isUser: boolean = role === "user";
    const isAdmin: boolean = role === "admin";
    const isDetail: boolean = role === "detail";
    const isReason: boolean = role === "reason";
    const isFinance: boolean = role === "finance";
    const cryptoAddress:string = isWithdrawal ? toAddress : fromAddress;

    return(
        <>  
            {
                isReason && (
                    <>
                        <ReasonInfo status={status} financeStatus={financeStatus} rejectReason={rejectReason} rejectTime={rejectTime} financeRejectReason={financeRejectReason} financeRejectTime={financeRejectTime}/>
                    </>
                )
            }
            <div>
                <BasicInfoWrapper username={username} name={name} type={type} asset={asset} amount={amount} timestamp={timestamp} isWithdrawal={isWithdrawal} fee={fee}/>
            </div>
            {
                !cryptoAsset && isWithdrawal && (
                    <BankInfo bankAccountName={bankAccountName} bankAccountNo={bankAccountNo} bankName={bankName}/>)
            }
            {
                cryptoAsset && (
                    <CryptoWalletInfo cryptoAddress={cryptoAddress} addressSuffix={addressSuffix}/>)
            } 
            {
                !cryptoAsset && !isWithdrawal &&  !isUser && ( isAdmin || isDetail || isReason ) && (
                    <TransferProof id={id}/>)
            }
        </>
    )
};
const BasicInfoWrapper = ({username, name, type, asset, amount, timestamp, isWithdrawal, fee} :InfoInterface ) => {
    const QUERY_INDEX = "TransferBalance";
    const URL = `${config.apiurl}/userInfo/balance?target=${username}`;
    const [fetchParams, setFetchParams] = useState<any>({});
    return (
        <>
          <ContextWrapper
            fetchParams={fetchParams}
            setFetchParams={setFetchParams}
            query_index={QUERY_INDEX}
            url={URL}
          >
            <BasicInfo  name={name} type={type} asset={asset} amount={amount} username={username} timestamp={timestamp} isWithdrawal={isWithdrawal} fee={fee}/>
          </ContextWrapper>
        </>
  );
};
const ReasonInfo = ({financeStatus, rejectReason, rejectTime, financeRejectReason, financeRejectTime, status} : InfoInterface) =>{
    let reasonDetail, reasonTime, rejectPerson;
    const rejectTimeFunc = ( timeStamp: any) =>{
        var t = new Date(timeStamp);
        const rejectTime = moment(t).format(
          "YYYY-MM-DD"
        );
        return rejectTime
    };
    if(financeStatus === "Rejected"){
        rejectPerson = "Finance";
        reasonDetail = financeRejectReason;
        reasonTime = financeRejectTime;
    }else if(status === "Rejected"){
        rejectPerson = "Admin";
        reasonDetail = rejectReason;
        reasonTime = rejectTime;
    };
    return(
    <div className="transfer_container">
        <div className="transfer_detail_row">
            <div className="transfer_detail_title">
                <span>
                    <strong>
                        Reject Status
                    </strong>
                </span>
            </div>
            <div className="transfer_detail_content">
                <span>
                    This transfer was rejected by {rejectPerson} {reasonTime && `on ${rejectTimeFunc(reasonTime)}`}.
                </span>
            </div>
        </div>
        <div className="transfer_detail_row">
            <div className="transfer_detail_title">
                <span>
                    <strong>
                        Reject Reason
                    </strong>
                </span>
            </div>
            <div className="transfer_detail_content">
                <span>
                    {reasonDetail ? reasonDetail : "Not stated"}
                </span>
            </div>
        </div>
        <hr/>
    </div>
    )
}
const BasicInfo = ({name, type, asset, amount, timestamp, isWithdrawal, fee} : InfoInterface )=> {
    const [balance, setBalance] = useState<any>(0);
    const { listData } = useContext(Context);
    let t = new Date(timestamp);
    const myMoment = moment(t).format(
      "YYYY-MM-DD hh:mm:ss"
    );
    useEffect(() => {
        if (listData) {
            const balanceAmount = listData.filter((item : any) => item.asset.id === asset)[0].free;
            setBalance(balanceAmount)
        }
    }, [listData]);
    return(
        <div className="transfer_container">
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Date
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {myMoment}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Account
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {name}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Side
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {capitalize(type)}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Currency
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {capitalize(asset)}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Available Balance
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {formatNumber(balance)} {asset}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Requested Amount
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {formatNumber(amount)} {asset}
                    </span>
                </div>
            </div>
                {isWithdrawal && <WithdrawalTransaction fee={fee} amount={amount} asset={asset}/>}
            <hr/>
        </div>
    )
};
const WithdrawalTransaction = ({fee, amount, asset} : InfoInterface) => {
    let withdrawalAmount;
    if(isNaN(fee)){
        fee = "Not stated";
        withdrawalAmount = amount;
        asset = ""
    }else{
        fee = fee;
        withdrawalAmount = amount - fee;
        asset = asset;
    }
    return (
        <>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Transaction Fee
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                         {fee} {asset}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                            Withdrawal Amount
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {formatNumber(withdrawalAmount)} {asset}
                    </span>
                </div>
            </div>
        </>
    )
};
const BankInfo = ({bankName, bankAccountNo, bankAccountName} : InfoInterface)=> {
    return(
        <div className="transfer_container">
            <strong>Bank Info</strong>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Bank Name
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                    {bankName}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Bank Account Name
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {bankAccountName}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Bank Account No
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                        {bankAccountNo}
                    </span>
                </div>
            </div>
            <hr/>
        </div>
    )
};
const CryptoWalletInfo = ({cryptoAddress, addressSuffix}: InfoInterface) => {
    return(
        <div className="transfer_container">
            <strong>Crypto Wallet Info</strong>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Address Name
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                    {addressSuffix || "Not Stated"}
                    </span>
                </div>
            </div>
            <div className="transfer_detail_row">
                <div className="transfer_detail_title">
                    <span>
                        <strong>
                        Destination Address
                        </strong>
                    </span>
                </div>
                <div className="transfer_detail_content">
                    <span>
                    {cryptoAddress ? <CopyTool text={cryptoAddress} digit={6}/> : "Not Stated"}
                    </span>
                </div>
            </div>
            <hr/>
        </div>
    )
};

const TransferProof = ({id}: InfoInterface)=> {
    const [previewLink, setPreviewLink] = useState('');
    const { configheadersTemplate } = tokenService;
    let configheaders =  configheadersTemplate();
    let binaryData = [] as any;
    const fetchTransferImage = () => {
      return axios.get(`${config.apiurl}/transfer/finance/approve/file/${id}`, {...configheaders, responseType: 'blob',})
    }
    const { isLoading, data, isError, error } = useQuery("transferImage", fetchTransferImage)
    useEffect( () => {
        let file = data?.data;
        binaryData.push(file)
        let imgaeUrl = window.URL.createObjectURL(new Blob(binaryData))
        setPreviewLink(imgaeUrl);
    }, [data?.data]);
    useEffect(() => {
        URL.revokeObjectURL(previewLink);
        setPreviewLink("");
    }, []);
    return (
        <div className="transfer_container">
            <strong>Transfer Proof</strong>
            {isLoading && (<Loader visible={isLoading}/>)}
            {!isLoading && previewLink && (<img className="img-size" src={previewLink}/>)}
            {!isLoading && !previewLink &&(<span>No image found</span>)}
        </div>
    )
};
interface InfoInterface{
    id?: string;
    data?: any;
    isWithdrawal?: boolean;
    name?: string;
    type?: any;
    asset?: any;
    amount?: any;
    bankAccountName?: string;
    bankAccountNo?: string;
    bankName?: string;
    fee?: any;
    username?: string;
    cryptoAddress?: string;
    addressSuffix?: string;
    balance?: any;
    timestamp?: any;
    role?: string;
    status?: string;
    financeStatus?: string;
    financeRejectTime?: number;
    financeRejectReason?: string;
    rejectTime?: number;
    rejectReason?: string;
};
