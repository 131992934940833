import jsPDF from "jspdf";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { userService } from "../../_services";

export interface HistoryUnit {
  timestamp: number;
  assetId: string;
  assetName: string;
  amount: string;
  type: string;
  side: any;
  balance: string;
  account: string;
}

export interface BankData {
  id: string;
  fromAddress: string;
  toAddress: string;
  bankName: any;
  bankBranchName: any;
  bankAddress: any;
  bankSwiftCode: any;
  bankAccountName: any;
  bankAccountNo: any;
  intermediaryBankName: any;
  intermediaryBankSwiftCode: any;
  intermediaryBankAddress: any;
  transferCode: string;
}

export interface ActivityUnit {
  id: string;
  type: string;
  amount: string;
  asset: string;
  fromAddress: string;
  toAddress: string;
  timestamp: number;
  username: string;
  name: string;
  status: string;
  rejectReason: any;
  data: BankData;
}

export interface TradeUnit {
  id: string;
  productId: string;
  productName: string;
  quoteQuantity: string;
  price: string;
  quantity: string;
  side: string;
  timestamp: number;
  orderId: string;
  fee: string;
  feeCurrency: string;
}

function useHistory(initialValue = false) {
  const csvRef = useRef() as any;
  const [csvData, setcsvData] = useState([]) as any;
  // const [ProductList,setProductList] = useState() as any;
  const [AssetList, setAssetList] = useState() as any;
  const [Tradlist, setTradlist] = useState<TradeUnit[]>();
  const [Transferlist, setTransferlist] = useState<ActivityUnit[]>([]);
  const [HistoryList, setHistoryList] = useState<HistoryUnit[]>();
  const [limit, SetLimit] = useState(10) as any;
  const [limit1, SetLimit1] = useState(5) as any;
  const [limitdepo, SetLimitdepo] = useState(5) as any;

  const [totaltrade, Settotaltrade] = useState(5) as any;
  const [totaldepo, Settotaldepo] = useState(5) as any;
  const [totaltransfer, Settotaltransfer] = useState() as any;
  // const [totalrefine,Settotalrefine] = useState(5) as any;
  const [startdate, SetStartdate] = useState("") as any;
  const [startdate1, SetStartdate1] = useState("") as any;
  const [enddate, SetEnddate] = useState("") as any;
  const [enddate1, SetEnddate1] = useState("") as any;
  const [side, SetSide] = useState("") as any;

  const [modalIsOpenSucc, setIsOpenSucc] = useState(false);
  const [modalIsOpenDetailDeposit, setIsOpenDetailDeposit] = useState(false);

  const [reason_msg, setReason] = useState("") as any;
  const [titlehead, setHead] = useState("Reason") as any;
  const [depositDetail, setDepositDetail] = useState() as any;
  const [activityAssets, setActivityAssets] = useState([]) as any;

  // eslint-disable-next-line
  const [myparams, SetParams] = useState({
    pageNum: 1,
    sortBy: "timestamp",
    sortOrder: "desc",
    limit: 2,
    type: "trade",
    side: side,
  }) as any;
  // eslint-disable-next-line
  const [myparams_history, Setmyparams_history] = useState({
    pageNum: 1,
    house: false,
    limit: limit1,
  }) as any;

  // eslint-disable-next-line
  const [myparams_transfer, SetParamsAll] = useState({
    pageNum: 1,
    sortOrder: "desc",
    type: "deposit,withdraw",
    limit: 5,
  }) as any;
  // eslint-disable-next-line

  const customStyles = {
    content: {
      left: "50%",
      border: "none",
      //  background                 : '#fff',
    },
    //  overlay: {zIndex: 99999}
  };

  function openModalSucc(mess: any, head: any) {
    setHead(head);
    setReason(mess);
    setIsOpenSucc(true);
  }
  function closeModalSucc() {
    setIsOpenSucc(false);
  }

  //deposit filter
  const handleChangasset = (id: any) => {
    myparams_transfer.asset = id;
  };
  const handleChangelimit = (limit: any) => {
    myparams_transfer.limit = limit;
    SetLimitdepo(limit);
  };

  const handleChange = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    let dates = new Date();
    const myMoments = moment(dates).format("yyyy-MM-DD");
    SetEnddate1(new Date());
    SetEnddate(myMoments);
    SetStartdate(myMoment);
    SetStartdate1(date);

    myparams_transfer.startDate = myMoment;
    myparams_transfer.endDate = myMoments;
  };
  const handleChange1 = (date: any) => {
    const myMoment1 = moment(date).format("yyyy-MM-DD");
    SetEnddate1(date);
    SetEnddate(myMoment1);
    myparams_transfer.startDate = startdate;
    myparams_transfer.endDate = myMoment1;
  };
  const handlePageChangedepo = (pageNumber: any) => {
    myparams_transfer.pageNum = pageNumber;
    getTransferActivity();
  };

  //all filter code
  // const handleChangeside1 = (side:any) => {

  //   if(side=="BUY" || side=="SELL"){
  //      myparams_history.side = side;
  //      myparams_history.type = "trade";
  //       }else{
  //     myparams_history.type = side;
  //    }
  //    };
  const handleChangelimit1 = (limit: any) => {
    myparams_history.limit = limit;
  };
  const handleChangassetA = (id: any) => {
    myparams_history.asset = id;
  };
  const handleChangeall = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    let dates = new Date();
    const myMoments = moment(dates).format("yyyy-MM-DD");
    SetEnddate1(new Date());
    SetEnddate(myMoments);
    SetStartdate(myMoment);
    SetStartdate1(date);
    myparams_history.startDate = myMoment;
    myparams_history.endDate = myMoments;
  };
  const handleChange1all = (date: any) => {
    const myMoment1 = moment(date).format("yyyy-MM-DD");
    SetEnddate1(date);
    SetEnddate(myMoment1);
    myparams_history.startDate = startdate;
    myparams_history.endDate = myMoment1;
  };

  const handlePageChangeall = (pageNumber: any) => {
    myparams_history.pageNum = pageNumber;
    getTradeListAll();
  };
  //trade filter
  const handleChangeT = (date: any) => {
    const myMoment = moment(date).format("yyyy-MM-DD");
    let dates = new Date();
    const myMoments = moment(dates).format("yyyy-MM-DD");
    SetEnddate1(new Date());
    SetEnddate(myMoments);
    SetStartdate(myMoment);
    SetStartdate1(date);
    myparams.startDate = myMoment;
    myparams.endDate = enddate;
  };
  const handleChange1T = (date: any) => {
    const myMoment1 = moment(date).format("yyyy-MM-DD");
    SetEnddate1(date);
    SetEnddate(myMoment1);
    myparams.startDate = startdate;
    myparams.endDate = myMoment1;
  };
  const handleChangeside = (side: any) => {
    myparams.side = side;
    SetSide(side);
    getTradeList();
  };
  const export_data = () => {
    userService
      .get_trade_download(myparams)
      .then(function (response: any) {
        setcsvData(response.data);
        csvRef.current.link.click();
      })
      .catch((error) => {
        // settransestyle({display:"none"})
        console.log(error);
      });
  };

  const handleChangeTransactionType = (val: string) => {
    SetParamsAll({ ...myparams_transfer, type: val });
  };

  useEffect(() => {
    getTransferActivity(); // This is be executed when `loading` state changes
  }, [myparams_transfer]);

  const handleChangelimitT = (limit: any) => {
    myparams.limit = limit;
    SetLimit(limit);
    // SetLimitdepo(limit)
  };
  const handleChangassetT = (id: any) => {
    myparams.baseCurrency = id;
  };
  const handlePageChangeT = (pageNumber: any) => {
    myparams.pageNum = pageNumber;
    getTradeList();
  };
  //functions to get listing of all tabs
  const getTradeList = () => {
    userService
      .get_tradeList(myparams)
      .then(function (response: any) {
        setTradlist(response.data.data);
        Settotaltrade(response.data.totalCount);
        // if(response.data.length<=5){
        //   Settotaltrade(response.data.length)
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTradeListAll = () => {
    SetEnddate1("");
    SetEnddate("");
    SetStartdate("");
    SetStartdate1("");
    userService
      .get_tradeList_balance(myparams_history)
      .then(function (response: any) {
        setHistoryList(response.data.data);
        Settotaltransfer(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const  getTransferListDepo=()=>{
  //     SetEnddate1("")
  //     SetEnddate("")
  //     SetStartdate("")
  //     SetStartdate1("")
  //     userService.get_transfer(myparams_transfer).then(function (response:any) {
  //       if(response.data.count>0){
  //         setTransferlist(response.data.data)
  //       }

  //     Settotaldepo(response.data.totalCount)
  //     }).catch(error => {
  //     console.log(error)
  //     });

  // }
  const getTransferActivity = () => {
    SetEnddate1("");
    SetEnddate("");
    SetStartdate("");
    SetStartdate1("");
    userService
      .get_transfer(myparams_transfer)
      .then(function (response: any) {
        myparams_transfer.startDate = undefined;
        myparams_transfer.endDate = undefined;

        setTransferlist(response.data.data);
        Settotaldepo(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTransferActivityAll = () => {
    userService
      .get_transfer({
        pageNum: 1,
        sortOrder: "desc",
        type: "deposit,withdraw",
      })
      .then(function (response: any) {
        let arrAsset: string[] = [];
        if (response.data.data) {
          Object.keys(response.data.data).map(function (key: any) {
            if (!arrAsset.includes(response.data.data[key].asset)) {
              arrAsset.push(response.data.data[key].asset);
            }
          });
        }
        setActivityAssets(arrAsset);
      })
      .catch((error) => {
        setActivityAssets([]);
        console.log(error);
      });
  };

  const export_datadepo = () => {
    userService
      .get_transfer_download(myparams_transfer)
      .then(function (response: any) {
        setcsvData(response.data);
        csvRef.current.link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const export_pdf = () => {
    userService
      .get_transfer_download(myparams_transfer)
      .then(function (response: any) {
        setcsvData(response.data);
        var splitted = response.data.split("\n");

        if (splitted.length > 1) {
          // initialize jsPDF
          const doc: any = new jsPDF("landscape", "pt", "A2");
          doc.setFontSize(10);
          var tableColumn: any = [];
          const tableRows: any = [];
          splitted.forEach((v: any, i: number) => {
            if (i === 0) {
              v = v.replace(/"/g, "");
              tableColumn = v.split(",");
            } else {
              tableRows.push(v.split('","'));
            }
          });
          doc.autoTable(tableColumn, tableRows, { startY: 30 });
          doc.text("CatchOTC Activities", 14, 15);
          doc.save(`catchOTC_activity.pdf`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const balance_export = () => {
    userService
      .balance_export(myparams_history)
      .then(function (response: any) {
        setcsvData(response.data);
        csvRef.current.link.click();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // userService.get_product().then(function (response:any) {
    //      setProductList(response.data)
    //      }).catch(error => {
    //         console.log(error)
    //     });

    userService
      .get_asset()
      .then(function (response: any) {
        setAssetList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    userService
      .get_tradeList_balance(myparams_history)
      .then(function (response: any) {
        setHistoryList(response.data.data);
        Settotaltransfer(response.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });

    getTransferActivityAll();
    // eslint-disable-next-line
  }, []);

  const openDepositDetailModal = (data: any) => {
    userService
      .transferDetail({ transferId: data.id })
      .then(function (response: any) {
        setDepositDetail(response.data);
        setIsOpenDetailDeposit(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let AssetOption: any = [];

  return {
    Transferlist,
    HistoryList,
    Tradlist,
    getTradeList,
    startdate1,
    handleChangeall,
    enddate1,
    handleChange1all,
    handleChangelimit1,
    getTradeListAll,
    handlePageChangeall,
    handleChangeTransactionType,
    handleChange,
    handleChange1,
    handleChangelimit,
    handleChangassetA,
    handleChangasset,
    AssetList,
    activityAssets,
    getTransferActivity,
    export_datadepo,
    export_pdf,
    handleChangeT,
    handleChange1T,
    handleChangeside,
    handleChangelimitT,
    handleChangassetT,
    AssetOption,
    handlePageChangeT,
    myparams,
    limit,
    totaltrade,
    modalIsOpenSucc,
    closeModalSucc,
    customStyles,
    modalIsOpenDetailDeposit,
    setIsOpenDetailDeposit,
    depositDetail,
    myparams_transfer,
    myparams_history,
    limit1,
    totaltransfer,
  };
}
export default useHistory;
