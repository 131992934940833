import axios from "axios";
import React from "react";
import { useQuery } from "react-query";
import { tokenService } from "./../../_services/tokens.services";

// import useExportData from "../Balance/useExportData";

export const Context = React.createContext<any>({});

const ContextWrapper: React.FC<{
  query_index: string;
  url: string;
  fetchParams: any;
  setFetchParams: any;
}> = ({ children, query_index, url, fetchParams, setFetchParams }) => {
  const fetchTable = async (fetchParams: any) => {
    for (var propName in fetchParams) {
      if (fetchParams[propName] === null || fetchParams[propName] === "") {
        delete fetchParams[propName];
      }
    }
    const { configheadersTemplate } = tokenService;
    let configheaders = configheadersTemplate();
    let customizedRequest = {
      ...configheaders,
      params: fetchParams,
    };
    const { data } :any =  await axios.get(url, customizedRequest) 
      .then(function (response: any) {
        return response})
      .catch((error:any) => { 
        console.log(error)
        if(error?.response?.status === 401){
          window.location.href = "/login"
        }
      })
   return data;
  };

  const {
    isLoading: listIsLoading,
    error: listError,
    data: listData,
    // refetch: listRefetch,
  } = useQuery([query_index, fetchParams], () => {
    return fetchTable(fetchParams);
  }, {
    enabled : true,
  });

  return (
    <Context.Provider
      value={{
        listIsLoading,
        listError,
        listData,
        // listRefetch,
        // export_data,
        fetchParams,
        setFetchParams,
        setPageNum: (val: any) => {
          setFetchParams({
            ...fetchParams,
            pageNum: val,
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default ContextWrapper;
