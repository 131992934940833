import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import ContextWrapper, { Context } from '../../Utils/ContextWrapper';
import BalanceModal from './Modals/BalanceModal';
import AssignModal from './Modals/AssignCompany';
import TransferModal from './Modals/TransferModal';
import AddFee from './Modals/AddFee';
import SpreadModal from './Modals/SpreadModal';
import AddRole from './Modals/AddRole';
import ConfirmKycModal from './Modals/ConfirmKycModal';
import Pagination from '../../Utils/Pagination';
import { FilterRow, TableContainer } from '../../Utils/FilterRow';
import moment from 'moment';
import { TableHeadFilter } from '../../Utils/TableHeadFilter';
import DisableModal from './Modals/DisableModal';
import UserDetailModal from './Modals/userDetails/UserDetails';
// import TransactionFee from './Modals/TransactionFee';

// Specific to this page
const QUERY_INDEX = 'AdminUser';
const URL = `${config.apiurl}/userInfo/filter`;
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;
  status?: string;
  limit: number;
  sortOrder?: string;
  accountType?: string;
  basisStatus?: string;
  completeKYC?: string;
  sortBy?: string;
}
const initialFetch = {
  pageNum: 1,
  sortOrder: 'desc',
  limit: 10,
  startDate: '',
  endDate: '',
  accountType: '',
  basisStatus: '',
  completeKYC: '',
  sortBy: '',
};
// End of specific to this page
const Index = () => {
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);
  return (
    <ContextWrapper
      fetchParams={fetchParams}
      setFetchParams={setFetchParams}
      query_index={QUERY_INDEX}
      url={URL}
    >
      <Table />
    </ContextWrapper>
  );
};
export default Index;

function Table() {
  const {
    listIsLoading,
    listError,
    listData,
    listRefetch,
    fetchParams,
    setFetchParams,
    setPageNum,
  } = useContext(Context);
  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(false);

  useEffect(() => {
    if (listData?.data) {
      setInitialized(true);
    }
  }, [listData?.data]);
  useEffect(() => {
    if (listData) {
      setCurrentPage(listData.data);
      setInitialized(true);
    }
  }, [listData]);
  useEffect(() => {
    if (fetchParams.sortBy) {
      setCurrentOrder(fetchParams.sortBy);
    }
  }, [fetchParams.sortBy]);

  return (
    <>
      <div className="border_box">
        <FilterRow
          searchParams={fetchParams}
          startDate={{
            value: fetchParams.startDate,
            set: (val: any) => {
              setFetchParams({
                ...fetchParams,
                startDate: val,
              });
            },
          }}
          endDate={{
            value: fetchParams.endDate,
            set: (val: any) => {
              setFetchParams({
                ...fetchParams,
                endDate: val,
              });
            },
          }}
          limit={{
            value: fetchParams.limit,
            set: (val: any) => {
              setFetchParams({
                ...fetchParams,
                limit: val,
                pageNum: 1,
              });
            },
          }}
          accountType={{
            value: fetchParams.accountType,
            set: (val: any) => {
              setFetchParams({
                ...fetchParams,
                accountType: val,
                pageNum: 1,
              });
            },
          }}
          clearFilter={{
            set: (val: any) => {
              setFetchParams(initialFetch);
            },
          }}
        />
      </div>
      <div className="table-responsive">
        <Loader visible={listIsLoading}></Loader>
        <table
          cellPadding="0"
          cellSpacing="0"
          className="main-table user_info_container"
        >
          <thead>
            <tr>
              <th className="user_item_row">
                <div className="filter-box tool_chain">
                  Account Name
                  <TableHeadFilter
                    value="account"
                    currentOrder={currentOrder}
                    set={(val: any, orderVal: any) => {
                      setFetchParams({
                        ...fetchParams,
                        sortBy: val,
                        sortOrder: orderVal,
                        pageNum: 1,
                      });
                    }}
                  />
                </div>
              </th>
              <th className="user_item_row">
                <div className="filter-box">
                  Email
                  <TableHeadFilter
                    value="email"
                    currentOrder={currentOrder}
                    set={(val: any, orderVal: any) => {
                      setFetchParams({
                        ...fetchParams,
                        sortBy: val,
                        sortOrder: orderVal,
                        pageNum: 1,
                      });
                    }}
                  />
                </div>
              </th>
              <th className="user_item_row">
                <div className="filter-box">
                  Registration Date
                  <TableHeadFilter
                    value="date"
                    currentOrder={currentOrder}
                    set={(val: any, orderVal: any) => {
                      setFetchParams({
                        ...fetchParams,
                        sortBy: val,
                        sortOrder: orderVal,
                        pageNum: 1,
                      });
                    }}
                  />
                </div>
              </th>
              <th className="user_item_row">Account Type</th>
              <th className="user_item_row">Verification Status</th>
              <th className="user_item_row">User Role</th>
              <th className="user_item_row">Action</th>
            </tr>
          </thead>
          <tbody>
            {!listIsLoading && currentPage?.length === 0 && (
              <p style={{ margin: '1rem' }}>No users found.</p>
            )}
            {!listIsLoading &&
              currentPage?.map((user: any, index: number) => {
                const {
                  name,
                  email,
                  createDate,
                  accountType,
                  basisStatus,
                  completeKYC,
                  roles,
                } = user;
                let kycstatus = '';
                let colors = '';
                let registrationDate = new Date(createDate);
                registrationDate = moment(registrationDate).format(
                  'YYYY-MM-DD hh:mm:ss'
                );
                if (completeKYC !== true) {
                  if (basisStatus === 'new') {
                    kycstatus = 'Not Available';
                    colors = '#000000';
                  } else if (basisStatus === 'request_data') {
                    kycstatus = 'Request Document';
                    colors = '#eaa200';
                  } else if (basisStatus === 'confirmed') {
                    kycstatus = 'Need Confirm';
                    colors = '#1eb700';
                  } else if (basisStatus === 'pending') {
                    kycstatus = 'Processing';
                  } else if (basisStatus === 'decline') {
                    kycstatus = 'Declined';
                    colors = '#ec0300';
                  } else {
                    kycstatus = 'Not Available';
                    colors = '#b6b9c1';
                  }
                } else {
                  kycstatus = 'Approved';
                  colors = '#00c7d3';
                }
                return (
                  <tr className="user_row">
                    <td className="user_row_item">
                      <span className="user_name">{name}</span>
                    </td>
                    <td className="user_row_item">
                      <span className="user_email">{email}</span>
                    </td>
                    <td className="user_row_item">
                      <span className="user_registration_date">
                        {registrationDate}
                      </span>
                    </td>
                    <td className="user_row_item">
                      <span className="user_type">{accountType}</span>
                    </td>
                    {kycstatus == 'Need Confirm' ? (
                      <td className="user_row_item">
                        <ConfirmKycModal data={user} />
                      </td>
                    ) : (
                      <td className="user_row_item" style={{ color: colors }}>
                        {kycstatus}
                      </td>
                    )}
                    <td className="user_row_item">
                      <div className="user_role">
                        {roles
                          .filter(
                            (role: string) =>
                              role !== 'Buyer' &&
                              role !== 'Seller' &&
                              role !== 'Custodian' &&
                              role !== 'Principal'
                          )
                          .map((item: string) => (
                            <span className="role_item">{item}</span>
                          ))}
                      </div>
                    </td>
                    <td className="user_row_item">
                      <div className="user_action_btns">
                        <AddFee data={user} />
                        <AddRole data={user} />
                        {/* <AssignModal data={user}/> */}
                        <SpreadModal data={user} />
                        {/* <TransactionFee data={user}/> */}
                        <BalanceModal data={user} />
                        <DisableModal data={user} />
                        <UserDetailModal data={user} />
                        {/* <TransferModal data={user}/> */}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="custom_table">
        <TableContainer isLoading={initialized && listIsLoading}>
          <table className="table"></table>
          {count !== 0 && (
            <div className={'col-md-11'}>
              <Pagination
                onChange={(page: any) => setPageNum(page)}
                count={fetchParams.limit}
                totalCount={totalCount}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </>
  );
}
