import { useContext, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  Table,
  Alert,
} from 'react-bootstrap';
import * as yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../../contexts/AuthContext';
import { HashLink } from 'react-router-hash-link';
import { ErrorMessage, formatNumber } from '../../Utils';
import TwoFactorAuthModal from '../../common/modals/TwoFactorAuthModal';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';
import { config } from '../../../config/config';
import TransactionFeeAppendix from './TransactionFeeAppendix';

function SellOrderModal({
  data,
  available,
}: {
  data: { asset: any; total: any; free: any; locked: any };
  available: number;
}) {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    mutation.reset();
    setAmount(0);
    setError('');
  };
  const [amount, setAmount] = useState<number>();
  const [receivedAmount, setReceivedAmount] = useState<any>();
  const [cryptoAddress, setCryptoAddress] = useState<any>(
    userInfo?.addressList?.[0]?.id || null
  );
  const [error, setError] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  const [showToFactor, setShowToFactor] = useState(false);
  let schema = yup.object().shape({
    amount: yup.number().required().positive().max(available),
    receivedAmount: yup
      .number()
      .positive('Insufficient balance available for withdrawal'),
  });
  const mutation: any = useMutation(
    ({ amount }: any) => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/transfer/withdraw`;
      let formData = new FormData();
      formData.append('amount', amount);
      formData.append('asset', data.asset.id);
      formData.append('userAddress', cryptoAddress);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setAmount(0);
        setCryptoAddress(null);
        queryClient.invalidateQueries(['CurrencyBalance', {}]);
        setToastObj({
          message: `Crypto withdrawal requested`,
          type: 'success',
        });
        handleClose();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Failed to withdraw`,
          type: 'error',
        });
        handleClose();
      },
    }
  );
  const addressList =
    userInfo?.addressList &&
    Object.keys(userInfo.addressList).filter((key) => {
      const typeWithdraw = userInfo?.addressList[key].type;
      const { asset } = userInfo.addressList[key];
      const actionType = 'withdraw';
      return asset.name === data.asset.name && typeWithdraw === actionType;
    });

  function getReceived(received: number) {
    setReceivedAmount(received);
  }
  return (
    <>
      <Button className="btn-light-border" onClick={handleShow}>
        <strong>Withdraw</strong>
      </Button>
      <TwoFactorAuthModal
        onSuccess={() => {
          setShowToFactor(false);
          // mutation.mutate({
          //   amount,
          // });
        }}
        onClose={() => {
          setShowToFactor(false);
        }}
        initialShow={showToFactor}
        callback={() => {}}
      ></TwoFactorAuthModal>
      <Modal size={'lg'} centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              Withdrawal Request
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
          <Alert variant={'warning'}>
            <span>
              {`Please make sure that you enter a ${data.asset.name} address below.
                Selecting any other address will result in the loss of your
                funds. For security purposes, we manually verify every
                withdrawal before sending. Withdrawals are processed within 24
                hours.`}
            </span>
          </Alert>
          <div className="withdrawal-formarea">
            <div className="row">
              <aside className="col-lg-3">Currency:</aside>
              <aside className="col-lg-9">
                {data.asset.name} {data.asset.name === 'USDT' ? '' : ''}
              </aside>
            </div>

            <div className="row">
              <aside className="col-lg-3">Available:</aside>
              <aside className="col-lg-9">{formatNumber(available)}</aside>
            </div>
            <form>
              <div className="row">
                <aside className="col-lg-3 paddingtop20">
                  <FormLabel>Amount:</FormLabel>
                </aside>
                <aside className="col-lg-9">
                  <FormControl
                    value={amount}
                    onChange={(event: any) => {
                      setAmount(event.currentTarget.value);
                    }}
                    type="number"
                    placeholder="Enter amount"
                    required
                  />
                  <ErrorMessage path={'amount'} error={error}></ErrorMessage>
                </aside>
              </div>
            </form>
            <TransactionFeeAppendix
              amount={Number(amount)}
              type="network"
              asset={data.asset.name}
              getReceived={getReceived}
            />
            <ErrorMessage path={'receivedAmount'} error={error}></ErrorMessage>
            <h4 className="table-title">Choose crypto address</h4>
            <Table hover className="withdraw-table-list">
              <tbody>
                {addressList?.length === 0 && (
                  <Alert variant={'danger'}>
                    No {data.asset.name} addresses provided. Please add one on
                    your{' '}
                    <HashLink to="/profile/manage_crypto_address">
                      Profile page
                    </HashLink>
                  </Alert>
                )}
                {userInfo?.addressList &&
                  Object.keys(addressList)
                    .filter((key: any) => {
                      const addressListIndex = addressList[0];
                      const { asset } = userInfo.addressList[addressListIndex];
                      return asset.name === data.asset.name;
                    })
                    .map((key: any, index) => {
                      const { asset, name, address, id } =
                        userInfo.addressList[addressList[key]];
                      return (
                        <tr key={key}>
                          <td>
                            <div className="withdraw-table-content">
                              <input
                                onChange={(event) => {
                                  setCryptoAddress(event.currentTarget.value);
                                }}
                                value={id}
                                checked={
                                  cryptoAddress
                                    ? cryptoAddress === id
                                    : index === 0
                                }
                                name={'group1'}
                                type={'radio'}
                                required
                              />
                              <label>{name}</label>
                              <div>{address}</div>
                              <div>{asset.name}</div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <>
            {mutation.isError ? (
              <div>An error occurred: {mutation.error.message}</div>
            ) : null}
            <Button
              disabled={addressList?.length === 0}
              className={'main-color-btn full-width-btn'}
              onClick={() => {
                schema
                  .validate({ receivedAmount, amount })
                  .then(function (valid: any) {
                    if (valid) {
                      // this is where we have the two factor authentication
                      setError(null);
                      setShowToFactor(true);
                      mutation.mutate({
                        amount,
                      });
                      handleClose();
                    } else {
                      alert('fail');
                    }
                    // valid; // => true
                  })
                  .catch(function (err: any) {
                    setError(err);
                  });
              }}
            >
              {mutation.isLoading ? <>Submitting order...</> : <> Submit</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SellOrderModal;
