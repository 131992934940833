import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "./contexts/AuthContext";

function ErrorModal() {
  const { errorObj, clearErrorObj } = useContext(AuthContext);
  const [show, setShow] = useState(errorObj ? true : false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (errorObj) {
      handleShow();
    }
  }, [errorObj, handleShow]);

  const handleClose = () => {
    clearErrorObj();

    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        style={{
          transform: "translate(0, 50%) !important",
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <img
            src={require("./images/warning.png").default}
            style={{ marginBottom: 10, height: "80px", marginLeft: "-9px" }}
            alt="green"
          />
          <h1 style={{ color: "red" }}>Error</h1>
          <p style={{ color: "black" }}>{errorObj?.message || "Error"}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button block className={"yellow_btn"} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ErrorModal;
