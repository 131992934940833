import React from "react";
import ManageCryptoAddress from "./ManageCryptoAddress/index";

const NavTabs = () => {
  return (
    <>
      <div
        className="tab-pane"
        id="profile-crypto-address"
        role="tabpanel"
        aria-labelledby="profile-crypto-address-tab"
      >
        <ManageCryptoAddress />
      </div>
    </>
  );
};
export default NavTabs;