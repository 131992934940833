import React, { useContext, useEffect, useState, Fragment } from 'react';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import AddWithdrawlFeeModal from './Modals/AddWithdrawlFeeModal';
import EditWithdrawlFeeModal from './Modals/EditWithdrawlFeeModal';
import { AdminContext } from '../AdminContextWrapper';
import { capitalize } from '../../Utils';
import { tokenService } from './../../../_services/tokens.services';
import axios from 'axios';
import { useQuery } from 'react-query';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import DeleteWithdrawalFeeModal from './Modals/DeleteWithdrawalFeeModal';

const AdminSetting = () => {
  const { ProductList } = useContext(AdminContext);
  const [currencyData, setCurrencyData] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const fiatCurrency = currencyData?.filter(
    (currency: any) => currency?.currencyType === 'Fiat'
  );
  const cryptCurrency = currencyData?.filter(
    (currency: any) => currency?.currencyType === 'Crypto'
  );

  useEffect(() => {
    if (ProductList) {
      setCurrencyData(ProductList);
      setIsLoading(false);
    }
  }, [ProductList]);

  return (
    <>
      <div className="border_box">
        <Table currencyList={cryptCurrency} type="network" key='fee_setting_network_table'/>
        <Table currencyList={fiatCurrency} type="withdraw" key='fee_setting_withdraw_table'/>
      </div>
    </>
  );
};

const Table = ({ currencyList, type }: any) => {
  const [data, setData] = useState(null);
  const [isTypeLoading, setIsTypeLoading] = useState(true);

  const fetchTransferFeeHelper = async (type: string): Promise<void> => {
    try {
      await setIsTypeLoading(true);
      const { configheadersTemplate } = tokenService;
      const configheaders = configheadersTemplate();
      const url = `${config.apiurl}/transfer/fee/${type}/default`;
      const res = await axios.get(url, configheaders);
      setData({ ...res.data });
      await setIsTypeLoading(false);
    } catch (error) {
      await setIsTypeLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchTransferFeeHelper(type);
  }, []);

  return (
    <>
      <div className='table-responsive setting_table'>
        <div className='filter-box'>
          <h5 className="equal">
            {capitalize(type)} Fee Setting&nbsp;&nbsp;
            <OverlayTrigger
              key={`add-${type}-fee`}
              placement="right"
              overlay={
                <Tooltip id={`add-${type}-fee`} className='tooltip'>
                  If withdrawal amount does not fall into any range, then fee =
                  0.
                </Tooltip>
              }
            >
              <BsFillInfoCircleFill/>
            </OverlayTrigger>
          </h5>
          <AddWithdrawlFeeModal
            currencyList={currencyList}
            type={type}
            fetchTransferFeeHelper={fetchTransferFeeHelper}
          />
        </div>
        <br />
        {isTypeLoading && <Loader visible={isTypeLoading}></Loader>}
        <table className='main-table fin_trade_container setting'>
          <thead>
            <tr className='table-row'>
              <th>Currency</th>
              <th>Range</th>
              <th>{capitalize(type)} Fee</th>
            </tr>
          </thead>
          <tbody>
            {!!data &&
              Object.entries(data).map((currency: any) => {
                return (
                  <Fragment key={currency[0]}>
                    {currency[1].map((item: any, index: number) => {
                      return (
                        <tr key={`fee_item_${item.asset}_${index}`}>
                          <td className='equal'>
                            <img
                              src={
                                require('../../../images/' +
                                  item.asset +
                                  '.png').default
                              }
                              alt={item.asset}
                              className='setting_currency_icon'
                            />
                            <span>{item.asset}</span>
                            <EditWithdrawlFeeModal
                              type={type}
                              feeData={item}
                              currentAsset={item.asset}
                              index={index}
                              fetchTransferFeeHelper={fetchTransferFeeHelper}
                            />
                            <DeleteWithdrawalFeeModal
                              type={type}
                              feeData={item}
                              currentAsset={item.asset}
                              index={index}
                              wholeData={currency[1]}
                              fetchTransferFeeHelper={fetchTransferFeeHelper}
                            />
                          </td>
                          <td>
                            {item.lower} - {item.upper}
                          </td>
                          <td>{item.value}</td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default AdminSetting;
