import React, { useEffect, useState, useContext } from "react";
import Loader from "../../Loader";
import { config } from "../../../config/config";
import { FilterRow } from "../../Utils/FilterRow";
import ContextWrapper, { Context } from "../../Utils/ContextWrapper";

// Specific to this page
const QUERY_INDEX = "AdminIncome";
const URL = `${config.apiurl}/otc/income/summary`;

// const { export_data } = useExportData();
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;  
}
const initialFetch = {
  pageNum: 1,
  startDate: "",
  endDate: "",
};

// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table/>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () =>{
  const {
    listIsLoading,
    listData,
    fetchParams,
    setFetchParams,
  } = useContext(Context)
  const [currentPage, setCurrentPage] = useState([]) as any;
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if(listData?.data){
      setCurrentPage(listData.data);
      setInitialized(true);
    }
  }, [listData])
  
  return(
    <div className="admin_income_box">
      <FilterRow
        searchParams={fetchParams}
        startDate={{
          value: fetchParams.startDate,
          set: (val: any) => {
            setFetchParams({
              ...fetchParams,
              startDate: val,
            });
          },
        }}
        endDate={{
          value: fetchParams.endDate,
          set: (val: any) => {
            setFetchParams({
              ...fetchParams,
              endDate: val,
            });
          },
        }}
        clearFilter={{
          set: (val: any) => {
          setFetchParams(
              initialFetch
          )}
      }}
    />
      <table
        cellPadding="0"
        cellSpacing="0"
        className="main-table"
      >
        <Loader visible={listIsLoading}/>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Spread Fee</th>
            <th>Transfer Fee</th>
            <th>Sum</th>
          </tr>
        </thead>
        <tbody>
          {
            !listIsLoading &&
            listData?.sum &&
            Object.keys(listData?.sum).length === 0 &&
            (
              <tr>
                <td>
                  No rows found. Try clearing your filters.
                </td>
              </tr>)
          }
          {
            !listIsLoading &&
            listData?.sum &&
            Object.keys(listData?.sum).length !== 0 &&
            (
              Object.entries(listData?.sum).map((([key, value]: any) => {
                let spread, transferFee;
                let spreadValue, transferFeeValue = 0;
                if(currentPage.hasOwnProperty("spread") && currentPage.hasOwnProperty("transfer_fee")){
                  spread = currentPage.spread;
                  transferFee =  currentPage.transfer_fee;
                  let filteredSpread = Object.keys(spread).filter((spreadKey)=>spreadKey === key)[0];
                  let filteredTransferFee = Object.keys(transferFee).filter((transferFeeKey)=> transferFeeKey === key)[0];
                  spreadValue = filteredSpread ? spread[filteredSpread] : 0;
                  transferFeeValue = filteredTransferFee ? transferFee[filteredTransferFee] : 0
                }
                else if(currentPage.hasOwnProperty("spread") && !currentPage.hasOwnProperty("transfer_fee")){
                  spread = currentPage.spread;
                  let filteredSpread = Object.keys(spread).filter((spreadKey)=>spreadKey === key)[0];
                  spreadValue = filteredSpread ? spread[filteredSpread] : 0;
                  transferFeeValue = 0;
                }else if(currentPage.hasOwnProperty("transfer_fee") && !currentPage.hasOwnProperty("spread")){
                  transferFee =  currentPage.transfer_fee;
                  let filteredTransferFee = Object.keys(transferFee).filter((transferFeeKey)=> transferFeeKey === key)[0];
                  transferFeeValue = filteredTransferFee ? transferFee[filteredTransferFee] : 0
                  spreadValue = 0;
                };
                return(
                  <tr className="table-row">
                    <td>
                      <img
                        className="currency-img"
                        src={
                          require("../../../images/" + key + ".png").default
                        }/>&nbsp;
                      {key}
                    </td>
                    <td>
                      {spreadValue}
                    </td>
                    <td>
                      {transferFeeValue}
                    </td>
                    <td>
                      {value}
                    </td>
                  </tr>
                )
              }))
            )
          }
        </tbody>
      </table>
    </div>
  )
}
