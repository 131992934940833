import React, { useContext, useEffect, useState } from "react";
import MarketContextWrapper, { MarketContext } from "./MarketContextWrapper";

const BuySellBox = () => {
  const { setSide, side } = useContext(MarketContext);
  return (
    <>
      <label className="custom-radio" id="buy-check">
        I want to buy
        <input
          type="radio"
          onChange={() => {
            setSide("BUY");
          }}
          checked={side === "BUY"}
          name="radio"
        />
        <span className="checkmark"></span>
      </label>
      <label className="custom-radio" id="sell-check">
        I want to sell
        <input
          checked={side === "SELL"}
          onChange={() => {
            setSide("SELL");
          }}
          type="radio"
          name="radio"
        />
        <span className="checkmark"></span>
      </label>
    </>
  );
};
export default BuySellBox;
