import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "./contexts/AuthContext";
import GREEN_CHECK_IMAGE from "./images/green_check.png";

function SuccessModal() {
  const { successObj, clearSuccessObj } = useContext(AuthContext);
  const [show, setShow] = useState(successObj ? true : false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (successObj) {
      handleShow();
    }
  }, [successObj, handleShow]);

  const handleClose = () => {
    clearSuccessObj();

    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        style={{
          transform: "translate(0, 50%) !important",
        }}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <img src={GREEN_CHECK_IMAGE} alt="green" />
          <h1 style={{ color: "green" }}>Congratulation</h1>
          <p style={{ color: "black" }}>
            {successObj?.message || "A new order successfully opened"}
            <br/>
            {successObj?.detail}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button block className={"yellow_btn"} onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SuccessModal;
