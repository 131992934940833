import React, { useState, useEffect, useContext } from "react";
import { userService } from "../../_services";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { AuthContext } from "../../contexts/AuthContext";
import { Line } from "rc-progress";
import * as moment from "moment";
//components
import QuoteForm from "./components/QuoteForm";
import localize from "../../localize/alerts";
import Crypto from "./Crypto";
import Sell from "./Sell";
import ExpireTrade from "./ExpireTrade";
import useMarket from "./useMarket";
import MarketContextWrapper, { MarketContext } from "./MarketContextWrapper";
import FailModal from "./FailModal";
import SuccessModal from "./SuccessModal";
import CancelModal from "./CancelModal";

const customStyles = {
  content: {
    left: "50%",
    border: "none",
  },
};

const Order_new = (props: any) => {
  const {
    loader,
    quateTemp,
    ExpTemp,
    TradeTemp,
    warningModal,
    setWarningModal,
    errorMessage,
    askStyle,
    setSide,
    currencyErr,
    baseCurrency,
    ProdOption,
    ProdOption1,
    getQuate,
    QuateErr,
    counter,
    quoteCurrencySize,
    baseCurrencySize,
    setquoteCurrencySizeFn,
    setquoteCurrencyFn,
    setbaseCurrencyFn,
    setbaseCurrencySizeFn,
    side,
    modalIsOpencong,
    setIsOpencong,
  } = useContext(MarketContext);

  return (
    <>
      <div className={"fullheight"}>
        <Crypto></Crypto>
        <Sell></Sell>
        <ExpireTrade></ExpireTrade>
      </div>
      <div className="topsections">
        <section
          className={
            loader ? "login_form pd_common blur-box" : "login_form pd_common"
          }
        >
          <div className="container-fluid">
            <div className="quote_sec">
              {/* <h1 className='text-center sec_heading' style={{marginBottom: "50px"}}>
            Get you’re best Quote here!
          </h1> */}
              {/* {quateTemp.length > 0 ? (
                quateTemp
              ) : (
                <QuoteForm
                  loader={loader}
                  setSide={setSide}
                  currencyErr={currencyErr}
                  baseCurrency={baseCurrency}
                  ProdOption={ProdOption}
                  ProdOption1={ProdOption1}
                  getQuate={getQuate}
                  QuateErr={QuateErr}
                  counter={counter}
                  quoteCurrencySize={quoteCurrencySize}
                  baseCurrencySize={baseCurrencySize}
                  setquoteCurrencySize={setquoteCurrencySizeFn}
                  setquoteCurrency={setquoteCurrencyFn}
                  setbaseCurrency={setbaseCurrencyFn}
                  setbaseCurrencySize={setbaseCurrencySizeFn}
                  side={side}
                />
              )}
              <div /> */}

              {/* <!--your quote section start-->  */}
              {/* <section className={loader ? 'your_quote blur-box' : 'your_quote'}>
          <div className='container'> */}
              {/* <!-- <h1 className='text-center sec_heading'>You’re</h1> --> */}
              {/* {quateTemp?(<> 
          <div className="row"><div className="col-sm-10 col-sm-offset-1">{quateTemp}</div></div></>):(<></>)} */}

              {/* <!-- <Line percent={percentage} strokeWidth={4} strokeColor="#D3D3D3" />
 {seconds} {percentage} --> */}

              {/* <!-- <div className="green_line"></div>
         <p>Expires in 06 Seconds!</p> --> */}
              {/* </div>
        </section> */}
            </div>
          </div>
        </section>
        {/* <div className="loader_bg quoteLoader" style={askStyle}>
          <img
            className="loader_image"
            src={require("../../images/loader.gif").default}
            alt="loader"
          />
        </div> */}
      </div>
      <FailModal></FailModal>
      <SuccessModal></SuccessModal>
      <CancelModal></CancelModal>
    </>
  );
};
export default Order_new;
