import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FilterRow, TableContainer } from '../../Utils/FilterRow';
import { formatNumber } from '../../Utils';
import Pagination from '../../Utils/Pagination';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import ContextWrapper, { Context } from '../../Utils/ContextWrapper';
import CopyTool from '../../Utils/CopyTool';
import { TradeDataInterface, TradeFetchParams } from '../financeDataType';

// Specific to this page
const QUERY_INDEX = 'FinanceTrade';
const URL = `${config.apiurl}/otc/trade/list?house=true`; //Currently same as the admin trade API
const initialFetch = {
  pageNum: 1,
  limit: 10,
  startDate: '',
  endDate: '',
};
// End of specific to this page
const Index = () => {
  const [fetchParams, setFetchParams] =
    useState<TradeFetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table />
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const { listIsLoading, listData, fetchParams, setFetchParams, setPageNum } =
    useContext(Context);
  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (listData?.data) {
      setInitialized(true);
    }
  }, [listData?.data]);
  useEffect(() => {
    if (listData) {
      setCurrentPage(listData.data);
      setInitialized(true);
    }
  }, [listData]);
  return (
    <>
      <div className="border_box">
        <FilterRow
          searchParams={fetchParams}
          startDate={{
            value: fetchParams.startDate,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                startDate: val,
              });
            },
          }}
          endDate={{
            value: fetchParams.endDate,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                endDate: val,
              });
            },
          }}
          limit={{
            value: fetchParams.limit,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                limit: val,
                pageNum: 1,
              });
            },
          }}
          clearFilter={{
            set: () => {
              setFetchParams(initialFetch);
            },
          }}
        />
      </div>
      <div className="table-responsive">
        <Loader visible={listIsLoading} />
        <table className="main-table fin_trade_container">
          <thead>
            <tr>
              <th className="user_item_row">Time</th>
              <th className="user_item_row">Account</th>
              <th className="user_item_row">Market</th>
              <th className="user_item_row">Side</th>
              <th className="user_item_row">Base Quantity</th>
              <th className="user_item_row">Quote Quantity</th>
              <th className="user_item_row">Price</th>
              <th className="user_item_row">Spread Fees</th>
              <th className="user_item_row">Trade Amount on Exchange</th>
              <th className="user_item_row">Price on Exchange</th>
              <th className="user_item_row">Trade ID</th>
            </tr>
          </thead>
          <tbody>
            {!listIsLoading && currentPage?.length === 0 && (
              <p>No trade record found.</p>
            )}
            {currentPage?.map((data: TradeDataInterface, index: number) => {
              const {
                fee,
                quantity,
                price,
                timestamp,
                makerName,
                productName,
                side,
                id,
                quotePrice,
                requestSize,
                requestAsset,
                profit,
                profitAsset,
                quoteQuantity,
              } = data;
              let Fee: number = 0;
              if (fee) {
                Fee = fee;
              }
              var t = new Date(timestamp);
              const myMoment = moment(t).format('YYYY-MM-DD hh:mm:ss');
              return (
                <tr className="trade_row" key={index}>
                  <td className="trade_row_item">
                    <span className="trade_time">{myMoment}</span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_makername">{makerName}</span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_productName">{productName}</span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_side">{side}</span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_baseQuantity">
                      {formatNumber(quantity)}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_quoteQuantity">
                      {formatNumber(quoteQuantity)}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_baseQuantity">
                      {formatNumber(price)}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_spreadFees">
                      {`${formatNumber(profit)} ${profitAsset}`}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_tradeAmountFTX">
                      {`${formatNumber(requestSize)} ${requestAsset}`}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <span className="trade_priceFTX">
                      {formatNumber(quotePrice)}
                    </span>
                  </td>
                  <td className="trade_row_item">
                    <CopyTool text={id} digit={6} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="custom_table">
        <TableContainer isLoading={initialized && listIsLoading}>
          {count !== 0 && (
            <div className={'col-md-11'}>
              <Pagination
                onChange={(page: number) => setPageNum(page)}
                count={fetchParams.limit}
                totalCount={totalCount}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </>
  );
};
