import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Modal,
  Table,
} from 'react-bootstrap';
import * as yup from 'yup';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { config } from '../../../config/config';
import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorMessage } from '../../Utils';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';

const { configheadersTemplate } = tokenService;

function SellOrderModal({
  data,
  status,
}: {
  data: { asset: any; total: any; free: any; locked: any };
  status: boolean;
}) {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [amount, setAmount] = useState<any>();
  const [error, setError] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  let schema = yup.object().shape({
    amount: yup.number().required().positive(),
  });

  const fetch = async () => {
    let configheaders = configheadersTemplate();
    let url = `${config.apiurl}/transfer/bank?asset=` + data.asset.name;
    const { data: test } = await axios.get(url, configheaders);
    return test;
  };

  const {
    isLoading: depositAddressIsLoading,
    error: depositAddressError,
    data: bankdata,
  } = useQuery('DepositAddress', () => {
    return fetch();
  });

  const mutation: any = useMutation(
    () => {
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/transfer/deposit`;
      let formData = new FormData();
      formData.append('amount', amount);
      formData.append('asset', data.asset.name);
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setAmount(null);
        setToastObj({
          message: `Bank deposit requested`,
          type: 'success',
        });
        queryClient.invalidateQueries(['CurrencyBalance', {}]);
        handleClose();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Failed to deposit`,
          type: 'error',
        });
        handleClose();
      },
    }
  );
  return (
    <>
      <Button
        className={`btn-dark-border ${status || 'disabled-status'}`}
        onClick={handleShow}
      >
        <strong>Deposit</strong>
      </Button>

      <Modal size={'lg'} centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              Deposit {data.asset.name}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert style={{ marginBottom: 30 }} variant={'primary'}>
            Please allow one business day for your deposit request to be
            confirmed and processed.
          </Alert>
          <h3 style={{ marginBottom: 15 }}>Wire Instruction</h3>
          <ul>
            {bankdata ? (
              <>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <td>Bank Name:</td>
                      <td>{bankdata.bankName}</td>
                    </tr>
                    <tr>
                      <td>Bank Address:</td>
                      <td>{bankdata.bankAddress}</td>
                    </tr>
                    <tr>
                      <td>Bank Swift Code:</td>
                      <td>{bankdata.bankSwiftCode}</td>
                    </tr>
                    <tr>
                      <td>Account Name:</td>
                      <td>{bankdata.bankAccountName}</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td>{bankdata.bankAccountNo}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <></>
            )}
          </ul>
          <p className="notee">
            <div style={{ fontWeight: 'bold' }}>
              In your wire memo, please include your identifier:{'   '}
              <u style={{ color: 'green' }}>{userInfo.fiatDepositCode}</u>
            </div>
          </p>

          <br />

          <form>
            <div className="row">
              <aside className="col-lg-3 paddingtop20">
                <FormLabel>Amount:</FormLabel>
              </aside>
              <aside className="col-lg-9">
                <FormControl
                  value={amount}
                  onChange={(event: any) => {
                    setAmount(event.currentTarget.value);
                  }}
                  type="number"
                  placeholder="Enter amount"
                />
                <ErrorMessage path={'amount'} error={error}></ErrorMessage>
              </aside>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <div>
              {mutation.isError ? (
                <div>An error occurred: {mutation.error.message}</div>
              ) : null}
            </div>
            <Button
              className={'main-color-btn full-btn'}
              onClick={() => {
                schema
                  .validate({ amount })
                  .then(function (valid: any) {
                    if (valid) {
                      setError(null);
                      mutation.mutate({
                        amount,
                      });
                    } else {
                      alert('fail');
                    }
                  })
                  .catch(function (err: any) {
                    setError(err);
                  });
              }}
            >
              {mutation.isLoading ? <>Submitting order...</> : <> Submit</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SellOrderModal;
