import React from "react";
import Activity from "./Activity";
import All from "./All";
import HistoryContextWrapper, { HistoryContext } from "./HistoryContextWrapper";
import NavTabs from "./NavTabs";
import Trade from "./Trade";
import useHistory from "./useHistory";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect
} from "react-router-dom";

const Index = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <HistoryContextWrapper>
        <section className="inner-main-section">
          <div className="container">
            <h3 className="main-heading-title">History</h3>
            <div className="white-shadowbox history-whitebox">
              <NavTabs></NavTabs>
              <div className="padding20">
                <Switch>
                  {/* <Route exact path={path}>
                    <All/>
                  </Route> */}
                  <Route exact path={`${path}`}>
                    <Activity/>
                  </Route>
                  <Route exact path={`${path}/trade`}>
                    <Trade/>
                  </Route>
                  <Redirect from="*" to="/history" />
                </Switch>
              </div>
            </div>
          </div>
        </section>
      </HistoryContextWrapper>
    </>
  );
};
export default Index;
