import { useContext, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { capitalize, ErrorMessage } from '../../../Utils';
import { Context } from '../../../Utils/ContextWrapper';
import { AuthContext } from '../../../../contexts/AuthContext';
import { tokenService } from '../../../../_services/tokens.services';
import { config } from '../../../../config/config';
import { AiFillEdit } from 'react-icons/ai';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as yup from 'yup';

type EditAssetProps = {
  index: number;
  type: string;
  currentAsset: string;
  feeData: {
    upper: number;
    lower: number;
    value: number;
  };
  fetchTransferFeeHelper: (type: string) => void;
};
const EditWithdrawlFeeModal = ({
  type,
  feeData,
  currentAsset,
  index,
  fetchTransferFeeHelper,
}: EditAssetProps) => {
  const { configheadersTemplate } = tokenService;
  let configheaders = configheadersTemplate();
  const { upper, lower, value } = feeData;
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState<any>();
  const handleClose = () => {
    setShow(false);
    setError('');
    setTransactionFee({
      lower: '',
      upper: '',
      value: '',
    });
  };
  const handleShow = () => setShow(true);
  const [transactionFee, setTransactionFee] = useState({
    lower: '',
    upper: '',
    value: '',
  });
  let schema = yup.object().shape({
    lower: yup.number().required().positive(),
    upper: yup.number().required().positive().min(Number(transactionFee.lower)),
    value: yup.number().required().positive(),
  });

  const [transactionFeeAll, setTransactionFeeAll] = useState<any>([]);
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);
  async function fetchTransactionFeeByAsset(
    type: string,
    currentAsset: string
  ) {
    axios
      .get(
        `${config.apiurl}/transfer/fee/${type}/default/asset/${currentAsset}`,
        configheaders
      )
      .then(res => {
        let removeAassetArray = res.data;
        removeAassetArray?.map((element: any) => {
          delete element.asset;
        });
        setTransactionFeeAll(removeAassetArray);
      })
      .catch(error => {
        console.log(error);
      });
  }
  function handleAEditFeeBtn(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    setTransactionFee({
      lower: lower.toString(),
      upper: upper.toString(),
      value: value.toString(),
    });

    fetchTransactionFeeByAsset(type, currentAsset);
    handleShow();
    setEdit(false);
  }
  function handleSaveChange(event: any) {
    setEdit(true);
    if (event.type === 'change') {
      const { name, value } = event.target;
      setTransactionFee({
        ...transactionFee,
        [name]: value,
      });
    }
  }
  function handleSubmitChange() {
    const { lower, upper, value } = transactionFee;
    schema
      .validate({ lower, upper, value })
      .then(function (valid: any) {
        if (valid) {
          setError(null);
          handleSubmit();
        } else {
          alert('fail');
        }
      })
      .catch(function (err: any) {
        setError(err);
      });
  }
  function handleSubmit() {
    transactionFeeAll[index] = transactionFee;
    editFeeMutation.mutate(transactionFeeAll);
  }
  const editFeeMutation: any = useMutation(
    (oid: string) => {
      let url = `${config.apiurl}/transfer/fee/${type}/default/asset/${currentAsset}`;
      let formData = new FormData();
      transactionFeeAll?.forEach((element: any) => {
        Object.entries(element).forEach(([key, value]: any) => {
          let stringizeVal = value.toString();
          formData.append(key, stringizeVal);
        });
      });
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries(['AdminSetting', fetchParams]);
        await fetchTransferFeeHelper(type);
        setToastObj({
          message: `${capitalize(type)} Fee Updated`,
          type: 'success'
        });
        handleClose();
      },
      onError: (data, variables, context) => {
        queryClient.invalidateQueries(['AdminSetting', fetchParams]);
        setToastObj({
          message: `Failed to update ${type} Fee`,
          type: 'error'
        });
        handleClose();
      },
    }
  );
  return (
    <>
      <OverlayTrigger
        key={`edit-${type}-${currentAsset}-fee`}
        placement="right"
        overlay={
          <Tooltip id={`edit-${type}-${currentAsset}-fee`}>
            Edit {currentAsset} {type} fee.
          </Tooltip>
        }
      >
        <button className='row-ele-center btn-icon setting_btn_icon' onClick={handleAEditFeeBtn}>
          <AiFillEdit/>
        </button>
      </OverlayTrigger>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <strong>
              Edit {currentAsset} {type} Fee
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Table borderless>
              <thead>
                <tr>
                  <th>Minimum Amount</th>
                  <th>Maximum Amount</th>
                  <th>Fee</th>
                </tr>
              </thead>
              <tbody>
                {
                  <tr>
                    <td>
                      <Form.Group className="mb-3" controlId="formAddFee">
                        <Form.Control
                          required
                          type="number"
                          name="lower"
                          placeholder={lower.toString()}
                          onChange={handleSaveChange}
                        />
                      </Form.Group>
                      <ErrorMessage path={'lower'} error={error}></ErrorMessage>
                    </td>
                    <td>
                      <Form.Group className="mb-3" controlId="formAddFee">
                        <Form.Control
                          required
                          type="number"
                          name="upper"
                          placeholder={upper.toString()}
                          onChange={handleSaveChange}
                        />
                      </Form.Group>
                      <ErrorMessage path={'upper'} error={error}></ErrorMessage>
                    </td>
                    <td>
                      <Form.Group className="mb-3" controlId="formAddFee">
                        <Form.Control
                          required
                          type="number"
                          name="value"
                          placeholder={value.toString()}
                          onChange={handleSaveChange}
                        />
                      </Form.Group>
                      <ErrorMessage path={'value'} error={error}></ErrorMessage>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </Form>
          <hr />
          <div className="btn-container">
            <Button
              type="button"
              className="btn-full-dark"
              onClick={handleClose}
            >
              Cancel
            </Button>
            {!edit && (
              <Button
                type="button"
                className="btn-full-light"
                onClick={handleSaveChange}
              >
                Confirm
              </Button>
            )}
            {edit && (
              <Button
                type="button"
                className="btn-full-light"
                onClick={handleSubmitChange}
              >
                {editFeeMutation.isLoading ? <>Submitting...</> : <> Submit</>}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EditWithdrawlFeeModal;
