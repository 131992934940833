import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { FilterRow, TableContainer } from "../../Utils/FilterRow";
import { ProgressMovingBar } from "../../Utils/ProgressMovingBar";
import { formatNumber } from "../../Utils";
import Pagination from "../../Utils/Pagination";
import Loader from "../../Loader";
import { config } from "../../../config/config";
import ContextWrapper, { Context } from "../../Utils/ContextWrapper";
import CopyTool from "../../Utils/CopyTool";

// Specific to this page
const QUERY_INDEX = "HistoryTrade";
const URL = `${config.apiurl}/otc/trade/list`;
// const { export_data } = useExportData();
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;
  io?: string;
  account?: string;
  type?: string;
  status?: string;
  limit: number;
  house: boolean;
  sortOrder: string;
  sortBy: string;
  side: string;
};
const initialFetch = {
  pageNum: 1,
  sortBy: "timestamp",
  sortOrder: "desc",
  limit: 10,
  type: "trade",
  side: "",
  house: false,
};

// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);
  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (listData?.data) {
      setCurrentPage(listData?.data);
      setInitialized(true);
    }
  }, [listData?.data]);

  return (
    <>
      {/* <pre>{JSON.stringify(listData, undefined, 2)}</pre> */}
      <div className="">
        <div>
          <div>
            <div>
              <div className="">
                <div className="border_box" style={{}}>
                  <FilterRow
                    export_data={export_data}
                    searchParams={fetchParams}
                    startDate={{
                      value: fetchParams.startDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          startDate: val,
                        });
                      },
                    }}
                    endDate={{
                      value: fetchParams.endDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          endDate: val,
                        });
                      },
                    }}
                    limit={{
                      value: fetchParams.limit,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          limit: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    side={{
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          side: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    base={{
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          baseCurrency: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    clearFilter={{
                      set: (val: any) => {
                      setFetchParams(
                          initialFetch
                      )}
                    }}
                    // exportCsv={{}}
                    // exportPdf={{}}
                    // source={{
                    //   set: (val: any) => {
                    //     setFetchBalanceMetalParams({
                    //       ...fetchBalanceMetalParams,
                    //       source: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    location={{
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          country: val,
                          pageNum: 1,
                        });
                      },
                    }}
                  ></FilterRow>
                  <div className="table-responsive">
                    <Loader visible={listIsLoading}></Loader>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="main-table"
                    >
                      <colgroup>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col style={{ width: "10px" }}></col>
                        <col></col>
                        <col></col>
                        <col></col>
                      </colgroup>

                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Pair</th>
                          <th>Side</th>
                          <th>Price</th>
                          <th>Executed</th>
                          <th>Total</th>
                          <th>Trade ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!initialized && listIsLoading ? (
                          <tr>
                            <td colSpan={8}>
                              {/* <ProgressMovingBar></ProgressMovingBar> */}
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPage?.length === 0 && (
                              <tr>
                                <td style={{ color: "#ffcb17" }} colSpan={8}>
                                  No rows found. Try clearing your filters.
                                </td>
                              </tr>
                            )}
                            {currentPage?.map((data: any, index: any) => {
                              const {
                                id,
                                timestamp,
                                productId,
                                productName,
                                quoteQuantity,
                                price,
                                quantity,
                                side,
                              } = data;
                              return (
                                <tr key={index}>
                                  <td>
                                    {moment(timestamp).format(
                                      "YYYY/MM/DD hh:mm:ss"
                                    )}
                                  </td>
                                  <td>{productName}</td>
                                  <td>{side}</td>
                                  <td>{formatNumber(price)}</td>
                                  <td>{formatNumber(quantity)}</td>
                                  <td>{formatNumber(quoteQuantity)} USD</td>
                                  <td><CopyTool text={id} digit={6}/></td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="custom_table">
                    <TableContainer isLoading={initialized && listIsLoading}>
                      <table className="table"></table>
                      {count !== 0 && (
                        <div className={"col-md-11"}>
                          <Pagination
                            onChange={(page: any) => {
                              setPageNum(page);
                            }}
                            count={fetchParams.limit}
                            totalCount={totalCount}
                          ></Pagination>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
