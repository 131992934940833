import React from "react";
import AccountInfo from "./AccountInfo/index";

const NavTabs = () => {
  return (
    <>
      <div
        className="tab-pane active"
        id="profile-account-info"
        role="tabpanel"
        aria-labelledby="profile-account-info-tab"
      >
        <AccountInfo />
      </div>
    </>
  );
};
export default NavTabs;