import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';

const NavTabs = () => {
  let { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
        <NavLink
          className="nav-link"
          id="finance-user-tab"
          isActive={() => ['/finance'].includes(pathname)}
          to={`${url}`}
        >
          User
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          id="finance-transfer-tab"
          isActive={() => ['/finance/transfer'].includes(pathname)}
          to={`${url}/transfer`}
        >
          Transfer
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          className="nav-link"
          id="finance-trade-tab"
          isActive={() => ['/finance/trade'].includes(pathname)}
          to={`${url}/trade`}
        >
          Trade
        </NavLink>
      </li>
    </ul>
  );
};
export default NavTabs;
