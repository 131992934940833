import Table from './Table';

const Index = () => (
  <div
    className="tab-pane"
    id="finance-trade"
    role="tabpanel"
    aria-labelledby="finance-trade-tab"
  >
    <Table />
  </div>
);
export default Index;
