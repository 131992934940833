// let  apibaseUrl =  "http://api.catchmarkets.io:8762";

let apiUrl =
  process.env.REACT_APP_API_URL || "https://stage-api.catchotc.com";
let apibaseUrl = `${apiUrl}/api`;
const baseURL =
  process.env.REACT_APP_BASE_URL || "http://otc-stage.catchmarkets.com/";

  const PAYMENTURL =
  process.env.REACT_APP_PAYMENTURL || "https://pay-stage.catchotc.com";

export const config = {
  //  apiUserUrl : "http://192.168.2.86:8762/LinkodesUserManagement/v1/api",
  // apiUserUrl  : apibaseUrl+"/LinkodesUserManagement/v1/api",
  // apiCardUrl  : apibaseUrl+"/LinkodesCardManagement/v1/api",
  //prod
  // apiurl :"https://otc-api.catchmarkets.com/api",
  // apiurl_login :"https://otc-api.catchmarkets.com",
  // dev
  apiurl: apibaseUrl,
  apiurl_login: apiUrl,
  //payment_url :"https://pay-stage.catchotc.com", //url for staging
  payment_url :PAYMENTURL, //url for production

  appurl: baseURL,
  //  appurl :baseURLProd,

  //  appurl : "http://127.0.0.1:3000/",
  socket_url: "wss://coinfulmatrix.com:18443/api/ws",
};


// let apiUrl = "https://coinfulmatrix.com:18443";

// let  apibaseUrl =  "http://api.catchmarkets.io:8762";

