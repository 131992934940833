import NavTabs from './NavTabs';
import Trade from './FinanceTrade';
import Transfer from './FinanceTransfer';
import User from './FinanceUser';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
const Index = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <section className="inner-main-section">
        <div className="container">
          <h3 className="main-heading-title">Finance</h3>
          <div className="white-shadowbox finance-whitebox">
            <NavTabs />
            <div className="padding20">
              <Switch>
                <Route exact path={`${path}`}>
                  <User />
                </Route>
                <Route exact path={`${path}/trade`}>
                  <Trade />
                </Route>
                <Route exact path={`${path}/transfer`}>
                  <Transfer />
                </Route>
              </Switch>
              <Redirect from="*" to="/finance" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Index;
