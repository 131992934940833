import jsPDF from 'jspdf';
import moment from 'moment';
import { Line } from 'rc-progress';
import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useAuth } from '../../auth-context';
import { AuthContext } from '../../contexts/AuthContext';
import { GlobalContext } from '../../contexts/GlobalContext';
import { userService } from '../../_services';
import { createTradeFailedMsg } from './tradeMsgHandler';

interface Product {
  id: string;
  name: string;
  description: string;
  baseAssetId: string;
  baseAssetName: string;
  quoteAssetId: string;
  quoteAssetName: string;
  tickSize: Float32Array;
}

export interface Quote {
  quoteId: string;
  orderId: string;
  baseCurrency: string;
  quoteCurrency: string;
  baseCurrencySize: any;
  quoteCurrencySize: any;
  requestTime: any;
  quoteTime: any;
  expiryTime: any;
  price: string;
  referencePrice: string;
  feeRate: string;
  side: string;
  fee: string;
  feeCurrency: string;
  quoteType: string;
  percentage: any;
  mysecond: any;
  remove: boolean;
}

function useBalance(initialValue = false) {
  const { expireQuate, SetexpireQuate } = useContext(GlobalContext);
  const [counter, setcounter] = useState(5) as any;
  const [QuateErr, setQuateErr] = useState('') as any;
  const [Qindex, setQindex] = useState() as any;

  const [productRelationshipMap, setProductRelationshipMap] = useState({
    baseRelationshipMap: {},
    quoteRelationshipMap: {},
  });
  const [quateDate, setquatedate] = useState('') as any;
  const [quateTime, setquatetime] = useState('') as any;
  const [warningModal, setWarningModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpencong, setIsOpencong] = useState(false);
  const [modalIsOpenCancel, setModalIsOpenCancel] = useState(false);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [cancelQuate, setCancelQuate] = useState({});
  const [currencyBalance, setCurrencyBalance] = useState([]);
  const [ProductList, setProductList] = useState<Product[]>();
  const [baseCurrency, setbaseCurrency] = useState<any>(1);
  const [quoteCurrency, setquoteCurrency] = useState<any>(1);
  const [baseCurrencySize, setbaseCurrencySize] = useState(null) as any;
  const [quoteCurrencySize, setquoteCurrencySize] = useState(null) as any;
  const [side, setSide] = useState('BUY') as any;
  const [QuateData, setQuateData] = useState<any>([]);
  //const [timestamp,SetTimestamp] = useState() as any;
  const [seconds, SetSeconds] = useState() as any;
  const [percentage, SetPercentage] = useState(0) as any;
  const [feeProduct, SetfeeProduct] = useState() as any;
  const { userInfo } = useAuth();
  const [TradeQuate, SetTradeQuate_] = useState() as any;
  const [intervals, Setintervals] = useState([]) as any;
  const [currencyErr, SetcurrencyErr] = useState('') as any;
  const [askStyle, setaskStyle] = useState({ display: 'none' }) as any;
  const [loader, setLoader] = useState(false) as any;
  // eslint-disable-next-line
  const queryClient = useQueryClient();
  const SetTradeQuate = (data: any) => {
    SetTradeQuate_(data);
    queryClient.invalidateQueries('RecentTrades');
  };
  useEffect(() => {
    // gettimer(40)
    SetfeeProduct(userInfo?.fee);
    userService
      .get_product()
      .then(function (response: any) {
        setProductList(response.data);
      })
      .catch(error => {
        console.log(error);
      });

    userService
      .trade_quate()
      .then(function (response: any) {
        SetTradeQuate(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (ProductList) {
      //GET ASSETS
      const productRelationshipMap = ProductList.reduce(
        (accumulator: any, product: any) => {
          const { baseRelationshipMap: brm, quoteRelationshipMap: qrm } =
            accumulator;
          accumulator.baseRelationshipMap = {
            ...brm,
            [product.baseAssetId]: {
              ...brm[product.baseAssetId],
              [product.quoteAssetId]: product.tickSize,
            },
          };
          accumulator.quoteRelationshipMap = {
            ...qrm,
            [product.quoteAssetId]: {
              ...qrm[product.quoteAssetId],
              [product.baseAssetId]: product.tickSize,
            },
          };
          return accumulator;
        },
        { baseRelationshipMap: {}, quoteRelationshipMap: {} }
      );
      setProductRelationshipMap(productRelationshipMap);
    }
  }, [ProductList]);

  let ProdOption: any = [];
  if (ProductList && feeProduct) {
    ProdOption = Object.keys(ProductList).map(function (key: any) {
      let addd: any = false;
      Object.keys(feeProduct).map(function (key1: any) {
        if (ProductList[key].name === key1) {
          addd = true;
        }
      }, []);
      if (addd == true) {
        return (
          <option value={ProductList[key].baseAssetId} key={key}>
            {ProductList[key].baseAssetName}
          </option>
        );
      }
    }, []);
  }
  let ProdOption1: any = [];
  if (ProductList && feeProduct) {
    ProdOption1 = Object.keys(ProductList).map(function (key: any) {
      let addd: any = false;
      Object.keys(feeProduct).map(function (key1: any) {
        if (ProductList[key].name === key1) {
          addd = true;
        }
      }, []);
      if (addd == true) {
        return (
          <option value={ProductList[key].quoteAssetId} key={key}>
            {ProductList[key].quoteAssetName}
          </option>
        );
      }
    }, []);
  }

  const take_quate_ = (id: any) => {
    setaskStyle({ display: 'block' });
    setLoader(true);
    userService
      .take_quate(id)
      .then(function (response: any) {
        window.clearInterval();
        SetexpireQuate([]);
        setQuateData([]);
        trade_quate();
        intervals.forEach(clearInterval);
        setTimeout(() => {
          setLoader(false);
          setaskStyle({ display: 'none' });
          openModalcong();
        }, 1000);
      })
      .catch(error => {
        setaskStyle({ display: 'none' });
        setLoader(false);
      });
  };
  // eslint-disable-next-line
  function openModalcong() {
    setIsOpencong(true);
  }
  function closeModalcong() {
    setIsOpencong(false);
  }
  useEffect(() => {
    Setintervals([]);
    SetfeeProduct(userInfo.fee);
    userService
      .get_product()
      .then(function (response: any) {
        setProductList(response.data);
      })
      .catch(error => {
        console.log(error);
      });

    userService
      .trade_quatelast()
      .then(function (response: any) {
        SetTradeQuate(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    userService
      .get_balance()
      .then(function (response: any) {
        var arr: any = {};
        response.data.forEach(function (value: any, k: any) {
          arr[value.asset.name] = {
            Total: value.total,
            Available: value.free,
            Locked: value.locked,
          };
        });
        setCurrencyBalance(arr);
      })
      .catch((error: any) => {
        console.log(error);
      });

    // eslint-disable-next-line
  }, []);

  const customStyles = {
    content: {
      left: '50%',
      border: 'none',
    },
  };

  const getQuate = () => {
    if (baseCurrency && quoteCurrency) {
      if (QuateData?.length === 0) {
        setaskStyle({ display: 'block' });
        setLoader(true);
      }
      var quoteType = baseCurrencySize ? 'base' : 'quote';

      // make sure baseCurrency is a string... not sure why sometimes it's used as a number.
      let params: any = {
        baseCurrency:
          typeof baseCurrency === 'string'
            ? baseCurrency.toUpperCase()
            : baseCurrency,
        quoteCurrency:
          typeof quoteCurrency === 'string'
            ? quoteCurrency.toUpperCase()
            : quoteCurrency,
        side: side,
        baseCurrencySize: baseCurrencySize
          ? baseCurrencySize.replaceAll(',', '')
          : 0,
        quoteCurrencySize: quoteCurrencySize
          ? quoteCurrencySize.replaceAll(',', '')
          : 0,
      };

      userService
        .get_quate(params)
        .then(function (response: any) {
          setQuateErr('');
          response.data.quoteType = quoteType;
          if (response.data.quoteId) {
            var counter = 0;
            var counter1 = 5;
            var interval = setInterval(function () {
              counter++;
              counter1--;
              setcounter(counter1);
              // Display 'counter' wherever you want to display it.
              if (counter == 5) {
                setcounter(5);
                clearInterval(interval);
              }
            }, 1000);
            QuateData.push(response.data);
            gettimer(
              response.data.expiryTime,
              response.data.requestTime,
              response.data
            );
          }
          setTimeout(() => {
            setLoader(false);
            setaskStyle({ display: 'none' });
          }, 500);
        })
        .catch(error => {
          if (error.response.data.message === 'Size exceeds maxPct') {
            const tradeFailedMsg = createTradeFailedMsg(params);
            setErrorMessage(tradeFailedMsg);
          } else if (
            error.response.data.message ===
            'Missing baseCurrencySize or quoteCurrencySize'
          ) {
            setErrorMessage(
              'Missing base currency or quote currency. Please input a valid value.'
            );
          } else {
            setErrorMessage(error?.response?.data?.message);
          }
          if (error?.response?.status === 401) {
            window.location.href = '/login';
          }
          setWarningModal(true);
          setLoader(false);
          setaskStyle({ display: 'none' });
        });
    } else {
      SetcurrencyErr('Please select currency');
    }
  };
  let a: any = [];
  if (ProductList) {
    // eslint-disable-next-line
    Object.keys(ProductList).map(function (key: any) {
      let addd: any = false;
      // eslint-disable-next-line
      // Object.keys(feeProduct).map(function (key1: any) {
      // console.log(key1,"==feeProduct111===",ProductList[key].name)
      // if (ProductList[key].name === key1) {
      addd = true;
      a.push(ProductList[key]);
      // }
      // console.log(key1,ProductList[key].name)
      // }, []);
    }, []);

    let newwaarr = removeDuplicates(a, 'baseAssetId');

    ProdOption = Object.keys(newwaarr).map(function (key2: any) {
      return (
        <option value={newwaarr[key2].baseAssetId} key={key2}>
          {newwaarr[key2].baseAssetName}
        </option>
      );
    }, []);
  }
  let a1: any = [];
  if (ProductList) {
    // eslint-disable-next-line
    Object.keys(ProductList).map(function (key: any) {
      let addd: any = false;
      // eslint-disable-next-line
      // Object.keys(feeProduct).map(function (key1: any) {
      // if (ProductList[key].name === key1) {
      addd = true;
      a1.push(ProductList[key]);
      // }
      // console.log(key1,ProductList[key].name)
      // }, []);
    }, []);
    let newwaarr = removeDuplicates(a1, 'quoteAssetId');

    ProdOption1 = Object.keys(newwaarr).map(function (key2: any) {
      return (
        <option value={newwaarr[key2].quoteAssetId} key={key2}>
          {newwaarr[key2].quoteAssetName}
        </option>
      );
    }, []);
  }

  function removeDuplicates(originalArray: any, prop: any) {
    var newArray: any = [];
    var lookupObject: any = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const take_quate = (id: any, index: any) => {
    setWarningModal(false);
    setErrorMessage('');
    // setaskStyle({display: 'block'});
    // setLoader(true);
    // if(userInfo.completeKYC) {
    userService
      .take_quate(id)
      .then(function (response: any) {
        QuateData[index].remove = true;
        quateTemp = [];
        setQuateData([]);
        // window.clearInterval();
        // SetexpireQuate([]);
        // setQuateData();
        trade_quate();
        // intervals.forEach(clearInterval);
        setTimeout(() => {
          setLoader(false);
          setaskStyle({ display: 'none' });
          openModalcong();
        }, 500);
      })
      .catch(error => {
        setaskStyle({ display: 'none' });
        setLoader(false);
        setWarningModal(true);
        if (error.response.data.message) {
          let targetCurrency = error.response.data.message.split(' ')[2];
          setErrorMessage(
            `You do not have enough ${targetCurrency}. Please transfer assets from other wallets or deposit funds to place an order.`
          );
        } else {
          setErrorMessage(
            'Trade Failed. Please contact admin support@catchotc.com'
          );
        }
        // if (
        //   error.response.data.code
        //   &&
        //     localize["en"].errors.quote[`${error.response.data.code}`]
        // ) {
        //     setErrorMessage(
        //       // localize["en"].errors.quote[`${error.response.data.code}`]
        //     );
        // } else {
        //     setErrorMessage(localize["en"].errors.quote.other);
        // }
        console.log(error);
      });
    // }
  };

  const cancel_quate = (id: any, index: any) => {
    setWarningModal(false);
    setErrorMessage('');
    userService
      .cancel_quate(id)
      .then((response: any) => {
        QuateData[index].remove = true;
        quateTemp = [];
        setQuateData([]);
        trade_quate();
        setLoader(false);
        setaskStyle({ display: 'none' });
        setCancelQuate(response?.data);
        setCancelStatus(response?.data.cancelled);
        setModalIsOpenCancel(true);
      })
      .catch(error => {
        setaskStyle({ display: 'none' });
        setLoader(false);
        console.log(error);
      });
  };

  const trade_quate_last = () => {
    userService
      .trade_quatelast()
      .then(function (response: any) {
        SetTradeQuate(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
  // const setQuoteBalanceError = (baseSize:any,baseCurr:any,quoteSize:any,qouteCurr:any,qouteSide:string,quoteType:string) => {
  //   var quoteAvailabel = (currencyBalance[qouteCurr]) ? (currencyBalance[qouteCurr] as any).Available : 0;
  //   var baseAvailabel = (currencyBalance[baseCurr]) ? (currencyBalance[baseCurr] as any).Available : 0;
  //   if(quoteType == 'base' && qouteCurr && baseCurr && currencyBalance[baseCurr] && baseSize && baseAvailabel < baseSize) {
  //     return 'You do not have enough ' + baseCurr + ' to ' + qouteSide.toLowerCase() + ' ' + qouteCurr + '. This is an indicative quote only.';
  //   } else if(quoteType == 'quote' && qouteCurr && baseCurr && currencyBalance[qouteCurr] && quoteSize && quoteAvailabel < quoteSize) {
  //     return 'You do not have enough ' + qouteCurr + ' to ' + qouteSide.toLowerCase() + ' ' + baseCurr + '. This is an indicative quote only.';
  //   }
  //   return '';
  // }

  const gettimer = (time: any, nowtime: any, mydata: any) => {
    //  alert(percentages)

    const myMoment = moment(time).format('YYYY-MM-DD hh:mm:ss');
    const myMomentdatae = moment(time).format('YYYY/MM/DD');
    const myMomenttime = moment(time).format('hh:mm:ss');
    setquatedate(myMomentdatae);
    setquatetime(myMomenttime);
    const myMoment1 = moment(nowtime).format('YYYY-MM-DD hh:mm:ss');
    const difference = +new Date(myMoment) - +new Date(myMoment1);
    let second: any = Math.floor((difference / 1000) % 60);
    let percentages = 100 / second;
    let index: any = QuateData.length - 1;
    QuateData[index].percentage = percentages;
    //  QuateData.splice(index, 0, percentages);
    SetPercentage(percentages);
    let i = 1;
    let mysecond: any = parseInt(second);
    let takequate: any = false;

    var refreshId: any = setInterval(function () {
      if (QuateData[index].remove === true) {
        mysecond = 1;
        takequate = true;
      }
      // execute code each second
      //  SetTimestamp(second--)// decrement timestamp with one second each second

      mysecond = mysecond - 1;
      let j: any = i++;
      SetPercentage(percentages * j);
      QuateData[index].percentage = percentages * j;
      QuateData[index].mysecond = parseInt(mysecond);
      QuateData[index].remove = false;
      //

      SetSeconds(parseInt(mysecond));

      if (mysecond <= 1) {
        if (takequate === false) {
          expireQuate.unshift(mydata);
        }
        QuateData[index].remove = true;
        quateTemp = [];
        setQuateData([]);
        //  alert(QuateData[index].remove)
        clearInterval(refreshId);
        setQindex(index);
        //   console.log(expireQuate,"=========================>expireQuate1111")
        // setQuateData();
        // setbaseCurrency();
        // setquoteCurrency();
      }
    }, 1000);
    intervals.push(refreshId);
  };
  var ExpTemp: any = [];
  if (expireQuate) {
    //console.log(expireQuate,"=========================>expireQuate")
    ExpTemp = Object.keys(expireQuate).map(
      function (key: any) {
        var t = new Date(expireQuate[key].requestTime);
        const myMoment = moment(t).format('YYYY/MM/DD hh:mm:ss');
        return (
          <>
            <div className="col-sm-12" key={key}>
              <div className="card_box">
                <div className="top_content">
                  <img
                    src={require('../../images/dollar_icon.png').default}
                    alt="dollar_icon"
                  />
                  <p>
                    {expireQuate[key].side}{' '}
                    {expireQuate[key].quoteType == 'base'
                      ? expireQuate[key].baseCurrencySize + ' '
                      : ''}
                    {expireQuate[key].baseCurrency} with{' '}
                    {expireQuate[key].quoteType == 'quote'
                      ? expireQuate[key].quoteCurrencySize
                      : ''}{' '}
                    {expireQuate[key].quoteCurrency}
                  </p>

                  <p>
                    <span className="date">{myMoment}</span>
                  </p>
                </div>
                <div className="bottom_content">
                  <div className="common_box convert_currency">
                    <p className="amount"> {expireQuate[key].quoteCurrency}</p>
                    <p className="currency">
                      {expireQuate[key].quoteCurrencySize}
                    </p>
                  </div>

                  <div className="common_box convertor">
                    {/* <p className='fee'>{parseFloat(expireQuate[key].fee).toFixed(2)}  Fee({expireQuate[key].feeCurrency})</p> */}
                    {expireQuate[key].side === 'SELL' ? (
                      <img
                        src={require('../../images/left_arrow.png').default}
                        alt="arrow"
                      />
                    ) : (
                      <img
                        src={require('../../images/arrow.png').default}
                        alt="arrow"
                      />
                    )}

                    <p>Price {parseFloat(expireQuate[key].price).toFixed(2)}</p>
                  </div>
                  <div className="common_box convert_currency">
                    <p className="amount">
                      {expireQuate[key].baseCurrencySize}
                    </p>
                    <p className="currency">{expireQuate[key].baseCurrency}</p>
                  </div>
                </div>
                {/* <h5 className="error" style={{fontSize:"11px", textAlign:"center"}}>{setQuoteBalanceError(expireQuate[key].baseCurrencySize,expireQuate[key].baseCurrency,expireQuate[key].quoteCurrencySize,expireQuate[key].quoteCurrency,expireQuate[key].side,expireQuate[key].quoteType)}</h5> */}
              </div>
            </div>
          </>
        );
      },
      [Qindex]
    );
  }
  const getQuatetime = () => {
    if (QuateData && QuateData.length > 0) {
      var counter = 0;
      var interval = setInterval(function () {
        counter++;
        setcounter(counter);
        // Display 'counter' wherever you want to display it.
        if (counter == 6) {
          setcounter(0);
          clearInterval(interval);
        }
      }, 1000);
      // getQuate()
      // setTimeout(getQuate, 5000);
    } else {
      getQuate();
    }
  };

  const closeQuote = (mydata: any, index: number) => {
    expireQuate.unshift(mydata);
    QuateData[index].remove = true;
    quateTemp = [];
    setQuateData([]);
  };

  var TradeTemp: any = [];
  if (TradeQuate) {
    TradeTemp = Object.keys(TradeQuate).map(function (key: any) {
      var t = new Date(TradeQuate[key].quoteTime);
      const myMoment = moment(t).format('YYYY/MM/DD hh:mm:ss');

      return (
        <div className="col-sm-12" key={key}>
          <div className="card_box last_trade_card">
            <div className="top_content">
              <img
                src={require('../../images/dollar_icon.png').default}
                alt="dollar_icon"
              />

              <p>
                {TradeQuate[key].side} {TradeQuate[key].baseCurrency}{' '}
                {parseFloat(TradeQuate[key].baseCurrencySize).toFixed(2)} with{' '}
                {TradeQuate[key].quoteCurrency}
              </p>
              <p>
                <span className="date">{myMoment}</span>
              </p>
            </div>

            <div className="bottom_content">
              <div className="common_box convert_currency">
                <p className="amount">
                  {' '}
                  {parseFloat(TradeQuate[key].quoteCurrencySize).toFixed(2)}
                </p>
                <p className="currency"> {TradeQuate[key].quoteCurrency}</p>
              </div>

              <div className="common_box convertor">
                {/* <p className='fee'>
                    {parseFloat(TradeQuate[key].fee).toFixed(2)} Fee({TradeQuate[key].feeCurrency})
                  </p> */}
                {TradeQuate[key].side === 'SELL' ? (
                  <img
                    src={require('../../images/left_arrow.png').default}
                    alt="arrow"
                  />
                ) : (
                  <img
                    src={require('../../images/green_arrow.png').default}
                    alt="arrow"
                  />
                )}

                <p>Price {parseFloat(TradeQuate[key].price).toFixed(2)}</p>
              </div>

              <div className="common_box convert_currency">
                <p className="amount">
                  {parseFloat(TradeQuate[key].baseCurrencySize).toFixed(2)}
                </p>
                <p className="currency">{TradeQuate[key].baseCurrency}</p>
              </div>
              {/* <a href="#" className="btnn">Tade Now</a> */}
            </div>
          </div>
        </div>
      );
      // }
    }, []);
  }

  var quateTemp: any = [];
  if (QuateData && QuateData.length > 0) {
    let index: any = QuateData.length - 1;
    quateTemp = Object.keys(QuateData).map(
      function (key: any) {
        // alert(QuateData[key].remove)
        if (QuateData[key].remove === false) {
          return (
            <div className="form_box2" key={key}>
              <div key={key}>
                <button
                  type="button"
                  className="close quoteCloseBtn"
                  onClick={() => closeQuote(QuateData[key], key)}
                >
                  <img
                    src={require('../../images/disable.png').default}
                    alt="disable"
                  />
                </button>
                <div className="card_box active">
                  <div className="top_content">
                    <img
                      src={require('../../images/dollar_icon.png').default}
                      alt="dollar_icon"
                    />
                    <p>
                      {QuateData[key].side}{' '}
                      {QuateData[key].quoteType == 'base'
                        ? QuateData[key].baseCurrencySize + ' '
                        : ''}
                      {QuateData[key].baseCurrency} with{' '}
                      {QuateData[key].quoteType == 'quote'
                        ? QuateData[key].quoteCurrencySize
                        : ''}{' '}
                      {QuateData[key].quoteCurrency}
                    </p>

                    <p>
                      <span className="date">{quateDate}</span>
                      <span className="time">{quateTime}</span>
                      {/* <span className='date'>{QuateData[key]}</span>
  <span className='time'>{QuateData[key]}</span> */}
                    </p>
                  </div>

                  <div className="bottom_content">
                    <p className="text-center">
                      <button
                        className="btn btn-info btn-confirm"
                        onClick={() => take_quate(QuateData[key].quoteId, key)}
                      >
                        Confirm
                      </button>
                    </p>
                    <div className="common_box convert_currency">
                      <p className="currency">
                        {parseFloat(QuateData[key].quoteCurrencySize).toFixed(
                          2
                        )}
                      </p>
                      <p className="amount"> {QuateData[key].quoteCurrency}</p>
                    </div>

                    <div className="common_box convertor">
                      {/* <p className='fee'>{parseFloat(QuateData[key].fee).toFixed(2)} Fee({QuateData[key].feeCurrency})</p> */}
                      {QuateData[key].side === 'SELL' ? (
                        <img
                          src={require('../../images/left_arrow.png').default}
                          alt="arrow"
                        />
                      ) : (
                        <img
                          src={require('../../images/arrow.png').default}
                          alt="arrow"
                        />
                      )}

                      <p>
                        Price {QuateData[key].price}
                        {/* <br></br>
                {QuateData.quoteCurrency} to {QuateData.baseCurrency} */}
                      </p>
                    </div>
                    <div className="common_box convert_currency">
                      <p className="amount">
                        {QuateData[key].baseCurrencySize}
                      </p>
                      <p className="currency">{QuateData[key].baseCurrency}</p>
                    </div>
                  </div>
                  {/* <h5 className="error" style={{fontSize:"11px", textAlign:"center"}}>{setQuoteBalanceError(QuateData[key].baseCurrencySize,QuateData[key].baseCurrency,QuateData[key].quoteCurrencySize,QuateData[key].quoteCurrency,QuateData[key].side,QuateData[key].quoteType)}</h5> */}
                </div>

                <Line
                  percent={QuateData[key].percentage}
                  strokeWidth={4}
                  strokeColor="#D3D3D3"
                />

                <p>Expires in {QuateData[key].mysecond} Seconds!</p>
              </div>
            </div>
          );
        }
      },
      [QuateData, Qindex]
    );
  }

  const setbaseCurrencySizeFn = (data: any) => {
    setQuateErr('');
    setquoteCurrencySize('');
    var availabel = currencyBalance[baseCurrency]
      ? (currencyBalance[baseCurrency] as any).Available
      : 0;
    if (
      quoteCurrency &&
      baseCurrency &&
      currencyBalance[baseCurrency] &&
      availabel < data
    ) {
      setQuateErr(
        'You do not have enough ' +
          baseCurrency +
          ' to ' +
          side +
          ' ' +
          quoteCurrency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
    setbaseCurrencySize(data);
  };

  const setquoteCurrencySizeFn = (data: any) => {
    setQuateErr('');
    setbaseCurrencySize('');
    var availabel = currencyBalance[quoteCurrency]
      ? (currencyBalance[quoteCurrency] as any).Available
      : 0;
    if (
      quoteCurrency &&
      baseCurrency &&
      currencyBalance[quoteCurrency] &&
      availabel < data
    ) {
      setQuateErr(
        'You do not have enough ' +
          quoteCurrency +
          ' to ' +
          side +
          ' ' +
          baseCurrency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
    setquoteCurrencySize(data);
  };

  const setquoteCurrencyFn = (currency: any) => {
    setQuateErr('');
    setquoteCurrency(currency);
    var baseAvailabel = currencyBalance[baseCurrency]
      ? (currencyBalance[baseCurrency] as any).Available
      : 0;
    var quoteAvailabel = currencyBalance[currency]
      ? (currencyBalance[currency] as any).Available
      : 0;
    if (
      currency &&
      baseCurrency &&
      currencyBalance[currency] &&
      quoteCurrencySize &&
      quoteAvailabel < quoteCurrencySize
    ) {
      setQuateErr(
        'You do not have enough ' +
          currency +
          ' to ' +
          side +
          ' ' +
          baseCurrency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
    if (
      currency &&
      baseCurrency &&
      currencyBalance[baseCurrency] &&
      baseCurrencySize &&
      baseAvailabel < baseCurrencySize
    ) {
      setQuateErr(
        'You do not have enough ' +
          baseCurrency +
          ' to ' +
          side +
          ' ' +
          currency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
  };

  const setbaseCurrencyFn = (currency: any) => {
    setQuateErr('');
    setbaseCurrency(currency);
    var quoteAvailabel = currencyBalance[quoteCurrency]
      ? (currencyBalance[quoteCurrency] as any).Available
      : 0;
    var baseAvailabel = currencyBalance[currency]
      ? (currencyBalance[currency] as any).Available
      : 0;
    if (
      quoteCurrency &&
      currency &&
      currencyBalance[currency] &&
      baseCurrencySize &&
      baseAvailabel < baseCurrencySize
    ) {
      setQuateErr(
        'You do not have enough ' +
          currency +
          ' to ' +
          side +
          ' ' +
          quoteCurrency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
    if (
      quoteCurrency &&
      currency &&
      currencyBalance[quoteCurrency] &&
      quoteCurrencySize &&
      quoteAvailabel < quoteCurrencySize
    ) {
      setQuateErr(
        'You do not have enough ' +
          quoteCurrency +
          ' to ' +
          side +
          ' ' +
          currency +
          '. You can still request a quote, but it will be indicative only.'
      );
    }
  };

  const trade_quate = () => {
    userService
      .trade_quate()
      .then(function (response: any) {
        SetTradeQuate(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  };
  const gettimer_ = (time: any, nowtime: any, mydata: any) => {
    //  alert(percentages)
    const myMoment = moment(time).format('YYYY-MM-DD hh:mm:ss');
    const myMoment1 = moment(nowtime).format('YYYY-MM-DD hh:mm:ss');
    const difference = +new Date(myMoment) - +new Date(myMoment1);
    let second: any = Math.floor((difference / 1000) % 60);
    let percentages = 100 / second;
    SetPercentage(percentages);
    let i = 1;
    var refreshId: any = setInterval(function () {
      // execute code each second
      //  SetTimestamp(second--)// decrement timestamp with one second each second
      let mysecond: any = parseInt(second) - 1;
      let j: any = i++;
      SetPercentage(percentages * j);

      SetSeconds(parseInt(mysecond));

      if (mysecond <= 1) {
        expireQuate.push(mydata);
        clearInterval(refreshId);
        setQuateData([]);
        setbaseCurrency(-1);
        setquoteCurrency(-1);
      }
    }, 1000);
    intervals.push(refreshId);
  };
  return {
    expireQuate,
    productRelationshipMap,
    productList: ProductList,
    loader,
    quateTemp,
    ExpTemp,
    TradeTemp,
    warningModal,
    setWarningModal,
    errorMessage,
    askStyle,
    setSide: (side: string) => {
      setSide(side);
    },
    currencyErr,
    baseCurrency,
    ProdOption,
    ProdOption1,
    getQuate,
    QuateData,
    QuateErr,
    counter,
    quoteCurrencySize,
    TradeQuate,
    baseCurrencySize,
    setquoteCurrencySizeFn,
    setquoteCurrencyFn,
    setbaseCurrencyFn,
    setbaseCurrencySizeFn,
    modalIsOpencong,
    setIsOpencong,
    modalIsOpenCancel,
    cancelStatus,
    cancelQuate,
    setModalIsOpenCancel,
    side,
    take_quate,
    cancel_quate,
    currencyBalance,
  };
}
export default useBalance;
