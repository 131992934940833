import { useContext, useState } from 'react';
import { Alert, Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import axios from 'axios';
import * as yup from 'yup';
import { ErrorMessage, objectToFormUrlEncoded } from '../Utils';
import { AuthContext } from '../../contexts/AuthContext';
import { useAuth } from '../../auth-context';
import { PulseLoader } from 'react-spinners';
import TwoFactorAuthModal from '../common/modals/TwoFactorAuthModal';
import QRCode from 'qrcode.react';
import { tokenService } from '../../_services/tokens.services';
import { config } from '../../config/config';


function QRCodeEnableModal() {
  const [show, setShow] = useState(false);
  const { setToastObj } = useContext(AuthContext);
  let { configheadersTemplate } = tokenService;

  const [failAuth, setFailAuth] = useState(false);
  let schema = yup.object().shape({
    authenticatorConfirmCode: yup.string().required().length(6),
    secret: yup.string().required(),
  });
  const handleClose = () => {
    setFailAuth(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const { userInfo, userRefetch, userIsFetching } = useAuth();
  const [qrCode, setQrCode] = useState();
  const [existingQrCode, setExistingQrCode] = useState();
  const [showToFactor, setShowToFactor] = useState(false);
  const [secret, setSecret] = useState('');
  const [authenticatorConfirmCode, setAuthenticatorConfirmCode] = useState('');

  const [enableTwoFactorData, setEnableTwoFactorData] = useState<any>();
  const mutation: any = useMutation(
    () => {
      let url = `${config.apiurl}/2fa/enable`;
      let configheaders = configheadersTemplate();
      return axios.post(
        url,
        {},
        configheaders
      );
    },
    {
      onSuccess: (data, variables, context) => {
        setSecret(data?.data?.secret);
        setEnableTwoFactorData(data);
        setQrCode(data?.data?.url);
        handleShow();
        // userRefetch();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || 'Error',
          type: 'error'
        });
      },
    }
  );

  const confirmQRCode: any = useMutation(
    (data: any) => {
      let url = `${config.apiurl}/2fa/enable/confirm`;
      let configheaders = configheadersTemplate();
      return axios.post(url, data, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        if (data.data.result) {
          setFailAuth(false);
          userRefetch();
          handleClose();
          setToastObj({
            message: 'Two factor authentication enabled',
            type: 'success'
          });
        } else {
          setFailAuth(true);
        }
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Error`,
          type: 'error'
        });
      },
    }
  );
  const getQRCode: any = useMutation(
    () => {
      let url = `${config.apiurl}/2fa/url`;
      let configheaders = configheadersTemplate();
      return axios.get(url, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setExistingQrCode(data?.data?.url);
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Error`,
          type: 'error'
        });
      },
    }
  );

  const disableMutation: any = useMutation(
    () => {
      let url = `${config.apiurl}/2fa/disable`;
      let configheaders = configheadersTemplate();
      return axios.post(
        url,
        {},
        configheaders
      );
    },
    {
      onSuccess: (data, variables, context) => {
        setQrCode(undefined);
        userRefetch();
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Error`,
          type: 'error'
        });
      },
    }
  );

  const [error, setError] = useState<any>();

  return (
    <>
      <TwoFactorAuthModal
        onSuccess={() => {
          setShowToFactor(false);
          disableMutation.mutate();
        }}
        onClose={() => {
          setShowToFactor(false);
        }}
        initialShow={showToFactor}
        callback={() => {}}
      ></TwoFactorAuthModal>

      <Button
        onClick={() => {
          // use react query to make a post here
          if (userInfo?.enable2FA) {
            // we need to add the two factor authentication
            setShowToFactor(true);
          } else {
            mutation.mutate();
          }
        }}
        className='main-color-btn indented'
      >
        {userInfo?.enable2FA ? 'Disable' : 'Enable'}
        {(disableMutation.isLoading ||
          userIsFetching ||
          mutation.isLoading) && (
          <>
            &nbsp;
            <PulseLoader loading={true} size={3} />
          </>
        )}
      </Button>

      {!userIsFetching && userInfo?.enable2FA && qrCode === undefined && (
        <>
          <Button
            onClick={() => {
              // use react query to make a post here
              getQRCode.mutate();
            }}
            className='btn grad_btn grad_rev_btn main-small-btn verify-btn'
          >
            QR Code
            {getQRCode.isLoading && (
              <>
                &nbsp;
                <PulseLoader loading={true} size={3} />
              </>
            )}
          </Button>
        </>
      )}
      {existingQrCode && (
        <>
          <p>Scan this QR code to open Google Authenticator</p>
          <QRCode value={existingQrCode} />
        </>
      )}
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm two factor authentication
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {failAuth && (
            <>
              <Alert variant={'warning'}>
                <span>The code you provided is not valid</span>
              </Alert>
            </>
          )}
          <form>
            <>
              {qrCode && (
                <>
                  <p>Scan this QR code to open Google Authenticator</p>
                  <QRCode value={qrCode} />
                </>
              )}
              <FormGroup
                className='qr-code-form'
                controlId='formBasicText'
              >
                <label>Confirm authenticator code:</label>
                <input
                  onChange={(e: any) => {
                    setAuthenticatorConfirmCode(e.target.value);
                  }}
                  type='text'
                  placeholder='Enter authenticator code'
                  className='form-control'
                />
                {confirmQRCode.isError &&(
                  <Alert variant={'warning'}>
                    <span>The code you provided is not valid</span>
                  </Alert>
                )}
                <FormControl.Feedback />
                <ErrorMessage
                  path={'authenticatorConfirmCode'}
                  error={error}
                ></ErrorMessage>
              </FormGroup>
            </>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <>
            <Button
              block
              className={'yellow_btn'}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                schema
                  .validate({
                    authenticatorConfirmCode,
                    secret,
                  })
                  .then(function (valid) {
                    if (valid) {
                      setError(null);
                      const data_ = objectToFormUrlEncoded({
                        code: authenticatorConfirmCode,
                        secret,
                      });
                      confirmQRCode.mutate(data_);
                    } else {
                      alert('fail');
                    }
                  })
                  .catch(function (err) {
                    setError(err);
                  });
              }}
            >
              {mutation.isLoading ? <>'Submitting ...'</> : <>Confirm</>}
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QRCodeEnableModal;
