import moment from "moment";
import React from "react";
import DollarIcon from "../../images/dollar-icon.png";
import Arrow from "../../images/long-arrow.png";
import { formatNumber } from "../Utils";

const TradingBox = ({
  quate,
  isExpired,
}: {
  quate: any;
  isExpired: boolean;
}) => {

  return (
    <>
      <div className={`trading-box ${isExpired && "expired-quotes"}`}>
        {isExpired && (
          <>
            <Box
              quate={quate}
              quateTime={quate.quoteTime}
              quoteString={`${quate.side} ${formatNumber(quate.baseCurrencySize)} ${quate.baseCurrency} with ${
                formatNumber(quate.quoteQuantity || quate.quoteCurrencySize)
              } ${quate.quoteCurrency}`}
              quote={{
                currency: quate.quoteCurrency,
                price: quate.referencePrice,
              }}
              base={{
                currency: quate.baseCurrency,
                size: quate.baseCurrencySize,
              }}
            ></Box>
          </>
        )}
        {!isExpired && (
          <Box
            quate={{
              quoteCurrency: quate.productName?.split("/")[1] || quate.quoteCurrency,
              quoteCurrencySize: quate.quoteQuantity || quate.quoteCurrencySize,
              price: quate.price,
              baseCurrency: quate.productName?.split("/")[0] || quate.baseCurrency,
              baseCurrencySize: quate.quantity || quate.baseCurrencySize ,

            }}
            quateTime={quate.timestamp || quate.quoteTime}
            quote={{
              currency: quate.productName?.split("/")[1] || quate.quoteCurrency,
              price: quate.quoteQuantity || quate.quoteCurrencySize,
            }}
            quoteString={`${quate.side} ${formatNumber(quate.quantity)} ${quate.feeCurrency} with ${formatNumber(
              quate.quoteQuantity || quate.quoteCurrencySize)
            } ${quate.productName?.split("/")[1] || quate.quoteCurrency} `}
            base={{
              currency: quate.productName?.split("/")[0] || quate.baseCurrency,
              size: quate.quantity || quate.baseCurrencySize,
            }}
          ></Box>
        )}
      </div>
    </>
  );
};
export default TradingBox;

const Box = ({ quoteString, quate, quateTime }: any) => {
  var t = new Date(quateTime);
  const myMoment = moment(t).format("YYYY/MM/DD hh:mm:ss");
  return (
    <>
      <div className="trading-topbox">
        <img src={DollarIcon} alt="" />
        {quoteString}
      </div>
      <div className="trading-bottombox">
        <div className="box-3">
          <h3>{quate.baseCurrency}</h3>
          <p>{formatNumber(quate.baseCurrencySize)}</p>
        </div>
        <div className="box-3 trading-price">
          <img
            style={{
              transform:
                quate.side === "BUY" ? "rotate(180deg)" : "rotate(0deg)",
            }}
            src={Arrow}
            alt=""
          />
          <p>Price {formatNumber(quate.price)}</p>
        </div>
        <div className="box-3">
          <h3>{quate.quoteCurrency}</h3>
          <p>{formatNumber(quate.quoteCurrencySize)}</p>
        </div>
        <div className="clearfix"></div>
      </div>
    </>
  );
};
