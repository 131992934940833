import React from "react";
import { FiExternalLink } from "react-icons/fi";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function ExternalLinkBtn({text, currency}:any) {
    const btcLink = "https://www.blockchain.com/search?search=";
    const defaultLink = "https://etherscan.io/tx/";
    let externalLinkUrl: string;
    switch (true) {
      case currency === "BTC":
        externalLinkUrl = btcLink + text;
        break;
      default:
        externalLinkUrl = defaultLink + text;
    }
    function btnHandler (){
        window.open(externalLinkUrl, "_blank");
    }
    return (
        <div className="tool-container">
            <OverlayTrigger
                key={text}
                placement="right"
                overlay={
                    <Tooltip id={`exterLink-${text}`}>
                        See transaction details.
                    </Tooltip>
                }
            >
                <button  
                    className="tool-btn externalBtn"
                    onClick={btnHandler}
                >
                    <FiExternalLink className="toolIcon"/>
                </button>
            </OverlayTrigger>
        </div>
  )
};