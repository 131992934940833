import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { FilterRow, TableContainer } from "../../Utils/FilterRow";
import { ProgressMovingBar } from "../../Utils/ProgressMovingBar";
import { formatNumber } from "../../Utils";
import Pagination from "../../Utils/Pagination";
import Loader from "../../Loader";
import { config } from "../../../config/config";
import ContextWrapper, { Context } from "../../Utils/ContextWrapper";
import { CSVLink } from "react-csv";
import TradingBox from "../TradingBox";

// Specific to this page
const QUERY_INDEX = "RecentTrades";
const URL = `${config.apiurl}/otc/trade/list`;
// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<any>({});
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (listData?.data) {
      setCurrentPage(listData?.data);
      setInitialized(true);
    }
  }, [listData?.data]);

  return (
    <>
      <div className="">
        <div>
          <div>
            <div>
              <div className="">
                <Loader visible={listIsLoading}></Loader>
                <>
                  {initialized && currentPage?.length === 0 && (
                    <p>No recent trades found</p>
                  )}

                  {currentPage?.map((data: any, index: any) => {
                    return (
                        <TradingBox isExpired={false} quate={data} key={index}></TradingBox>
                    );
                  })}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
