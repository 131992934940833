import * as React from 'react';
import './css/bootstrap.min.css';
import './css/style_.css';
import './css/font-awesome.css';
import store from './store';
import { Provider } from 'react-redux';
import { Layout } from './components/common/Layout';
import Footer from './components/Market/Footer';
import AuthContextProvider from './contexts/AuthContext';
import GlobalContextProvider from './contexts/GlobalContext';
import ProfileContextWrapper from './components/Profile/ProfileContextWrapper';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import { Routes } from './AuthStateApp';
import { useAuth } from './auth-context';
import ToastNotification from './components/Utils/ToastNotification';

const WithHeader = (props: any) => {
  const { userInfo } = useAuth();
  const roles = userInfo?.roles;
  const myroles = roles?.split(',');
  let layout = '';
  if (
    myroles &&
    (myroles.includes('Administrator') ||
      myroles.includes('Accounting') ||
      myroles.includes('Refiner'))
  ) {
    layout = 'admin';
    //alert("1")
  }
  if (
    myroles &&
    (myroles.includes('OreSupplier') ||
      myroles.includes('OreBuyer') ||
      myroles.includes('GoldTrader'))
  ) {
    layout = 'loggedin';
  }
  if (
    myroles &&
    (myroles.includes('Administrator') ||
      myroles.includes('Accounting') ||
      myroles.includes('Refiner')) &&
    (myroles.includes('OreSupplier') ||
      myroles.includes('OreBuyer') ||
      myroles.includes('GoldTrader'))
  ) {
    layout = 'common';
    // alert("2")
  }

  return (
    <Layout isHome={props.isHome} type={layout}>
      {props.children}
    </Layout>
  );
};

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <GlobalContextProvider>
          <ProfileContextWrapper>
            <SuccessModal></SuccessModal>
            <ErrorModal></ErrorModal>
            <ToastNotification />
            <Provider store={store}>
              <Routes childProps={{}} />
              <Footer></Footer>
            </Provider>
          </ProfileContextWrapper>
        </GlobalContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default App;
