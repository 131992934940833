import { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { FilterRow, TableContainer } from '../../Utils/FilterRow';
import { formatNumber, capitalize } from '../../Utils';
import Pagination from '../../Utils/Pagination';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import ContextWrapper, { Context } from '../../Utils/ContextWrapper';
import ApproveModal from './Modals/ApproveModal';
import DenyModal from './Modals/DenyModal';
import { BsBoxArrowLeft, BsBoxArrowInRight } from 'react-icons/bs';
import CopyTool from '../../Utils/CopyTool';
import DetailsModal from './Modals/DetailsModal';
import ReasonModal from './Modals/ReasonModal';
import { GrDocumentText } from 'react-icons/gr';
import Decimal from 'decimal.js-light';
import { TransferDataInterface, TransferFetchParams } from '../financeDataType';

// Specific to this page
const QUERY_INDEX = 'FinanceTransfer';
const URL = `${config.apiurl}/transfer/finance/currency/list`;
const initialFetch = {
  pageNum: 1,
  limit: 10,
  startDate: '',
  endDate: '',
};
// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] =
    useState<TransferFetchParams>(initialFetch);
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table />
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const { listIsLoading, listData, fetchParams, setFetchParams, setPageNum } =
    useContext(Context);
  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const getTransferStatus = (
    status: String,
    financeStatus: string,
    data: TransferDataInterface
  ) => {
    switch (true) {
      case financeStatus === 'Rejected':
      case status === 'Rejected':
        return {
          status_clr: 'clr-deny',
          status_text: 'Rejected',
          status_action: <ReasonModal data={data} />,
        };
      case status === 'Approved':
        return {
          status_clr: 'clr-approve',
          status_text: 'Approved',
          status_action: <DetailsModal data={data} />,
        };
      case status === 'Pending' && financeStatus === 'Approved':
        return {
          status_clr: 'clr-pending',
          status_text: 'Pending',
          status_action: (
            <div className="pending_box">
              <GrDocumentText /> &nbsp;
              <span> Admin has not yet approved this transfer.</span>
            </div>
          ),
        };
      case financeStatus === 'Pending':
        return {
          status_clr: 'clr-pending',
          status_text: 'Pending',
          status_action: (
            <>
              <ApproveModal data={data} />
              <DenyModal data={data} />
            </>
          ),
        };
      default:
        return {
          status_clr: 'clr-grey',
          status_text: 'Other',
          status_action: <></>,
        };
    }
  };
  useEffect(() => {
    if (listData?.data) {
      setInitialized(true);
    }
  }, [listData?.data]);
  useEffect(() => {
    if (listData) {
      setCurrentPage(listData.data);
      setInitialized(true);
    }
  }, [listData]);

  return (
    <>
      <div className="border_box">
        <FilterRow
          searchParams={fetchParams}
          startDate={{
            value: fetchParams.startDate,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                startDate: val,
              });
            },
          }}
          endDate={{
            value: fetchParams.endDate,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                endDate: val,
              });
            },
          }}
          limit={{
            value: fetchParams.limit,
            set: (val: string) => {
              setFetchParams({
                ...fetchParams,
                limit: val,
                pageNum: 1,
              });
            },
          }}
          clearFilter={{
            set: () => {
              setFetchParams(initialFetch);
            },
          }}
        />
      </div>
      <div className="table-responsive">
        <Loader visible={listIsLoading} />
        <table className="main-table fin_transfer_container">
          <thead>
            <tr>
              <th className="user_item_row">Date</th>
              <th className="user_item_row">Side</th>
              <th className="user_item_row">Account</th>
              <th className="user_item_row">Currency</th>
              <th className="user_item_row">Amount</th>
              <th className="user_item_row">Transaction Fee</th>
              <th className="user_item_row">Wire Amount</th>
              <th className="user_item_row">Destination</th>
              <th className="user_item_row">Status</th>
              <th className="user_item_row">Action</th>
            </tr>
          </thead>
          <tbody>
            {!listIsLoading && currentPage?.length === 0 && (
              <tr>
                <td className="clr-pending" colSpan={9}>
                  No rows found. Try clearing your filters.
                </td>
              </tr>
            )}
            {currentPage?.map((data: TransferDataInterface, index: number) => {
              const {
                type,
                financeStatus,
                asset,
                name,
                amount,
                timestamp,
                toAddress,
                fee,
                status,
              } = data;
              let icon: any;
              if (type === 'deposit') {
                icon = <BsBoxArrowInRight className="clr-grey" />;
              } else {
                icon = <BsBoxArrowLeft className="clr-grey" />;
              }
              var t = new Date(timestamp);
              const myMoment = moment(t).format('YYYY-MM-DD hh:mm:ss');
              let status_info = getTransferStatus(status, financeStatus, data);
              let status_clr = status_info?.status_clr;
              let status_text = status_info?.status_text;
              let status_action = status_info?.status_action;
              let processedAmount, processedFee, wireAmount;
              try {
                processedAmount = new Decimal(amount);
                processedFee = fee ? new Decimal(fee) : 0;
                wireAmount = processedAmount.minus(processedFee);
              } catch (error) {
                processedAmount = '';
                processedFee = '';
                wireAmount = '';
                console.log(error);
              }

              return (
                <tr key={index} className="transfer_row">
                  <td className="transfer_row_item">
                    <span className="transfer_time">{myMoment}</span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_side row-ele-center">
                      {icon}&nbsp;&nbsp;
                      {capitalize(type)}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_name">{name}</span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_currency">{asset}</span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_amount">
                      {processedAmount
                        ? formatNumber(processedAmount)
                        : 'Not stated'}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_network_fee">
                      {processedFee ? formatNumber(processedFee) : 'Not stated'}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_wire_amount">
                      {wireAmount ? formatNumber(wireAmount) : 'Not stated'}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <span className="transfer_destination">
                      {toAddress && <CopyTool text={toAddress} digit={6} />}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <span className={`transfer_status ${status_clr}`}>
                      {status_text}
                    </span>
                  </td>
                  <td className="transfer_row_item">
                    <div className="user_action_btns">{status_action}</div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="custom_table">
        <TableContainer isLoading={initialized && listIsLoading}>
          <table className="table"></table>
          {count !== 0 && (
            <div className={'col-md-11'}>
              <Pagination
                onChange={(page: number) => setPageNum(page)}
                count={fetchParams.limit}
                totalCount={totalCount}
              />
            </div>
          )}
        </TableContainer>
      </div>
    </>
  );
};
