const items = [
  {
    src: (isDarkMode: boolean) =>
      require(`../../images/landing-page/items/${
        isDarkMode ? 'dark-' : ''
      }item-1.svg`).default,
    title: 'Best-in-class Technology',
    description:
      'Powered by in-house technology. Get real-time responses for immediate trading needs in fast markets.',
  },
  {
    src: (isDarkMode: boolean) =>
      require(`../../images/landing-page/items/${
        isDarkMode ? 'dark-' : ''
      }item-2.svg`).default,
    title: 'Secure',
    description: 'All assets held in custody and not on exchange.',
  },
  {
    src: (isDarkMode: boolean) =>
      require(`../../images/landing-page/items/${
        isDarkMode ? 'dark-' : ''
      }item-3.svg`).default,
    title: 'Ultra-competitive',
    description: 'Competitive and firm pricing without trading on order books.',
  },
  {
    src: (isDarkMode: boolean) =>
      require(`../../images/landing-page/items/${
        isDarkMode ? 'dark-' : ''
      }item-4.svg`).default,
    title: 'Compliant',
    description:
      'Trade block trades on a fast, reliable, and compliant platform.',
  },
];

const currency = [
  {
    src: require('../../images/landing-page/currency/BTC.svg').default,
    title: 'BTC',
  },
  {
    src: require('../../images/landing-page/currency/USDC.svg').default,
    title: 'USDC',
  },
  {
    src: require('../../images/landing-page/currency/ETH.svg').default,
    title: 'ETH',
  },
  {
    src: require('../../images/landing-page/currency/USDT.svg').default,
    title: 'USDT',
  },
];

const features = [
  {
    src: require('../../images/landing-page/features/feature-1.svg').default,
    title: '1. Create an account',
    description: 'Open an account and complete KYC.',
  },
  {
    src: require('../../images/landing-page/features/feature-2.svg').default,
    title: '2. Deposit funds',
    description:
      'Deposit crypto or fiat with us. All funds are held in custody off exchange.',
  },
  {
    src: require('../../images/landing-page/features/feature-3.svg').default,
    title: '3. Start trading',
    description: 'Start trading and receive instant settlement.',
  },
];

export { items, currency, features };
