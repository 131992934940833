// import { authHeader } from '../_helpers';
import { config } from '../config/config';
import { tokenService } from './tokens.services';
import axios from 'axios';
export const userService = {
  login,
  logout,
  userdetail,
  take_order,
  close_order,
  balance_export,
  get_balance,
  get_tradeList,
  get_transfer,
  get_product,
  get_open_orderList,
  get_tradeList_balance,
  get_transfer_balance,
  get_refine_list,
  get_candle,
  get_transfer_download,
  get_trade_download,
  get_orderList,
  place_order,
  get_asset,
  get_quate,
  take_quate,
  cancel_quate,
  trade_quate,
  changepass,
  updateProfile,
  deposit_request,
  withdrawRequest,
  addBank,
  delete_bank,
  addcrypto,
  delete_crypto,
  get_bank,
  get_bankdetail,
  get_deposite_address,
  trade_quatelast,
  transferDetail,
  accountType,
  jwtVerify,
  jwtLogout,
  get_currency_pair,
  settlement,
};

const { configheadersTemplate } = tokenService;
const baseURL = config.apiurl;

function withdrawRequest(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('amount', params.amount);
  formData.append('asset', params.asset);
  if (params.userBankId) {
    formData.append('userBankId', params.userBankId);
  } else {
    formData.append('userAddress', params.userAddressId);
  }
  let url = `${baseURL}/transfer/withdraw`;
  return axios.post(url, formData, configheaders);
}

function login(params: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/users/login`;
  return axios.post(url, params, configheaders);
}

function get_quate(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('baseCurrency', params.baseCurrency);
  formData.append('side', params.side);
  formData.append('quoteCurrency', params.quoteCurrency);
  formData.append(
    'baseCurrencySize',
    params.baseCurrencySize ? params.baseCurrencySize.replace(',', '') : '0'
  );
  formData.append(
    'quoteCurrencySize',
    params.quoteCurrencySize ? params.quoteCurrencySize.replace(',', '') : '0'
  );
  let url = `${baseURL}/otc/quote`;
  return axios.post(url, formData, configheaders);
}

function take_quate(id: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/otc/quote/` + id + '/accept';
  let formData = new FormData();
  formData.append('quoteId ', id);
  return axios.post(url, formData, configheaders);
}

function cancel_quate(id: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/otc/quote/` + id + '/cancel';
  let formData = new FormData();
  formData.append('quoteId ', id);
  return axios.post(url, formData, configheaders);
}

function trade_quate() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/otc/trade/list`;
  return axios.get(url, configheaders);
}

function trade_quatelast() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/otc/trade/last`;
  return axios.get(url, configheaders);
}

function place_order(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('product', params.product);
  formData.append('side', params.side);
  formData.append('price', params.price);
  formData.append('partial', params.partial);
  formData.append('quantity', params.quantity);
  formData.append('order_type', 'limit');
  let url = `${baseURL}/order`;
  return axios.post(url, formData, configheaders);
}

function take_order(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('quantity', params.quantity);
  formData.append('id', params.id);
  let url = `${baseURL}/order/take`;
  return axios.post(url, formData, configheaders).catch(function (error) {
    console.log(error);
  });
}

async function close_order(id: any) {
  let configheaders = await configheadersTemplate();
  let url = `${baseURL}/order?id=` + id;
  return axios.delete(url, configheaders);
}

function logout() {
  let url = '';
  if (process.env.NODE_ENV === 'development') {
    url = `http://localhost:3000`;
  } else {
    url = `https://api.catchotc.com/logout`;
  }
  return url;
}

function deposit_request(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('amount', params.amount);
  formData.append('asset', params.asset);
  formData.append('cryptoFromAddress', params.cryptoFromAddress);
  formData.append('cryptoTXId', params.cryptoTXId);
  let url = `${baseURL}/transfer/deposit`;
  return axios.post(url, formData, configheaders);
}

function userdetail() {
  let configheaders = configheadersTemplate();
  let url: any = `${baseURL}/userInfo`;
  return axios.get(url, configheaders);
}

function get_bank(bank_id: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/bank?bankId=` + bank_id;
  return axios.get(url, configheaders);
}

function get_asset() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/asset/list`;
  return axios.get(url, configheaders);
}

function get_candle() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/market/candle?product=p1&interval=5m`;
  return axios.get(url, configheaders);
}

//================= ISSUE RWQUEST
function get_balance() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance?house=false`;
  return axios.get(url, configheaders);
}

function get_deposite_address(asset: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/address?asset=` + asset;
  return axios.get(url, configheaders);
}

function get_tradeList(myparams: any) {
  let configheaders = configheadersTemplate();
  for (let propName in myparams) {
    if (myparams[propName] === null || myparams[propName] === '') {
      delete myparams[propName];
    }
  }
  let url = `${baseURL}/otc/trade/list`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function changepass(password: any, name: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/updatePassword`;
  let formData = new FormData();
  formData.append('password', password);
  formData.append('username', name);
  return axios.put(url, formData, configheaders);
}

function updateProfile(address: any, phone: any, country: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/profile`;
  let formData = new FormData();
  formData.append('address', address);
  formData.append('phone', phone);
  formData.append('country', country);
  return axios.put(url, formData, configheaders);
}

function get_transfer(myparams: any) {
  let configheaders = configheadersTemplate();
  for (let propName in myparams) {
    if (myparams[propName] === null || myparams[propName] === '') {
      delete myparams[propName];
    }
  }
  let url = `${baseURL}/transfer/list`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
    Cookie: 'developkey=value',
  };
  return axios.get(url, customizedRequest);
}

function get_transfer_download(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/list/download`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
    Cookie: 'developkey=value',
  };
  return axios.get(url, customizedRequest);
}

function get_bankdetail(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/transfer/bank?asset=` + myparams;
  return axios.get(url, configheaders);
}

function get_product() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/product/list`;
  return axios.get(url, configheaders);
}

function get_open_orderList(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/order/open`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function get_orderList(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/order/list`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function get_tradeList_balance(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance/history`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function get_transfer_balance(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance/history`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function get_trade_download(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/trade/list/download`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function balance_export(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/balance/history/download`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function get_refine_list(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/trade/refine/list`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function addcrypto(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('asset', params.asset);
  formData.append('name', params.name);
  formData.append('address', params.address);
  let url = `${baseURL}/userInfo/withdrawaddress`;
  return axios.post(url, formData, configheaders);
}

function addBank(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  console.log(params);
  formData.append('description', params.description);
  formData.append('asset', params.asset);
  formData.append('intermediaryBankName', params.intermediaryBankName);
  formData.append(
    'intermediaryBankSwiftCode',
    params.intermediaryBankSwiftCode
  );
  formData.append('intermediaryBankAddress', params.intermediaryBankAddress);
  formData.append('bankAccountName', params.bankAccountName);
  formData.append('bankAccountNo', params.bankAccountNo);
  formData.append('bankAddress', params.bankAddress);
  formData.append('bankName', params.bankName);
  formData.append('bankSwiftCode', params.bankSwiftCode);
  formData.append('bankBranchName', params.bankBranchName);
  formData.append('name ', params.name);
  let url = `${baseURL}/userInfo/bank`;
  return axios.post(url, formData, configheaders);
}

function delete_bank(id: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('id', id);
  let url = `${baseURL}/userInfo/bank`;
  let qs = require('qs');
  let data = qs.stringify({
    id: id,
  });
  let config_t: any = {
    method: 'delete',
    url: url,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: configheaders.headers['Authorization'],
    },
    data,
    withCredentials: true,
  };
  return axios(config_t);
}

function delete_crypto(id: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('id', id);
  let url = `${baseURL}/userInfo/withdrawaddress`;
  let qs = require('qs');
  let data = qs.stringify({
    id: id,
  });
  let config_t: any = {
    method: 'delete',
    url: url,
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: configheaders.headers['Authorization'],
    },
    data,
    withCredentials: true,
  };
  return axios(config_t);
}

function transferDetail(myparams: any) {
  let configheaders = configheadersTemplate();
  let url = `${config.apiurl}/transfer/detail`;
  let customizedRequest = {
    ...configheaders,
    params: myparams,
  };
  return axios.get(url, customizedRequest);
}

function accountType(username: string, type: string, orgName: string) {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/userInfo/accountType`;
  let formData = new FormData();
  formData.append('username', username);
  formData.append('type', type);
  formData.append('orgName', orgName);
  return axios.put(url, formData, configheaders);
}

function jwtVerify(accessToken: string, expiresIn: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('accessToken', accessToken);
  formData.append('expiresIn', expiresIn);
  let url = `${config.apiurl_login}/jwt_verify`;
  return axios.post(url, formData, configheaders);
}

function jwtLogout() {
  let configheaders = configheadersTemplate();
  let url = `${config.apiurl_login}/jwt_logout`;
  return axios.get(url, configheaders);
}

function get_currency_pair() {
  let configheaders = configheadersTemplate();
  let url = `${baseURL}/product/groups`;
  return axios.get(url, configheaders);
}

function settlement(params: any) {
  let configheaders = configheadersTemplate();
  let formData = new FormData();
  formData.append('id', params);
  let url = `${baseURL}/otc/trade/settle`;
  return axios.post(url, formData, configheaders);
}
