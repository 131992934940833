import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ProfileContext } from './ProfileContextWrapper';
import * as yup from 'yup';
import { ErrorMessage } from '../Utils';

const NewBankModal = () => {
  const [error, setError] = useState<any>(null);
  const { setToastObj } = useContext(AuthContext);
  const [add, setAdd] = useState(false);

  const {
    myparams_bank,
    saveBankAsset,
    bankAccountName,
    handleChange_bankname,
    add_bank,
    ProductList,
    SetParamsBank,
  } = useContext(ProfileContext);
  let schema = yup.object().shape({
    bankName: yup.string().required(),
    bankBranchName: yup.string().required(),
    bankSwiftCode: yup.string().min(8).max(11).required(),
    bankAccountNo: yup.string().min(5).required(),
  });
  function handleSaveChange(event: any) {
    if (event.type === 'change') {
      const { name, value } = event.target;
      SetParamsBank({
        ...myparams_bank,
        [name]: value,
      });
    }
  }
  useEffect(() => {
    if (add) {
      setError(null);
      setAdd(false);
    }
  }, [add]);

  return (
    <>
      <div
        className="modal fade"
        id="add-new-bank-popup"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Bank Account
              </h5>
              <button
                type="button"
                className="close close-popup"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src="images/close.svg" alt="" className="" />
              </button>
            </div>
            <div className="modal-body">
              <form className="withdrawal-formarea main-form">
                <div className="form-group">
                  <label>Currency:</label>
                  <select
                    className="form-control custom-select"
                    style={{ width: '100%' }}
                    onChange={(e: any) => saveBankAsset(e.target.value)}
                  >
                    {ProductList &&
                      ProductList.filter(
                        (item: any) => item.currencyType === 'Fiat'
                      ).map((item: any) => {
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="form-group">
                  <label>Description:</label>
                  <input
                    className="form-control"
                    placeholder="Enter Description"
                    type="text"
                    name="description"
                    onChange={handleSaveChange}
                    value={myparams_bank.description}
                  />
                </div>
                <div className="form-group">
                  <label>Bank Account Name:</label>
                  <input
                    onChange={(e: any) => handleChange_bankname(e.target.value)}
                    type="text"
                    placeholder="Enter Bank Account Name"
                    name="bankAccountName"
                    value={myparams_bank.bankAccountName}
                    className="form-control"
                  />
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Bank Name:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Bank Name (Required)"
                      type="text"
                      name="bankName"
                      onChange={handleSaveChange}
                      value={myparams_bank.bankName}
                    />
                    <ErrorMessage
                      path={'bankName'}
                      error={error}
                    ></ErrorMessage>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Bank Branch Name:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Bank Branch Name (Required)"
                      type="text"
                      name="bankBranchName"
                      onChange={handleSaveChange}
                      value={myparams_bank.bankBranchName}
                    />
                    <ErrorMessage
                      path={'bankBranchName'}
                      error={error}
                    ></ErrorMessage>
                  </div>
                </div>
                <div className="form-group">
                  <label>Bank Address:</label>
                  <textarea
                    className="form-control"
                    placeholder="Enter Bank Address"
                    name="bankAddress"
                    onChange={handleSaveChange}
                    value={myparams_bank.bankAddress}
                  />
                  <ErrorMessage
                    path={'bankAddress'}
                    error={error}
                  ></ErrorMessage>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Bank Swift Code:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Bank Swift Code (Required)"
                      type="text"
                      name="bankSwiftCode"
                      onChange={handleSaveChange}
                      value={myparams_bank.bankSwiftCode}
                    />
                    <ErrorMessage
                      path={'bankSwiftCode'}
                      error={error}
                    ></ErrorMessage>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Bank Account Number:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Bank Account Number (Required)"
                      type="text"
                      name="bankAccountNo"
                      onChange={handleSaveChange}
                      value={myparams_bank.bankAccountNo}
                    />
                    <ErrorMessage
                      path={'bankAccountNo'}
                      error={error}
                    ></ErrorMessage>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Intermediary Bank Name:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Intermediary Bank Name"
                      type="text"
                      name="intermediaryBankName"
                      onChange={handleSaveChange}
                      value={myparams_bank.intermediaryBankName}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Intermediary Bank Swift/BIC:</label>
                    <input
                      className="form-control"
                      placeholder="Enter Intermediary Bank Swift/BIC"
                      type="text"
                      name="intermediaryBankSwiftCode"
                      onChange={handleSaveChange}
                      value={myparams_bank.intermediaryBankSwiftCode}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Intermediary Bank Address:</label>
                  <input
                    className="form-control"
                    placeholder="Enter Intermediary Bank Address"
                    type="text"
                    name="intermediaryBankAddress"
                    onChange={handleSaveChange}
                    value={myparams_bank.intermediaryBankAddress}
                  />
                </div>
                <div className="text-center margintop40">
                  <button
                    data-dismiss="modal"
                    className="main-color-btn main-color-bigbtn"
                    type="submit"
                    onClick={() => {
                      const {
                        bankName,
                        bankBranchName,
                        bankSwiftCode,
                        bankAccountNo,
                      } = myparams_bank;
                      const validationForm = {
                        bankName: bankName,
                        bankBranchName: bankBranchName,
                        bankSwiftCode: bankSwiftCode,
                        bankAccountNo: bankAccountNo,
                      };
                      schema
                        .validate(validationForm)
                        .then((valid: any) => {
                          if (valid) {
                            add_bank();
                            setAdd(true);
                            setToastObj({
                              message: 'You have added the new bank address.',
                              type: 'success',
                            });
                            SetParamsBank({
                              asset: '',
                              description: '',
                              name: '',
                              bankName: '',
                              bankBranchName: '',
                              bankAddress: '',
                              bankSwiftCode: '',
                              bankAccountName: '',
                              bankAccountNo: '',
                              intermediaryBankName: '',
                              intermediaryBankSwiftCode: '',
                              intermediaryBankAddress: '',
                            });
                          }
                        })
                        .catch((err: any) => {
                          setError(err);
                          setToastObj({
                            message: 'Failed to add new bank address',
                            type: 'error',
                          });
                          SetParamsBank({
                            asset: '',
                            description: '',
                            name: '',
                            bankName: '',
                            bankBranchName: '',
                            bankAddress: '',
                            bankSwiftCode: '',
                            bankAccountName: '',
                            bankAccountNo: '',
                            intermediaryBankName: '',
                            intermediaryBankSwiftCode: '',
                            intermediaryBankAddress: '',
                          });
                        });
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewBankModal;
