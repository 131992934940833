import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenSucc,
    closeModalSucc,
    titlehead,
    reason_msg,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        isOpen={modalIsOpenSucc}
        onHide={closeModalSucc}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={modalIsOpenSucc}
        size={"lg"}
      >
        <Modal.Header>
          <button type="button" className="close" onClick={closeModalSucc}>
            &times;
          </button>
          <h4 className="modal-title">{titlehead}</h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form_box modal_parent">
              <div className="confirm_btn">
                <h4>{reason_msg}</h4>
                <div className="confirm_btn">
                  <button className="grad_btn" onClick={closeModalSucc}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
