import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const NotesModal = ({ show, onClose }: { onClose: any; show: boolean }) => {
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div>
              <div style={{ padding: 30 }}>
                <h4 style={{ marginBottom: 10 }}>NOTES:</h4>
                <ol>
                  <li>
                    <strong>
                      Fiat withdrawals below $10,000 in value on USD denominated
                      transfers will have a $75 fee.
                    </strong>{" "}
                    This is a fee our bank charges us.
                  </li>
                  <li>
                    <strong>
                      CatchOTC is not responsible for delays in wire transfers
                      due to your bank,
                    </strong>{" "}
                    or intermediary banks that your bank uses.
                  </li>
                  <li>
                    <strong>
                      CatchOTC is not responsible for any impact this has on
                      your bank account.
                    </strong>{" "}
                    You should be aware that many banks are uncomfortable
                    sending cryptocurrency-related wire transfers.
                  </li>
                  <li>
                    CatchOTC can only process wire transfers on{" "}
                    <strong>weekdays</strong>.
                  </li>
                  <li>
                    <strong>USD Wire transfers</strong> can take up to one
                    weekday to process. In rare cases it may take longer. This
                    does not include transfer time; it can take up to a day to
                    send a wire transfer to some countries, plus however much
                    time intermediary banks spend processing it.
                  </li>
                  <li>
                    <strong>Foreign currency (non-USD) transfers</strong>{" "}
                    potentially take longer to process.
                  </li>
                  <li>
                    We can only send to or receive funds from{" "}
                    <strong>
                      the bank accounts in the exact name that is contracted with CatchOTC.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      You must include the correct memo in your wire transfers
                      or they will not be credited.
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Note that all fiat transfers are handled through a third
                      party OTC desk.
                    </strong>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NotesModal;
