import React, { useContext } from "react";
import AdminUser from "./AdminUser";

const Index = () => {
  return (
    <>
      <div
        className="tab-pane active"
        id="admin-user"
        role="tabpanel"
        aria-labelledby="admin-user-tab"
      >
        <AdminUser></AdminUser>
      </div>
    </>
  );
};
export default Index;
