import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenCompany,
    setIsOpenCompany,
    setIsOpengrp,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        isOpen={modalIsOpenCompany}
        onHide={() => setIsOpenCompany(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={modalIsOpenCompany}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div
              className="modal fade custom_modal"
              id="admin_transfer_add"
              role="dialog"
            >
              <div className="modal-dialog modal-md">
                <div className="modal-content">
                  <div className="modal-body"></div>
                </div>
              </div>
            </div>
            ;
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpenCompany(false);
              onClose?.();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
