import React from "react";
import Table from "./Table";

const Index = () => {
  return (
    <>
      <Table></Table>
    </>
  );
};
export default Index;
