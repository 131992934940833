const awsexports = {
        aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION || "eu-west-1",
        aws_cognito_identity_pool_id:
          process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID ||
          "eu-west-1:6d78bc82-f2dd-4824-8ef1-18da6bf4c112",
        // aws_cognito_identity_pool_id:
        //   "eu-west-1:6d78bc82-f2dd-4824-8ef1-18da6bf4c112",
        aws_mandatory_sign_in:
          process.env.REACT_APP_AWS_MANDATORY_SIGN_IN || "enable",
      
        // Test
        // aws_user_pools_id: "eu-west-1_Dvj4E9aPS",
      
        // Staging
        aws_user_pools_id:
          process.env.REACT_APP_AWS_USER_POOLS_ID || "eu-west-1_MbW00gdGd",
        aws_user_pools_web_client_id:
          process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ||
          "226qf7dvep0hi6p97h8tl90bk6",
      
        // Production
        // "aws_user_pools_id": "eu-west-1_RwQEnTsLq",
        // "aws_user_pools_web_client_id": "4802oleisnjtmjb37o8p0iq5it",
      };
      
      export default awsexports;
      