import React, { createContext, useState, useEffect } from "react";

export const GlobalContext = createContext<any>({});

const GlobalContextProvider = (props: any) => {
  const [expireQuate, SetexpireQuate] = useState([]) as any;
  return (
    <GlobalContext.Provider value={{ expireQuate, SetexpireQuate }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
