import React, { useState } from 'react';
import cn from 'classnames';

import styles from './Table.module.scss';
import FrankieOne from '../../Utils/FrankieOne/FrankieOne';
import KybPopUp from '../../Utils/KybPopUp/KybPopUp';
import QRCodeEnable from '../QRCodeEnable';
import { useAuth } from '../../../auth-context';
import {
  BsCircleFill,
  BsCheck2All,
  BsCheckCircleFill,
  BsExclamationCircleFill,
} from 'react-icons/bs';
import { HiIdentification } from 'react-icons/hi';
import { RiKey2Fill } from 'react-icons/ri';

const Index = () => {
  const { userInfo, userIsLoading, userIsFetching, putUserInfoWithEntityId } =
    useAuth();

  const [isKybActive, setIsKybActive] = useState(false);

  return (
    <>
      <div className="white-shadowbox">
        <div className="padding20">
          <div className="row">
            <aside className="col-md-12 profile-informationarea">
              {/* hi
              <pre>{JSON.stringify(userInfo, undefined, 2)}</pre> */}
              <h3 className="section-subheading">Profile Information</h3>
              {userIsFetching || userIsLoading ? (
                <>loading..</>
              ) : (
                <>
                  <p>
                    <span className="profile-label">User ID:</span>
                    {userInfo?.username}
                  </p>
                  <p>
                    <span className="profile-label">Email:</span>
                    {userInfo?.email || 'undefined'}
                  </p>
                  <p>
                    <span className="profile-label">Name:</span>
                    {userInfo?.name || 'undefined'}
                  </p>
                  <p>
                    <span className="profile-label">Status:</span>
                    <span className={userInfo?.enable ? 'active' : 'inactive'}>
                      <BsCircleFill />{' '}
                      {userInfo?.enable ? 'Active' : 'Inactive'}
                    </span>
                  </p>

                  {/* <Loader visible={listIsLoading}></Loader> */}
                  {/* {!userInfo?.completeKYC && (
                    <p style={{ color: "#ff0000", paddingTop: "10px" }}>
                      {" "}
                      Note: Verification must be completed before trading with
                      us.
                    </p>
                  )} */}
                </>
              )}
            </aside>

            {/* <aside className="col-md-5 border-left2">
              <div className="padding15">
                <h3 className="section-subheading">Change Password</h3>
                <form className="main-form">
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      value={password}
                      onChange={(e: any) => setpassword(e.target.value)}
                      type="password"
                      placeholder="Enter New Password"
                      className="form-control"
                    />
                    <p className="error">{passwordErr}</p>
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      onChange={(e: any) => setCpassword(e.target.value)}
                      type="password"
                      placeholder="Re-Enter Password"
                      className="form-control"
                    />
                    <p className="error">{CpasswordErr}</p>
                  </div>
                  <div className="text-center margintop40">
                    <a
                      onClick={chanePassword}
                      className="main-color-btn main-color-bigbtn"
                    >
                      Change Password
                    </a>
                  </div>
                </form>
              </div>
            </aside> */}
          </div>
        </div>
      </div>
      <div className="white-shadowbox">
        <div className="padding20">
          <div className="row">
            <aside className="col-md-12 profile-informationarea">
              {/* hi <pre>{JSON.stringify(userInfo, undefined, 2)}</pre> */}
              <h3 className="section-subheading">Security Settings</h3>
              {userIsFetching || userIsLoading ? (
                <>loading..</>
              ) : (
                <>
                  <p className="identification-verification">
                    <span className="profile-label">
                      <HiIdentification />
                      &nbsp;Identity Verification*:
                    </span>
                    {!!userInfo?.completeKYC ? (
                      <span className="pending-area-box">
                        <big>
                          <BsCheck2All />
                          &nbsp;&nbsp;Verified
                        </big>
                      </span>
                    ) : (
                      (() => {
                        switch (userInfo?.basisStatus) {
                          case 'new':
                            return (
                              <span>
                                <FrankieOne
                                  userId={userInfo?.username}
                                  putUserInfoWithEntityId={
                                    putUserInfoWithEntityId
                                  }
                                  kycBtnText={'Complete the Verification'}
                                />
                                <br />
                                <span className="red_warn">
                                  You are halfway there, please complete the
                                  verification process to start trading.
                                </span>
                              </span>
                            );
                          case 'request_data':
                            return (
                              <span>
                                <big>
                                  <BsExclamationCircleFill />
                                  &nbsp;&nbsp;Requesting Document
                                </big>
                                <br />
                                <small className="indented">
                                  <i>
                                    Please check your email to upload the
                                    requested document.
                                  </i>
                                </small>
                              </span>
                            );
                          case 'confirmed':
                            return (
                              <span>
                                <big>
                                  <BsCheckCircleFill />
                                  &nbsp;&nbsp;Processing
                                </big>
                                <br />
                                <small className="indented">
                                  <i>
                                    Request is received. Identification is under
                                    review.
                                  </i>
                                </small>
                              </span>
                            );
                          case 'pending':
                            return (
                              <span>
                                <big>
                                  <BsCheckCircleFill />
                                  &nbsp;&nbsp;Processing
                                </big>
                                <br />
                                <small className="indented">
                                  <i>
                                    Request is received. Identification is under
                                    review.
                                  </i>
                                </small>
                              </span>
                            );
                          case 'decline':
                            return (
                              <span>
                                <big className="red_warn">
                                  <BsExclamationCircleFill />
                                  &nbsp;&nbsp;Identitiy verification failed
                                </big>
                                <br />
                                <small className="indented">
                                  <i>
                                    Please contact CatchOTC
                                    Support(support@catchotc.com) or your
                                    account manager
                                  </i>
                                </small>
                              </span>
                            );
                          default: //done
                            return (
                              <span className={cn(styles.Verification)}>
                                <FrankieOne
                                  userId={userInfo?.username}
                                  putUserInfoWithEntityId={
                                    putUserInfoWithEntityId
                                  }
                                  kycBtnText={'Verify yourself'}
                                />
                                <button
                                  className={cn(
                                    'main-color-btn btn',
                                    styles.Btn
                                  )}
                                  onClick={() => setIsKybActive(true)}
                                >
                                  Verify your business
                                </button>
                                {
                                  <KybPopUp
                                    isKybActive={isKybActive}
                                    onClose={() => setIsKybActive(false)}
                                  />
                                }
                                <span className="red_warn">
                                  <small className="indented">
                                    <i>
                                      {' '}
                                      Note: Verification must be completed
                                      before trading with us.
                                    </i>
                                  </small>
                                </span>
                              </span>
                            );
                        }
                      })()
                    )}
                  </p>
                  <p className="google-authenticator">
                    <span className="profile-label">
                      <RiKey2Fill />
                      &nbsp;Google Authenticator:
                    </span>
                    <QRCodeEnable></QRCodeEnable>
                  </p>
                </>
              )}
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
