import React, { useContext, useEffect, useRef, useState } from 'react';
import Loader from '../../Loader';
import { config } from '../../../config/config';
import ContextWrapper, { Context } from '../../Utils/ContextWrapper';
import { BalanceContext } from '../BalanceContextWrapper';
import useBalance, { BalanceAccount } from '../useBalance';
import { CSVLink } from 'react-csv';
import DepositRequestModal from '../Modals/DepositRequestModal';
import DepositRequestCryptoModal from '../Modals/DepositRequestCryptoModal';
import WithdrawRequestCryptoModal from '../Modals/WithdrawRequestCryptoModal';
import WithdrawRequestModal from '../Modals/WithdrawRequestModal';
import { formatNumber } from '../../Utils';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../../../auth-context';
import { tokenService } from './../../../_services/tokens.services';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { BsFillInfoCircleFill } from 'react-icons/bs';

// Specific to this page
const QUERY_INDEX = 'CurrencyBalance';
const URL = `${config.apiurl}/userInfo/balance?house=false`;
// const { export_data } = useExportData();
// End of specific to this page

const Index = () => {
  const [fetchParams, setFetchParams] = useState<any>({});
  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const {
    csvData,
    balance_export_pdf,
    openModaldepoc,
    openModalwith,
    CurrencyBal,
  } = useContext(BalanceContext);
  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);

  useEffect(() => {
    if (listData) {
      setCurrentPage(listData);
      setInitialized(true);
    }
  }, [listData]);
  const csvRef = useRef() as any;
  const [accountMap, setAccountMap] = useState<{
    [key: string]: BalanceAccount;
  }>({});
  const { accountInfo } = useBalance();

  const balance_export = () => {
    csvRef.current.link.click();
  };

  useEffect(() => {
    if (accountInfo) {
      const account_ = accountInfo.reduce<{ [coin: string]: BalanceAccount }>(
        (accumulator, account) => {
          accumulator[account.Coin] = account;
          return accumulator;
        },
        {}
      );
      setAccountMap(account_);
    }
  }, [accountInfo]);

  return (
    <>
      {/* <pre>
      {JSON.stringify(listData, undefined, 2)}
    </pre> */}
      <div className="wallet_">
        <div>
          <div>
            <div>
              <div className="">
                <div className="border_box" style={{}}>
                  <div className="filters-area">
                    <div>
                      <span>Select Coin:</span>
                      <div className="select-outer">
                        <CSVLink
                          ref={csvRef}
                          filename="CatchOTC_balance.csv"
                          data={csvData}
                          className="yellow_btn"
                          id="exp_data"
                          style={{ marginRight: '10px', display: 'none' }}
                          onClick={() => console.log('clicked')}
                        >
                          Export
                        </CSVLink>
                        <select
                          className="custom-select"
                          style={{ width: '150px' }}
                          onChange={(data) => {
                            if (data.target.value) {
                              setSelectedAccount(data.target.value);
                            } else {
                              setSelectedAccount(null);
                            }
                          }}
                        >
                          <option>All</option>
                          {accountInfo.map((account, index: any) => {
                            return (
                              <>
                                {account.Coin !== 'HKD' ? (
                                  <option key={index}>{account.Coin}</option>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="filter-btnsarea">
                      <a
                        onClick={balance_export}
                        href="#"
                        className="main-small-btn main-small-black-btn"
                      >
                        <i className="fa fa-file-text-o" aria-hidden="true"></i>
                        Export CSV
                      </a>
                      <a
                        onClick={balance_export_pdf}
                        href="#"
                        className="main-small-btn"
                      >
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                        Export PDF
                      </a>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <Loader visible={listIsLoading}></Loader>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="main-table balance_table"
                    >
                      <thead>
                        <tr>
                          <th>Coin</th>
                          <th>
                            <OverlayTrigger
                              key="balance_total"
                              placement="right"
                              overlay={
                                <Tooltip id="balance_total" className="tooltip">
                                  The total sum of the available amount plus the
                                  locked amount.
                                </Tooltip>
                              }
                            >
                              <span>Total &nbsp; &nbsp;</span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <OverlayTrigger
                              key="balance_available"
                              placement="right"
                              overlay={
                                <Tooltip
                                  id={`balance_available`}
                                  className="tooltip"
                                >
                                  The amount that is available to spend or
                                  withdraw.
                                </Tooltip>
                              }
                            >
                              <span>
                                Available <BsFillInfoCircleFill />
                                &nbsp; &nbsp;
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th>
                            <OverlayTrigger
                              key="balance_Locked"
                              placement="right"
                              overlay={
                                <Tooltip
                                  id={`balance_Locked`}
                                  className="tooltip"
                                >
                                  Ｗhen you request for a transfer, it will lock
                                  or reserve the funds that need to fill that
                                  request.
                                </Tooltip>
                              }
                            >
                              <span>
                                Locked <BsFillInfoCircleFill />
                                &nbsp; &nbsp;
                              </span>
                            </OverlayTrigger>
                          </th>
                          <th className="last-actionbox">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!initialized && listIsLoading ? (
                          <></>
                        ) : (
                          <>
                            {currentPage?.length === 0 && (
                              <tr>
                                <td style={{ color: '#ffcb17' }} colSpan={9}>
                                  No rows found. Try clearing your filters.
                                </td>
                              </tr>
                            )}
                            {currentPage
                              ?.filter((data: any) => {
                                return data?.asset?.id !== 'EUR';
                              })
                              .map((data: any, index: any) => {
                                if (
                                  selectedAccount !== 'All' &&
                                  selectedAccount !== null
                                ) {
                                  if (data.asset.id === selectedAccount) {
                                    return (
                                      <BalanceRow
                                        key={data.id}
                                        data={data}
                                      ></BalanceRow>
                                    );
                                  } else {
                                    return <></>;
                                  }
                                } else {
                                  return (
                                    <>
                                      <BalanceRow
                                        key={index}
                                        data={data}
                                      ></BalanceRow>
                                    </>
                                  );
                                }
                              })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const BalanceRow = ({ data }: any) => {
  const { asset, total, free, locked } = data;
  const [showSentCode, setShowSentCode] = useState(false);
  const [paymenturl, setpaymentUrl] = useState('') as any;
  const { get_deposite_address } = useBalance();
  const { userInfo } = useAuth();
  const kycStatus: boolean = userInfo?.completeKYC;
  const handleCloseSent = () => {
    setShowSentCode(false);
  };
  let currencyType: any = asset.currencyType;
  let imageSrc = '';
  try {
    imageSrc = require('../../../images/' + asset.name + '.png').default;
  } catch (e) {
    imageSrc = require('../../../images/dollar-icon.png').default;
    console.log(e);
  }
  // if(asset.name!="HKD"){
  const mutation: any = useMutation(
    ({ reason }: any) => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `https://pay-stage.catchotc.com`;
      // let params = {
      //   "currencyCode":"BTC",
      //   "walletAddress":"2NF9PrsCsTJcKU59wgJGaib7iFrpA87iohR",
      //   "email":"",
      //   "redirectURL":"http://localhost:3000/balance"
      // }
      let formData = new FormData();
      formData.append('currencyCode', 'BTC');
      formData.append('walletAddress', '2NF9PrsCsTJcKU59wgJGaib7iFrpA87iohR');
      formData.append('email', '');
      formData.append('redirectURL', 'https://catchotc.com/balance');
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        //   setAmount(null);
        //   setSuccessObj({ message: "Spread request successful" });
        //  setcurrencypairval([])
        //   queryClient.invalidateQueries(["AdminUser", fetchParams]);
        //queryClient.invalidateQueries(["inventoryMetalList"]);
        // handleClose();
      },
      onError: (error: any, variables, context) => {
        // setAmount(null);
        // const errorMessage = error?.response?.data?.message;
        // setErrorObj({ message: errorMessage });
        // handleClose();
      },
    }
  );
  const openlink = (currencycode: any, id: any) => {
    const list: any[] = userInfo?.addressList;
    const address_ = list?.find((address) => {
      return address.asset.id === id && address.type === 'deposit';
    });
    let email = userInfo.email;
    let urlredirect = `${config.appurl}/balance`;
    let walletadddress = '';
    if (address_) {
      walletadddress = address_.address;
    }
    setpaymentUrl(
      `${config.payment_url}?currencyCode=` +
        currencycode +
        '&walletAddress=' +
        walletadddress +
        '&email=' +
        email +
        '&redirectURL=' +
        urlredirect
    );
    setShowSentCode(true);
  };
  return (
    <>
      <Modal
        centered
        style={{
          transform: 'translate(0, 50%) !important',
        }}
        show={showSentCode}
        onHide={handleCloseSent}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>
          <div className="catch-frame-box">
            <iframe
              id="frame"
              src={paymenturl}
              height="750"
              width="100%"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>

      {asset.name !== 'HKD' ? (
        <tr>
          <td>
            <img
              src={imageSrc}
              alt="mm"
              height={25}
              width={25}
              style={{ verticalAlign: 'middle' }}
            />
            {asset.name}
          </td>
          <td>{formatNumber(total)}</td>
          <td>{formatNumber(free)}</td>
          <td>{formatNumber(locked)}</td>
          <td>
            {/* <pre>

          {JSON.stringify(data.asset, undefined, 2)}
          </pre> */}
            {currencyType === 'Crypto' ? (
              <>
                <DepositRequestCryptoModal data={data} status={kycStatus} />
                <WithdrawRequestCryptoModal data={data} available={free} />
                {asset.name !== 'USDC' && (
                  <button
                    type="button"
                    className="btn-full-light btn-large"
                    onClick={() => openlink(asset.name, asset.id)}
                  >
                    Pay by Credit Card
                  </button>
                )}
              </>
            ) : (
              <>
                <DepositRequestModal data={data} status={kycStatus} />
                {!!Object.keys(userInfo).length && (
                  <WithdrawRequestModal data={data} available={free} />
                )}
              </>
            )}
          </td>
        </tr>
      ) : (
        <tr></tr>
      )}
    </>
  );
};
