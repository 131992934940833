import React from "react";
import useMarket from "./useMarket";
type Props = {};
export const MarketContext = React.createContext<any>({});
const MarketContextWrapper: React.FC<Props> = ({ children }) => {
  const {
    productRelationshipMap,
    productList,
    loader,
    quateTemp,
    ExpTemp,
    TradeTemp,
    warningModal,
    setWarningModal,
    errorMessage,
    askStyle,
    setSide,
    currencyErr,
    baseCurrency,
    ProdOption,
    ProdOption1,
    getQuate,
    QuateErr,
    QuateData,
    expireQuate,
    counter,
    quoteCurrencySize,
    baseCurrencySize,
    take_quate,
    cancel_quate,
    setquoteCurrencySizeFn,
    setquoteCurrencyFn,
    setbaseCurrencyFn,
    setbaseCurrencySizeFn,
    TradeQuate,
    side,
    modalIsOpencong,
    setIsOpencong,
    modalIsOpenCancel,
    cancelStatus,
    cancelQuate,
    setModalIsOpenCancel,
    currencyBalance
  } = useMarket();
  return (
      <MarketContext.Provider
        value={{
          productRelationshipMap,
          TradeQuate,
          expireQuate,
          QuateData,
          productList,
          loader,
          quateTemp,
          ExpTemp,
          TradeTemp,
          warningModal,
          setWarningModal,
          errorMessage,
          askStyle,
          setSide,
          currencyErr,
          baseCurrency,
          ProdOption,
          ProdOption1,
          getQuate,
          QuateErr,
          counter,
          quoteCurrencySize,
          baseCurrencySize,
          take_quate,
          cancel_quate,
          setquoteCurrencySizeFn,
          setquoteCurrencyFn,
          setbaseCurrencyFn,
          setbaseCurrencySizeFn,
          side,
          modalIsOpencong,
          setIsOpencong,
          modalIsOpenCancel,
          setModalIsOpenCancel,
          cancelStatus,
          cancelQuate,
          currencyBalance
        }}
      >
        {children}
      </MarketContext.Provider>
  );
};
export default MarketContextWrapper;
