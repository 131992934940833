import { useContext, useEffect, useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { config } from '../../../../config/config';
import { formatNumber } from '../../../Utils';
import ContextWrapper, { Context } from '../../../Utils/ContextWrapper';
import Loader from '../../../Loader';
import {
  BalanceModalType,
  UserDetailDataInterface,
} from '../../financeDataType';

// Specific to this page
const QUERY_INDEX = 'BalanceUser';
// End of specific to this page

function BalanceModal({ data }: { data: UserDetailDataInterface }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fetchParams, setFetchParams] = useState({});
  const URL = `${config.apiurl}/userInfo/balance?target=${data.username}`;

  return (
    <>
      <Button className="main-small-btn mt-0" onClick={handleShow}>
        Balance
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="modal-title" id="exampleModalLabel">
              Balance
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContextWrapper
            fetchParams={fetchParams}
            setFetchParams={setFetchParams}
            query_index={`${QUERY_INDEX}-${data.username}`}
            url={URL}
          >
            <Table_></Table_>
          </ContextWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button className={'main-color-btn full-btn'} onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BalanceModal;

const Table_ = () => {
  const { listIsLoading, listData } = useContext(Context);
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (listData) {
      setCurrentPage(listData);
      setInitialized(true);
    }
  }, [listData]);

  return (
    <div>
      {listIsLoading ? (
        <Loader visible={listIsLoading} />
      ) : (
        <div className="table-responsive">
          {initialized && currentPage?.length === 0 && <p>No balance found</p>}
          <Table striped bordered>
            <thead>
              <tr>
                <th>Currency</th>
                <th>Available</th>
                <th>Lock</th>
              </tr>
            </thead>
            <tbody>
              {currentPage?.map((data: BalanceModalType, index: number) => {
                return (
                  <tr key={index}>
                    <td>{data?.asset?.name}</td>
                    <td>{formatNumber(data.free)}</td>
                    <td>{formatNumber(data.locked)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};
