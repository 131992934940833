import React, { useContext } from "react";
import { HistoryContext } from "./HistoryContextWrapper";
import {
  NavLink,
  useRouteMatch,
  useLocation
} from "react-router-dom";

const NavTabs = () => {
  const { HistoryList, Tradlist, Transferlist, getTradeList } = useContext(
    HistoryContext
  );
  let { url } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* <li className="nav-item">
          <NavLink
            className="nav-link"
            id="history-all-tab"
            isActive={() => ['/history'].includes(pathname)}
            to={`${url}`}
          >
            All
          </NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink
            className="nav-link"
            id="history-activity-tab"
            isActive={() => ['/history'].includes(pathname)}
            to={`${url}`}
          >
            Transfer
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            onClick={() => {
              getTradeList();
            }}
            className="nav-link"
            id="history-trade-tab"
            isActive={() => ['/history/trade'].includes(pathname)}
            to={`${url}/trade`}
          >
            Trade
          </NavLink>
        </li>
      </ul>
    </>
  );
};
export default NavTabs;
