import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    modalIsOpenBal,
    closeModalBal,
    BalanceCurr,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
    setIsOpenBal,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        onHide={() => {
          setIsOpenBal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={modalIsOpenBal}
        size={"lg"}
      >
        <Modal.Body>
          <div>
            <div className="balance_box_sec modal_parent">
              <div className="balance_box">
                <div className="table-responsive custom_table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Currency</th>
                        <th>Available</th>
                        <th>Lock</th>
                      </tr>
                    </thead>
                    <tbody>{BalanceCurr}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              closeModalBal();
              setIsOpenBal(false);
              onClose?.();
            }}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
