import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MarketContext } from "./MarketContextWrapper";

const CancelModal = () => {
  const { modalIsOpenCancel, setModalIsOpenCancel, cancelStatus } = useContext(MarketContext);
  const [show, setShow] = useState(modalIsOpenCancel);
  function closeModalCancel() {
    setModalIsOpenCancel(false);
  }
  useEffect(() => {
    if(cancelStatus){
      setShow(modalIsOpenCancel);
    }
  }, [modalIsOpenCancel]);

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="warning_modal"
        show={show}
        onHide={closeModalCancel}
      >
        <Modal.Body>
          <div className="modal-content-center">
            <img
              src={require("../../images/green_check.png").default}
              alt="green"
            />
            <h3>Cancellation</h3>
            <p>The quote has been cancelled.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalCancel}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CancelModal;
