import React from "react";

export const searchParamsToURL = (params: any) => {
  const url = Object.keys(params).reduce((accumulator, param, index) => {
    if (params[param] !== "") {
      if (accumulator === "") {
        accumulator += `?${param}=${params[param]}`;
      } else {
        accumulator += `&${param}=${params[param]}`;
      }
    }
    return accumulator;
  }, "");
  return url;
};

export const formatNumber = (num: any, digits?: number): any => {
  if(!digits){
    return Number(num).toLocaleString('en', {minimumFractionDigits: 8})
  }else{
    return Number(num).toLocaleString('en', {minimumFractionDigits: digits})
  }
};

export const configheaders = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  withCredentials: true,
  crossDomain: true,
};

export const objectToFormUrlEncoded = (details: any) => {
  var formBody: any = [];
  Object.entries(details).map(([k, v]: any) => {
    var encodedKey = encodeURIComponent(k);
    var encodedValue = encodeURIComponent(v);
    formBody.push(encodedKey + "=" + encodedValue);
  });
  formBody = formBody.join("&");
  return formBody;
};

export const ErrorMessage = ({ path, error }: any) => {
  return (
    <div style={{ color: "red", marginTop: 5 }}>
      {error?.path === path && (
        <>
          {error?.errors.map((error: any) => {
            return <>{capitalize(error)}</>;
          })}
        </>
      )}
    </div>
  );
};

export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const splitCamelCase = (s: string) => {
  const upperCaseString = s.charAt(0).toUpperCase() + s.slice(1);
  const newString = upperCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
  return newString;
}

function CommaFormatted(amount:any) {
	var delimiter = ","; // replace comma if desired
	var a = amount.split('.',2)
	var d = a[1];
	var i = parseInt(a[0]);
	if(isNaN(i)) { return ''; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	var n = new String(i);
	var a:any = [];
	while(n.length > 3) {
		var nn = n.substr(n.length-3);
		a.unshift(nn);
		n = n.substr(0,n.length-3);
	}
	if(n.length > 0) { a.unshift(n); }
	n = a.join(delimiter);
	if(d.length < 1) { amount = n; }
	else { amount = n + '.' + d; }
	amount = minus + amount;
	return amount;
}