import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { capitalize, ErrorMessage } from '../../../Utils';
import { Context } from '../../../Utils/ContextWrapper';
import { AuthContext } from '../../../../contexts/AuthContext';
import { tokenService } from '../../../../_services/tokens.services';
import { config } from '../../../../config/config';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { MdLibraryAdd } from 'react-icons/md';
import * as yup from 'yup';

const AddWithdrawlFeeModal = ({
  currencyList,
  type,
  fetchTransferFeeHelper,
}: any) => {
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const handleClose = () => {
    setShow(false);
    setError('');
    setSetting({
      currency: '',
      lower: '',
      upper: '',
      value: '',
    });
  };
  const handleShow = () => setShow(true);
  const [error, setError] = useState<any>();
  const [setting, setSetting] = useState({
    currency: '',
    lower: '',
    upper: '',
    value: '',
  });
  const { configheadersTemplate } = tokenService;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);
  const [allCurrencyFee, setAllCurrencyFee] = useState<string[] | undefined>();
  let schema = yup.object().shape({
    lower: yup.number().required().positive(),
    upper: yup.number().required().positive().min(Number(setting.lower)),
    value: yup.number().required().positive(),
  });

  const addFeeMutation: any = useMutation(
    () => {
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/transfer/fee/${type}/default/asset/${setting.currency}`;
      let formData = new FormData();
      allCurrencyFee?.forEach(element => {
        Object.entries(element)
          .filter(key => !key.includes('asset'))
          .forEach(([key, value]) => formData.append(key, value));
      });
      return axios.post(url, formData, configheaders);
    },
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries(['TransactionFee', fetchParams]);
        await fetchTransferFeeHelper(type);
        setToastObj({
          message: `${capitalize(type)} Fee Added`,
          type: 'success'
        });
        handleClose();
      },
      onError: (data, variables, context) => {
        queryClient.invalidateQueries(['TransactionFee', fetchParams]);
        setToastObj({
          message: `Failed to add ${type} Fee`,
          type: 'error'
      });
        handleClose();
      },
    }
  );
  function handleAddBtn() {
    handleShow();
    setAdd(false);
    setSetting({
      currency: currencyList[0].id,
      lower: '',
      upper: '',
      value: '',
    });
  }
  function handleSaveChange(event: any) {
    const { name, value } = event.target;
    setSetting({
      ...setting,
      [name]: value,
    });
    setAdd(true);
  }
  function handleSubmitChange() {
    const { lower, upper, value } = setting;
    schema
      .validate({ lower, upper, value })
      .then(function (valid: any) {
        if (valid) {
          setError(null);
          handleSubmit();
        } else {
          alert('fail');
        }
      })
      .catch(function (err: any) {
        setError(err);
      });
  }
  async function handleSubmit() {
    const asset = setting.currency;
    const currencyFeeAll = await getCurrencyTransactionFee(type, asset);
    const newCurrencyFeeAll = [...currencyFeeAll, setting];
    setAllCurrencyFee(newCurrencyFeeAll);
    addFeeMutation.mutate({ allCurrencyFee });
  }
  const getCurrencyTransactionFee = async (type: string, asset: string) => {
    let configheaders = configheadersTemplate();
    let url = `${config.apiurl}/transfer/fee/${type}/default/asset/${asset}`;
    const { data } = await axios.get(url, configheaders);
    return data;
  };
  return (
    <>
      <OverlayTrigger
        key={`add-new-${type}-fee`}
        placement='left'
        overlay={
          <Tooltip id={`add-new-${type}-fee`}>Add new {type} fee.</Tooltip>
        }
      >
        <button className='row-ele-center btn-icon setting_btn_icon' onClick={handleAddBtn}>
          <MdLibraryAdd size={30} />
        </button>
      </OverlayTrigger>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h3 className='modal-title'>Add {capitalize(type)} Fee</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3' controlId='formAddFee'>
              <Form.Label>
                <strong>Currency</strong>
              </Form.Label>
              <Form.Control
                required
                as='select'
                name='currency'
                onChange={handleSaveChange}
              >
                {currencyList?.map((currency: any) => {
                  return (
                    <option value={currency.name} key={currency.id}>
                      {currency.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className='mb-3' controlId='formAddFee'>
              <Form.Label>
                <strong>Minimum Amount</strong>
              </Form.Label>
              <Form.Control
                required
                type='number'
                name='lower'
                placeholder='Please enter minimum amount'
                onChange={handleSaveChange}
              />
            </Form.Group>
            <ErrorMessage path={'lower'} error={error}></ErrorMessage>
            <Form.Group className='mb-3' controlId='formAddFee'>
              <Form.Label>
                <strong>Maximum Amount</strong>
              </Form.Label>
              <Form.Control
                required
                type='number'
                name='upper'
                placeholder='Please enter maximum amount'
                onChange={handleSaveChange}
              />
            </Form.Group>
            <ErrorMessage path={'upper'} error={error}></ErrorMessage>
            <Form.Group className='mb-3' controlId='formAddFee'>
              <Form.Label>
                <strong>{capitalize(type)} Fee</strong>
              </Form.Label>
              <Form.Control
                required
                type='number'
                name='value'
                placeholder='Please enter transaction fee'
                onChange={handleSaveChange}
              />
            </Form.Group>
            <ErrorMessage path={'value'} error={error}></ErrorMessage>
          </Form>
          <hr />
          <div className='btn-container'>
            <Button
              type='button'
              className='btn-full-dark'
              onClick={handleClose}
            >
              Cancel
            </Button>
            {!add && (
              <Button
                type='button'
                className='btn-full-light'
                onClick={handleSaveChange}
              >
                Confirm
              </Button>
            )}
            {add && (
              <Button
                type='button'
                className='btn-full-light'
                onClick={handleSubmitChange}
              >
                {addFeeMutation.isLoading ? <>Submitting...</> : <> Submit</>}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddWithdrawlFeeModal;
