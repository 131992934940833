import { useContext, useState } from 'react';
import { Button, Form, FormControl, FormLabel, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import {  useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/AuthContext';
import { Context } from '../../../Utils/ContextWrapper';
import { tokenService } from './../../../../_services/tokens.services';
import { config } from './../../../../config/config';
import { ErrorMessage } from '../../../Utils';
import { TransferDetail } from '../../../Utils/TransferDetail';

function DenyModal({ data }: { data: any }) {
  const {
    type, cryptoAsset, id 
  } = data;
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [reason, setReason] = useState<any>();
  const [purpose, setPurpose] = useState<any>();
  const [error, setError] = useState<any>();
  const { setToastObj } = useContext(AuthContext);
  const isWithdrawal: boolean = type === 'withdraw';
  const fiatDeposit: boolean = !isWithdrawal && !cryptoAsset;
  let schema = yup.object().shape({
    reason: yup.string().required(),
  });
  const mutation: any = useMutation(
    ({ reason }: any) => {
      const { configheadersTemplate } = tokenService;
      const configheaders = configheadersTemplate();
      if(purpose !== 'Reject Finance'){
        let url = `${config.apiurl}/transfer/deny/${id}`;
        let formData = new FormData();
        formData.append('reason', reason);
        return axios.put(url, formData, configheaders);
      }else{
        let url = `${config.apiurl}/transfer/admin/return/finance/${id}`;
        let formData = new FormData();
        formData.append('reason', reason);
        return axios.post(url, formData, configheaders);
      }
    },
    {
      onSuccess: (data, variables, context) => {
        setReason(null);
        setPurpose(null);
        setToastObj({
          message: 'You have successfully denied the request.',
          type: 'success'
        });
        queryClient.invalidateQueries(['AdminTransfer', fetchParams]);
        handleClose();
      },
      onError: (error: any, variables, context) => {
        setReason(null);
        setPurpose(null);
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message:  errorMessage || 'Deny transfer request failed',
          type: 'error'
        });
        handleClose();
      },
    }
  );
  function handleSubmit (){
    if(purpose !== 'Reject Finance'){
      schema
      .validate({ reason })
      .then(function (valid: any) {
        if (valid) {
          setError(null);
          mutation.mutate({
            reason
          });
        } else {
          alert('fail');
        }
        // valid; // => true
      })
      .catch(function (err: any) {
        setError(err);
      });
    }else{
      try {
        setError(null);
        mutation.mutate({
          reason
        });
      } catch (error) {
        setError(error); 
      }
    }
  }
  return (
    <>
      <Button
        className='btn-alert-border row-ele-center'
        onClick={handleShow}
      >
        <strong>Deny</strong>
      </Button>
      <Modal
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <h5 className='modal-title' id='exampleModalLabel'>
              Deny transfer
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <pre>{JSON.stringify(data, undefined, 2)}</pre> */}
          <TransferDetail data={data} role='admin'/>
          <div className='withdrawal-formarea'>
            {
              fiatDeposit && (
                <Form>
                  <Form.Group
                    className='mb-2'
                    controlId='formDenyPurpose'
                  >
                    <FormLabel> <strong>Deny Purpose : </strong></FormLabel>
                    <FormControl
                      as='select'
                      type='text'
                      className='yp-0'
                      value={purpose}
                      onChange={(event: any) => {
                        setPurpose(event.currentTarget.value);
                      }}
                    >
                      <option value='' disabled selected>Please select your option</option>
                      <option value='Reject User'>Reject User</option>
                      <option value='Reject Finance'>Reject Finance</option>
                    </FormControl>
                  </Form.Group>
                  {
                    purpose === 'Reject User' &&
                      <Form.Group
                        className='mb-2'
                        controlId='formRejectReason'
                      >
                        <FormLabel> <strong>Reject Reason : </strong></FormLabel>
                        <FormControl
                          type='text'
                          placeholder='Please enter if any'
                          value={reason}
                          onChange={(event: any) => {
                            setReason(event.currentTarget.value);
                          }}
                        />
                          <ErrorMessage path={'reason'} error={error}></ErrorMessage>
                      </Form.Group>
                    }
                  </Form>
                )
            }
            {
              !fiatDeposit && (
                <Form>
                  <Form.Group
                    className='mb-3'
                    controlId='formRejectReason'
                  >
                    <FormLabel> <strong>Reason : </strong></FormLabel>
                    <FormControl
                      type='text'
                      placeholder='Please enter if any'
                      value={reason}
                      onChange={(event: any) => {
                        setReason(event.currentTarget.value);
                      }}
                    />
                    <ErrorMessage path={'reason'} error={error}></ErrorMessage>
                  </Form.Group>
                </Form>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='btn-container'>
            <Button
              type='button'
              className='btn-full-dark'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className='btn-full-light'
              onClick={handleSubmit}
            >
              {mutation.isLoading ? <>Submitting...</> : <> Submit</>}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default DenyModal;