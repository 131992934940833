import React, { useContext, useState } from "react";
import HistoryActivity from "./HistoryActivity";
import { HistoryContext } from "./HistoryContextWrapper";

const NavTabs = () => {
  const {
    handleChangeTransactionType,
    handleChangasset,
    AssetList,
    activityAssets,
    getTransferActivity,
    export_datadepo,
    export_pdf,
  } = useContext(HistoryContext);
  return (
    <>
      <div
        className="tab-pane"
        id="history-activity"
        role="tabpanel"
        aria-labelledby="history-activity-tab"
      >
        <HistoryActivity></HistoryActivity>
      </div>
    </>
  );
};
export default NavTabs;
