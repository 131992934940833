import React from "react";
import useHistory, { HistoryUnit } from "./useHistory";
type Props = {};
export const HistoryContext = React.createContext<any>({
  HistoryList: null,
  Tradlist: [],
  Transferlist: [],
  getTradeList: () => {},
  startdate1: null,
  handleChangeall: null,
  enddate1: null,
  handleChange1all: null,
  handleChangelimit1: null,
  getTradeListAll: null,
  handlePageChangeall: null,
  handleChangeTransactionType: null,
  handleChange: null,
  handleChange1: null,
  handleChangelimit: null,
  handleChangassetA: null,
  handleChangasset: null,
  AssetList: null,
  activityAssets: null,
  getTransferActivity: null,
  export_datadepo: null,
  export_pdf: null,
  handleChangeT: null,
  handleChange1T: null,
  handleChangeside: null,
  handleChangelimitT: null,
  handleChangassetT: null,
  AssetOption: null,
  handlePageChangeT: null,
  myparams: null,
  limit: null,
  totaltrade: null,
  modalIsOpenSucc: null,
  closeModalSucc: null,
  customStyles: null,
  modalIsOpenDetailDeposit: null,
  setIsOpenDetailDeposit: null,
  depositDetail: null,
});
const HistoryContextWrapper: React.FC<Props> = ({ children }) => {
  const {
    Transferlist,
    HistoryList,
    Tradlist,
    getTradeList,
    startdate1,
    handleChangeall,
    enddate1,
    handleChange1all,
    handleChangelimit1,
    getTradeListAll,
    handlePageChangeall,
    handleChangeTransactionType,
    handleChange,
    handleChange1,
    handleChangelimit,
    handleChangassetA,
    handleChangasset,
    AssetList,
    activityAssets,
    getTransferActivity,
    export_datadepo,
    export_pdf,
    handleChangeT,
    handleChange1T,
    handleChangeside,
    handleChangelimitT,
    handleChangassetT,
    AssetOption,
    handlePageChangeT,
    myparams,
    limit,
    totaltrade,
    modalIsOpenSucc,
    closeModalSucc,
    customStyles,
    modalIsOpenDetailDeposit,
    setIsOpenDetailDeposit,
    depositDetail,

    myparams_transfer,
    myparams_history,
    limit1,
    totaltransfer,
  } = useHistory();
  return (
      <HistoryContext.Provider
        value={{
          myparams_history,
          limit1,
          totaltransfer,
          HistoryList,
          Tradlist,
          Transferlist,
          getTradeList,
          startdate1,
          handleChangeall,
          enddate1,
          handleChange1all,
          handleChangelimit1,
          getTradeListAll,
          handlePageChangeall,
          handleChangeTransactionType,
          handleChange,
          handleChange1,
          handleChangelimit,
          handleChangassetA,
          handleChangasset,
          AssetList,
          activityAssets,
          getTransferActivity,
          export_datadepo,
          export_pdf,
          handleChangeT,
          handleChange1T,
          handleChangeside,
          handleChangelimitT,
          handleChangassetT,
          AssetOption,
          handlePageChangeT,
          myparams,
          limit,
          totaltrade,
          modalIsOpenSucc,
          closeModalSucc,
          customStyles,
          modalIsOpenDetailDeposit,
          setIsOpenDetailDeposit,
          depositDetail,
          myparams_transfer,
        }}
      >
        {children}
      </HistoryContext.Provider>
  );
};
export default HistoryContextWrapper;
