import React, { useContext, useEffect, useRef, useState } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { createSecureContext } from 'tls';
import Loader, { FullWidthLoader, TableLoader } from '../Loader';
import BalanceContextWrapper, { BalanceContext } from './BalanceContextWrapper';
import BalanceTable from './BalanceTable';
import NotesModal from './NotesModal';
import useBalance, { BalanceAccount } from './useBalance';
import './index.css';

const Index = () => {
  const [openNoteModal, setOpenNoteModal] = useState<any>(false);
  return (
    <>
      <section className="inner-main-section">
        {/* <pre>{JSON.stringify(accountInfo, undefined, 2)}</pre> */}
        <div className="container">
          <h3 className="main-heading-title">Wallet</h3>
          <div className="white-shadowbox">
            <div className="padding20">
              <BalanceTable></BalanceTable>

              <div className="balance-descriptions">
                <p>
                  <b>Deposit your funds before trading.</b> The deposit and
                  withdrawal process works as follows:
                </p>
                <p>
                  <b>
                    <u>Deposit</u>
                  </b>
                </p>
                <p>For crypto</p>
                <ul>
                  <li>
                    1. Send the crypto asset(s) to the address provided in the
                    deposit button next to the desired asset.
                  </li>
                  <li>
                    2. We suggest you send a small test transfer when sending to
                    an address for the first time.
                  </li>
                </ul>
                <p>For fiat</p>
                <ul>
                  <li>
                    1. Wire money to the bank account provided in the deposit
                    button next to the selected currency.
                  </li>
                  <li>2. Fill in the amount of the funds being transferred.</li>
                  <li>
                    3. Include your account identifier number in your wire memo
                  </li>
                </ul>
                <p>
                  When we confirm the receipt of the funds (fiat or crypto), we
                  will credit funds to your account balance.
                  <br />
                  This will bring you into positive equity. We will endeavour to
                  process the request as soon as possible.
                  <br />
                </p>
                <p>
                  <b>
                    <u>Withdraw</u>
                  </b>
                </p>
                <p>For crypto</p>
                <ul>
                  <li>
                    1. input the amount and select the wallet address saved in
                    your profile. Only saved addresses can be selected for
                    withdrawals.
                  </li>
                </ul>
                <p>For fiat</p>
                <ul>
                  <li>
                    1. input the amount and select the bank account saved in
                    your profile. We will only transfer funds to the bank
                    account that is the same name as the account holder.
                  </li>
                </ul>

                <p>
                  Please read our{' '}
                  <a
                    onClick={event => {
                      event.preventDefault();
                      setOpenNoteModal(true);
                    }}
                    href="#"
                  >
                    notes
                  </a>{' '}
                  before any transactions. For any queries, please contact
                  CatchOTC Support (support@catchotc.com) or your account
                  manager.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotesModal
        show={openNoteModal}
        onClose={() => {
          setOpenNoteModal(false);
        }}
      ></NotesModal>
    </>
  );
};
const IndexWrapper = () => {
  return (
    <BalanceContextWrapper>
      <Index></Index>
    </BalanceContextWrapper>
  );
};
export default IndexWrapper;

// <tr>
//   <td>
//     <img src="images/usdt-icon.png" alt="" />
//     USDT
//   </td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>
//     <a
//       className="border-btn border-black-btn"
//       data-toggle="modal"
//       data-target="#deposit-request-popup"
//     >
//       Deposit
//     </a>
//     <a
//       className="border-btn"
//       data-toggle="modal"
//       data-target="#withdrawal-popup"
//     >
//       Withdraw
//     </a>
//   </td>
// </tr>
// <tr>
//   <td>
//     <img src="images/usd-icon.png" alt="" />
//     USD
//   </td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>
//     <a
//       className="border-btn border-black-btn"
//       data-toggle="modal"
//       data-target="#deposit-request-popup"
//     >
//       Deposit
//     </a>
//     <a
//       className="border-btn"
//       data-toggle="modal"
//       data-target="#withdrawal-popup"
//     >
//       Withdraw
//     </a>
//   </td>
// </tr>
// <tr>
//   <td>
//     <img src="images/eur-icon.png" alt="" />
//     EUR
//   </td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>
//     <a
//       className="border-btn border-black-btn"
//       data-toggle="modal"
//       data-target="#deposit-request-popup"
//     >
//       Deposit
//     </a>
//     <a
//       className="border-btn"
//       data-toggle="modal"
//       data-target="#withdrawal-popup"
//     >
//       Withdraw
//     </a>
//   </td>
// </tr>
// <tr>
//   <td>
//     <img src="images/hkd-icon.png" alt="" />
//     HKD
//   </td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>0.00000000</td>
//   <td>
//     <a
//       className="border-btn border-black-btn"
//       data-toggle="modal"
//       data-target="#deposit-request-popup"
//     >
//       Deposit
//     </a>
//     <a
//       className="border-btn"
//       data-toggle="modal"
//       data-target="#withdrawal-popup"
//     >
//       Withdraw
//     </a>
//   </td>
// </tr>
