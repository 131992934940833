import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AdminContext } from "./AdminContextWrapper";
import ReactHtmlParser from "html-react-parser";

const ModalExample = ({ show, onClose }: { onClose?: any; show?: boolean }) => {
  const {
    roles_html,
    modalIsOpenrole,
    closeModalrole,
    add_role,
    setIsOpengrp,
    setIsOpenCompany,
    setIsOpenconfirm,
    setIsOpenaddfee,
    setIsOpenrole,
    setIsOpentrans,
  } = useContext(AdminContext);
  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        isOpen={modalIsOpenrole}
        onHide={() => {
          setIsOpenrole(false);
        }}
        centered
        id="warning_modal"
        show={modalIsOpenrole}
        size={"lg"}
      >
        <Modal.Body>
          <div className="form_box modal_parent">
            <ul className="add_user_admin">{ReactHtmlParser(roles_html)}</ul>
          </div>
          <div></div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              add_role();
              setIsOpenrole(false);
              onClose?.();
            }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalExample;
