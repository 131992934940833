import { useContext } from "react";
import { Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Context } from "../../../Utils/ContextWrapper";
import { tokenService } from "./../../../../_services/tokens.services";
import { config } from "./../../../../config/config";


function SellOrderModal({ data }: { data: any }) {
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { setToastObj } = useContext(AuthContext);
  const mutation: any = useMutation(
    () => {
      const { configheadersTemplate } = tokenService;
      let configheaders = configheadersTemplate();
      let url = `${config.apiurl}/userInfo/completeKYC`;
      let formData = new FormData();
      formData.append("username", data.username);
      return axios.put(url, formData, configheaders);
    },
    {
      onSuccess: (data, variables, context) => {
        setToastObj({
          message: `Confirmed`,
          type: 'success'
        });
        queryClient.invalidateQueries(["AdminUser", fetchParams]);
      },
      onError: (error: any, variables, context) => {
        const errorMessage = error?.response?.data?.message;
        setToastObj({
          message: errorMessage || `Failed to confirm`,
          type: 'error'
      });
      },
    }
  );
  return (
    <Button
      className="main-color-btn main-color-smbtn"
      onClick={() => mutation.mutate()}
      style={{ marginTop: -4, height: 36 }}
    >
      Confirm { data.accountType === "Individual" ? "KYC" : "KYB"}
    </Button>
  );
}

export default SellOrderModal;
