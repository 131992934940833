import cn from 'classnames';

import styles from './FrankieSmartUI.module.scss';

interface props {
  isFrankieSmartUILoaded: boolean;
  isFrankieSmartUILoading: boolean;
  onClose: () => void;
}

const FrankieSmartUI: React.FC<props> = ({
  isFrankieSmartUILoaded = false,
  isFrankieSmartUILoading = false,
  onClose,
}) => {
  return (
    <div
      className={cn(
        styles.Modal,
        (isFrankieSmartUILoading || isFrankieSmartUILoaded) && styles.active
      )}
    >
      <div className={styles.Backdrop} onClick={onClose}>
        <div className={styles.load}>
          <p>Loading</p>
          <div className={styles.loader} />
        </div>
      </div>
      <div
        className={cn(
          styles.Main,
          !isFrankieSmartUILoading && isFrankieSmartUILoaded && styles.active
        )}
      >
        <section>
          <button type="button" onClick={onClose}>
            Close
          </button>
          <ff-onboarding-widget />
        </section>
      </div>
    </div>
  );
};

export default FrankieSmartUI;
