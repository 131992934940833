import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import moment from "moment";
import { FilterRow, TableContainer } from "../../Utils/FilterRow";
import { ProgressMovingBar } from "../../Utils/ProgressMovingBar";
import { formatNumber } from "../../Utils";
import Pagination from "../../Utils/Pagination";
import Loader from "../../Loader";
import { config } from "../../../config/config";
import ContextWrapper, { Context } from "../../Utils/ContextWrapper";
import { AdminContext } from "../AdminContextWrapper";
import { adminService,userService } from "../../../_services";
import CopyTool from "../../Utils/CopyTool";
// Specific to this page      v
const QUERY_INDEX = "AdminTrade";
const URL = `${config.apiurl}/otc/trade/list?house=true`;
// const { export_data } = useExportData();
interface FetchParams {
  startDate?: string;
  endDate?: string;
  pageNum?: number;
  io?: string;
  account?: string;
  type?: string;
  status?: string;
  limit: number;
  house?: boolean;
  sortOrder: string;
  sortBy: string;
}
const initialFetch = {
  pageNum: 1,
  sortBy: "timestamp",
  sortOrder: "desc",
  limit: 20,
};
// End of specific to this page

const Index = () => {
  const [count, setCount] = useState(0);
  const [fetchParams, setFetchParams] = useState<FetchParams>(initialFetch);

  return (
    <>
      <ContextWrapper
        fetchParams={fetchParams}
        setFetchParams={setFetchParams}
        query_index={QUERY_INDEX}
        url={URL}
      >
        <Table ></Table>
      </ContextWrapper>
    </>
  );
};
export default Index;

const Table = () => {
  const {
    listIsLoading,
    listError,
    listData,
    export_data,
    fetchParams,
    setPageNum,
    setFetchParams,
  } = useContext(Context);

  const { count, totalCount } = listData || {};
  const [currentPage, setCurrentPage] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [settleId, setsettleId] = useState() as any;
  const { getTradeList } = useContext(
    AdminContext
  );
  useEffect(() => {
    if (listData?.data ) {
      setCurrentPage(listData?.data);
      setInitialized(true);
    }
  }, [listData?.data]);
  const [showSentCode, setShowSentCode] = useState(false);
  function refreshPage() {
    window.location.reload();
  }
  const getTradeList1= ()=>{
    adminService
      .get_tradeList(fetchParams)
      .then(function (response: any) {
        setCurrentPage(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  const settlement = ()=>{
      //   let  link:any = document.getElementById('admin-trade-tab');
      // link.click();
    userService.settlement(settleId)
      .then(function (response: any) {
        setShowSentCode(false)
        getTradeList1()
       })
      .catch((error) => {
        console.log(error);
      });
  }
  const opensettle = (id:any) => {
    setsettleId(id)
    setShowSentCode(true);
  };
  const handleCloseSent = () => {
    setShowSentCode(false);
  }; 
  return (
    <>
      {/* {JSON.stringify(listData)} */}
      <div className="">
        <div>
          <div>
            <div>
              <div className="">
                <div className="border_box" style={{}}>
                  <FilterRow
                    export_data={export_data}
                    searchParams={fetchParams}
                    startDate={{
                      value: fetchParams.startDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          startDate: val,
                        });
                      },
                    }}
                    endDate={{
                      value: fetchParams.endDate,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          endDate: val,
                        });
                      },
                    }}
                    limit={{
                      value: fetchParams.limit,
                      set: (val: any) => {
                        setFetchParams({
                          ...fetchParams,
                          limit: val,
                          pageNum: 1,
                        });
                      },
                    }}
                    clearFilter={{
                      set: (val: any) => {
                      setFetchParams(
                          initialFetch
                      )}
                  }}
                    // side={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       side: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // market={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       market: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // insert={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       asset: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // exportCsv={{}}
                    // exportPdf={{}}
                    // source={{
                    //   set: (val: any) => {
                    //     setFetchBalanceMetalParams({
                    //       ...fetchBalanceMetalParams,
                    //       source: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                    // location={{
                    //   set: (val: any) => {
                    //     setFetchParams({
                    //       ...fetchParams,
                    //       country: val,
                    //       pageNum: 1,
                    //     });
                    //   },
                    // }}
                  ></FilterRow>

                  <div className="table-responsive">
                    <Loader visible={listIsLoading}></Loader>
                    <table
                      cellPadding="0"
                      cellSpacing="0"
                      className="main-table trade_info_container"
                    >
                      <colgroup>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col></col>
                        <col style={{ width: "10px" }}></col>
                        <col></col>
                        <col></col>
                      </colgroup>

                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Account</th>
                          <th>Market</th>
                          <th>Side</th>
                          <th>Base Quantity</th>
                          <th>Quote Quantity</th>
                          <th>Price</th>
                          <th>Spread Fees</th>
                          <th>Trade Amount on Exchange</th>
                          <th>Price on Exchange</th>
                          <th>Settlement</th>
                          <th>Settlement Time</th>
                          <th>Trade ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!initialized && listIsLoading ? (
                          <tr>
                            <td colSpan={8}>
                              {/* <ProgressMovingBar></ProgressMovingBar> */}
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPage?.length === 0 && (
                              <tr>
                                <td style={{ color: "#ffcb17" }} colSpan={10}>
                                  No rows found. Try clearing your filters.
                                </td>
                              </tr>
                            )}
                            {currentPage?.map((data: any, index: any) => {
                              const {
                                fee,
                                quantity,
                                price,
                                timestamp,
                                makerName,
                                productName,
                                side,
                                id,
                                settleTime,
                                quotePrice,
                                requestSize,
                                requestAsset,
                                profit,
                                profitAsset,
                                quoteQuantity
                              } = data;
                              let Fee: any = 0;
                              if (fee) {
                                Fee = fee;
                              }
                              var t = new Date(timestamp);
                              const myMoment = moment(t).format(
                                "YYYY-MM-DD hh:mm:ss"
                              );
                              var setTime = new Date(settleTime);
                              const myMomenta = moment(setTime).format(
                                "YYYY-MM-DD hh:mm:ss"
                              );
                      
                              return (
                                <tr className="trade_row" key={index}>
                                  <td>{myMoment}</td>
                                  <td>{makerName}</td>
                                  <td>{productName}</td>
                                  <td>{side}</td>
                                  <td>{formatNumber(quantity)}</td>
                                  <td>{formatNumber(quoteQuantity)}</td>
                                  <td>{formatNumber(price)}</td>
                                  <td>{`${formatNumber(profit)} ${profitAsset}`}</td>
                                  <td>{`${formatNumber(requestSize)} ${requestAsset}`}</td>
                                  <td>{formatNumber(quotePrice)}</td>
                                  {settleTime?( <td style={{color:"#00c7d3"}}>Settled</td>):(<td><button style={{ width: "100%" }} type="button" className="btn btn-confirm" onClick={()=>opensettle(id)}>Settle</button> </td>)}
                                  {settleTime?( <td>{myMomenta}</td>):(<td></td>)}                                
                                  <td><CopyTool text={id} digit={6}/></td>
                                </tr>
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="custom_table">
                    <TableContainer isLoading={initialized && listIsLoading}>
                      <table className="table"></table>
                      {count !== 0 && (
                        <div className={"col-md-11"}>
                          <Pagination
                            onChange={(page: any) => {
                              setPageNum(page);
                            }}
                            count={fetchParams.limit}
                            totalCount={totalCount}
                          ></Pagination>
                        </div>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        style={{
          transform: "translate(0, 50%) !important",
        }}
        show={showSentCode}
        onHide={handleCloseSent}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
         
          {/* <h1 style={{ color: "green" }}>Congratulation</h1> */}
          <p style={{ color: "black" }}>
         Are you sure you want to confirm settled?
          </p>
          <div className="form-group row">
                        <div className="col-sm-12">
                        <button type="button" className="btn btn-cancel mr-2" onClick={handleCloseSent}>Cancel</button>
                        <button type="button" className="btn btn-confirm"   onClick={settlement} > Confirm</button> 
                        </div>
                      </div>
        </Modal.Body>
        <Modal.Footer>
      
        </Modal.Footer>
      </Modal>
    </>
  );
};

function setCount(arg0: any) {
  throw new Error("Function not implemented.");
}

