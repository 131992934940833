import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs";
import { TransferDetail } from "../../../Utils/TransferDetail";

function ReasonModal({ data }: { data: any }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button
        className="btn-dark-border row-ele-center btn-large"
        onClick={handleShow}
      >
        <BsQuestionCircle/>&nbsp; &nbsp; 
        <strong>Reason</strong>
      </Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title">
              Reject reason
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex-column">
            <TransferDetail data={data} role="reason"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className={"main-color-btn full-btn"}
            onClick={handleClose}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReasonModal;