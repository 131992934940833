import React, { useEffect, useState } from "react";
import { RiArrowUpSLine, RiArrowDownSFill } from "react-icons/ri";

export const TableHeadFilter = ({ value, currentOrder, set } :  FilterRowInterface ) => {
    const [activeStatus, setActiveStatus] = useState(false as boolean);
    function handleOrderBtn (val: any){
        setActiveStatus(!activeStatus)
        let orderVal = activeStatus ? "asc" : "desc"
        set(value, orderVal)
    };
    useEffect(() => {
        if(currentOrder !== value){
            setActiveStatus(!activeStatus)
        }
    }, [currentOrder]);
    return (
        <button
            type="button"
            onClick={()=>handleOrderBtn(value)}
            className="tool-container tableFilter"
        >
            {activeStatus ? <RiArrowDownSFill/> : <RiArrowUpSLine/>}
        </button>
    )
}
interface FilterRowInterface {
    value?: any;
    currentOrder?: any;
    set?: any;
}