import React from 'react';
import useProfile from './useProfile';
import useMarket from './useProfile';
type Props = {};
export const ProfileContext = React.createContext<any>({});
const ProfileContextWrapper: React.FC<Props> = ({ children }) => {
  const {
    userInfo,
    myparams_bank,
    SetParamsBank,
    saveBankAsset,
    bankAccountName,
    handleChange_bankname,
    add_bank,
    ProductList,
    myparams_crypto,
    SetParamsCrypto,
    address,
    phone,
    country,
    saveCryptoAsset,
    addcrypto,
    password,
    setpassword,
    passwordErr,
    setCpassword,
    CpasswordErr,
    chanePassword,
    delete_bank,
    delete_crypto,
  } = useProfile();
  return (
    <ProfileContext.Provider
      value={{
        delete_bank,
        delete_crypto,
        chanePassword,
        password,
        setpassword,
        passwordErr,
        setCpassword,
        CpasswordErr,
        addcrypto,
        userInfo,
        myparams_bank,
        SetParamsBank,
        saveBankAsset,
        bankAccountName,
        handleChange_bankname,
        add_bank,
        ProductList,
        saveCryptoAsset,
        myparams_crypto,
        SetParamsCrypto,
        address,
        phone,
        country,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
export default ProfileContextWrapper;
