import React, { useContext, useState } from "react";
import { useAuth } from "../../../auth-context";
import ConfirmDeleteCrypto from "../ConfirmDeleteCrypto";
import CryptoAddressModal from "../CryptoAddressModal";
import { ProfileContext } from "../ProfileContextWrapper";

const Index = () => {
  const { userInfo } = useAuth();
  const [currentSelectedCrypto, setCurrentSelectedCrypto] = useState(null);
  return (
    <>
      <div className="white-shadowbox manage-crypto-address">
        <div className="padding20">
          <h3 className="section-subheading section-subheading2">
            Manage Crypto Address
          </h3>
          <p>
            The following addresses are used by CatchOTC to send you funds.
            Please note that adding the address for a given currency will
            require a confirmation to get the address approved. Transfers will
            take more time than usual for new addresses since a manual approval
            procedure and a test TX will be required on our side.
          </p>
          <p>
            <b>
              When adding a new address, a 24 hours cooldown will be applied for
              the security reasons, meaning no transaction will be sent to that
              address before 24 hours.
            </b>
          </p>
          <p>
            Please ensure that addresses provided are <u>your own</u> wallets.
          </p>
          <div className="table-responsive">
            {/* <pre>

            {JSON.stringify(userInfo.addressList, undefined, 2)}
            </pre> */}
            <table cellPadding="0" cellSpacing="0" className="main-table">
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Address Suffix</th>
                  <th>Address</th>
                  <th style={{ width: 100 }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {userInfo?.addressList &&
                  Object.keys(userInfo.addressList)
                    .filter((address: any) => {
                      return userInfo.addressList[address].type !== "deposit";
                    })
                    .map((key) => {
                      const { type, asset, name, address, id } =
                        userInfo.addressList[key];
                      return (
                          <tr key={key}>
                            <td>{asset.name}</td>
                            <td>{name}</td>
                            <td>{address}</td>
                            <td>
                              <a
                                onClick={() => {
                                  setCurrentSelectedCrypto(
                                    userInfo.addressList[key]
                                  );
                                }}
                                className="border-btn border-black-btn"
                                data-toggle="modal"
                                data-target="#confirm-delete-crypto-popup"
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
 
                      );
                    })}
              </tbody>
            </table>
          </div>
          <div id={"cryptoaddress"} className="text-center margintop40">
            <a
              className="main-color-btn main-color-bigbtn"
              data-toggle="modal"
              data-target="#add-new-crypto-popup"
            >
              Add New Crypto Address
            </a>
          </div>
        </div>
      </div>
      <CryptoAddressModal></CryptoAddressModal>
      <ConfirmDeleteCrypto
        currentSelectedCrypto={currentSelectedCrypto}
      ></ConfirmDeleteCrypto>
    </>
  );
};
export default Index;
