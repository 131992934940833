import { useContext, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { Context } from "../../../Utils/ContextWrapper";
import { AuthContext } from "../../../../contexts/AuthContext";
import { tokenService } from "../../../../_services/tokens.services";
import { config } from "../../../../config/config";
import { AiOutlineDelete } from "react-icons/ai";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { capitalize } from "../../../Utils";

type DeleteFeeProps = {
  wholeData: any;
  index: number;
  type: string;
  currentAsset: string;
  feeData: {
    asset: string;
    upper: number;
    lower: number;
    value: number;
  };
  fetchTransferFeeHelper: any;
}
const DeleteWithdrawalFeeModal = ({ type, feeData, currentAsset, index, wholeData, fetchTransferFeeHelper }: DeleteFeeProps) => {
  const { asset, lower, upper, value } = feeData;
  const [transactionFeeAll, setTransactionFeeAll] = useState<any>([]);
  const { fetchParams } = useContext(Context);
  const queryClient = useQueryClient();
  const { configheadersTemplate } = tokenService;
  let configheaders =  configheadersTemplate();
  const { setToastObj } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () =>{
    setShow(false);
  };
  function handleADeleteFeeBtn(){
    setTransactionFeeAll(wholeData);
    handleShow();
  };
  function handleSubmit (){
    setTransactionFeeAll(transactionFeeAll.splice(index, 1));
    deleteFeeMutation.mutate(transactionFeeAll);
  };
  const deleteFeeMutation: any = useMutation(
    (oid: string) => {
      let url = `${config.apiurl}/transfer/fee/${type}/default/asset/${currentAsset}`;
      let formData = new FormData();
      transactionFeeAll?.forEach((element: any)=>{
        Object.entries(element)
          .forEach(([key, value] : any) =>{
            let stringizeVal = value.toString()
            formData.append(key, stringizeVal)
          })
      })
      return axios.post(
        url,
        formData,
        configheaders
      );
    },
    {
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries(["AdminDeleteFee", fetchParams]);
        await fetchTransferFeeHelper(type);
        setToastObj({
          message: `${capitalize(type)} Fee Deleted`,
          type: 'success'
        });
        handleClose();
      },
      onError: (data, variables, context) => {
        queryClient.invalidateQueries(["AdminDeleteFee", fetchParams]);
        setToastObj({
          message: `Failed to delete ${type} Fee`,
          type: 'error'
        });
        handleClose();
      },
    }
  );
  return (
    <>
      <OverlayTrigger
        key={`delete-${type}-${currentAsset}-fee`}
        placement="right"
        overlay={
          <Tooltip id={`delete-${type}-${currentAsset}-fee`}>
            Delete {currentAsset} {type} fee.
          </Tooltip>
        }
      >
        <button 
          className='row-ele-center btn-icon setting_btn_icon'
          onClick={handleADeleteFeeBtn}
        >
          <AiOutlineDelete/>
        </button>
      </OverlayTrigger>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <strong>
              Are you sure you want to delete this {type} fee ?
            </strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-title">
        <Table responsive borderless={true}>
          <thead >
            <tr>
              <th>Asset</th>
              <th>Range</th>
              <th>Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{asset}</th>
              <th>{lower} - {upper}</th>
              <th>{value}</th>
            </tr>
          </tbody>
          </Table>
          <div className="btn-container">
            <Button
              type="button"
              className="btn-full-dark"
              onClick={handleClose}
              >
              Cancel
            </Button>
            <Button
              type="button"
              className="btn-full-light"
              onClick={handleSubmit}
            >
              {deleteFeeMutation.isLoading ? <>Submitting...</> : <>Yes</>}
           </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DeleteWithdrawalFeeModal;
