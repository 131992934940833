import awsexports from "../config/aws-exports";
import { Auth } from "aws-amplify";
const clientID = awsexports.aws_user_pools_web_client_id;
const accessToken = "accessToken";
const lastAuthUser = "LastAuthUser";


function configheadersTemplate (){
  Auth.currentSession()
  let currentUserID = localStorage.getItem(`CognitoIdentityServiceProvider.${clientID}.${lastAuthUser}`);
  let accessURL = `CognitoIdentityServiceProvider.${clientID}.${currentUserID}.${accessToken}`;
  let currentAccessToken = localStorage.getItem(accessURL);
  const configheaders = {
    headers:{ 
      "Content-Type": "application/x-www-form-urlencoded", "Authorization": `Bearer ${currentAccessToken}`
    },
    withCredentials: true, crossDomain: true,
  }
  return configheaders;
}

export const tokenService = {
  configheadersTemplate
}